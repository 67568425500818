import { SVGProps } from 'react';

const LogoSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" {...props}>
    <path
      d="M17.733 26.86c0-.258.224-.483.48-.483h6.407c.256 0 .48.225.48.482a.493.493 0 0 1-.48.482h-6.406a.493.493 0 0 1-.48-.482Z"
      fill="#09091A"
    />
    <path
      d="M26.35 29.333h-9.866c-1.954 0-3.556-1.606-3.556-3.566V6.233c0-1.96 1.602-3.566 3.556-3.566h9.865c1.954 0 3.556 1.606 3.556 3.566v19.534c0 1.96-1.602 3.566-3.556 3.566ZM16.483 3.63a2.588 2.588 0 0 0-2.595 2.603v19.534a2.588 2.588 0 0 0 2.595 2.602h9.865a2.588 2.588 0 0 0 2.595-2.602V6.233a2.588 2.588 0 0 0-2.595-2.603h-9.865Z"
      fill="#09091A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.006 18.345c.032.065.096.193.128.29 0 .032.032.064.032.128v-.771c0 .032-.032.064-.032.096-.032.064-.064.193-.128.257ZM2.038 17.863c0 .033-.032.097-.032.161v.096c.032-.032.032-.064.032-.096.032-.032.032-.096.064-.16 0-.033.032-.097.032-.13 0-.063 0-.128-.032-.192 0 .032-.032.064-.032.129 0 .064-.032.128-.032.192ZM2.07 18.666c0-.032-.032-.064-.064-.096v.096c0 .065.032.097.032.161.032.064.032.129.064.193 0 .032.032.096.032.128 0-.064 0-.128.032-.16 0-.032-.032-.097-.032-.129-.032-.096-.064-.16-.064-.193ZM1.846 17.831c0-.225-.032-.45-.032-.674v-.225c-.064.064-.096.192-.128.385 0 .032.032.064.032.097.032.128.096.257.128.417ZM1.974 18.795v.9c.064-.065.096-.193.128-.386 0-.032-.032-.064-.032-.096-.032-.097-.064-.257-.096-.418ZM1.974 17.157v.674c0-.128.064-.257.096-.385 0-.032.032-.065.032-.097a1.03 1.03 0 0 0-.16-.417c.032.096.032.16.032.225ZM1.718 19.18c0 .033-.032.065-.032.097.032.193.096.321.16.418v-.9c-.032.16-.064.29-.128.386ZM1.75 18.763c0-.032.032-.096.032-.16v-.097c-.032.032-.032.064-.032.096-.032.032-.032.097-.064.161 0 .032-.033.096-.033.129 0 .064 0 .128.033.192 0-.032.032-.064.032-.128 0-.032.032-.097.032-.193ZM1.782 18.313c-.032-.064-.097-.193-.129-.289 0-.032-.032-.064-.032-.129v.772c0-.033.032-.065.032-.097.032-.096.097-.193.129-.257ZM1.75 17.992c0 .032.032.064.064.096v-.096c0-.064-.032-.096-.032-.16-.032-.065-.032-.13-.064-.193 0-.032-.032-.097-.032-.129 0 .064 0 .129-.033.16 0 .065 0 .097.033.13 0 .063.032.128.064.192ZM1.91 17.767c0-.064 0-.16.032-.225v-.61h-.064v.61c0 .064 0 .129.032.225-.032 0-.032 0 0 0Z"
      fill="#09091A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.91 19.116v.611h.064v-.257c-.032-.129-.064-.225-.064-.354ZM8.22 20.466h-.064c0 .096 0 .193-.032.321V21.59h.128V20.787c-.032-.096-.032-.225-.032-.321ZM8.572 20.209c-.032-.032-.064-.097-.128-.129 0 .032 0 .065-.032.097.032.064.064.16.096.224.064.097.096.193.16.29.032.064.064.128.128.16.032-.064.032-.16.064-.225l-.096-.192c-.096-.097-.128-.161-.192-.225ZM8.348 20.401c.032.29.032.611.032.9v.29c.128-.065.256-.226.352-.483-.032-.032-.064-.096-.096-.128a2.433 2.433 0 0 1-.288-.579ZM8.476 19.759c.096.096.224.257.32.418.032.064.064.096.096.16.032-.16.032-.353.032-.514 0-.16 0-.353-.032-.514-.032.032-.064.097-.096.129a1.51 1.51 0 0 1-.32.321ZM8.508 19.148l-.096.193c0 .032.032.064.032.129.032-.032.064-.064.128-.097.064-.064.128-.128.16-.192.032-.065.064-.097.096-.161a.465.465 0 0 0-.064-.225c-.032.032-.064.096-.096.16-.064.033-.096.097-.16.194ZM8.188 18.988c0 .032 0 .032 0 0 .032-.097.032-.193.032-.322V17.863h-.128v.804c.064.128.064.224.096.32ZM8.348 18.217c0 .289 0 .61-.032.9.096-.193.192-.354.288-.483.032-.032.064-.096.096-.128-.096-.257-.224-.45-.352-.546v.257ZM8.028 19.084c-.032-.289-.032-.61-.032-.9v-.289c-.129.065-.257.225-.353.482.032.033.064.097.096.129.128.193.225.386.289.578ZM7.803 19.277c.032.032.064.096.128.128 0-.032 0-.064.032-.096-.032-.064-.064-.16-.096-.225-.064-.096-.096-.192-.16-.289-.032-.064-.064-.128-.128-.16-.032.064-.032.16-.064.224l.096.193c.096.064.128.16.192.225ZM7.771 20.851c-.032.032-.064.097-.096.129.096.257.224.45.352.546v-.29c0-.288 0-.61.033-.899a2.437 2.437 0 0 1-.289.514ZM7.867 20.337l.096-.192c0-.033-.032-.065-.032-.129a.442.442 0 0 1-.128.096c-.064.065-.128.129-.16.193s-.064.096-.096.16c0 .097.032.161.064.226a.7.7 0 0 0 .096-.161c.064-.032.096-.129.16-.193ZM7.9 19.695a2.355 2.355 0 0 1-.321-.418c-.032-.064-.064-.096-.096-.16-.032.16-.032.353-.032.513 0 .161 0 .354.032.515.032-.033.064-.097.096-.129l.32-.321Z"
      fill="#09091A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.444 16.803c-.192.064-.32.16-.385.16-.128.033-.96-.096-1.057-.192-.096-.096-.48-1.157-.48-1.285 0-.129.064-.129.096-.16 0 0 .064 0 .8.031.193 0 .577.065 1.026.097v-2.731c-1.09.032-2.114.064-2.53.096-.93.064-1.154.097-1.314-.064-.192-.16-.32-2.281-.32-2.41 0-.225.256-.321.416-.321.128 0 1.826-.16 3.748-.193v-.739h-1.217c-1.858 0-3.62.193-3.62.193s-.929.096-1.537.321c-.609.225-1.025.707-1.378 1.093-.32.353-1.249 1.638-1.377 1.863 0 .032-.032.032-.032.032-.064.032-.256.16-.48.322-.289.192-.545.385-.577.642-.032.29-.16 1.092-.224 1.221s-.224.193-.32.578c-.097.354-.065 1.157-.065 1.253v.032c0 .033 0 .129-.064.45-.064.418-.096 1.382 0 2.378.096.996.449 1.381.609 1.446.16.064 1.826.032 1.954-.097.096-.096.224-.418.288-.61.032-.064.096-.129.192-.097L7 20.787c.064 0 .096.064.096.097.032.096.064.256.192.578.192.514.448 1.06 1.025 1.253.544.192 2.242.128 2.466.064.224-.096.288-.193.449-.514.128-.225.224-.514.256-.675.032-.064.064-.096.128-.096h.833v-4.69ZM2.066 20.048c-.192-.032-.385-.803-.385-1.767 0-.964.16-1.703.353-1.703.192.032.384.803.384 1.767 0 .964-.16 1.735-.352 1.703Zm1.633-7.325c-.096 0-.192-.032-.192-.032s.128-.225.385-.643c.256-.418.928-1.189.928-1.189s-.224.386-.352.61c-.096.194-.48.9-.608 1.157a.176.176 0 0 1-.16.097Zm1.282.128-.801-.064c-.096 0-.16-.128-.128-.225.16-.321.48-.996.704-1.35.289-.513.449-.706.449-.706s.256-.257.64-.418c.385-.16.545-.16.577-.192.032 0 .032.032.032.032s-.448.77-.705 1.413a26.174 26.174 0 0 0-.608 1.446.244.244 0 0 1-.16.064Zm2.274-.546c-.256 0-1.217.032-1.281.064-.064.033-.097.29-.129.45 0 .064-.064.129-.16.129s-.16-.097-.128-.193c0-.064.032-.16.064-.257.096-.418.16-.578.513-1.446.256-.61.416-.932.512-1.06.032-.032.064-.064.128-.064H7c.16 0 .352.128.48.417.128.257.16 1.318.16 1.575 0 .032 0 .064-.032.096l-.192.29c-.064-.033-.128 0-.16 0Zm1.089 9.703c-.48-.064-.897-1.124-.897-2.378 0-1.253.384-2.249.865-2.184.48.064.897 1.124.897 2.377.032 1.253-.353 2.25-.865 2.185Z"
      fill="#09091A"
    />
    <path
      d="M15.655 15.968c.096-.096.096-.096.064-.129 0 0-.576-.064-1.281-.128v.9h.096c.32-.033 1.025-.547 1.121-.643ZM23.15 15.807c-.064 0-.512-.096-1.665-.096-.513 0-1.154 0-1.986.064l-2.627.129h-.032c-.064 0-.224.064-.448.257-.289.225-.48.385-.513.482-.032.096 0 .257 0 .289 0 .032.929 2.634 1.025 2.795.096.16.128.225.192.257.032.032.897.032 1.826.032.673 0 1.41 0 1.89-.032 1.185-.064 2.498-.129 2.69-.193.16-.032.257-.16.289-.321.032-.16.288-.739.384-1.542.096-.803.064-1.253.064-1.285-.032-.033-.192-.322-.576-.579-.417-.225-.417-.225-.513-.257Zm.417 2.056a4.524 4.524 0 0 1-.32 1.286c0 .032 0 .032-.033.064-.48.032-1.922.128-2.434.16-.417.033-1.09.033-1.858.033-.673 0-1.217 0-1.505-.032-.16-.418-.801-2.12-.93-2.539.065-.032.129-.096.257-.192.096-.065.16-.097.224-.129l2.595-.128c.736-.032 1.377-.065 1.954-.065 1.057 0 1.473.065 1.505.065.032 0 .096.064.32.16.16.097.257.193.32.257-.031.193-.031.514-.095 1.06Z"
      fill="#09091A"
    />
    <path
      d="M27.442 18.024a.674.674 0 0 0-.128-.193v-.16a4.45 4.45 0 0 0-.256-1.51c-.352-1.093-.577-1.35-.673-1.446-.256-.29-.865-.675-1.473-.996-.32-.16-.993-.418-1.57-.61v-.161c0-.322-.096-.546-.32-.74-.096-.063-.288-.288-1.505-.288-.096 0-.16 0-.257.032-.288-.29-.704-.675-1.089-1.028-.672-.61-1.185-.9-1.441-1.028-.032-.033-.096-.033-.128-.065-.288-.16-.769-.32-1.442-.45-.128-.032-.48-.096-2.722-.256h-.032v.61c.993.064 2.274.16 2.626.225.609.128 1.025.257 1.25.385.224.129.704.322 1.473 1.028a36.749 36.749 0 0 1 1.537 1.446h.064s0-.257.064-.257h.129c.288 0 .993.032 1.057.097.064.064.096.128.128.32 0 .194-.064.194-.16.226-.064.032-.545.096-.545.096s2.018.643 2.627.932c.608.29 1.12.675 1.28.867.193.193.417.836.513 1.221.353.996.16 1.607.32 1.864 0 0 .065 0 .097.096.128.225.064 2.153-.256 2.25-.065.031-.129.031-.129.031s-.16.514-.448.74c-.288.224-1.153.353-1.762.353h-.064c-.576 0-1.057-.065-1.345-.257-.224-.129-.384-.354-.448-.45-.033-.032-.065-.064-.129-.064-.576.032-4.132.16-5.413.192-.544 0-1.505.032-2.466.032v.61c1.153 0 1.986-.031 2.466-.031 1.153-.032 4.132-.129 5.19-.16.127.16.287.288.48.417.384.225.96.353 1.697.353h.064c.417 0 1.634-.096 2.146-.514.257-.193.417-.514.545-.739.128-.064.256-.193.352-.418.192-.546.32-2.12.096-2.602Z"
      fill="#09091A"
    />
    <path
      d="M18.797 11.31c-.48-.418-.96-.772-1.185-.772-.224-.032-1.124-.128-3.174-.193v.61c1.986.065 2.79.162 3.078.194.064.032.288.128.865.642.16.16.416.386.672.61-1.056-.031-1.797-.096-2.757-.096H14.406v.61c.544 0 .993-.031 1.345-.031h.513c1.537 0 3.814.128 4.039.128.032 0 .096-.032 0-.129-.032-.128-1.057-1.156-1.506-1.574ZM24.784 16.354c.128.032.769.032.929.064.16.064.288.225.416.225.096 0 .288-.129.288-.161.064-.16-.416-1.189-.512-1.317h-.064c-.032 0-1.762.546-1.794.546-.032 0-.064.032-.064.064 0 .064.673.546.8.579Z"
      fill="#09091A"
    />
  </svg>
);

export default LogoSmall;
