import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '.';

export type Loads = [];
export type LoadData = {};

export type Filters = {
  type: string;
  sortBy: string;
  search?: string;
  driverId?: string;
};

export type LoadDetails = {
  load_id: string;
  internal_load_id: string;
  inspection_type: string;
  driver_instructions: string;
};

export type Driver = {
  id?: number;
  first_name?: string;
  last_name?: string;
};

export type Pickup = {
  type?: string;
  business_name: string;
  street_address: string;
  city: string;
  state: string;
  zip?: string;
  date: string;
  buyer_number?: string;
  notes?: string;
  full_name?: string;
  phone?: string;
  email?: string;
};

export type Delivery = {
  type?: string;
  business_name: string;
  street_address: string;
  city: string;
  state: string;
  zip?: string;
  date: string;
  notes?: string;
  full_name?: string;
  phone?: string;
  email?: string;
};

export type Customer = {
  business_name: string;
  street_address: string;
  city: string;
  state: string;
  zip?: string;
  full_name?: string;
  phone?: string;
  email?: string;
  fax?: string;
};

export type Payment = {
  price: number | null;
  method: string;
  driver_pay?: number | null;
  notes?: string;
  broker_fee?: string;
  paid_amount?: string;
  date: string;
  reference_number?: string;
  invoice_id?: string;
  invoice_notes?: string;
};

export type Vehicle = {
  vehicle_id?: string;
  type: string;
  vin: string;
  year: string;
  make: string;
  model: string;
  color: string;
  lot_number: string;
  price: number | null;
  inop: boolean;
  enclosed: boolean;
};

export type Expense = {
  id?: number;
  expense_type_id: number;
  price: string;
  date: string;
  specify_type: string;
  images: string[];
  invoice: boolean;
  driver_pay: boolean;
};

export type VehicleList = Vehicle[] | [];

export type LoadItem = {
  id?: number;
  load_id: string;
  internal_load_id: string;
  inspection_type: string;
  driver_instructions?: string;
  internal_notes?: string;
  status?: string;
  points: [Pickup, Delivery];
  customer: Customer;
  payment: Payment;
  vehicles: VehicleList;
  attachments?: string[];
  driver?: Driver;
};

export type LoadId = {
  id: number;
};

export const routes = {
  loads: 'api/crm/loads',
  assignLoad: 'api/crm/loads/assign-driver',
  loadForData: 'api/crm/loads/create',
  updatePoint: 'api/crm/loads/update-point',
  updateVehicle: 'api/crm/loads/update-vehicle',
  updatePayment: 'api/crm/loads/update-payment',
  updateCustomer: 'api/crm/loads/update-customer',
  updateDriverInstructions: 'api/crm/loads/update-driver-instructions',
  updateInternalNotes: 'api/crm/loads/update-internal-notes',
  detachVehicle: 'api/crm/loads/detach-vehicle',
  importLoads: 'api/crm/loads/import',
  attachments: 'api/crm/loads/attachments',
  updateLoadStatus: 'api/crm/loads/update-status',
  publicLoadData: 'api/public/loads',
};

export const getLoads = createAsyncThunk<
  Loads,
  Filters,
  {
    rejectValue: ErrorType;
  }
>('loads/getLoads', async (data, { rejectWithValue }) => {
  try {
    let reg = '&';
    if (data.search) {
      reg += `filters[keywords]=${data.search}&`;
    }
    if (data.driverId) {
      reg += `filters[driver]=${data.driverId}&`;
    }
    const response = await api.get(
      `${routes.loads}?filters[type]=${data.type}&filters[sort_by]=${data.sortBy}${reg.slice(0, -1)}`
    );
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getLoadData = createAsyncThunk<
  LoadItem,
  number,
  {
    rejectValue: ErrorType;
  }
>('loads/getLoadData', async (id, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.loads}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateLoadStatus = createAsyncThunk<
  null,
  [status: string, id: number],
  {
    rejectValue: ErrorType;
  }
>('loads/updateLoadStatus', async ([status, id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updateLoadStatus}`, {
      status: status,
      load_id: id,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const addLoad = createAsyncThunk<
  LoadItem,
  FormData,
  {
    rejectValue: ErrorType;
  }
>('loads/addLoad', async (createData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.loads}`, createData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const assignLoad = createAsyncThunk<
  null,
  [driverId: number, id: number],
  {
    rejectValue: ErrorType;
  }
>('loads/assignLoad', async ([driverId, id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.assignLoad}`, {
      driver_id: driverId,
      load_id: id,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const removeDriver = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>('loads/removeDriver', async (id, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.loads}/${id}/remove-driver`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getDataForLoad = createAsyncThunk<
  LoadData,
  null,
  {
    rejectValue: ErrorType;
  }
>('loads/getDataForLoad', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.loadForData}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getPublicDataLoad = createAsyncThunk<
  { id: number },
  { load_uuid: string },
  {
    rejectValue: ErrorType;
  }
>('loads/getPublicDetails', async ({ load_uuid }, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.publicLoadData}/${load_uuid}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateLoadDetails = createAsyncThunk<
  null,
  [LoadDetails, number],
  {
    rejectValue: ErrorType;
  }
>('loads/updateLoadDetails', async ([data, id], { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.loads}/${id}`, {
      load_id: data.load_id,
      internal_load_id: data.internal_load_id,
      inspection_type: data.inspection_type,
      driver_instructions: data.driver_instructions,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updatePayment = createAsyncThunk<
  null,
  [Payment, number],
  {
    rejectValue: ErrorType;
  }
>('loads/updatePayment', async ([data, id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updatePayment}`, {
      price: data.price,
      method: data.method,
      driver_pay: data.driver_pay,
      notes: data.notes,
      broker_fee: data.broker_fee,
      paid_amount: data.paid_amount,
      date: data.date,
      reference_number: data.reference_number,
      invoice_id: data.invoice_id,
      invoice_notes: data.invoice_notes,
      load_id: id,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateCustomer = createAsyncThunk<
  null,
  [Customer, number],
  {
    rejectValue: ErrorType;
  }
>('loads/updateCustomer', async ([data, id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updateCustomer}`, {
      business_name: data.business_name,
      street_address: data.street_address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      full_name: data.full_name,
      phone: data.phone,
      fax: data.fax,
      email: data.email,
      load_id: id,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updatePickup = createAsyncThunk<
  null,
  [Pickup, number, boolean],
  {
    rejectValue: ErrorType;
  }
>('loads/updatePickup', async ([data, id, copy], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updatePoint}`, {
      type: data.type,
      business_name: data.business_name,
      street_address: data.street_address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      date: data.date,
      buyer_number: data.buyer_number,
      notes: data.notes,
      full_name: data.full_name,
      phone: data.phone,
      email: data.email,
      load_id: id,
      copy_to_customer: copy,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateDelivery = createAsyncThunk<
  null,
  [Delivery, number, boolean],
  {
    rejectValue: ErrorType;
  }
>('loads/updateDelivery', async ([data, id, copy], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updatePoint}`, {
      type: data.type,
      business_name: data.business_name,
      street_address: data.street_address,
      city: data.city,
      state: data.state,
      zip: data.zip,
      date: data.date,
      notes: data.notes,
      full_name: data.full_name,
      phone: data.phone,
      email: data.email,
      load_id: id,
      copy_to_customer: copy,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateDriverInstructions = createAsyncThunk<
  null,
  [id: number, text: string],
  {
    rejectValue: ErrorType;
  }
>('loads/updateDriverInstructions', async ([id, text], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updateDriverInstructions}`, {
      load_id: id,
      driver_instructions: text,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateInternalNotes = createAsyncThunk<
  null,
  [id: number, text: string],
  {
    rejectValue: ErrorType;
  }
>('loads/updateInternalNotes', async ([id, text], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updateInternalNotes}`, {
      load_id: id,
      internal_notes: text,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const addExpense = createAsyncThunk<
  null,
  [Expense, number],
  {
    rejectValue: ErrorType;
  }
>('loads/addExpense', async ([data, id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.loads}/${id}/expenses`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateExpense = createAsyncThunk<
  null,
  [Expense, number, number],
  {
    rejectValue: ErrorType;
  }
>('loads/updateExpense', async ([data, id, expenseId], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.loads}/${id}/expenses/${expenseId}`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const deleteExpense = createAsyncThunk<
  null,
  [number, number],
  {
    rejectValue: ErrorType;
  }
>('loads/addExpense', async ([id, expenseId], { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.loads}/${id}/expenses/${expenseId}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const addVehicle = createAsyncThunk<
  null,
  [Vehicle, number],
  {
    rejectValue: ErrorType;
  }
>('loads/addVehicle', async ([data, id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.loads}/${id}/create-vehicle`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateVehicle = createAsyncThunk<
  null,
  Vehicle,
  {
    rejectValue: ErrorType;
  }
>('loads/updateVehicle', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.updateVehicle}`, {
      vehicle_id: data.vehicle_id,
      type: data.type,
      vin: data.vin,
      year: data.year,
      make: data.make,
      model: data.model,
      color: data.color,
      lot_number: data.lot_number,
      price: data.price,
      inop: data.inop,
      enclosed: data.enclosed,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const detachVehicle = createAsyncThunk<
  null,
  {
    loadId: string;
    vehicleId: string;
  },
  {
    rejectValue: ErrorType;
  }
>('loads/detachVehicle', async ({ vehicleId = '', loadId = '' }, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.loads}/${loadId}/vehicles/${vehicleId}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const importLoads = createAsyncThunk<
  null,
  object,
  {
    rejectValue: ErrorType;
  }
>('loads/importLoads', async (importData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.importLoads}`, importData);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.data.message,
      errors: e.data.errors,
    } as ErrorType);
  }
});

export const attachAttachments = createAsyncThunk<
  null,
  FormData,
  {
    rejectValue: ErrorType;
  }
>('loads/attachAttachments', async (formdata, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.attachments}`, formdata);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const detachAttachments = createAsyncThunk<
  null,
  {
    attachmentId: string;
    loadId: string;
  },
  {
    rejectValue: ErrorType;
  }
>('loads/detachAttachments', async ({ attachmentId = '', loadId = '' }, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.loads}/${loadId}/attachments/${attachmentId}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const deleteLoad = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>('loads/deleteLoad', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.loads}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});
