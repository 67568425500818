import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 60%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const MapBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const DriverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DriverBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 7px;
`;

export const Drivers = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
`;

export const DriversLength = styled.span`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  margin: 12px auto;
  width: 95%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 16px;
  outline: none;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #f4f4f4;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Actions = styled.div`
  width: fit-content;
  display: flex;
`;

export const MapWrapper = styled.div`
  width: 75%;
  height: 700px;
  border-radius: 8px;
`;

export const ListWrapper = styled.div`
  height: 88%;
  overflow: auto;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  overflow: hidden;
  border-radius: 4px;
`;

export const DriverItem = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const ImageDefault = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9eaf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageName = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -1px;
  color: #09091a;
  text-transform: uppercase;
`;

export const Name = styled.p`
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #09091a;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
`;

export const DriverExtra = styled.div`
  width: 100%;
`;

export const DriverBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

export const DriverExtraBlock = styled.div`
    padding: 4px;
`;

export const DriverTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #9292A4;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const DriverData = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091A;
`;
