import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 30%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin: 0 23px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #f4f4f4;
`;

export const LineBlock = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #f4f4f4;

  &:last-child {
    display: none;
  }
`;

export const ScrollBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  height: 320px;
  padding: 0 32px;

  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemText = styled.div`
  display: flex;
  margin: 8px 0;
`;

export const RegularText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #333443;
  margin-left: 10px;
`;

export const BoldText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-left: 10px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 23px 0 0;
`;

export const Actions = styled.div`
  display: inline-flex;
  align-items: center;
`;
