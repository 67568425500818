import styled from 'styled-components';

export const Block = styled.div`
  margin: 0 auto;
  max-width: 600px;
`;

export const AuthWrapper = styled.div`
  margin: 40px auto 0;
  padding: 40px;
  background-color: #fff;
`;

export const Title = styled.div`
  margin-bottom: 40px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #09091a;
`;

export const Info = styled.div`
  margin: 20px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const InfoText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #0e71eb;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    line-height: 40px;
  }
`;

export const TitleBlue = styled.span`
  color: #0e71eb;
`;

export const AuthBlock = styled.div`
  position: relative;
  padding-bottom: 30px;
`;

export const Error = styled.span`
  font-family: 'Montserrat';
  position: absolute;
  bottom: 0;
  font-weight: 400;
  font-size: 14px;
  color: #f30f00;
`;

export const Agreement = styled.p`
  margin-top: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
`;

export const AgreementBold = styled.a`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  color: #09091a;
  cursor: pointer;
  text-decoration: none;
`;

export const Footer = styled.p`
  text-align: center;
  margin-top: 20px;
  padding-bottom: 16px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #9292a4;
  text-transform: uppercase;

  @media screen and (max-width: 375px) {
    width: 250px;
    margin: 20px auto 0;
  }
`;
