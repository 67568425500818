import * as React from "react"
import { SVGProps } from "react"

type IconProps = {
  isActive?: boolean;
};

const Reports: React.FC<IconProps> = ({ isActive }, props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path fill={isActive ? '#0E71EB' : '#9292A4'} d="M17 14h4v6h-4zM10 4h4v16h-4zM3 11h4v9H3z" />
  </svg>
)

export default Reports
