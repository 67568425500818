import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 40%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  margin: 0 23px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 12px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 23px;
  text-transform: uppercase;
`;

export const Block = styled.div`
  margin: 40px 23px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoItem = styled.div`
  width: 100%;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const ValueText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-left: 5px;
`;

export const Activity = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  min-height: auto;
  max-height: 690px;

  @media screen and (max-width: 1280px) {
    max-height: 500px;
  }

  @media screen and (max-width: 600px) {
    max-height: 400px;
  }
`;

export const ActivityScroll = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
`;

export const ActivityBlock = styled.div`
  padding: 16px 0 16px 25px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    z-index: 1;
    background: #0e71eb;
  }

  &:last-child::before {
    background: #c6c7da;
  }

  &:last-child::after {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 25px;
    left: 7px;
    width: 2px;
    height: 100%;
    z-index: 0;
    background-color: #c6c7da;
  }
`;

export const ActivityTitle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 8px;
`;

export const ActivityText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-left: 4px;
  word-break: break-word;
  width: 100%;
`;
