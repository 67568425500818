import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Count = styled.div`
  margin-left: 24px;
  background-color: #fff1c8;
  border: 2px solid #f9bb01;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;

  &:hover {
    background-color: #f9bb01;
    color: #252736;
  }
`;

export const Value = styled.span`
  color: #252736;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
`;

export const Block = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const ChartBlock = styled.div`
  width: 49%;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  @media screen and (max-width: 1280px) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

export const Label = styled.p`
  color: #09091a;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
`;

export const IconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Icon = styled.div`
  padding: 12px 50px 12px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 16px;
  cursor: pointer;

  &:hover {
    background-color: #f4f4f4;
    border-radius: 8px;
  }
`;

export const IconItem = styled.div`
  display: flex;
  align-items: center;
`;

export const DashboardSvg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9eaf8;
  margin-right: 12px;
`;

export const IconText = styled.p`
  color: #333443;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 48px;
`;

export const IconDesc = styled.p`
  margin-top: 4px;
  color: #616172;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
`;

export const StatisticBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const StatisticItem = styled.p`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
`;

export const BarChartBlock = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const StatisticDropDown = styled.p`
  position: relative;
  color: #616172;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(73, 79, 89, 0.15);
  border-radius: 4px;
  padding: 12px 0 0;
  min-width: 225px;
  z-index: 99;
`;

export const MenuBlock = styled.div`
  margin-bottom: 12px;
`;

export const MenuItem = styled.p`
  padding: 12px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #1e2529;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
  }
`;
