import * as React from "react"
import { SVGProps } from "react"

const ContactSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={16}
    fill="none"
    {...props}
  >
    <path
      d="M3.111 3.26c0-.328.274-.593.611-.593h11c.338 0 .611.265.611.592v7.111a.602.602 0 0 1-.61.593h-11a.602.602 0 0 1-.612-.592V3.258ZM13.5 9.777a.602.602 0 0 0 .61-.593v-4.74a.602.602 0 0 0-.61-.593H4.944a.602.602 0 0 0-.61.593v4.74c0 .328.273.593.61.593H13.5Zm-.611 2.963a.602.602 0 0 0-.611-.593H2.5a.602.602 0 0 1-.611-.592V5.63a.602.602 0 0 0-.611-.593.602.602 0 0 0-.611.593v7.11c0 .328.273.593.61.593h11a.602.602 0 0 0 .612-.592ZM6.167 5.63c0-.328.273-.593.61-.593.338 0 .612.265.612.593a.602.602 0 0 1-.611.592.602.602 0 0 1-.611-.592ZM8 8a.602.602 0 0 0-.611-.593H6.166a.602.602 0 0 0-.61.593c0 .327.273.593.61.593H7.39A.602.602 0 0 0 7.999 8Zm1.222-2.37c0-.328.274-.593.611-.593h2.445c.337 0 .61.265.61.593a.602.602 0 0 1-.61.592H9.833a.602.602 0 0 1-.61-.592ZM12.89 8a.602.602 0 0 0-.611-.593H9.833a.602.602 0 0 0-.61.593c0 .327.273.593.61.593h2.445a.602.602 0 0 0 .61-.593Z"
      fill="#9292A4"
    />
  </svg>
)

export default ContactSmall
