import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import {
  Wrapper,
  Plan,
  Name,
  Block,
  Label,
  Value,
  Btn,
  ActionsWrapper,
  NewSubscriptionContainer,
} from './subscription.style';
import {
  cancelSubscription,
  resumeSubscription,
  deleteSubscriptionInfo,
  getSubscriptionInfo,
  SubscriptionStatusType,
  updateSubscriptionInfo,
} from '../../../apis/subscription';
import Button from '../../../components/Button/Button';
import ModalSubscription from '../../../layout/Modal/ModalSubscription/ModalSubscription';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { dollarSignDecorator } from '../../../shared/utils/common';
import { useAppSelector } from '../../../store/hooks';

const subscriptionLabelHash = {
  inactive: 'Inactive',
  active: 'Active',
  on_grace_period: 'Grace period',
  on_trial: 'Trial',
};

const Subscription: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const refSubscription = useRef<any>();

  const { settings } = useAppSelector((state: { settings: any }) => state.settings);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useOnClickOutside(refSubscription, () => setOpen(false));

  const { isLoading, error, data, refetch } = useQuery('subscriptionInfo', () => getSubscriptionInfo());

  if (isLoading || error || !data) {
    return null;
  }

  const statusLabel =
    data?.status in subscriptionLabelHash
      ? subscriptionLabelHash[data?.status]
      : subscriptionLabelHash[SubscriptionStatusType.inactive];

  const buttonHash = {
    checkout: {
      text: 'New Subscription',
      action: () =>
        updateSubscriptionInfo().then((res) => {
          window.open(res.url, '_blank');
        }),
    },
    deleteImmediately: {
      text: 'Delete immediately',
      action: () =>
        deleteSubscriptionInfo().then(() => {
          refetch();
        }),
    },
    cancelSubscription: {
      text: 'Cancel',
      action: () =>
        cancelSubscription().then(() => {
          refetch();
        }),
    },
    resumeSubscription: {
      text: 'Resume',
      action: () =>
        resumeSubscription().then((res) => {
          if (res.url) {
            window.open(res.url, '_blank');
          }
        }),
    },
  };

  const buttonContent = (status: SubscriptionStatusType) => {
    const result: { text: string; action: any }[] = [];

    if ([SubscriptionStatusType.active, SubscriptionStatusType.gracePeriod].includes(status)) {
      result.push(buttonHash.deleteImmediately);
    } else {
      result.push(buttonHash.checkout);
    }

    if (status === SubscriptionStatusType.active) {
      result.push(buttonHash.cancelSubscription);
    }

    if (status === SubscriptionStatusType.gracePeriod) {
      result.push(buttonHash.resumeSubscription);
    }

    return result;
  };

  const terminalNameContent = () => {
    if (!data?.subscription) {
      return null;
    }
    const quantity = data?.subscription?.quantity;
    const price = 20;

    return (
      <Plan>
        <Name>Terminal Name</Name>
        <Block>
          <Label>Type:</Label>
          <Value>{`Price: ${dollarSignDecorator(price)}/driver`}</Value>
        </Block>
        <Block>
          <Label>Drivers:</Label>
          <Value>{quantity}</Value>
        </Block>
        <Block style={{ marginBottom: 0 }}>
          <Label>Total price:</Label>
          <Value>{dollarSignDecorator(quantity * price)}/mo</Value>
        </Block>
      </Plan>
    );
  };

  const paymentInfoContent = () => {
    if ([SubscriptionStatusType.active, SubscriptionStatusType.gracePeriod].includes(data?.status)) {
      return (
        <Plan>
          <Name>Payment Information</Name>
          <Block>
            <Label>Status:</Label>
            <Value>{statusLabel}</Value>
          </Block>
          <Block>
            <Label>Next payment amount:</Label>
            <Value>{data?.next_payment_amount ?? 'no info'}</Value>
          </Block>
          <Block>
            <Label>Expiration date:</Label>
            <Value>{data?.current_period_end ?? '-'}</Value>
          </Block>
          <Block style={{ marginBottom: 0 }}>
            <Label>Cardholder's name:</Label>
            <Value>{settings?.subscription_name ? settings?.subscription_name : '-'}</Value>
          </Block>
          <Block style={{ margin: '32px 0 0', justifyContent: 'flex-end' }}>
            <ActionsWrapper>
              {buttonContent(data?.status).map((button, idx) => (
                <Button key={idx} handleButton={button.action} pad="8px 24px" text={button.text} />
              ))}
            </ActionsWrapper>
          </Block>
        </Plan>
      );
    }

    if (data?.status === SubscriptionStatusType.onTrial) {
      return (
        <Plan>
          <Name>Payment Information</Name>
          <Block>
            <Label>Status:</Label>
            <Value>{statusLabel}</Value>
          </Block>
          <Block>
            <Label>Expiration date:</Label>
            <Value>{data?.current_period_end ?? '-'}</Value>
          </Block>
          <Block style={{ marginBottom: 0 }}>
            <Label>Cardholder's name:</Label>
            <Value>{settings?.subscription_name ? settings?.subscription_name : '-'}</Value>
          </Block>
          <Block style={{ margin: '32px 0 0', justifyContent: 'flex-end' }}>
            <ActionsWrapper>
              {buttonContent(data?.status).map((button, idx) => (
                <Button key={idx} handleButton={button.action} pad="8px 24px" text={button.text} />
              ))}
            </ActionsWrapper>
          </Block>
        </Plan>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      {terminalNameContent()}
      {paymentInfoContent()}
      {data?.status === SubscriptionStatusType.inactive && (
        <NewSubscriptionContainer>
          <Button handleButton={buttonHash.checkout.action} pad="8px 24px" text={buttonHash.checkout.text} />
        </NewSubscriptionContainer>
      )}
      {open && <ModalSubscription refSubscription={refSubscription} close={() => setOpen(false)} />}
    </Wrapper>
  );
};

export default Subscription;
