import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import fleetTrailerTable from './fleetTrailer.json';
import fleetTruckTable from './fleetTruck.json';
import ViewFleetImage from '../../layout/Modal/ModalFleet/ViewFleetImage/ViewFleetImage';
import { useAppDispatch } from '../../store/hooks';
import { showFleetInfo } from '../../store/slices/fleetSlice';


type FleetTableProps = {
  fleetList: [];
  tab: number;
};

const FleetTable: React.FC<FleetTableProps> = ({ fleetList, tab }) => {
  const json: any = ['', fleetTruckTable, fleetTrailerTable];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [image, setImage] = useState();

  const [open, setOpen] = useState<boolean>(false);
  const openModal = (elem: any) => {
    setOpen(true);
    setImage(elem);
  };

  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleShowFleetItem = (fleetItem: any, type: string) => {
    navigation(`/fleet/${type}/${fleetItem.id}`);
    dispatch(showFleetInfo(fleetItem));
  };

  const chooseType = (elem: any, fleetItem: any) => {
    switch (elem.type) {
      case 'driver':
        return (
          <div onClick={() => handleShowFleetItem(fleetItem, tab === 1 ? 'truck' : 'trailer')}>
            {fleetItem[elem.array[0]]?.id
              ? fleetItem[elem.array[0]].first_name +
                ' ' +
                fleetItem[elem.array[0]].last_name
              : '-'}
          </div>
        );

      case 'date':
        const formattedDate = fleetItem[elem.array[0]].split(".");
        return (
          <div onClick={() => handleShowFleetItem(fleetItem, tab === 1 ? 'truck' : 'trailer')}>
            {moment(formattedDate).format('MM/DD/yyyy')}
          </div>
        );

      case 'simpleType':
        return (
          <div onClick={() => handleShowFleetItem(fleetItem, tab === 1 ? 'truck' : 'trailer')}>
            {fleetItem[elem.array[0]]}
          </div>
        );

      case 'truck':
        return (
          <div
            onClick={() => handleShowFleetItem(fleetItem, 'truck')}
          >{`Truck #${fleetItem.id}`}</div>
        );

      case 'trailerElem':
        return (
          <div
            onClick={() => handleShowFleetItem(fleetItem, tab === 1 ? 'truck' : 'trailer')}
          >{`Trailer #${fleetItem.id}`}</div>
        );

      case 'trailer':
        return (
          <div onClick={() => handleShowFleetItem(fleetItem[elem.array[0]], 'trailer')}>
            {fleetItem[elem.array[0]]
              ? `Trailer #${fleetItem[elem.array[0]].id}`
              : '-'}
          </div>
        );

      case 'view':
        return (
          <div onClick={() => openModal(fleetItem[elem.array[0]])}>
            {fleetItem[elem.array[0]] ? 'View' : ''}
          </div>
        );
    }
  };

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open])

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ height: 'auto' }}>
          <Table sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                {json[tab][0].label?.map((elem: any) => (
                  <TableCell
                    key={elem}
                    sx={{
                      whiteSpace: 'nowrap',
                      padding: '15px',
                      fontFamily: 'MontserratBold',
                      fontWeight: 700,
                      fontSize: '12px',
                      color: '#616172',
                      textTransform: 'uppercase',
                    }}
                  >
                    {elem}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fleetList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fleetItem: any, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {json[tab][1].data?.map(
                        (elem: string | number, i: React.Key | null) => {
                          return (
                            <TableCell
                              key={i}
                              sx={{
                                padding: '15px',
                                whiteSpace: 'nowrap',
                                fontFamily: 'Montserrat',
                                fontWeight: 400,
                                fontSize: '16px',
                                color: '#09091A',
                                textTransform: 'uppercase',
                                cursor: 'pointer',
                                display:
                                  tab === fleetItem.type.id
                                    ? 'table-cell'
                                    : 'none',
                              }}
                            >
                              {typeof elem === 'object'
                                ? chooseType(elem, fleetItem)
                                : fleetItem[elem]}
                            </TableCell>
                          );
                        },
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={fleetList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {open && <ViewFleetImage close={() => setOpen(false)} image={image} />}
    </>
  );
};

export default FleetTable;
