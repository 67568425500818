import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 0 92px 0;
`;

export const LoadsListBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1370px) {
    flex-direction: column;
  }
`;

export const EmptyLoads = styled.p`
margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 24px;
  color: #616172;
`;

export const PaginationBlock = styled.div`
  margin-top: 32px;
`;
