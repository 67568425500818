import moment from 'moment';
import React, { useState } from 'react';

import Drivers from './Drivers/Drivers';
import Fields from './Fields/Fields';
import General from './General/General';
import {
  Wrapper,
  TopText,
  ReportBlock,
  ReportBlockScroll,
  ReportTabs,
  ReportTab,
  VerticalLine,
  ReportData,
  Line,
  Bottom,
  FieldSelect,
  Actions,
  ButtonWrapper,
  Cancel,
} from './modalReport.style';
import { createReport } from '../../../API/reports';
import Button from '../../../components/Button/Button';
import { useAppDispatch } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  refReport: any;
};

const orderSelect = [
  { title: 'Creation date', value: 'created_at' },
  { title: 'Delivery date', value: 'delivered_at' },
];
const includeSelect = [
  { title: 'All Orders', value: 'all' },
  { title: 'Active', value: 'active' },
  { title: 'Archived', value: 'archived' },
];
const statusSelect = [
  { title: 'All', value: 'all' },
  { title: 'New', value: 'new' },
  { title: 'Picked up', value: 'picked_up' },
  { title: 'Delivered', value: 'delivered' },
];

const ModalReport: React.FC<ModalContentProps> = ({ close, refReport }) => {
  const [tab, setTab] = useState<number>(0);
  const dispatch = useAppDispatch();

  const [err, setErr] = useState({
    message: '',
    errors: { receiver_email: [], drivers: [] },
  });

  const [email, setEmail] = useState<string>('');
  const [createDate, setCreateDate] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const [deliveryDate, setDeliveryDate] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);
  const [order, setOrder] = useState<string>(orderSelect[0].title);
  const [orderValue, setOrderValue] = useState<string>(orderSelect[0].value);
  const [include, setInclude] = useState<string>(includeSelect[0].title);
  const [includeValue, setIncludeValue] = useState<string>(
    includeSelect[0].value,
  );
  const [status, setStatus] = useState<string>(statusSelect[0].title);
  const [statusValue, setStatusValue] = useState<string>(statusSelect[0].value);
  const [noDriver, setNoDriver] = useState<boolean>(false);
  const [removedDrivers, setRemovedDrivers] = useState<boolean>(false);

  const [activeDrivers, setActiveDrivers] = useState<number[]>([]);
  const [disabledDrivers, setDisabledDrivers] = useState<number[]>([]);

  const [fields, setFields] = useState({
    generalLoadID: false,
    internalLoadID: false,
    dispatcher: false,
    trip: false,
    hasTerminal: false,
    dispatchToCarrier: false,
    pickupName: false,
    pickupAddress: false,
    pickupDate: false,
    actualPickupDate: false,
    buyerNumber: false,
    vehicleLoadID: false,
    vin: false,
    deliveryName: false,
    deliveryAddress: false,
    deliveryDate: false,
    scheduledDeliveryDate: false,
    deliveryNotes: false,
    deliveryDriver: false,
  });

  const setSelectAll = (check: boolean) => {
    if (check) {
      setFields({
        generalLoadID: true,
        internalLoadID: true,
        dispatcher: true,
        trip: true,
        hasTerminal: true,
        dispatchToCarrier: true,
        pickupName: true,
        pickupAddress: true,
        pickupDate: true,
        actualPickupDate: true,
        buyerNumber: true,
        vehicleLoadID: true,
        vin: true,
        deliveryName: true,
        deliveryAddress: true,
        deliveryDate: true,
        scheduledDeliveryDate: true,
        deliveryNotes: true,
        deliveryDriver: true,
      });
    } else {
      setFields({
        generalLoadID: false,
        internalLoadID: false,
        dispatcher: false,
        trip: false,
        hasTerminal: false,
        dispatchToCarrier: false,
        pickupName: false,
        pickupAddress: false,
        pickupDate: false,
        actualPickupDate: false,
        buyerNumber: false,
        vehicleLoadID: false,
        vin: false,
        deliveryName: false,
        deliveryAddress: false,
        deliveryDate: false,
        scheduledDeliveryDate: false,
        deliveryNotes: false,
        deliveryDriver: false,
      });
    }
  };

  const handleConfirm = async () => {
    let formData = new FormData();
    formData.append('receiver_email', email);
    formData.append(
      'creation_period',
      `${moment(createDate[0]).format('DD.MM.YYYY')}-${moment(
        createDate[1],
      ).format('DD.MM.YYYY')}`,
    );
    formData.append(
      'delivery_period',
      `${moment(deliveryDate[0]).format('DD.MM.YYYY')}-${moment(
        deliveryDate[1],
      ).format('DD.MM.YYYY')}`,
    );
    formData.append('order_by', orderValue);
    formData.append('include', includeValue);
    formData.append('status', statusValue);
    formData.append('no_driver', noDriver ? '1' : '0');
    formData.append('removed_drivers', removedDrivers ? '1' : '0');
    activeDrivers.forEach((elem: any) => {
      formData.append('drivers[]', elem);
    });
    disabledDrivers.forEach((elem: any) => {
      formData.append('drivers[]', elem);
    });
    formData.append('general_fields[]', fields.generalLoadID ? 'load_id' : '');
    formData.append(
      'general_fields[]',
      fields.internalLoadID ? 'internal_load_id' : '',
    );
    formData.append('general_fields[]', fields.dispatcher ? 'dispatcher' : '');
    formData.append('general_fields[]', fields.trip ? 'trip' : '');
    formData.append(
      'general_fields[]',
      fields.hasTerminal ? 'terminal_id' : '',
    );
    formData.append(
      'general_fields[]',
      fields.dispatchToCarrier ? 'dispatched' : '',
    );
    formData.append(
      'pickup_fields[]',
      fields.pickupName ? 'business_name' : '',
    );
    formData.append(
      'pickup_fields[]',
      fields.pickupAddress ? 'street_address' : '',
    );
    formData.append('pickup_fields[]', fields.pickupDate ? 'date' : '');
    formData.append('pickup_fields[]', fields.actualPickupDate ? 'date' : '');
    formData.append('pickup_fields[]', fields.buyerNumber ? 'buyer_name' : '');
    formData.append(
      'vehicle_fields[]',
      fields.vehicleLoadID ? 'vehicle_info' : '',
    );
    formData.append('vehicle_fields[]', fields.vin ? 'vin' : '');
    formData.append(
      'delivery_fields[]',
      fields.deliveryName ? 'business_name' : '',
    );
    formData.append(
      'delivery_fields[]',
      fields.deliveryAddress ? 'street_address' : '',
    );
    formData.append('delivery_fields[]', fields.deliveryDate ? 'date' : '');
    formData.append(
      'delivery_fields[]',
      fields.scheduledDeliveryDate ? 'buyer_name' : '',
    );
    formData.append('delivery_fields[]', fields.deliveryNotes ? 'notes' : '');
    {
      formData.append(
        'driver_fields[]',
        fields.deliveryDriver ? 'driver_info' : '',
      );
    }

    const res: any = await dispatch(createReport(formData));
    if (res.type === 'reports/createReport/rejected')
      return setErr(res.payload);
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refReport}>
        <TopText>Custom report</TopText>
        <Line />

        <ReportBlock>
          <ReportBlockScroll>
            <ReportTabs>
              <ReportTab
                onClick={() => setTab(0)}
                style={{ color: tab === 0 ? '#0E71EB' : '#09091a' }}
              >
                General {tab === 0 && <VerticalLine />}
              </ReportTab>
              <ReportTab
                onClick={() => setTab(1)}
                style={{ color: tab === 1 ? '#0E71EB' : '#09091a' }}
              >
                Drivers {tab === 1 && <VerticalLine />}
              </ReportTab>
              <ReportTab
                onClick={() => setTab(2)}
                style={{ color: tab === 2 ? '#0E71EB' : '#09091a' }}
              >
                Export Fields {tab === 2 && <VerticalLine />}
              </ReportTab>
            </ReportTabs>

            <ReportData>
              {(() => {
                switch (tab) {
                  case 0: {
                    return (
                      <General
                        orderSelect={orderSelect}
                        includeSelect={includeSelect}
                        statusSelect={statusSelect}
                        email={email}
                        setEmail={setEmail}
                        createDate={createDate}
                        setCreateDate={setCreateDate}
                        deliveryDate={deliveryDate}
                        setDeliveryDate={setDeliveryDate}
                        order={order}
                        setOrder={setOrder}
                        setOrderValue={setOrderValue}
                        include={include}
                        setInclude={setInclude}
                        setIncludeValue={setIncludeValue}
                        status={status}
                        setStatus={setStatus}
                        setStatusValue={setStatusValue}
                        noDriver={noDriver}
                        setNoDriver={setNoDriver}
                        removedDrivers={removedDrivers}
                        setRemovedDrivers={setRemovedDrivers}
                        err={err}
                        setErr={setErr}
                      />
                    );
                  }
                  case 1: {
                    return (
                      <Drivers
                        activeDrivers={activeDrivers}
                        setActiveDrivers={setActiveDrivers}
                        disabledDrivers={disabledDrivers}
                        setDisabledDrivers={setDisabledDrivers}
                        err={err}
                        setErr={setErr}
                      />
                    );
                  }
                  case 2: {
                    return <Fields fields={fields} setFields={setFields} />;
                  }
                  default:
                    return null;
                }
              })()}
            </ReportData>
          </ReportBlockScroll>
        </ReportBlock>

        <Line />
        <Bottom style={{ justifyContent: tab === 2 ? 'space-between' : 'end' }}>
          {tab === 2 && (
            <Actions>
              <FieldSelect
                style={{ marginRight: '32px' }}
                onClick={() => setSelectAll(true)}
              >
                Select all
              </FieldSelect>
              <FieldSelect onClick={() => setSelectAll(false)}>
                Clear all
              </FieldSelect>
            </Actions>
          )}
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <ButtonWrapper>
              <Button
                text="Generate"
                pad="8px 24px"
                handleButton={handleConfirm}
              />
            </ButtonWrapper>
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalReport;
