import * as React from "react"
import { SVGProps } from "react"

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      d="M26.968 24 41.892 8.643c.25-.255.04-.643-.347-.643h-4.537a.993.993 0 0 0-.7.28L24 20.947 11.691 8.28a.973.973 0 0 0-.699-.28H6.455c-.386 0-.597.388-.347.643L21.032 24 6.108 39.357a.367.367 0 0 0-.103.2.343.343 0 0 0 .038.218.415.415 0 0 0 .168.164c.073.04.158.061.244.061h4.537a.993.993 0 0 0 .7-.28L24 27.053 36.309 39.72c.17.177.426.28.699.28h4.537c.386 0 .597-.388.346-.643L26.968 24Z"
      fill="#fff"
    />
  </svg>
)

export default CloseIcon
