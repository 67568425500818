import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AuthBlock,
  BackButton,
  BackTitle,
  Block,
  Error,
  Footer,
  Margin,
  Reset,
} from './resetPassword.style';
import { resetPassword } from '../../API/auth';
import { Back } from '../../assets/icons';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import Input from '../../components/Input/Input';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [err, setErr] = useState<string>('')

  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const { error } = useAppSelector(state => state.auth);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any) =>
    setState(e.target.value);

  const sentToReset = async () => {
    const res = await dispatch(resetPassword({ email }));
    if (error) setErr(error.message);
    
    if (res.type === 'auth/resetPassword/fulfilled')
      navigation('/confirm-password');
  };

  return (
    <>
      <Header />
      <Block>
        <Reset>
          <BackButton onClick={() => navigation('/')}>
            <Back />
            <BackTitle>Reset Password</BackTitle>
          </BackButton>
          <AuthBlock>
          <Input
            inputId="email"
            inputType="email"
            label="Email"
            handleInput={e => handleInput(e, setEmail)}
            value={email}
          />
          <Error>{err}</Error>
          </AuthBlock>
          <Margin />
          <Button text="Send confirmation" handleButton={sentToReset} borderRadius="8px" />
        </Reset>

        <Footer>
          © {new Date().getFullYear()} Turbo dispatch. All rights reserved.
        </Footer>
      </Block>
    </>
  );
};

export default ResetPassword;
