import {
  Actions,
  Button,
  Line,
  Terms,
  TermsItem,
  Title,
  TopText,
  Wrapper,
} from './actionModalDriver.style';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  handleConfirm: () => void;
  driverFirstName: string;
  title: string;
  text: string[];
  driverLastName: string;
  colorTitle: string;
  refDriver: any;
};

const ActionModalDriver: React.FC<ModalContentProps> = ({
  driverFirstName,
  driverLastName,
  title,
  text,
  colorTitle,
  refDriver,
  close,
  handleConfirm,
}) => {
  return (
    <ModalPortal>
      <Wrapper ref={refDriver}>
        <TopText>
          <Title style={{ color: colorTitle }}>{title}</Title> {driverFirstName}{' '}
          {driverLastName}?
        </TopText>
        <Terms>
          {text.map((item, i) => {
            return <TermsItem key={i}>{item}</TermsItem>;
          })}
        </Terms>

        <Line />

        <Actions>
          <Button onClick={handleConfirm}>Yes</Button>
          <Button onClick={close}>No</Button>
        </Actions>
      </Wrapper>
    </ModalPortal>
  );
};

export default ActionModalDriver;
