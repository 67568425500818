import * as React from "react"
import { SVGProps } from "react"

const Calendar: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.75 1.875h-2.625v-1A.125.125 0 0 0 10 .75h-.875A.125.125 0 0 0 9 .875v1H5v-1A.125.125 0 0 0 4.875.75H4a.125.125 0 0 0-.125.125v1H1.25a.5.5 0 0 0-.5.5V12.75a.5.5 0 0 0 .5.5h11.5a.5.5 0 0 0 .5-.5V2.375a.5.5 0 0 0-.5-.5Zm-.625 10.25H1.875V6.187h10.25v5.938Zm-10.25-7V3h2v.75c0 .069.056.125.125.125h.875A.125.125 0 0 0 5 3.75V3h4v.75c0 .069.056.125.125.125H10a.125.125 0 0 0 .125-.125V3h2v2.125H1.875Z"
      fill="#616172"
    />
  </svg>
)

export default Calendar
