import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
    margin-bottom: 24px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchField = styled.div`
  position: relative;
  margin: 20px 0;
  width: 25%;

  @media screen and (max-width: 1024px) {
    width: 45%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 30px 8px 16px;
  outline: none;
  background-color: transparent;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;

  &::placeholder {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    color: #616172;
  }
`;

export const SearchWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

export const TripTabs = styled(Tabs)`
  display: flex;
  align-items: center;
`;

export const Tab = styled.div`
  display: flex;
  font-family: 'MontserratSemiBold';
  font-weight: '600';
  font-size: 20px;
  margin-right: 20px;
  padding: 0 12px;
  cursor: pointer;
  position: relative;
`;

export const Count = styled.div`
  margin: 0 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: '700';
  font-size: 12px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: -5px 0 16px;
`;

export const BorderBottom = styled.div`
  position: absolute;
  bottom: -17px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1267cf;
`;
