import moment from 'moment';
import React from 'react';

import { AddressSmall, CalendarSmall, ContactSmall, PersonSmall } from '../../../../assets/icons';
import {
  DeliverText,
  DeliverTextEmpty,
  DeliveryFlex,
  FlexBetween,
  FlexTopBetween,
  LoadsBlock,
  LoadsEdit,
  LoadsFlexColumn,
  LoadsNameSemiBold,
  LoadsTitle,
} from '../loadsItem.style';

type LoadsItemProps = {
  setPointDelivery?: () => void;
  deliveryData: any;
};

const LoadsItemDelivery: React.FC<LoadsItemProps> = ({ setPointDelivery, deliveryData }) => {
  return (
    <LoadsBlock overflow>
      <FlexBetween>
        <LoadsTitle>Delivery Information</LoadsTitle>
        {setPointDelivery && (
          <LoadsEdit className="noPrint" onClick={setPointDelivery}>
            Edit
          </LoadsEdit>
        )}
      </FlexBetween>
      <FlexTopBetween>
        <LoadsFlexColumn>
          <LoadsNameSemiBold overflow>{deliveryData?.business_name}</LoadsNameSemiBold>

          <DeliveryFlex>
            <AddressSmall />
            <DeliverText>
              {deliveryData?.street_address + ', ' + deliveryData?.state + ', ' + deliveryData?.zip}
            </DeliverText>
          </DeliveryFlex>

          <DeliveryFlex>
            <CalendarSmall />
            <DeliverText>{deliveryData?.date}</DeliverText>
          </DeliveryFlex>

          <DeliveryFlex>
            <PersonSmall />
            <LoadsFlexColumn>
              {deliveryData?.full_name ? (
                <DeliverText>{deliveryData?.full_name}</DeliverText>
              ) : (
                <DeliverTextEmpty>No contact name</DeliverTextEmpty>
              )}
              <DeliverText>
                {deliveryData?.phone && deliveryData?.phone}
                {!deliveryData?.phone && 'No phone number'}
              </DeliverText>
            </LoadsFlexColumn>
          </DeliveryFlex>

          <DeliveryFlex>
            <ContactSmall />
            {deliveryData?.notes ? (
              <DeliverText>{deliveryData?.notes}</DeliverText>
            ) : (
              <DeliverTextEmpty>No pickup notes</DeliverTextEmpty>
            )}
          </DeliveryFlex>
        </LoadsFlexColumn>
      </FlexTopBetween>
    </LoadsBlock>
  );
};

export default LoadsItemDelivery;
