import * as React from 'react';
import { SVGProps } from 'react';

type IconProps = {
  isActive?: boolean;
};

const Contact: React.FC<IconProps> = ( { isActive }, props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={16} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm2 0a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM0 13c0-2.66 4.664-4 7-4 2.336 0 7 1.34 7 4v3H0v-3Zm2 0v1h10v-1c-.175-.71-2.637-2-5-2-2.362 0-4.825 1.28-5 2Zm11.28-5c-.449 0-.88-.07-1.28-.2.8-.901 1.28-2.05 1.28-3.3S12.8 2.101 12 1.2c.4-.13.831-.2 1.28-.2 2.121 0 3.84 1.567 3.84 3.5S15.401 8 13.28 8ZM20 15h-4v-2.625c0-1.609-.852-2.635-2-3.375 2.358.196 6 1.262 6 3.375V15Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
  </svg>
);

export default Contact;
