import React, { useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Title,
  Block,
  InfoBlock,
  BlockTitle,
  Name,
  Email,
  Button,
  Edit,
} from './dispatcherProfile.style';
import { getDispatcherProfile } from '../../API/dispatcherProfile';
import ModalDispatcherProfile from '../../layout/Modal/ModalDispatcherProfile/ModalDispatcherProfile';
import useOnClickOutside from '../../layout/Modal/useClickOutsideModal';
import SettingsContainer from '../../layout/SettingsContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const DispatcherProfile: React.FC = () => {
  const [hover, setHover] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const refDispatcher = useRef<any>();

  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state: { dispatcherProfile: any }) => state.dispatcherProfile);

  const openModal = (elem: string) => {
    setValue(elem)
    setOpen(true);
  }

  useEffect(() => {
    dispatch(getDispatcherProfile(null));
  }, []);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open])

  useOnClickOutside(refDispatcher, () => setOpen(false));

  if (loading) return <Preloader />;

  return (
    <>
      <SettingsContainer>
        <Wrapper>
          <Title>Dispatcher Profile</Title>
          <Block
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <InfoBlock>
              <BlockTitle>Profile Info</BlockTitle>
              <Name>{data ? data?.name : ''}</Name>
              <BlockTitle>Account Info</BlockTitle>
              <Email>{data ? data?.email : ''}</Email>

              <Button onClick={() => openModal('password')}>Change password</Button>
            </InfoBlock>
            {(hover || window.matchMedia('(max-width: 1280px)').matches) && (
              <InfoBlock>
                <Edit onClick={() => openModal('edit')}>Edit</Edit>
              </InfoBlock>
            )}
          </Block>

          {open && <ModalDispatcherProfile refDispatcher={refDispatcher} value={value} close={() => setOpen(false)} />}
        </Wrapper>
      </SettingsContainer>
    </>
  );
};

export default DispatcherProfile;
