import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 40%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091A;
  margin: 0 23px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #f4f4f4;
`;

export const BlockScroll = styled.div`
  overflow-y: scroll;
  max-height: 690px;

  @media screen and (max-width: 1280px) {
    height: 500px;
  }

  @media screen and (max-width: 600px) {
    height: 400px;
  }
`;

export const LoadsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 24px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const InputFieldHalf = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  padding: 0 24px;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const Input = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  outline: none;
  width: 100%;
  max-height: 162px;
  margin: 0 auto;
`;

export const LoadsText = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  margin-bottom: 12px;
  text-transform: uppercase;
  padding: 0 24px;
`;

export const CheckBoxBlock = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const CheckBoxItem = styled.div`
  margin: 12px 0;
  display: flex;
  align-items: center;
  position: relative;
`;

export const CheckBoxTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
`;

export const CheckBoxTitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const CheckBoxSubtitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-top: 5px;
`;

export const CustomCheckBox = styled.span`
  position: absolute;
  top: 0;
  left: 5px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #c6c7da;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomCheckBoxSquare = styled.span`
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const CheckBoxSquare = styled.input`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 32px;
  height: 32px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked + span {
    background-color: #0e71eb;
    border: 1px solid transparent;
  }
`;

export const CheckBox = styled.input`
  position: absolute;
  top: 0;
  left: 5px;
  width: 32px;
  height: 32px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked + span {
    background-color: #0e71eb;
    border: 1px solid transparent;
  }
`;

export const Error = styled.span`
  margin-top: 5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 23px 0 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;