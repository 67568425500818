import * as React from 'react';
import { SVGProps } from 'react';

type BackProps = {
  fill?: string;
};

const Back: React.FC<BackProps> = (
  { fill },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.92 12 6.33 6.77L15.165 21 6.75 12l8.415-9 2.085 2.23L10.92 12Z"
      fill={fill ? fill : '#09091A'}
    />
  </svg>
);

export default Back;
