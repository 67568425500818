import styled from 'styled-components';

export const Block = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 8px;
`;

export const InputField = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 8px;
  padding: 10px;
  outline: none;
  font-size: 16px;
  color: #09091a;
`;
