import { SVGProps } from 'react';

const DashboardArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      d="m17 14.76-1.75-1.867-1.977 2.133V4h-2.5v11.027L8.75 12.893 7 14.76 12.023 20 17 14.76Z"
      fill="#616172"
    />
  </svg>
);

export default DashboardArrowDown;
