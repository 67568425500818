import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  TopPanel,
  TopTitle,
  Actions,
  Info,
  ImageTruck,
  TrailerInfo,
  SemiBoldText,
  BoldText,
  RegularText,
  SpanText,
  Line,
  FleetInfoItem,
  FleetInfoItemColumn,
  FleetInfoBlockTrailer,
  FleetInfoItemTrailer,
  FleetInfoBlock,
  FleetSectionBlockMedium,
  FleetSectionBlock,
  FleetSection,
  FleetInfoLabel,
  FleetBlock,
  TrailerBlock,
  Status,
} from './trailerPage.style';
import {
  Back,
  Document,
  FleetCamera,
  FleetClock,
  FleetNotes,
  Trips,
} from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import AuthHeader from '../../../components/Header/AuthHeader';
import ModalAddComment from '../../../layout/Modal/ModalFleet/ModalAddComment/ModalAddComment';
import ModalFleet from '../../../layout/Modal/ModalFleet/ModalFleet';
import ModalFleetExpense from '../../../layout/Modal/ModalFleet/ModalFleetExpense/ModalFleetExpense';
import ModalFleetHistory from '../../../layout/Modal/ModalFleet/ModalFleetHistory/ModalFleetHistory';
import ModalFleetNotes from '../../../layout/Modal/ModalFleet/ModalFleetNotes/ModalFleetNotes';
import ModalInspectionHistory from '../../../layout/Modal/ModalFleet/ModalInspectionHistory/ModalInspectionHistory';
import ModalMap from '../../../layout/Modal/ModalFleet/ModalMap/ModalMap';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const Trailer: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const refFleet = useRef<any>();
  const refExpense = useRef<any>();
  const refInspHistory = useRef<any>();
  const refNotes = useRef<any>();
  const refAddNotes = useRef<any>();
  const refMap = useRef<any>();
  const refHistory = useRef<any>();

  const { fleetInfo } = useAppSelector((state: { fleet: any }) => state.fleet);
  const [open, setOpen] = useState<boolean>(false);
  const [openFleetExpense, setOpenFleetExpense] = useState<boolean>(false);
  const [openInspectionHistory, setOpenInspectionHistory] =
    useState<boolean>(false);
  const [openNotes, setOpenNotes] = useState<boolean>(false);
  const [openAddNotes, setOpenAddNotes] = useState<boolean>(false);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);

  const openModal = () => setOpen(true);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useEffect(() => {
    document.body.style.overflow = openFleetExpense ? 'hidden' : 'unset';
  }, [openFleetExpense]);

  useEffect(() => {
    document.body.style.overflow = openInspectionHistory ? 'hidden' : 'unset';
  }, [openInspectionHistory]);

  useEffect(() => {
    document.body.style.overflow = openHistory ? 'hidden' : 'unset';
  }, [openHistory]);

  useEffect(() => {
    document.body.style.overflow = openMap ? 'hidden' : 'unset';
  }, [openMap]);

  useEffect(() => {
    document.body.style.overflow = openNotes ? 'hidden' : 'unset';
  }, [openNotes]);

  useEffect(() => {
    if (fleetInfo.type === '') {
      navigation('/fleet');
    }
  }, []);

  useOnClickOutside(refFleet, () => setOpen(false));
  useOnClickOutside(refExpense, () => setOpenFleetExpense(false));
  useOnClickOutside(refInspHistory, () => setOpenInspectionHistory(false));
  useOnClickOutside(refNotes, () => setOpenNotes(false));
  useOnClickOutside(refAddNotes, () => setOpenAddNotes(false));
  useOnClickOutside(refMap, () => setOpenMap(false));
  useOnClickOutside(refHistory, () => setOpenHistory(false));

  return (
    <>
      <AuthHeader />
      <Wrapper>
        <Top>
          <TopTitle onClick={() => navigation('/fleet')}>
            <Back fill="#616172" /> Trailer
          </TopTitle>
        </Top>
        <TopPanel>
          <TrailerInfo>
            <ImageTruck></ImageTruck>
            <Info>
              <SemiBoldText>Trailer #{fleetInfo.id}</SemiBoldText>
              <RegularText>
                {fleetInfo.make + ' ' + fleetInfo.model}{' '}
                <SpanText>VIN</SpanText>
              </RegularText>
            </Info>
          </TrailerInfo>
          <Actions>
            <SemiBoldText>Reminder</SemiBoldText>
            <Button text="Edit" pad="10px 20px" handleButton={openModal} />
          </Actions>
        </TopPanel>
        <Line />
        <FleetBlock>
          <FleetInfoItem>
            <FleetInfoBlock>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenInspectionHistory(true)}
              >
                <div>
                  <FleetCamera />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>
                  Inspection history
                </RegularText>
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenHistory(true)}
              >
                <div>
                  <FleetClock />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>
                  History
                </RegularText>
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenMap(true)}
              >
                <div>
                  <Trips />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>Map</RegularText>
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenNotes(true)}
              >
                <div>
                  <FleetNotes />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>
                  Coments / notes
                </RegularText>
              </div>
            </FleetInfoBlock>
          </FleetInfoItem>
          <FleetSectionBlock>
            <FleetSection>
              <BoldText>Information</BoldText>
              <FleetInfoItemColumn>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Truck</FleetInfoLabel>
                  <RegularText>{'Truck #' + fleetInfo.id}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Year</FleetInfoLabel>
                  <RegularText>{fleetInfo.year}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Make</FleetInfoLabel>
                  <RegularText>{fleetInfo.make}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Model</FleetInfoLabel>
                  <RegularText>{fleetInfo.model}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Color</FleetInfoLabel>
                  <RegularText>{fleetInfo.color}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Plate</FleetInfoLabel>
                  <RegularText>{fleetInfo.plate}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>State</FleetInfoLabel>
                  <RegularText>{fleetInfo.state}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Plate exp</FleetInfoLabel>
                  <RegularText>
                    {moment(fleetInfo.plate_expired_at).format('MM/DD/yyyy')}
                  </RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Vin #</FleetInfoLabel>
                  <RegularText>{fleetInfo.vin}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Owner</FleetInfoLabel>
                  <RegularText>{fleetInfo.owner}</RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Annual INSP</FleetInfoLabel>
                  <RegularText>
                    {moment(fleetInfo.annual_inspection_at).format(
                      'MM/DD/yyyy',
                    )}
                  </RegularText>
                </FleetInfoBlockTrailer>
                <FleetInfoBlockTrailer>
                  <FleetInfoLabel>Pass</FleetInfoLabel>
                  <RegularText>{fleetInfo.pass}</RegularText>
                </FleetInfoBlockTrailer>
              </FleetInfoItemColumn>
            </FleetSection>

            <TrailerBlock>
              <FleetSectionBlockMedium>
                <FleetSection>
                  <BoldText>Truck assignment</BoldText>
                  <FleetInfoItemColumn style={{ flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText style={{ marginBottom: 0 }}>
                        Assignment:
                      </SemiBoldText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Sun 01
                      </RegularText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Mono
                      </RegularText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Space
                      </RegularText>
                    </div>
                    <Line
                      style={{ backgroundColor: '#E8E8E8', margin: '24px 0' }}
                    />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Truck-1</SemiBoldText>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                      <div></div>
                      <div></div>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Truck-2</SemiBoldText>
                      <div></div>
                      <div></div>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          display: 'inline-flex',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Truck-3</SemiBoldText>
                      <div></div>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          display: 'inline-flex',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                      <div></div>
                    </div>
                  </FleetInfoItemColumn>
                </FleetSection>
              </FleetSectionBlockMedium>
              <FleetSectionBlockMedium>
                <FleetSection>
                  <BoldText>Driver assignment</BoldText>
                  <FleetInfoItemColumn style={{ flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText style={{ marginBottom: 0 }}>
                        Assignment:
                      </SemiBoldText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Sun 01
                      </RegularText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Mono
                      </RegularText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Space
                      </RegularText>
                    </div>
                    <Line
                      style={{ backgroundColor: '#E8E8E8', margin: '24px 0' }}
                    />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Driver-1</SemiBoldText>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                      <div></div>
                      <div></div>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Driver-2</SemiBoldText>
                      <div></div>
                      <div></div>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          display: 'inline-flex',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Driver-3</SemiBoldText>
                      <div></div>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          display: 'inline-flex',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                      <div></div>
                    </div>
                  </FleetInfoItemColumn>
                </FleetSection>
              </FleetSectionBlockMedium>
            </TrailerBlock>

            <FleetSection>
              <BoldText>Paperwork</BoldText>
              <FleetInfoItemTrailer>
                <Status
                  style={{
                    margin: '16px',
                    height: '46px',
                    padding: '10px',
                    display: 'inline-flex',
                    color: '#0e71eb',
                    backgroundColor: '#b0dcff',
                    cursor: 'pointer',
                  }}
                >
                  <Document color="#0E71EB" />
                  <p style={{ marginLeft: '5px' }}>Insurence</p>
                </Status>
                <Status
                  style={{
                    margin: '16px',
                    height: '46px',
                    padding: '10px',
                    display: 'inline-flex',
                    color: '#0e71eb',
                    backgroundColor: '#b0dcff',
                    cursor: 'pointer',
                  }}
                >
                  <Document color="#0E71EB" />
                  <p style={{ marginLeft: '5px' }}>Registration</p>
                </Status>
                <Status
                  style={{
                    margin: '16px',
                    height: '46px',
                    padding: '10px',
                    display: 'inline-flex',
                    color: '#0e71eb',
                    backgroundColor: '#b0dcff',
                    cursor: 'pointer',
                  }}
                >
                  <Document color="#0E71EB" />
                  <p style={{ marginLeft: '5px' }}>Contract</p>
                </Status>
              </FleetInfoItemTrailer>
            </FleetSection>
          </FleetSectionBlock>
        </FleetBlock>

        {open && (
          <ModalFleet
            refFleet={refFleet}
            text='Edit Trailer'
            close={() => setOpen(false)}
          />
        )}

        {openFleetExpense && (
          <ModalFleetExpense refExpense={refExpense} close={() => setOpenFleetExpense(false)} />
        )}

        {openInspectionHistory && (
          <ModalInspectionHistory
          refInspHistory={refInspHistory}
            close={() => setOpenInspectionHistory(false)}
          />
        )}

        {openNotes && (
          <ModalFleetNotes
          refNotes={refNotes}
            setOpenAddNotes={setOpenAddNotes}
            close={() => setOpenNotes(false)}
          />
        )}

        {openHistory && (
          <ModalFleetHistory refHistory={refHistory} close={() => setOpenHistory(false)} />
        )}

        {openMap && <ModalMap refMap={refMap} close={() => setOpenMap(false)} />}

        {openAddNotes && (
          <ModalAddComment refAddNotes={refAddNotes} close={() => setOpenAddNotes(false)} />
        )}
      </Wrapper>
    </>
  );
};

export default Trailer;
