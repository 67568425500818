import * as React from 'react';
import { SVGProps } from 'react';

type IconProps = {
  fill?: boolean;
  color?: string;
};

const Document: React.FC<IconProps> = (
  { fill, color },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      d="M10 1.333H2.667v13.334h10.666v-10L10 1.333Zm0 2 1.333 1.334H10V3.333Zm2 10H4V2.667h4.666v3.34H12v7.326Z"
      fill={color ? color : fill ? '#fff' : '#9292A4'}
    />
  </svg>
);

export default Document;
