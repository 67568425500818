import { SVGProps } from "react"

const FleetCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={21}
    fill="none"
    {...props}
  >
    <path
      d="M21.12 1.856H16.5V.206c0-.113-.099-.206-.22-.206h-1.54c-.121 0-.22.093-.22.206v1.65H7.48V.206C7.48.093 7.381 0 7.26 0H5.72c-.121 0-.22.093-.22.206v1.65H.88c-.487 0-.88.369-.88.825V19.8c0 .456.393.825.88.825h20.24c.487 0 .88-.369.88-.825V2.681c0-.456-.393-.825-.88-.825Zm-1.1 16.913H1.98V8.972h18.04v9.797ZM1.98 7.219V3.712H5.5V4.95c0 .113.099.206.22.206h1.54c.121 0 .22-.093.22-.206V3.712h7.04V4.95c0 .113.099.206.22.206h1.54c.121 0 .22-.093.22-.206V3.712h3.52V7.22H1.98Z"
      fill="#333443"
    />
  </svg>
)

export default FleetCalendar
