import moment from 'moment';
import React from 'react';

import {
  Activity,
  ActivityBlock,
  ActivityScroll,
  ActivityVew,
  DeliverTextEmpty,
  FlexBetween,
  LoadsBlock,
  LoadsNameSemiBold,
  LoadsTitle,
} from '../loadsItem.style';

type LoadsItemProps = {
  loadInfo: any;
  setViewActivity: () => void;
  setTypeVeiw: () => void;
};

const LoadItemActivity: React.FC<LoadsItemProps> = ({ loadInfo, setViewActivity, setTypeVeiw }) => {
  return (
    <>
      <LoadsBlock>
        <FlexBetween>
          <LoadsTitle>Activity</LoadsTitle>
        </FlexBetween>
        <Activity>
          <ActivityScroll>
            {loadInfo?.load?.activity?.map((item: any, i: number) => {
              const correctDateFormat = item.date;
              return (
                <ActivityBlock key={i}>
                  <LoadsNameSemiBold>{item.title}</LoadsNameSemiBold>
                  <DeliverTextEmpty>{correctDateFormat}</DeliverTextEmpty>
                  <DeliverTextEmpty>{item.user.email}</DeliverTextEmpty>
                </ActivityBlock>
              );
            })}
          </ActivityScroll>
        </Activity>
        <ActivityVew
          className="noPrint"
          onClick={() => {
            setViewActivity();
            setTypeVeiw();
          }}
        >
          View full activity
        </ActivityVew>
      </LoadsBlock>
    </>
  );
};

export default LoadItemActivity;
