import * as React from "react"
import { SVGProps } from "react"

type IconProps = {
  isActive?: boolean;
};

const Loads: React.FC<IconProps> = ({ isActive }, props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      d="M23 12.714c0-1.59-1.35-2.66-2.603-2.97-.31-.07-.648-.169-.986-.267a29.23 29.23 0 0 0-1.14-.296C17.455 8.477 14.19 6 11.614 6c-.31 0-.634.014-1.042.028-.183.014-.38.014-.605.028-.24.014-.45.014-.62.028-.31.015-.52.029-.731.029-1.69 0-3.28.9-4.758 2.674-.014.014-.028.042-.056.056h-.028c-1.506 0-1.887.042-2.126.38-.464.634-.661 1.464-.647 2.576v.14c.028 1.296.07 3.73 2.393 3.73h.52a2.601 2.601 0 0 0 2.407 1.605c1.07 0 2.013-.661 2.407-1.604h5.87a2.601 2.601 0 0 0 2.406 1.604 2.588 2.588 0 0 0 2.421-1.646 60.421 60.421 0 0 1 1.647-.028c.437 0 1.084-.043 1.492-.48.437-.435.423-1.055.423-1.632v-.647l.014-.028v-.099Zm-5.995 1.309a.63.63 0 0 1 .633.633.63.63 0 0 1-.634.634.63.63 0 0 1-.633-.634.63.63 0 0 1 .634-.633Zm-8.263-.324c-.394-.97-1.351-1.646-2.42-1.646A2.61 2.61 0 0 0 3.9 13.684h-.506c-.113 0-.198 0-.282-.281-.127-.394-.127-1.042-.127-1.619 0-.436.057-.718.141-.943.394-.014.9-.028 1.098-.028.535 0 .816-.338 1.168-.774 1.07-1.281 2.196-1.957 3.252-1.957.239 0 .478-.014.816-.028.169-.014.366-.014.605-.028.226-.014.437-.014.62-.028.38-.014.675-.028.943-.028 1.506 0 3.913 1.421 4.842 2.223l.746.69.14.141.282.056c.056.014.197.057.408.099.493.127 1.323.338 1.9.492.521.127 1.098.507 1.112 1.028v.704c0 .112 0 .197-.014.253-.506 0-1.14.014-1.619.028a2.61 2.61 0 0 0-4.842 0h-5.84v.014Zm-2.42.324a.63.63 0 0 1 .633.633.63.63 0 0 1-.634.634.63.63 0 0 1-.633-.634.63.63 0 0 1 .633-.633Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
  </svg>
)

export default Loads
