import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import {
  AxiosResponseLogin,
  createChangeEmail,
  finishChangeEmail,
  getUser,
  loginUser,
  resetPassword,
  restorePassword,
  User,
} from '../../API/auth';

type InitialStateType = AxiosResponseLogin & {
  isLogin: boolean;
  user: User;
  loading: boolean;
  isReset: boolean;
  error: ErrorType | null;
};

const initialState: InitialStateType = {
  access_token: '',
  user: {
    id: 0,
    first_name: '',
    last_name: '',
    image: null,
    phone: '',
    email: '',
    usdot: '',
    company_name: '',
  },
  expire_in: 0,
  isLogin: false,
  loading: false,
  error: null,
  isReset: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogout: state => {
      state.isLogin = false;
      state.error = null;
      state.access_token = '';
    },
    setIsLogin: state => {
      state.isLogin = true;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLogin = true;
      state.loading = false;
      state.access_token = action.payload?.access_token;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(loginUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, state => {
      state.loading = false;
      state.isReset = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(resetPassword.pending, state => {
      state.loading = true;
    });
    builder.addCase(restorePassword.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(restorePassword.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(restorePassword.pending, state => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(createChangeEmail.fulfilled, state => {
      state.loading = false;
      state.isReset = true;
    });
    builder.addCase(createChangeEmail.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(createChangeEmail.pending, state => {
      state.loading = true;
    });
    builder.addCase(finishChangeEmail.fulfilled, state => {
      state.loading = false;
      state.isReset = true;
    });
    builder.addCase(finishChangeEmail.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(finishChangeEmail.pending, state => {
      state.loading = true;
    });
  },
});

export const { setLogout, setIsLogin } = authSlice.actions;

export default authSlice.reducer;
