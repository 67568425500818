import * as React from "react"
import { SVGProps } from "react"

const Import = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M10.123 8.965a.156.156 0 0 0-.246 0L7.69 11.733a.156.156 0 0 0 .123.252h1.443v4.734c0 .086.07.156.156.156h1.172c.086 0 .156-.07.156-.156v-4.732h1.448c.13 0 .203-.15.123-.252l-2.188-2.77Z"
      fill="#616172"
    />
    <path
      d="M15.848 7.162a6.252 6.252 0 0 0-5.844-4.037A6.25 6.25 0 0 0 4.16 7.16a3.908 3.908 0 0 0-2.91 3.777 3.905 3.905 0 0 0 3.904 3.907h.784c.085 0 .156-.07.156-.156v-1.172a.157.157 0 0 0-.157-.157h-.783a2.42 2.42 0 0 1-1.908-3.914 2.448 2.448 0 0 1 1.291-.853l.74-.194.272-.714a4.797 4.797 0 0 1 1.72-2.213 4.729 4.729 0 0 1 2.735-.864 4.73 4.73 0 0 1 2.734.864 4.798 4.798 0 0 1 1.721 2.213l.27.712.738.196a2.433 2.433 0 0 1 1.799 2.345c0 .647-.252 1.256-.71 1.713a2.404 2.404 0 0 1-1.71.71h-.784a.157.157 0 0 0-.156.156v1.171c0 .086.07.157.156.157h.784a3.907 3.907 0 0 0 1.002-7.682Z"
      fill="#616172"
    />
  </svg>
)

export default Import
