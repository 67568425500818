import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  BackBlock,
  DownloadSample,
  Block,
  InputFile,
  Browse,
  TypeFile,
  Errors,
  ErrorTitle,
  ErrorItem,
} from './importContacts.style';
import { importContact } from '../../../API/contacts';
import { Back, Download } from '../../../assets/icons';
import Container from '../../../layout/Container';
import { useAppDispatch } from '../../../store/hooks';

const ImportContacts: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [err, setErr] = useState({
    message: '',
    errors: {},
  });

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);

    const res: any = await dispatch(importContact(formData));
    if (res.type === 'contacts/importContact/rejected')
      return setErr(res.payload);
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <BackBlock onClick={() => navigate('/contacts')}>
              <Back fill="#616172" /> Import contacts
            </BackBlock>
            <DownloadSample href="/import_sample.csv" download>
              <Download /> Download sample
            </DownloadSample>
          </Top>
          <Block>
            <InputFile
              onChange={handleChange}
              type="file"
              accept=".xlsx, .xls, .csv, .txt"
            />
            <Browse>Browse</Browse>
            <TypeFile>Drag&Drop or upload CSV, XLS, XLSX file here</TypeFile>
          </Block>
          <Errors>
            <ErrorTitle>0:</ErrorTitle>
            {/* <ErrorItem>{err.errors}</ErrorItem> */}
          </Errors>
        </Wrapper>
      </Container>
    </>
  );
};

export default ImportContacts;
