import React from 'react';

import Header from '../../components/Header/Header';
import {
    AuthWrapper,
    Block,
} from '../Auth/auth.style';

const PrivacyPolice: React.FC = () => {
    return (
        <>
            <Header />
            <Block>
                <AuthWrapper>
                    <b>Privacy Policy</b><br /><br />
                    <p >Last Updated on Nov 08, 2022</p><br />
                    Myturbodispatch.com PRIVACY NOTICE<br /><br />
                    Welcome to  myturbodispatch.com the “Service”). The Service is owned and operated by TURBO DISPATCH INC, or one or more of its direct or indirect affiliates (collectively, the “Company”, “our”, “we”, or “us”).<br /><br />
                    We respect your privacy and are committed to protecting it through our compliance with this privacy notice (the “Privacy Notice”) for the Service. This Privacy Notice describes the types of information we may collect from you or that you may provide when you download, install, use, register for, or utilize functionalities of, the Service, and our practices for collecting, using, maintaining, protecting, and disclosing such information. We have created this Privacy Notice in order to inform you of our firm commitment to safeguarding the privacy and security of information you provide.<br /><br />
                    Please read this Privacy Notice carefully before using the Service. We will treat any information we collect from you through any part of the Service in accordance with this Privacy Notice. By accessing or using any part of the Service, you consent to all actions we may take with respect to your information in compliance with this Privacy Notice. If you are unwilling to accept the terms and conditions of this Privacy Notice, please do not use any part of the Service.<br /><br />
                    This Privacy Notice may change from time to time. Any changes to this Privacy Notice will be posted to this page. Your continued use of any part of the Service after we make changes is deemed to be acceptance of those changes, so please check this Privacy Notice for updates by clicking the “Privacy” link in the footer of the Company website. See Section 12 for more information on modifications to this Privacy Notice.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>1.	APPLICATION OF THIS NOTICE</h1><br />
                    This Privacy Notice applies to information collected through the Service, including the services provided via the Service and information exchanged by e-mail, text message, and other electronic messages between you and any part of the Service or the Company.<br /><br />
                    NOTE TO USERS FROM OUTSIDE THE UNITED STATES: This Privacy Notice and the Terms of Use  are governed by United States law, and we currently only accept business and visitors of the Service within the United States. Translations of the Service into languages other than English are for your convenience only. In the event you visit the Service from outside the United States, please know that your information may be transferred to, stored, and processed in the United States, where our servers are located and our central database is operated. The laws of the United States and other countries might not be as comprehensive as those in your country, but please be assured that we take steps to protect your privacy. By using the Service, you understand that your information may be transferred to our facilities, our affiliated companies, and to those third parties with whom we may share it as described in this Privacy Notice.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>2.	CHILDREN UNDER THE AGE OF 13</h1><br />
                    No part of the Service is intended or designed to attract children under the age of 13. We do not collect Personally Identifiable Information from any person we actually know is a child under the age of 13. If you are under the age of 13, do not use or provide any information on the Service or on or through any of its features, register to use the Service, use any of the interactive or public comment features of any part of the Service or provide any information about yourself to us, including your name, address, telephone number, e-mail address or any screen name or user name you may use.<br /><br />
                    If we learn we have collected or received Personally Identifiable Information from a child under the age of 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under the age of 13, please contact us by sending us an e-mail stating your request to <a href="mailto:myturbodispatch@gmail.com">myturbodispatch@gmail.com</a> or contacting us in writing at the address provided below.<br /><br />
                    By using any part of the Service, you represent and warrant that you meet all of the eligibility requirements set forth in the Terms of Use. If you do not meet all of the requirements, you must not access or use any part of the Service.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>3.	INFORMATION WE COLLECT</h1><br />
                    We collect several different types of information from and about users of the Service, including:<br /><br />
                    <ul style={{ marginLeft: "36px" }}>
                        <li>information that is about you but does not identify you individually (“Anonymous Information”); and</li>
                        <li>information by which you may be personally identified and is provided by you to us in certain areas of the Service (“Personally Identifiable Information”).</li><br />
                    </ul>
                    We collect this information in several ways:<br /><br />
                    <ul style={{ marginLeft: "36px" }}>
                        <li>directly from you when you provide it to us;</li>
                        <li>directly from your employer or the entity on whose behalf you are using the Service, if applicable, when they provide it to us;</li>
                        <li>automatically as you navigate through the Service (e.g., information collected automatically may include usage details, geolocation data, device information, IP address, and information collected through other tracking technologies); and</li>
                        <li>from third parties such as, for example, our business partners, drivers, brokers, shippers, service contractors and affiliates.</li>
                    </ul><br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "22px" }}>3.1 ANONYMOUS INFORMATION</h1><br />
                    As you navigate through and interact with the Service, we automatically collect certain Anonymous Information about your equipment, browsing actions, and patterns, including details of your visits to the Service, such as traffic data, and the resources that you access and use on the Service, as well as information about your computer and internet connection, including your IP address (a unique string of numbers that is automatically assigned to your computer by your Internet Service Provider), device data, geolocation data, operating system, and browser type. <br /><br />
                    The Anonymous Information we collect automatically does not identify any individual (however, in some cases, an IP address could be used to implicate the identity of an individual). It helps us to administer and improve the Service and the solutions we offer. We do not automatically collect Personally Identifiable Information (except in those situations where an IP address might implicate the identity of an individual), but we may link anonymous information to Personally Identifiable Information from time to time in the ordinary course of providing the Service to you. We use cookies and other automatic data collection tools to collect this Anonymous Information.<br /><br />
                    Cookies and Other Information Collection Tools<br /><br />
                    What are cookies? A cookie is a small file containing a string of characters that is sent to your computer when you visit a website or use an online service. When you visit the website or use the service again, the cookie allows that website or online service to recognize your browser or device. Cookies may store unique identifiers, user preferences and other information.<br /><br />
                    What types of cookies do we use?<br /><br />
                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ width: "25%", textAlign: "center", fontWeight: "bold" }}>Category of Cookies</p>	<p style={{ width: "80%", textAlign: "center", fontWeight: "bold" }}>Why we use these cookies</p></div>
                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ width: "25%" }}>Preferences</p>	<p style={{ width: "80%" }}>We use these cookies to remember user settings and preferences. For example, we may use these cookies to remember users’ language preferences.</p></div>
                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ width: "25%" }}>Security</p>	<p style={{ width: "80%" }}>We use these cookies to help identify and prevent security risks. For example, we may use these cookies to store users’ session information to prevent others from changing users’ password without their username and password.</p></div>
                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ width: "25%" }}>Performance</p>	<p style={{ width: "80%" }}>We use these cookies to collect information about how users interact with our services and to help us improve them. For example, we may use these cookies to determine if users have interacted with a certain page.</p></div>
                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ width: "25%" }}>Analytics</p>	<p style={{ width: "80%" }}>We use these cookies to help us improve our services. For example, we can use these cookies to learn more about which features are the most popular with our users and which ones might need some tweaks.</p></div>
                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ width: "25%" }}>Advertising</p>	<p style={{ width: "80%" }}>We use these cookies to deliver advertisements, to make them more relevant and meaningful to consumers, and to track the efficiency of our advertising campaigns, both on our Service and on other websites or mobile apps.<br /><br /></p></div>
                    How do I control cookies? You may set your browser to decline cookies, by using private browsing settings. Although in many cases you may refuse cookies and still fully navigate our Service, certain functionalities of the Service may not operate as intended without the use of cookies. Technologies used. The cookies and other technologies we use may collect geolocation information and the browsing clicks from your IP address during your visit to the Service in order to better understand your needs and interests so that we may serve you better. Information you provide through your account may be stored in our CRM system or the CRM systems of our affiliated companies, and we may link your past browsing information to your account. If you do not wish for us to have your Personally Identifiable Information, please do not fill out any of the forms on this Service.<br /><br />
                    We collect Personally Identifiable Information directly from you when you provide it to us and from third parties, for example, our business partners, drivers, brokers, shippers, service contractors, and affiliates. The Personally Identifiable Information we collect on or through the Service may include:<br /><br />
                    <ul style={{ marginLeft: "36px" }}>
                        <li>E-Mail Correspondence and Form Information. Scattered throughout the Service are links or forms that can be used to create an account with us or sign up for our carrier and shipper solutions and facilitate electronic proof of delivery documentation, as well as to contact us through the Service’s “Contact Us” page. We may collect from such forms your name, e-mail address, password, phone number, company name, company type, USDOT number, your role, and any other information you provide to us when you contact us. We may share such information with the relevant third parties involved in the respective order (e.g., drivers, brokers, shippers), but do not currently sell, license, or transmit this information to third parties for those third parties’ direct marketing activities or otherwise outside the scope of the Service without express authorization from you. By providing your e-mail address, you are requesting that we communicate with you in that manner. The Service may also be used to communicate with you in the following ways: <br /></li>
                        <li>Company Communication. We may communicate with you about the Services and let you know about terms and policies and other important updates.<br /></li>
                        <li>Administrator Communication. Your employer and/or its administrator may communicate with you via the Service.<br /></li>
                        <li>Third-Party Communication. Third parties involved in order may communicate with you via the Service.<br /></li>
                        <li>These communications are necessary to provide the Service, and as such, you cannot opt-out of receiving them. Therefore, if you do not wish to receive these communications, please discontinue using the Service.<br /></li>
                        <li>Geolocation Data. We may collect the geolocation of carriers to be able to offer them loads when they are in a particular area. We may also collect the geolocation of drivers with their consent and the explicit approval of carriers to provide the status of shipping. <br /><br /></li>
                    </ul>
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>4.	HOW WE USE AND/OR DISCLOSE INFORMATION YOU PROVIDE</h1><br />
                    The information you share with us may be used in a few ways:<br /><br />
                    <ul style={{ marginLeft: "36px" }}>
                        <li>Anonymous Information: Anonymous Information is used internally for administration and optimization of the Service, troubleshooting, and to help improve the quality of the Service and its design. We may use and share such aggregated, non-personally identifiable information, without restriction, including with existing and prospective third-party business partners, researchers, and advertisers.<br /></li>
                        <li>Personally Identifiable Information: We will only use or disclose your Personally Identifiable Information as permitted by law and our agreements with you, including this Privacy Notice, or as otherwise desirable to facilitate the processing of an order. Personally Identifiable Information collected by us through the Service or otherwise provided to us may be disclosed and used by us and our subsidiaries and affiliates as follows:<br /></li>
                        <li>to our subsidiaries and affiliates; for purposes including administration of the Service;<br /></li>
                        <li>to notify you about changes to the Service or any products or services we offer or provide through it;<br /></li>
                        <li>to allow you to communicate with us;<br /></li>
                        <li>to shippers, brokers, drivers, contractors, and service providers in connection with an order, as well as other third parties we use to support our business and who are bound by contractual obligations to keep Personally Identifiable Information confidential and to use it only for the purposes for which we disclose it to them;<br /></li>
                        <li>to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of assets of ours or of our subsidiaries or affiliates, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding in which Personally Identifiable Information about users of the Service is among the assets transferred;<br /></li>
                        <li>to fulfill the purpose for which you provide it;<br /></li>
                        <li>for any other purpose we may disclose when you provide the information; or<br /></li>
                        <li>otherwise with your consent.<br /><br /></li>
                    </ul>
                    We may also disclose Personally Identifiable Information if we are required to do so by law or if we in good faith believe that such action is necessary to (a) comply with any court order, law, or legal process, including to respond to any government or regulatory request; (b) protect and defend our rights and property; (c) protect against misuse or unauthorized use of the Service; or (d) protect the personal safety or property of our users or the public. Please note that we might not provide you with notice prior to disclosure in such cases.<br /><br />
                    We may also disclose your Personally Identifiable Information to enforce or apply our Terms of We may combine the information we collect with information we receive from third parties such as government agencies (e.g., the Federal Motor Carrier Safety Administration) to allow us to monitor and improve motor carrier safety,<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>5.	TRACKING TECHNOLOGIES AND OPTING OUT</h1><br />
                    We support the right of our visitors to choose. We strive to provide you with choices regarding the Personally Identifiable Information you provide to us. We have created mechanisms to provide you with the following control over your information:<br /><br />
                    <ul style={{ marginLeft: "36px" }}>
                        <li>Cookies and Other Automated Data Collection Technologies. Users can opt-out of cookies at any time. You can also set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. Users can use their browser settings to decide whether to turn on and off cookies on the Service. If you disable or refuse cookies, please note that some parts of the Service may then be inaccessible or not function properly. We may use Google Analytics to help analyze how users use the Service.If you would like to opt-out from the use of your information by Google Analytics.<br /></li>
                        <li>Geotracking Technologies. Users can opt-out of geotracking at any time. However, if you disable or refuse to permit geotracking, please note that the Service may then be inaccessible or not function properly.<br /></li>
                        <li>Research and Analytics. We may also work with other business partners to conduct research in order to improve the user experience on the Service. We encourage you to read the privacy statements of these business partners to learn about their data practices.<br /><br /></li>
                    </ul>
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>6.	SECURITY</h1> <br />
                    The Service incorporates safeguards designed to protect the security, integrity, and privacy of the Personally Identifiable Information we have collected. We have put in place reasonable precautions designed to protect information from loss, misuse, and alteration, including Transport Layer Security (TLS) technology on the Service. When we collect sensitive information provided when using the Service such information is encrypted during transmission. You can verify this by looking for a closed lock icon in your web browser, or looking for “https” at the beginning of the address of the web page. While on a secure page, the lock icon on web browsers such as Microsoft Edge, Google Chrome, and Mozilla Firefox becomes locked, as opposed to unlocked, or open when you are merely “surfing.”<br /><br />
                    The safety and security of your information also depend on you. Where we have given you (or where you have chosen) a password for access to any parts of the Service, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Service. The information you share in public areas may be viewed by any user of the Service.<br /><br />
                    Unfortunately, the transmission of information via the Internet is not completely secure. Although we strive to protect your Personally Identifiable Information, we cannot guarantee the security of your Personally Identifiable Information transmitted to the Service. Any transmission of Personally Identifiable Information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Service.<br /><br />
                    Once we receive your transmission, we will use reasonable efforts to ensure its security on our internal systems. Depending on the nature of the inquiry, your communication may be discarded or archived. Please note that your e-mail, like most, if not all, non-encrypted Internet communications, may be accessed and viewed by other Internet users without your knowledge and permission, while in transit to us. For that reason, to protect your privacy, please do not use e-mail to communicate information to us that you consider sensitive or confidential. If you wish, you may contact us instead via telephone at the numbers provided throughout the Service or at the address set forth below.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>7.	CONSENT TO RECEIVE PHONE AND TEXT MESSAGES</h1> <br />
                    By providing your phone number and clicking submit online, you agree to our Terms of Use and this Privacy Notice, and authorize us to make or initiate sales calls, text messages and pre-recorded voicemails to that phone number using an automated system. Your agreement is not a condition of purchasing any products, goods, or services and you may elect to do business with us over the phone. Message & data rates may apply.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>8.	EXTERNAL LINKS</h1> <br />
                    The Service may provide links to various websites that we do not control. When you click on one of these links, you will be transferred out of the Service and connected to the website of the organization that you selected.<br /><br />
                    We are not responsible for the nature, quality, or accuracy of the content or opinions expressed on such websites, and such websites are not investigated, monitored, or checked for quality, accuracy, or completeness by us.<br /><br />
                    Inclusion of any linked website on the Service does not imply or express our approval or endorsement of the linked website or any of the content, opinions, products, or services provided through the Service. We exercise no control over linked sites even if an affiliation exists between the Service and a third-party website.<br /><br />
                    Each of these linked sites maintains its own independent privacy and data collection policies and procedures. While we expect our business partners and affiliates to respect the privacy of our users, we cannot be responsible for the actions of third parties. If you visit a website that is linked to or from the Service, we encourage you to consult that website’s privacy policy before providing any Personally Identifiable Information and whenever interacting with any website.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>9.	CONSENT TO PRIVACY NOTICE; BINDING AGREEMENT</h1> <br />
                    All parts of the Service are controlled, operated, and administered entirely within the United States. By using any part of the Service, you signify your agreement to the terms of this Privacy Notice. If you do not agree with any provision of this Privacy Notice, please do not disclose any Personally Identifiable Information through the Service.<br /><br />
                    This Privacy Notice is part of our Terms of Use. The use of the Service is governed by our Terms of Use and the laws of the State of Illinois.<br /><br />
                    This Privacy Notice and the notices outlined herein are not intended to and do not create any contractual or other legal rights in or on behalf of any third party.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>10.	DO NOT TRACK</h1> <br />
                    The Service currently does not take any action when it receives a Do Not Track request. Do Not Track is a privacy preference that you can set in your web browser to indicate that you do not want certain information about your webpage visits collected across websites when you have not interacted with that service on the page. For details, including how to turn on Do Not Track, visit www.donottrack.us.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>11.	SPECIAL NOTICE TO CALIFORNIA RESIDENTS</h1> <br />
                    If you are a resident of California, under the California Consumer Privacy Act (“CCPA”), you have the rights to:<br /><br />
                    <ul style={{ marginLeft: "36px" }}>
                        <li>Know what Personally Identifiable Information about you the Company has collected, disclosed, or sold, if any, and to access your Personally Identifiable Information.<br /></li>
                        <li>Delete your Personally Identifiable Information.<br /></li>
                        <li>Opt-out of the sale of Personally Identifiable Information.<br /></li>
                        <li>Not be discriminated against for exercising any of the aforementioned rights.<br /><br /></li>
                    </ul>
                    To exercise the rights described above, you must submit a verifiable request to us through your account or at the following contact information and provide the requested information:<br /><br />
                    Toll-free telephone number: 847-636-8432<br /><br />
                    Email: myturbodispatch@gmail.com<br /><br />
                    Website: <a href="https://myturbodispatch.com/">https://myturbodispatch.com/</a><br /><br />
                    You may submit a request as frequently as you would like, but we are not required to respond to requests to provide Personally Identifiable Information to you more than twice in any 12-month period.<br /><br />
                    To submit a verifiable request, you will be asked to provide certain information to help us verify your identity. The information we ask you to provide to initiate a request may differ depending upon the type of request, the type, sensitivity, and value of the Personally Identifiable Information that is the subject of the request, and the risk of harm to you that may occur as a result of unauthorized access or deletion, among other factors. We may also require you to provide a written declaration that you are who you say you are.<br /><br />
                    You may also designate an authorized agent to make a request on your behalf by providing the agent with signed written permission to do so.<br /><br />
                    Company does not sell Personally Identifiable Information to third parties for monetary consideration. However, the collection of data on our behalf by certain of our third-party Service analytics or digital advertising service providers may be considered a “sale” under the CCPA. For example, as mentioned above, we use Google Analytics to help us understand how our Service is used. To opt-out of the sale of Personally Identifiable Information by Company, you may go to Company’s “Do Not Sell My Personal Information” web page. Company does not have actual knowledge that it sells Personally Identifiable Information about minors under the age of 16.<br /><br />
                    Company has collected the following Personally Identifiable Information in the preceding 12 months about consumers in the instances described in Section 3 (“Information We Collect”) above. In the preceding 12 months Company has also disclosed the categories of Personally Identifiable Information set forth below for its business purposes to the categories of third parties set forth in Section 4 “How We Use and/or Disclose Information You Provide” above.<br /><br />
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", columnGap: "10px" }}><p style={{ width: "28%", textAlign: "center", fontWeight: "bold" }}>Categories of Personal Information</p>	<p style={{ width: "36%", textAlign: "center", fontWeight: "bold" }}>Description</p> <p style={{ width: "36%", textAlign: "center", fontWeight: "bold" }}>Purpose(s)</p></div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px", columnGap: "10px" }}><p style={{ width: "28%" }}>Identifiers</p>	<p style={{ width: "40%" }}>Name, address, phone numbers, email address, IP address or other information that you choose to provide to us when contacting us</p>	<p style={{ width: "40%" }}>To provide the Service to you and improve the Service. To give each user a more consistent and personalized experience when interacting with us through the Service</p></div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px", columnGap: "10px" }}><p style={{ width: "35%" }}>Categories listed in the California Customer Records statute</p>	<p style={{ width: "52%" }}>Name, address, telephone number</p>	<p style={{ width: "50%" }}>For security purposes, to detect fraud or illegal activities, and for archival and backup purposes in connection with the provision of the Service</p></div>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px", columnGap: "10px" }}><p style={{ width: "35%" }}>Internet or other electronic network activity information</p>	<p style={{ width: "52%" }}>Pages viewed, device information such as browser type and language</p>	<p style={{ width: "50%" }}>To communicate with you. To troubleshoot problems, and enforce our Service Terms of Use</p></div>
                    <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}><p style={{ width: "34%" }}>Geolocation data</p>	<p style={{ width: "50%" }}>Geolocation data</p>	<p style={{ width: "50%" }}>To provide services relating to the solutions we offer.<br /><br /></p></div>
                    Under California Civil Code § 1798.83, residents of the State of California that have provided any Personally Identifiable Information to us and with whom we have an established business relationship have the right to request a list of all third parties to which we have disclosed Personally Identifiable Information during the preceding year for those third parties’ direct marketing purposes. If you are a resident of the State of California and would like to request such information, please send a request to myturbodispatch@gmail.com.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>12.	NEVADA RESIDENTS</h1> <br />
                    Under Nevada law, we do not sell your Personally Identifiable Information. However, if you are a Nevada resident, you may submit a request that we do not sell any Personally Identifiable Information we have collected about you by contacting us at the contact information set forth below.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>13.	MODIFICATIONS TO THIS PRIVACY NOTICE</h1> <br />
                    We may modify this Privacy Notice at any time, at our discretion, and modifications are effective upon being posted on any part of the Service.<br /><br />
                    It is our policy to post any changes we make to our privacy policies on this page. The date on which this Privacy Notice was last revised is identified at the top of the page. You are responsible for periodically visiting the Service and this Privacy Notice to check for any changes.<br /><br />
                    If we make material changes to how we treat our users’ Personally Identifiable Information, we will notify you through a notice on the Service’s home page or by other reasonable means, which may include e-mail to the e-mail address we have on file for you. You are responsible for ensuring we have an up-to-date active and deliverable e-mail address for you.<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>14.	ACCOUNT REVISIONS, REVOKING AND DELETING PERSONALLY IDENTIFIABLE INFORMATION</h1> <br />
                    We welcome your questions, comments, and all feedback pertaining to your privacy or any other issue with regard to the Service.<br /><br />
                    At any time, you may ask us to cease any further use of your e-mail address and direct us to delete any Personally Identifiable Information you have supplied. Although it is not always possible to remove or modify such information, we will make reasonable efforts to do so. In some cases, we cannot delete your Personally Identifiable Information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect. If you have given us information for one of those third parties we mentioned above and we have already passed it on, we may not be able to delete or change the information.<br /><br />
                    In the event that you have given us Personally Identifiable Information in the past and then have second thoughts or want to update it, or if you have any other questions regarding this Privacy Notice or any part of the Service, you may contact us by sending us an e-mail stating your request myturbodispatch@gmail.com  or by contacting us in writing at the address provided below:<br /><br />
                    Address:<br /><br />
                    Turbo Dispatch inc, 1204 Sterling dr , Mundelein IL 60060<br /><br />
                    <h1 style={{ marginLeft: "20px", fontSize: "24px" }}>15.	QUESTIONS/CONTACT US</h1> <br />
                    If you have any questions regarding this Privacy Notice, please contact us at myturbodispatch@gmail.com or in writing at the address provided below:<br /><br />
                    Turbo Dispatch inc<br /><br />
                    1204 Sterling dr, Mundelei, IL 60060<br /><br />
                    Copyright © 2022, Turbo Dispatch inc. All rights reserved.<br /><br />
                    Turbo Dispatch inc is a trademark of Turbo Dispatch inc or its affiliated companies. All other trademarks are the property of their respective owners.<br /><br />

                </AuthWrapper>
            </Block>
        </>
    );
};

export default PrivacyPolice;
