import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  Image,
  ImageDefault,
  ImageName,
  Input,
  Item,
  List,
  ListWrapper,
  Name,
  Tab,
  TabWrapper,
  Wrapper,
} from './driversList.style';
import { DriverItem } from '../../../API/drivers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showDriverInfo } from '../../../store/slices/driversSlice';

const DriversList: React.FC = () => {
  const [value, setValue] = useState<string>('');
  const [hover, setHover] = useState<number>();
  const [isActive, setIsActive] = useState<number>();
  const [tab, setTab] = useState<string>('active');

  const dispatch = useAppDispatch();

  const { driversList } = useAppSelector((state: { driver: any; }) => state.driver);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const filteredPersons = driversList.filter((item: any) => {
    return item.first_name.toLowerCase().includes(value.toLowerCase())
  });

  const handleClick = (elem: DriverItem) => {
    dispatch(
      showDriverInfo({
        id: elem.id,
        first_name: elem.first_name,
        last_name: elem.last_name,
        email: elem.email,
        phone: elem.phone,
        status: elem.status,
        image: elem.image,
        driver_license: elem.driver_license,
        medcard: elem.medcard,
        contract: elem.contract,
        deposit: elem.deposit,
        birthday: elem.hire_at,
        hire_at: elem.hire_at,
        driver_type: elem.driver_type,
        truck_id: elem.truck_id,
        trailer_id: elem.trailer_id,
      }),
    );
  };

  useEffect(() => {
    dispatch(showDriverInfo({}));
  }, []);

  return (
    <Wrapper>
      <Input
        type="text"
        value={value}
        onChange={handleInput}
        placeholder="Search..."
      />
      <TabWrapper>
        <Tab
          style={{
            color: tab === 'active' ? '#0E71EB' : '#616172',
            backgroundColor: tab === 'active' ? '#E9EAF8' : 'transparent',
          }}
          onClick={() => setTab('active')}
        >
          Active
        </Tab>
        <Tab
          style={{
            color: tab === 'inactive' ? '#0E71EB' : '#616172',
            backgroundColor: tab === 'inactive' ? '#E9EAF8' : 'transparent',
          }}
          onClick={() => setTab('inactive')}
        >
          Deactivated
        </Tab>
      </TabWrapper>
      <ListWrapper>
        <List>
          {filteredPersons.map((elem: any) => {
            return (
              <Item
                style={{
                  backgroundColor:
                    elem.id === isActive || elem.id === hover
                      ? '#f4f4f4'
                      : '#fff',
                  borderRadius: '4px',
                  display: tab === elem.status ? 'flex' : 'none',
                }}
                key={elem.id}
                onMouseEnter={() => setHover(elem.id)}
                onMouseLeave={() => setHover(0)}
                onClick={() => {
                  handleClick(elem);
                  setIsActive(elem.id);
                }}
              >
                {elem.image ? <Image src={elem.image} /> : <ImageDefault><ImageName>{elem.first_name[0]} {elem.last_name[0]}</ImageName></ImageDefault>}

                <Name>
                  {elem.first_name} {elem.last_name}
                </Name>
              </Item>
            );
          })}
        </List>
      </ListWrapper>
    </Wrapper>
  );
};

export default DriversList;
