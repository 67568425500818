import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 50%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const TopText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  padding: 24px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 20px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const FleetNumber = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 24px 12px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const FleetForm = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin: 0 24px 20px;
  max-height: 690px;

  @media screen and (max-width: 1280px) {
    max-height: 400px;
  }
`;

export const FleetBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FleetField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  top: 42px;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
  max-height: 37px;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 42px;
  right: 0;
  padding: 10px;
`;

export const FleetLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin: 20px 0 4px;
`;

export const FleetInput = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 8px 16px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputDate = styled(DatePicker)`
  width: 100%;
  min-height: 37px;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 7px 16px;
  background: transparent;
  cursor: pointer;
`;

export const FleetSelect = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
  min-height: 37px;
  max-height: 37px;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const TrailerBlock = styled.div`
  margin-top: 20px;
`;

export const Assign = styled.p`
  margin-top: 20px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  text-transform: uppercase;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -17px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const PaperWorkBlock = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #9292a4;
  margin-bottom: 12px;
  margin-top: 20px;
`;

export const InputField = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const FieldFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 8px;
`;

export const IconField = styled.div`
  cursor: pointer;
`;

export const FilePlace = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 0 0;
`;

export const InputFilePlace = styled.div`
  border: 2px dashed #c6c7da;
  min-height: 60px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

export const InputPlace = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextEmpty = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-left: 4px;
  word-break: break-word;
  width: 100%;
  padding-bottom: 21px;
`;

export const AttachFile = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DocumentName = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

export const DocumentText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  margin-left: 4px;
`;

export const BlueButton = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  cursor: pointer;
  padding-top: 21px;
`;