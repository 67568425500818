import styled from 'styled-components';

export const Block = styled.div`
  @media screen and (max-width: 720px) {
    padding-top: 80px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0;
  border: 1px solid #ccd6e0;
  background-color: #fff;
  max-width: 500px;
  border-radius: 8px;
  padding: 50px 40px 40px;

  @media screen and (max-width: 360px) {
    padding: 50px 16px 40px;
  }
`;

export const LogoWrapper = styled.div`
  transform: scale(2);
`;

export const Title = styled.p`
  margin-top: 32px;
  color: #09091a;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Text = styled.p`
  margin-top: 32px;
  color: #09091a;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  text-align: center;
`;

export const Bottom = styled.div`
  padding-top: 32px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const BottomText = styled.p`
  margin-top: 16px;
  color: #9292a4;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
`;
