import React, { useEffect, useState } from 'react';

import CargoInsurance from './CargoInsurance/CargoInsurance';
import CarrierInformation from './CarrierInformation/CarrierInformation';
import {
  Block,
  BorderBottom,
  Count,
  Item,
  Line,
  Tab,
  TabPanel,
  Text,
  Title,
  Wrapper,
} from './carrierProfile.style';
import Step from './Step/Step';
import USDOT from './USDOT/USDOT';
import W9 from './W9/W9';
import { getCarrierProfile } from '../../API/carrierProfile';
import SettingsContainer from '../../layout/SettingsContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const CarrierProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState<string>('Carrier information');
  const [tabValue, setTabValue] = useState<number>(0);

  const { data, loading } = useAppSelector(
    (state: { carrierProfile: any }) => state.carrierProfile,
  );

  useEffect(() => {
    dispatch(getCarrierProfile(null));
  }, []);

  if (loading) return <Preloader />;

  return (
    <>
      <SettingsContainer>
        <Wrapper>
          <Title>Profile</Title>
          <Text>
            Fill out all four steps to become a Super Carrier and get access to
            the Super Loadboard.
          </Text>
          <Block>
            <TabPanel
              variant="scrollable"
              value={tabValue}
              scrollButtons
              allowScrollButtonsMobile
            >
              <Tab
                style={{
                  color: tab === 'Carrier information' ? '#1267CF' : '#616172',
                }}
                onClick={() => {
                  setTab('Carrier information');
                  setTabValue(0);
                }}
              >
                Carrier information{' '}
                <Count
                  style={{
                    color:
                      tab === 'Carrier information'
                        ? '#0E71EB'
                        : data && data?.carrier_name
                        ? '#0BAA1B'
                        : '#616172',
                    backgroundColor:
                      tab === 'Carrier information'
                        ? '#B0DCFF'
                        : data && data?.carrier_name
                        ? 'rgba(11, 170, 27, 0.15)'
                        : '#e9eaf8',
                  }}
                >
                  {data && data?.carrier_name ? 'Done' : 'Pending'}
                </Count>
              </Tab>

              <Tab
                style={{
                  color: tab === 'Cargo Insurance' ? '#1267CF' : '#616172',
                }}
                onClick={() => {
                  setTab('Cargo Insurance');
                  setTabValue(1);
                }}
              >
                Cargo Insurance
                <Count
                  style={{
                    color:
                      tab === 'Cargo Insurance'
                        ? '#0E71EB'
                        : data && data?.cargo_limit
                        ? '#0BAA1B'
                        : '#616172',
                    backgroundColor:
                      tab === 'Cargo Insurance'
                        ? '#B0DCFF'
                        : data && data?.cargo_limit
                        ? 'rgba(11, 170, 27, 0.15)'
                        : '#e9eaf8',
                  }}
                >
                  {data && data?.cargo_limit ? 'Done' : 'Pending'}
                </Count>
              </Tab>
              <Tab
                style={{
                  color: tab === 'W9' ? '#1267CF' : '#616172',
                }}
                onClick={() => {
                  setTab('W9');
                  setTabValue(2);
                }}
              >
                W9
                <Count
                  style={{
                    color:
                      tab === 'W9'
                        ? '#0E71EB'
                        : data && data?.w9
                        ? '#0BAA1B'
                        : '#616172',
                    backgroundColor:
                      tab === 'W9'
                        ? '#B0DCFF'
                        : data && data?.w9
                        ? 'rgba(11, 170, 27, 0.15)'
                        : '#e9eaf8',
                  }}
                >
                  {data && data?.w9 ? 'Done' : 'Pending'}
                </Count>
              </Tab>

              <Tab
                style={{
                  color: tab === 'USDOT certificate' ? '#1267CF' : '#616172',
                }}
                onClick={() => {
                  setTab('USDOT certificate');
                  setTabValue(3);
                }}
              >
                USDOT certificate{' '}
                <Count
                  style={{
                    color:
                      tab === 'USDOT certificate'
                        ? '#0E71EB'
                        : data && data?.usdot_certificate
                        ? '#0BAA1B'
                        : '#616172',
                    backgroundColor:
                      tab === 'USDOT certificate'
                        ? '#B0DCFF'
                        : data && data?.usdot_certificate
                        ? 'rgba(11, 170, 27, 0.15)'
                        : '#e9eaf8',
                  }}
                >
                  {data && data?.usdot_certificate ? 'Done' : 'Pending'}
                </Count>
              </Tab>
            </TabPanel>
            <Line />
            <Item>
              {(() => {
                switch (tab) {
                  case 'Carrier information': {
                    return <CarrierInformation data={data} />;
                  }
                  case 'Cargo Insurance': {
                    return <CargoInsurance data={data} />;
                  }
                  case 'W9': {
                    return <W9 data={data} />;
                  }
                  case 'USDOT certificate': {
                    return <USDOT data={data} />;
                  }
                  default:
                    return null;
                }
              })()}
              <Step />
            </Item>
          </Block>
        </Wrapper>
      </SettingsContainer>
    </>
  );
};

export default CarrierProfile;
