import React from 'react';

import { Wrapper, Title } from './preloader.style';
import { PreloaderIcon } from '../../assets/icons';

const Preloader: React.FC = () => {
  return (
    <>
      <Wrapper>
        <PreloaderIcon />
        <Title>Loading...</Title>
      </Wrapper>
    </>
  );
};

export default Preloader;
