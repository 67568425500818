import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 40%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 60%;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const Title = styled.p`
  color: #09091a;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const ClaimBlock = styled.div`
  padding: 20px;
  overflow: scroll;
  height: 600px;

  @media screen and (max-width: 1024px) {
    height: 400px;
  }

  @media screen and (max-width: 540px) {
    padding: 16px;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LoadID = styled.p`
  position: relative;
  border: 1px solid #9292a4;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
  height: 38px;

  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

export const LoadName = styled.span`
  color: #09091a;
  margin-left: 5px;
  font-weight: 700;
`;

export const LoadsAssigned = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  cursor: pointer;
`;

export const LoadsPhone = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;
  margin-top: 10px;
`;

export const LoadsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 540px) {
    width: 100%;
    margin-top: 8px;
  }
`;

export const Block = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 8px 0;
  }
`;

export const BlockPhone = styled.div`
  display: flex;
  align-items: center;
`;

export const BlockColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    width: 50%;
    margin: 8px 0;
  }

  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

export const DriverContactTitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  word-wrap: break-word;
`;

export const DriverContactText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;

export const DriverSmallTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
`;

export const InputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  outline: none;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

export const FilePlace = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 0 0;
`;

export const InputFilePlace = styled.div`
  border: 2px dashed #c6c7da;
  min-height: 60px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

export const InputPlace = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
`;

export const TextEmpty = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-left: 4px;
  word-break: break-word;
  width: 100%;
  padding-bottom: 21px;
`;

export const AttachFile = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlueButton = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  cursor: pointer;
  padding-top: 21px;
`;

export const DocumentName = styled.a`
  display: flex;
  align-items: center;
  margin: 4px 0;
  text-decoration: none;
  cursor: pointer;
`;

export const DocumentText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  margin-left: 4px;
`;

export const More = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 30px;
  right: 0px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(73, 79, 89, 0.15);
  border-radius: 4px;
  padding: 12px 0 0;
  min-width: 160px;
  z-index: 99;
`;

export const MenuBlock = styled.div`
  margin-bottom: 12px;
  text-align: center;
`;

export const MenuItem = styled.p`
  padding: 8px 12px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #09091a;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
    color: #0e71eb;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 20px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

// Load Search
export const SelectedValue = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 40px 0 3px;
  height: 100%;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 90%;
  transform: translateY(-80%);
  right: 0;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(14, 26, 37, 0.25);
  padding: 12px 8px;
  width: 100%;
  z-index: 100;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 16px;
  outline: none;
  margin-bottom: 20px;

  &:focus {
    border: 1px solid #0e71eb;
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  color: #09091a;

  &:hover {
    background-color: #f4f4f4;
    color: #0e71eb;
  }
`;

export const Label = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
`;
