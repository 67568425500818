import { SVGProps } from "react"

const FleetNotes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 4H3.889A1.889 1.889 0 0 0 2 5.889V19.11A1.89 1.89 0 0 0 3.889 21H17.11A1.89 1.89 0 0 0 19 19.111V12.5"
      stroke="#9292A4"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.666 2.572a1.953 1.953 0 0 1 2.762 2.762l-8.746 8.745L8 15l.92-3.682 8.746-8.746Z"
      stroke="#9292A4"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FleetNotes
