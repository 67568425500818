import React, { ChangeEvent, useState } from 'react';

import {
  Wrapper,
  Title,
  InputTextArea,
  Line,
  Bottom,
  Actions,
  Cancel,
} from './modalAddComment.style';
import {
  getLoadData,
  updateDriverInstructions,
} from '../../../../API/loads';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  refAddNotes: any;
};

const ModalAddComment: React.FC<ModalAssignProps> = ({ close, refAddNotes }) => {
  const { loadId, loadInfo } = useAppSelector(
    (state: { loads: any }) => state.loads,
  );

  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string>(
    loadInfo?.load?.driver_instructions
      ? loadInfo?.load?.driver_instructions
      : '',
  );

  const handleConfirm = async () => {
    await dispatch(updateDriverInstructions([loadId.id, value]));

    await dispatch(getLoadData(loadId.id));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refAddNotes}>
        <Title>Add Note</Title>
        <Line />

        <InputTextArea
          rows={8}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setValue(e.target.value)
          }
          placeholder='Enter new text...'
          value={value}
        />

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalAddComment;
