import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Actions,
  BottomActions,
  BottomWrapper,
  FlexBetween,
  LoadsBig,
  LoadsSmall,
  Top,
  TopText,
  TopTitle,
  Wrapper,
} from './createLoad.style';
import Attachments from './LoadsAttachments/Attachments';
import LoadsCustomer from './LoadsCustomer/LoadsCustomer';
import LoadsDelivery from './LoadsDelivery/LoadsDelivery';
import LoadsDetail from './LoadsDetail/LoadsDetail';
import LoadsPayment from './LoadsPayment/LoadsPayment';
import LoadsPickup from './LoadsPickup/LoadsPickup';
import LoadsVehicle from './LoadsVehicle/LoadsVehicle';
import americaStates from '../../../americaStates.json';
import { addLoad, getDataForLoad } from '../../../API/loads';
import { Back, Check } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import Preloader from '../../../containers/Preloader/Preloader';
import Container from '../../../layout/Container';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetError } from '../../../store/slices/loadsSlice';

const CreateLoad: React.FC = () => {
  const { loadData, error, loading } = useAppSelector((state: { loads: any }) => state.loads);

  useEffect(() => {
    setMethodPayment(loadData['payment_methods']?.[0]?.title);
    setMethodPaymentId(loadData['payment_methods']?.[0]?.id);
    setTypeVehicleId(loadData['vehicle_types']?.[0]?.id);
    setTypeVehicle(loadData['vehicle_types']?.[0]?.title);
  }, [loadData]);

  useEffect(() => {
    dispatch(getDataForLoad(null));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, []);

  const [loadDetails, setLoadDetails] = useState({
    loadID: '',
    internalLoadID: '',
    driverInstructions: '',
  });

  const [inspectionType, setInspectionType] = useState<string>('standard');

  const [loadsPickup, setLoadsPickup] = useState({
    businessNamePickup: '',
    streetAddressPickup: '',
    cityPickup: '',
    zipPickup: '',
    buyerNumberPickup: '',
    notesPickup: '',
    fullNamePickup: '',
    emailPickup: '',
  });

  const [phonePickup, setPhonePickup] = useState<string>('');

  const [pickupDate, setPickupDate] = useState<Date>(new Date());
  const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());
  const [receiptDatePayment, setReceiptDatePayment] = useState<Date>(new Date());
  const [methodPayment, setMethodPayment] = useState<string>('');
  const [methodPaymentId, setMethodPaymentId] = useState<string>('');
  const [typeVehicleId, setTypeVehicleId] = useState<string>('');
  const [typeVehicle, setTypeVehicle] = useState<string>('');

  const [statePickup, setStatePickup] = useState<string>(americaStates[0]);
  const [stateDelivery, setStateDelivery] = useState<string>(americaStates[0]);
  const [stateCustomer, setStateCustomer] = useState<string>(americaStates[0]);

  const [loadsDelivery, setLoadsDelivery] = useState({
    businessNameDelivery: '',
    streetAddressDelivery: '',
    cityDelivery: '',
    zipDelivery: '',
    notesDelivery: '',
    fullNameDelivery: '',
    emailDelivery: '',
  });

  const [phoneDelivery, setPhoneDelivery] = useState<string>('');

  const [vehicle, setVehicle] = useState([
    {
      vin: '',
      year: '',
      make: '',
      model: '',
      color: '',
      type: typeVehicle,
      lot_number: '',
      price: '',
      inop: false,
      enclosed: false,
    },
  ]);

  const [loadsCustomer, setLoadsCustomer] = useState({
    businessNameCustomer: '',
    streetAddressCustomer: '',
    cityCustomer: '',
    zipCustomer: '',
    notesDelivery: '',
    fullNameCustomer: '',
    faxCustomer: '',
    emailCustomer: '',
  });

  const [phoneCustomer, setPhoneCustomer] = useState<string>('');

  const [loadsPayment, setLoadsPayment] = useState({
    paymentPrice: '',
    brokerFeePayment: '',
    driverPayPayment: '',
    notePayment: '',
    paidAmountPayment: '',
    receiptDatePayment: new Date(),
    referenceNumberPayment: '',
    invoiceIDPayment: '',
    notesPayment: '',
  });

  const [attachment, setAttachment] = useState([]);

  const addVehicleFields = () => {
    const object = {
      vin: '',
      year: '',
      make: '',
      model: '',
      color: '',
      type: typeVehicle,
      lot_number: '',
      price: '',
      inop: false,
      enclosed: false,
    };

    setVehicle([...vehicle, object]);
  };

  const removeVehicleFields = (index: number) => {
    const data = [...vehicle];
    data.splice(index, 1);
    setVehicle(data);
  };

  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state: any) => {
    if (name === 'businessNamePickup')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'cityPickup')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'fullNamePickup')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'businessNameCustomer')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'cityCustomer')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'fullNameCustomer')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'businessNameDelivery')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'cityDelivery')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'fullNameDelivery')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else setState({ ...state, [name]: e.target.value });
  };

  const handleDetailCheckbox = (e: React.ChangeEvent<HTMLInputElement>, setState: any) => {
    setState(e.target.value);
  };

  const handleInputVehicle = (e: React.ChangeEvent<HTMLInputElement>, setState: any, index: number) => {
    const data: any = [...vehicle];
    if (e.target.name === 'year') {
      data[index][e.target.name] = e.target.value.slice(0, 4);
      setState(data);
    } else if (e.target.name === 'make') {
      data[index][e.target.name] = e.target.value.replace(/[^A-Za-z\s]/gi, '');
      setState(data);
    } else if (e.target.name === 'color') {
      data[index][e.target.name] = e.target.value.replace(/[^A-Za-z\s]/gi, '');
      setState(data);
    } else {
      data[index][e.target.name] = e.target.value;
      setState(data);
    }
  };

  const handleCheckboxVehicle = (e: React.ChangeEvent<HTMLInputElement>, setState: any, index: number, elem: any) => {
    const data: any = [...vehicle];
    data[index][e.target.name] = !elem;
    setState(data);
  };

  const handleInputTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, setState: any, name: any, state: any) =>
    setState({ ...state, [name]: e.target.value });

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const changeSelectVehicle = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
    index: number,
    setStateIndex: any
  ) => {
    const data: any = [...vehicle];
    data[index][e.target.name] = e.target.value;
    setState(() => data);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const handleConfirm = async () => {
    const formData = new FormData();
    formData.append('general[load_id]', loadDetails.loadID);
    formData.append('general[internal_load_id]', loadDetails.internalLoadID);
    formData.append('general[inspection_type]', inspectionType);
    formData.append('general[driver_instructions]', loadDetails.driverInstructions);
    formData.append('pickup[business_name]', loadsPickup.businessNamePickup);
    formData.append('pickup[street_address]', loadsPickup.streetAddressPickup);
    formData.append('pickup[city]', loadsPickup.cityPickup);
    formData.append('pickup[state]', statePickup);
    formData.append('pickup[zip]', loadsPickup.zipPickup);
    formData.append('pickup[date]', moment(pickupDate).format('DD.MM.YYYY'));
    formData.append('pickup[buyer_number]', loadsPickup.buyerNumberPickup);
    formData.append('pickup[notes]', loadsPickup.notesPickup);
    formData.append('pickup[full_name]', loadsPickup.fullNamePickup);
    formData.append('pickup[phone]', phonePickup);
    formData.append('pickup[email]', loadsPickup.emailPickup);
    formData.append('delivery[business_name]', loadsDelivery.businessNameDelivery);
    formData.append('delivery[street_address]', loadsDelivery.streetAddressDelivery);
    formData.append('delivery[city]', loadsDelivery.cityDelivery);
    formData.append('delivery[state]', stateDelivery);
    formData.append('delivery[zip]', loadsDelivery.zipDelivery);
    formData.append('delivery[date]', moment(deliveryDate).format('DD.MM.YYYY'));
    formData.append('delivery[notes]', loadsDelivery.notesDelivery);
    formData.append('delivery[full_name]', loadsDelivery.fullNameDelivery);
    formData.append('delivery[phone]', phoneDelivery);
    formData.append('delivery[email]', loadsDelivery.emailDelivery);
    formData.append('customer[business_name]', loadsCustomer.businessNameCustomer);
    formData.append('customer[street_address]', loadsCustomer.streetAddressCustomer);
    formData.append('customer[city]', loadsCustomer.cityCustomer);
    formData.append('customer[state]', stateCustomer);
    formData.append('customer[zip]', loadsCustomer.zipCustomer);
    formData.append('customer[fax]', loadsCustomer.faxCustomer);
    formData.append('customer[full_name]', loadsCustomer.fullNameCustomer);
    formData.append('customer[phone]', phoneCustomer);
    formData.append('customer[email]', loadsCustomer.emailCustomer);
    formData.append('payment[price]', loadsPayment.paymentPrice);
    formData.append('payment[broker_fee]', loadsPayment.brokerFeePayment);
    formData.append('payment[method]', methodPaymentId);
    formData.append('payment[methodName]', methodPayment);
    formData.append('payment[driver_pay]', loadsPayment.driverPayPayment);
    formData.append('payment[notes]', loadsPayment.notePayment);
    formData.append('payment[paid_amount]', loadsPayment.paidAmountPayment);
    formData.append('payment[date]', moment(receiptDatePayment).format('DD.MM.YYYY'));
    formData.append('payment[reference_number]', loadsPayment.referenceNumberPayment);
    formData.append('payment[invoice_id]', loadsPayment.invoiceIDPayment);
    formData.append('payment[invoice_notes]', loadsPayment.notesPayment);
    attachment.forEach((elem: any) => {
      formData.append('attachments[]', elem);
    });
    vehicle.forEach((elem: any, i: number) => {
      const vehicleId = loadData?.vehicle_types.find((types: any) => types.title === elem.type)?.id;

      formData.append(`vehicles[${i}][vin]`, elem.vin);
      formData.append(`vehicles[${i}][year]`, elem.year);
      formData.append(`vehicles[${i}][make]`, elem.make);
      formData.append(`vehicles[${i}][model]`, elem.model);
      formData.append(`vehicles[${i}][type]`, vehicleId);
      formData.append(`vehicles[${i}][color]`, elem.color);
      formData.append(`vehicles[${i}][lot_number]`, elem.lot_number);
      formData.append(`vehicles[${i}][price]`, elem.price);
      formData.append(`vehicles[${i}][inop]`, elem.inop ? '1' : '0');
      formData.append(`vehicles[${i}][enclosed]`, elem.enclosed ? '1' : '0');
    });
    const res: any = await dispatch(addLoad(formData));
    if (res.type === 'loads/addLoad/rejected') return;
    navigation('/loads');
  };

  if (loading) return <Preloader />;

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <TopTitle onClick={() => navigation('/loads')}>
              <Back fill="#616172" /> <TopText>Create load</TopText>
            </TopTitle>
            <Actions>
              <Button text="Save new Load" pad="4px 20px" icon={<Check />} handleButton={handleConfirm} />
            </Actions>
          </Top>

          <LoadsBig>
            <LoadsDetail
              handleInput={handleInput}
              handleInputTextArea={handleInputTextArea}
              handleDetailCheckbox={handleDetailCheckbox}
              loadDetails={loadDetails}
              setLoadDetails={setLoadDetails}
              inspectionType={inspectionType}
              setInspectionType={setInspectionType}
              error={error}
            />
          </LoadsBig>

          <FlexBetween>
            <LoadsSmall>
              <LoadsPickup
                handleInput={handleInput}
                handleInputTextArea={handleInputTextArea}
                loadPickup={loadsPickup}
                setLoadPickup={setLoadsPickup}
                pickupDate={pickupDate}
                setPickupDate={setPickupDate}
                phonePickup={phonePickup}
                setPhonePickup={setPhonePickup}
                statePickup={statePickup}
                setStatePickup={setStatePickup}
                changeSelectState={changeSelectState}
                americaStates={americaStates}
                error={error}
              />
            </LoadsSmall>

            <LoadsSmall>
              <LoadsDelivery
                handleInput={handleInput}
                handleInputTextArea={handleInputTextArea}
                loadDelivery={loadsDelivery}
                setLoadDelivery={setLoadsDelivery}
                deliveryDate={deliveryDate}
                setDeliveryDate={setDeliveryDate}
                phoneDelivery={phoneDelivery}
                setPhoneDelivery={setPhoneDelivery}
                stateDelivery={stateDelivery}
                setStateDelivery={setStateDelivery}
                changeSelectState={changeSelectState}
                americaStates={americaStates}
                error={error}
              />
            </LoadsSmall>
          </FlexBetween>

          <LoadsBig>
            <LoadsVehicle
              handleInputVehicle={handleInputVehicle}
              changeSelectVehicle={changeSelectVehicle}
              handleCheckboxVehicle={handleCheckboxVehicle}
              vehicle={vehicle}
              setVehicle={setVehicle}
              addVehicleFields={addVehicleFields}
              removeVehicleFields={removeVehicleFields}
              loadData={loadData}
              setTypeVehicleId={setTypeVehicleId}
              typeVehicle={typeVehicle}
              error={error}
            />
          </LoadsBig>

          <FlexBetween>
            <LoadsSmall>
              <LoadsCustomer
                handleInput={handleInput}
                loadsCustomer={loadsCustomer}
                setLoadsCustomer={setLoadsCustomer}
                phoneCustomer={phoneCustomer}
                setPhoneCustomer={setPhoneCustomer}
                stateCustomer={stateCustomer}
                setStateCustomer={setStateCustomer}
                changeSelectState={changeSelectState}
                americaStates={americaStates}
                error={error}
              />
              <Attachments attachment={attachment} setAttachment={setAttachment} />
            </LoadsSmall>
            <LoadsSmall>
              <LoadsPayment
                handleInput={handleInput}
                handleInputTextArea={handleInputTextArea}
                changeSelectState={changeSelectState}
                setMethodPaymentId={setMethodPaymentId}
                loadsPayment={loadsPayment}
                setLoadsPayment={setLoadsPayment}
                receiptDatePayment={receiptDatePayment}
                setReceiptDatePayment={setReceiptDatePayment}
                methodPayment={methodPayment}
                setMethodPayment={setMethodPayment}
                loadData={loadData}
                error={error}
              />
            </LoadsSmall>
          </FlexBetween>

          <BottomWrapper>
            <BottomActions>
              <Button text="Save new Load" pad="4px 20px" icon={<Check />} handleButton={handleConfirm} />
            </BottomActions>
          </BottomWrapper>
        </Wrapper>
      </Container>
    </>
  );
};

export default CreateLoad;
