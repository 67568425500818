import * as React from "react"
import { SVGProps } from "react"

const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.667 6.667a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-.73 4.213a5.333 5.333 0 1 1 .943-.943l3.591 3.592a.667.667 0 1 1-.942.943L9.937 10.88Z"
      fill="#09091A"
    />
  </svg>
)

export default Search
