import { SVGProps } from 'react';

const DashboardToDeliver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 10.667 14.254 4v4C7.878 8 4 13.09 4 20c2.814-4.64 6.193-7 10.254-7v4L21 10.667Z"
      fill="#4A8AFF"
    />
  </svg>
);

export default DashboardToDeliver;
