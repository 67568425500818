import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-left: 32px;
  overflow: hidden;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;

  @media screen and (max-width: 600px) {
    margin-bottom: 16px;
  }
`;

export const Length = styled.span`
  color: #616172;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 20px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ReportList = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ReportItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  width: 23%;
  height: fit-content;

  @media screen and (max-width: 1280px) {
    width: 48%;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ReportTitle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  margin-bottom: 12px;
`;

export const ReportTextBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

export const ReportBoldText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-left: 5px;
`;

export const ReportText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const ButtonMore = styled.div`
padding: 8px 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  background-color: #616172;
  border-radius: 4px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
`;
