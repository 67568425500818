import * as React from "react"
import { SVGProps } from "react"

type IconProps = {
  isActive?: boolean;
};

const Wheel: React.FC<IconProps> = ({ isActive }, props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1ZM3.56 9.658a1 1 0 0 1 1.282-.598l5.5 2a1 1 0 0 1-.684 1.88l-5.5-2a1 1 0 0 1-.598-1.282ZM20.44 9.658a1 1 0 0 1-.598 1.282l-5.5 2a1 1 0 1 1-.684-1.88l5.5-2a1 1 0 0 1 1.282.598Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
  </svg>
)

export default Wheel
