import styled from 'styled-components';

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 14px;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const CheckBoxItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CustomCheckBoxSquare = styled.span`
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const CheckBoxSquare = styled.input`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 26px;
  height: 26px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked + span {
    background-color: #0e71eb;
    border: 1px solid transparent;
  }
`;