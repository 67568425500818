import * as React from 'react';
import { SVGProps } from 'react';

const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.28024L3.48679 11.5L2 10.1099L8 4.5L14 10.1099L12.5132 11.5L8 7.28024Z"
        fill="#616172"
    />
  </svg>
);

export default ArrowUp;
