import React from 'react';

import { Wrapper, TopText, Title, Terms, Line, Actions, Button } from './modalDeleteVehicle.style';
import { detachVehicle, getLoadData } from '../../../../API/loads';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  vehicleId: any;
  vehicleYear: any;
  vehicleModel: any;
  vehicleMake: any;
  refDeleteVehicle: any;
};

const ModalDeleteVehicle: React.FC<ModalAssignProps> = ({
  close,
  vehicleId,
  vehicleYear,
  vehicleModel,
  vehicleMake,
  refDeleteVehicle,
}) => {
  const dispatch = useAppDispatch();
  const { loadId } = useAppSelector((state: { loads: any }) => state.loads);

  const handleConfirm = async () => {
    await dispatch(detachVehicle({ loadId: loadId.id, vehicleId }));
    await dispatch(getLoadData(loadId.id));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refDeleteVehicle}>
        <TopText>
          <Title>Delete</Title> {vehicleYear + ' ' + vehicleMake + ' ' + vehicleModel}
        </TopText>
        <Terms>This vehicle will be deleted from this load.</Terms>

        <Line />

        <Actions>
          <Button onClick={handleConfirm}>Yes</Button>
          <Button onClick={close}>No</Button>
        </Actions>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalDeleteVehicle;
