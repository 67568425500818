import * as React from "react"
import { SVGProps } from "react"

const Camera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.75 9.688h-5.313L27.172 6.14a1.253 1.253 0 0 0-1.18-.829H14.008c-.527 0-1 .333-1.176.829l-1.27 3.546H6.25a3.124 3.124 0 0 0-3.125 3.126v17.812A3.124 3.124 0 0 0 6.25 33.75h27.5a3.124 3.124 0 0 0 3.125-3.125V12.812a3.124 3.124 0 0 0-3.125-3.124Zm.313 20.937c0 .172-.141.313-.313.313H6.25a.313.313 0 0 1-.313-.313V12.812c0-.171.141-.312.313-.312h7.293l.668-1.867.894-2.508h9.786l.894 2.508.668 1.867h7.297c.172 0 .313.14.313.313v17.812ZM20 15a6.248 6.248 0 0 0-6.25 6.25A6.248 6.248 0 0 0 20 27.5a6.248 6.248 0 0 0 6.25-6.25A6.248 6.248 0 0 0 20 15Zm0 10a3.751 3.751 0 0 1 0-7.5 3.751 3.751 0 0 1 0 7.5Z"
      fill="#9292A4"
    />
  </svg>
)

export default Camera
