import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { changePassword, DispatcherProfile, getDispatcherProfile, saveDispatcherProfile } from '../../API/dispatcherProfile';

type InitialStateType = {
  data: DispatcherProfile;
  loading: boolean;
  error: ErrorType | null;
};

const initialState: InitialStateType = {
  data: {
    name: '',
    phone: '',
    email: '',
  },
  loading: false,
  error: null,
};

export const dispatcherProfileSlice = createSlice({
  name: 'dispatcherProfile',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getDispatcherProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
      builder.addCase(getDispatcherProfile.pending, state => {
        state.loading = true;
      });
      builder.addCase(getDispatcherProfile.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
      builder.addCase(saveDispatcherProfile.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
      builder.addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
  },
});

export default dispatcherProfileSlice.reducer;
