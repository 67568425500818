import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 600px;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
  max-height: 90%;
  overflow: auto;
  text-align: center;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  @media screen and (max-width: 768px) {
    width: 60%;
  }
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  margin: 0 23px 30px;
`;

export const MainText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  color: #09091a;
  margin: 0 23px 0;
  overflow: hidden;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: #f4f4f4;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 20px 0 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;
