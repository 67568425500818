import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 50%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const TopText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  padding: 20px 20px 24px 20px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const InputField = styled.div`
  padding: 0 24px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
  border: 1px solid #c6c7da;
  padding: 10px;
  outline: none;
  color: #09091a;
  margin-bottom: 24px;
`;

export const ReportBlock = styled.div`
  height: 530px;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    height: 420px;
  }
`;

export const ReportBlockScroll = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
  height: 100%;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const ReportTabs = styled.div`
  width: 20%;
  border-right: 1px solid #e9eaf8;

  @media screen and (max-width: 720px) {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #e9eaf8;
    display: flex;
    justify-content: space-between;
  }
`;

export const ReportTab = styled.div`
  padding: 25px 24px;
  height: 78px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  @media screen and (max-width: 720px) {
    display: inline;
    height: auto;
    padding: 12px 18px;
  }

  @media screen and (max-width: 425px) {
    padding: 12px;
  }
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 24px;
  background-color: #0e71eb;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const ReportData = styled.div`
  width: 80%;
  padding: 18px;

  @media screen and (max-width: 720px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 12px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: space-between;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    margin: 8px 0;
    width: 100%;
    justify-content: space-between;
  }
`;

export const FieldSelect = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #0e71eb;
  cursor: pointer;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const ButtonWrapper = styled.div`
  max-width: 124px;
`;

export const Error = styled.span`
  position: absolute;
  bottom: 5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;
