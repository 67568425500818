import * as React from "react"
import { SVGProps } from "react"

const Plus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M9.167 1.667h1.667v16.667H9.167z" />
    <path fill="#fff" d="M18.333 9.167v1.667H1.666V9.167z" />
  </svg>
)

export default Plus
