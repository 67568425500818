import * as React from "react"
import { SVGProps } from "react"

type IconProps = {
  isActive?: boolean;
};

const Fleet: React.FC<IconProps> = ({ isActive }, props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 17a3 3 0 0 0 6 0h12v-2h-3V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11h2Zm13-2V6H4v9h.764a3 3 0 0 1 4.472 0H17ZM6 17a1 1 0 1 0 2 0 1 1 0 0 0-2 0Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
  </svg>
)

export default Fleet
