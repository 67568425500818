import React, { useState } from 'react';

import { ArrowDown, ArrowUp } from '../../../../assets/icons';
import {
  CarouselRoot,
  FlexBetween,
  ImageContainer,
  LoadsBlock,
  LoadsFlexColumn,
  LoadsInspection,
  LoadsInspectionBlock,
  LoadsTextSemiBold,
  LoadsTitle,
  SliderBlock,
  VehicleItemText,
} from '../loadsItem.style';

type LoadsItemProps = {
  setShowSlider: () => void;
  showSlider: any;
  loadInfo: any;
  classes?: {
    root: string;
  };
};

const LoadsItemInspectionDetails: React.FC<LoadsItemProps> = ({ loadInfo, classes }) => {
  const [activeSliderList, setActiveSliderList] = useState<any>({});

  const handleActiveSliderClick = (vehicleId: number, status: boolean = false) => {
    setActiveSliderList((_prev) => ({
      ..._prev,
      [vehicleId]: status,
    }));
  };

  const vehicleContent = (vehicle) => {
    const { id = '', year = '', make = '', model = '', lot_number = '' } = vehicle;

    return (
      <LoadsInspection key={id}>
        <LoadsInspectionBlock onClick={() => handleActiveSliderClick(id, !activeSliderList[id])}>
          <LoadsTextSemiBold style={{ display: 'flex' }}>
            {`${year} ${make} ${model} `}
            <VehicleItemText>{lot_number}</VehicleItemText>
          </LoadsTextSemiBold>
          {activeSliderList[id] ? <ArrowUp /> : <ArrowDown />}
        </LoadsInspectionBlock>
        <SliderBlock isOpen={activeSliderList[id]}>
          <CarouselRoot infiniteLoop interval={1000} transitionTime={1000} showIndicators={false} showStatus={false}>
            {loadInfo?.load?.vehicles.length > 0 &&
              vehicle?.inspections?.images.map(({ id, image }) => {
                return (
                  <ImageContainer key={id}>
                    <img src={image} />
                  </ImageContainer>
                );
              })}
          </CarouselRoot>
        </SliderBlock>
      </LoadsInspection>
    );
  };

  return (
    <LoadsBlock className={classes && classes.root}>
      <FlexBetween>
        <LoadsTitle>Inspection Details</LoadsTitle>
      </FlexBetween>
      <LoadsFlexColumn>
        {loadInfo?.load?.vehicles.map((vehicle) => vehicle?.inspections?.images && vehicleContent(vehicle))}
      </LoadsFlexColumn>
    </LoadsBlock>
  );
};

export default LoadsItemInspectionDetails;
