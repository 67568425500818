import styled from 'styled-components';

export const LoadsBlock = styled.div`
  padding: 18px 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
`;

export const LoadsTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #9292a4;
`;

export const BlueButton = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  cursor: pointer;
  padding-top: 21px;
`;

export const LoadsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadsImagePlace = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 0 0;
`;

export const LoadsInputFile = styled.div`
  border: 2px dashed #c6c7da;
  min-height: 60px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

export const InputPlace = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
`;

export const DeliverText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  margin-left: 4px;
`;

export const DeliverTextEmpty = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-left: 4px;
  word-break: break-word;
  width: 100%;
  padding-bottom: 21px;
`;

export const LoadsAttachFile = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: ${(props) => (props.clickable ? 'pointer' : 'unset')};
`;

export const DocumentName = styled.div`
  display: flex;
  align-items: center;
`;
