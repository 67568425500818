import * as React from 'react';
import { SVGProps } from 'react';

type IconProps = {
  isActive?: boolean;
};

const Dashboard: React.FC<IconProps> = (
  { isActive },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4v4h4V4H4ZM3 2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3ZM14 4v7h6V4h-6Zm-1-2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-8ZM14 17v3h6v-3h-6Zm-1-2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-8ZM4 14v6h4v-6H4Zm-1-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1H3Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
  </svg>
);

export default Dashboard;
