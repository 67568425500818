import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export const routes = {
  dispatcherProfile: 'api/crm/user/dispatcher_profile',
  changePassword: 'api/auth/password/change',
};

export type DispatcherProfile = {
  name: string;
  phone: string;
  email: string;
};

export type ChangePassword = {
  old_password: string;
  password: string;
  password_confirmation: string;
};

export const getDispatcherProfile = createAsyncThunk<
  DispatcherProfile,
  null,
  {
    rejectValue: ErrorType;
  }
>('dispatcherProfile/getDispatcherProfile', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.dispatcherProfile}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveDispatcherProfile = createAsyncThunk<
  null,
  DispatcherProfile,
  {
    rejectValue: ErrorType;
  }
>('dispatcherProfile/saveDispatcherProfile', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.dispatcherProfile}`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const changePassword = createAsyncThunk<
  null,
  ChangePassword,
  {
    rejectValue: ErrorType;
  }
>('dispatcherProfile/changePassword', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.changePassword}`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});