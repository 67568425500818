import * as React from "react"
import { SVGProps } from "react"

const Print = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="M13.126 2.5a1.875 1.875 0 0 1 1.875 1.875v.628h.625a2.712 2.712 0 0 1 2.71 2.708l.003 4.997a1.874 1.874 0 0 1-1.874 1.875H15v1.042a1.875 1.875 0 0 1-1.875 1.875h-6.25A1.875 1.875 0 0 1 5 15.625v-1.042H3.541a1.875 1.875 0 0 1-1.875-1.875V7.712a2.708 2.708 0 0 1 2.708-2.709H5v-.628A1.875 1.875 0 0 1 6.874 2.5h6.252Zm-.002 8.75h-6.25a.625.625 0 0 0-.625.625v3.75c0 .345.28.625.625.625h6.25a.625.625 0 0 0 .625-.625v-3.75a.625.625 0 0 0-.625-.625Zm2.502-4.997H4.374a1.458 1.458 0 0 0-1.458 1.459v4.996c0 .345.28.625.625.625h1.458v-1.458A1.875 1.875 0 0 1 6.874 10h6.25A1.875 1.875 0 0 1 15 11.875v1.458h1.486a.624.624 0 0 0 .604-.624l-.003-4.997a1.462 1.462 0 0 0-1.46-1.459Zm-2.5-2.503H6.874a.625.625 0 0 0-.625.625v.628h7.502v-.628a.625.625 0 0 0-.625-.625Z"
      fill="#616172"
    />
  </svg>
)

export default Print
