import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Action,
  Actions,
  Car,
  DetailBlock,
  Details,
  DetailText,
  DetailTitle,
  DriverExtra,
  DriverInfo,
  LoadID,
  LoadName,
  LoadsDays,
  LoadsFlexColumn,
  LoadsFlexColumnRight,
  Status,
  Top,
  Wrapper,
} from './loadItem.style';
import ModalTripAction from '../../../../layout/Modal/ModalTrip/ModalTripAction';
import useOnClickOutside from '../../../../layout/Modal/useClickOutsideModal';

type LoadItemProps = {
  elem: any;
  tripId: number;
};

const LoadItem: React.FC<LoadItemProps> = ({ elem, tripId }) => {
  const [open, setOpen] = useState<boolean>(false);

  const refTrips = useRef<any>();

  const navigation = useNavigate();

  const openModal = () => setOpen(true);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useOnClickOutside(refTrips, () => setOpen(false));

  return (
    <>
      <Wrapper>
        <Top>
          <LoadID>
            Load ID:<LoadName>{elem?.load_id}</LoadName>
          </LoadID>
          <Actions>
            <Action onClick={() => navigation(`/loads/${elem.id}`)}>Edit</Action>
            <Action style={{ color: '#F30F00', marginRight: 0 }} onClick={openModal}>
              Remove
            </Action>
          </Actions>
        </Top>
        <DriverInfo>
          <Top>
            <LoadsFlexColumn>
              <DriverExtra>
                <Status
                  style={{
                    color: elem?.status === 'New' ? '#0e71eb' : elem?.status === 'Archived' ? '#9292A4' : '#0baa1b',
                    backgroundColor:
                      elem?.status === 'New'
                        ? '#b0dcff'
                        : elem?.status === 'Archived'
                          ? '#E9EAF8'
                          : 'rgba(11, 170, 27, 0.15)',
                  }}
                >
                  {elem?.status}
                </Status>
                <Car>{elem?.vehicles[0]?.year + ' ' + elem?.vehicles[0]?.make + ' ' + elem?.vehicles[0]?.model}</Car>
              </DriverExtra>
            </LoadsFlexColumn>
            <LoadsFlexColumnRight>
              <LoadsDays>
                {elem?.payment.price} ({elem?.payment.method})
              </LoadsDays>
            </LoadsFlexColumnRight>
          </Top>
          <Details>
            <DetailBlock>
              <DetailTitle>ORIGIN</DetailTitle>
              <DetailText>
                {elem?.points[0].street_address} <br />
                {elem?.points[0].state + ', ' + elem?.points[0].zip}
              </DetailText>
            </DetailBlock>
            <DetailBlock>
              <DetailTitle>DESTINATION</DetailTitle>
              <DetailText>
                {elem?.points[1].street_address} <br />
                {elem?.points[1].state + ', ' + elem?.points[1].zip}
              </DetailText>
            </DetailBlock>
            <DetailBlock>
              <DetailTitle>SHIPPER/CUSTOMER</DetailTitle>
              <DetailText>{elem?.customer.business_name}</DetailText>
            </DetailBlock>
          </Details>
        </DriverInfo>

        {open && (
          <ModalTripAction
            refTrips={refTrips}
            typeData="load"
            tripId={tripId}
            load_id={elem?.id}
            close={() => setOpen(false)}
          />
        )}
      </Wrapper>
    </>
  );
};

export default LoadItem;
