import React from 'react';

import Header from '../../components/Header/Header';
import {
    AuthWrapper,
    Block,
} from '../Auth/auth.style';

const Terms: React.FC = () => {
    return (
        <>
            <Header />
            <Block>
                <AuthWrapper>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <b>TURBO DISPATCH</b>
                        <b>DASHBOARD TERMS OF USE</b><br />
                    </div>
                    <p>
                        This <b>Dashboard</b> Terms of Use (this “<b>Agreement</b>”) is a binding agreement between You (“<b>End User</b>” or “<b>You</b>”) and TURBO DISPATCH INC (the “<b>Company</b>”). This Agreement governs your use of <b>Dashboard</b> available via third parties, including Apple, Inc.’s “App Store” or Google, Inc.’s “Google Play” store (collectively, the “<b>Third-Party Outlets</b>”), including all related documentation, (collectively, the “<b>Application</b>”). The Application is licensed, not sold, to you.
                    </p>
                    <ol>
                        <li style={{ marginTop: "16px" }}>Acknowledgements.</li>
                        <ol style={{ marginLeft: "36px" }} type="a">
                            <li style={{ marginTop: "16px" }}>By downloading, installing, registering for, or using the Application (including when you check the relevant box upon being presented with this Agreement and/or upon first creating an Account in the Application), You (I) acknowledge that you have read and understand this Agreement; (II) represent that you are 18 years of age or older; (III) accept this agreement (including the limitations of liability, disclaimers, indemnification, obligations, arbitrations provisions and the Privacy Notice, which is hereby incorporated herein), (IV) that downloading, installing, registering for or use of the Application by You and/or Your employees, independent contractors and/or agents shall constitute Your Agreement to be legally bound by the Agreement’s Terms; (V) if you are an employee, independent contractor and/or agent of a carrier company or brokerage, you hereby warrant and represent that You have the authority to bind such employer to this Agreement; (VI) if you are an employee, independent contractor and/or agent of a carrier company or brokerage, you hereby acknowledge that the account you create and/or use is not for your personal use but rather is assigned to the carrier company or brokerage for its use consistent with these Terms and may be updated or changed by the carrier company or brokerage pursuant to these Terms, and (VII) if you do not agree to these Terms, do not download, install, register for or use the Application as taking said actions shall be viewed as your acceptance of these Terms.</li>
                            <li style={{ marginTop: "16px" }}>You and the Company acknowledge that this Agreement is between you and the Company only, and not with a Third-Party Outlet. The Company, and not the Third-Party Outlets, is solely responsible for the Application and the content thereof. None of the Third-Party Outlets are a party to this Agreement. The Third-Party Outlets are not sponsors to, nor in any ways affiliated with any of, the Company’s promotions or the Content and Services.</li>
                            <li style={{ marginTop: "16px" }}>You and the Company acknowledge that the Third-Party Outlets have no obligation whatsoever to furnish any maintenance and support services with respect to the Application.</li>
                            <li style={{ marginTop: "16px" }}>You and the Company acknowledge that the Company, and not the Third-Party Outlet, is responsible for addressing any claims of the End User or any third party relating to the Application, including, but not limited to: (i) product liability claims; (ii) any claim that the Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</li>
                            <li style={{ marginTop: "16px" }}>You acknowledge and agree that it is Your sole responsibility to obtain the consent of your Customer/s/ for the use of electronic documents, including but not limited to, Electronic Bills of Lading (EBOL or EBOLs).</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>You understand that MYTURBODISPATCH.COM does not warrant that the customer consent provided for by the Application will satisfy the requirements of applicable Federal, State and Municipal laws and regulations governing the use of electronic documents. It is Your responsibility to ensure that the consent obtained by Your customer’s meets those requirements.</li>
                            </ol>
                            <li style={{ marginTop: "16px" }}>You acknowledge that the Application is a neutral venue where customers may meet; as such MYTURBODISPATCH.COM  has no control over the quality, safety, or legal aspects of the transactions that may take place and You certify:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>For the Application services:</li>
                                <ol style={{ marginLeft: "48px" }} type='1'>
                                    <li style={{ marginTop: "16px" }}>You are a bona fide shipper, freight broker, 3PL, freight forwarder, intermodal or rail company or motor carrier of a legal age to operate and to enter into an agreement of this nature.</li>
                                    <li style={{ marginTop: "16px" }}>You shall maintain appropriate authority and will cease immediately to use the Application if for any reason You no longer maintain such authority.</li>
                                    <li style={{ marginTop: "16px" }}>You will not represent yourselves as operating under the authority of any company without express permission from such company.</li>
                                    <li style={{ marginTop: "16px" }}>You will not attempt to broker freight without proper legal authority.</li>
                                    <li style={{ marginTop: "16px" }}>You will not enter into any transaction to transport freight without the appropriate carrier authority and/or outside the geographic bounds of your carrier authority. You must be authorized as an interstate carrier to use the Application in any way that involves interstate transport. If you are an intrastate carrier only, you are permitted to use the Application only as your authority permits.</li>
                                    <li style={{ marginTop: "16px" }}>You will not enter into any transaction to transport freight without the appropriate level of bond as well as any required insurance coverage.</li>
                                    <li style={{ marginTop: "16px" }}>You will not enter into any transaction to transport freight on equipment that fails to meet any applicable Federal, State or municipal law or regulations.</li>
                                    <li style={{ marginTop: "16px" }}>You may not enter into any transaction to transport any items or cargo forbidden by Federal, State or municipal law or regulations.</li>
                                </ol>
                                <li style={{ marginTop: "16px" }}>That your use of the Application is solely for your commercial purposes related to your movement of freight or other services offered by MYTURBODISPATCH.COM on the Application and that You shall not reproduce, republish, resell, or distribute such information in any format, in whole or in part, for sale or commercial use by third parties.</li>
                                <li style={{ marginTop: "16px" }}>Your access or use of the Application is not for the purpose of competing with MYTURBODISPATCH.COM with respect to the services offered on the Application. You agree any violation shall create irreparable harm for which MYTURBODISPATCH.COM is also entitled to compensation.</li>
                                <li style={{ marginTop: "16px" }}>That without prior written permission of MYTURBODISPATCH.COM, You will not allow non-registered users access to the Application and will never provide your password to any non-registered user, nor will You share any information from the Application with any non-authorized users. It is a violation of these Terms & Conditions to share your login.</li>
                                <li style={{ marginTop: "16px" }}>You shall not and may not resell or assign your rights or obligations under these Terms & Conditions.</li>
                                <li style={{ marginTop: "16px" }}>You shall conduct your business in an ethical manner and shall not engage in any illegal, deceptive, misleading or fraudulent practice.</li>
                                <li style={{ marginTop: "16px" }}>Use of any import/export capability to transfer MYTURBODISPATCH.COM or the Application information from your computer system shall be restricted to one or more identified computers located at the address(es) noted on your service agreement, and shall not be distributed to any other location(s). Unless otherwise stated, all information downloaded or exported from the Application is intended for use by You or the employee performing the download and shall not be distributed to any other users or locations.</li>
                                <li style={{ marginTop: "16px" }}>All seats provided under your office subscription are for use by your employees or agents located at the physical address listed on your subscription agreement. If desired, your employees may download a second copy of the application on a home computer for temporary or occasional use for company business. Your subscription does not cover your employees or agents that are employed at a different location from the one listed on your subscription agreement. A separate subscription is required for each business location used by your agents and employees.</li>
                                <li style={{ marginTop: "16px" }}>Job aggregators are not allowed to post jobs on the Application and You agree such access may be terminated at any time.</li>
                                <li style={{ marginTop: "16px" }}>You agree that any violation of the above warranties may result in immediate termination of your registration and access to the Application. Furthermore, MYTURBODISPATCH.COM reserves the right to assert and pursue any and all remedies available to it under applicable Federal, State and Municipal law or regulations. You shall be responsible for all legal costs, expenses and fees incurred by MYTURBODISPATCH.COM in the pursuit of such remedies, including without limitation, reasonable attorney fees.</li>
                            </ol>
                            <li style={{ marginTop: "16px" }}>The Application is not intended or designed to attract children under the age of 13. We do not collect personally identifiable information from any person we actually know is a child under the age of 13. If you are under the age of 13, do not use or provide any information on the Application or on or through any of its features, register on the Application, or provide any information about yourself to us, including your name, address, telephone number, e-mail address, and any screen name or username you may use. If we learn we have collected or received personal information from a child under the age of 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under the age of 13, please contact us by sending us an e-mail stating your request to myturbodispatch@gmail.com</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Accessing the Application and Security.</li>
                        <ol style={{ marginLeft: "36px" }} type="a">
                            <li style={{ marginTop: "16px" }}>We reserve the right to withdraw or amend this Application, as well as any service or material we provide on the Application, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Application is unavailable at any time or for any period.</li>
                            <li style={{ marginTop: "16px" }}>You are responsible for the following:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>Making all arrangements necessary for you to have access to the Application.</li>
                                <li style={{ marginTop: "16px" }}>Ensuring that all persons who access the Application through your Internet connection and or your Account/s/ are aware of these Terms of Use and comply with them.</li>
                            </ol>
                            <li style={{ marginTop: "16px" }}>To access the Application or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Application that all the information you provide on the Application is correct, current, and complete. You agree that all information you provide to register with this Application or otherwise, including but not limited to the use of any interactive features on the Application, is governed by our Privacy Notice, available at the end of the Terms of Use, and you consent to all actions we may take with respect to your information consistent with our Privacy Notice.</li>
                            <li style={{ marginTop: "16px" }}>We have the right to suspend the Application or your access to the Application at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>License Grant .</li>
                        <ol style={{ marginLeft: "36px" }} type="a">
                            <li style={{ marginTop: "16px" }}>Subject to the terms of this Agreement, and strictly in accordance with this Agreement and the terms and conditions applicable to such Content and Services as set forth in Section 6 the Company grants you a limited, non-exclusive, and nontransferable license to:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>download, install, and use the Application for your use on a single mobile device owned or otherwise controlled or leased to you (“<b>Mobile Device</b>”) strictly in accordance with the Application’s documentation; and</li>
                                <li style={{ marginTop: "16px" }}>access, stream, download, and use on such Mobile Device the Content and Services (as defined in Section 6 made available in or otherwise accessible through the Application.</li>
                            </ol>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>License Restrictions.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>Each of the following License Restrictions shall survive any termination of Your agreement(s). You shall not:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>copy the Application, except as expressly permitted by this license;</li>
                                <li style={{ marginTop: "16px" }}>modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable, of the Application;</li>
                                <li style={{ marginTop: "16px" }}>reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the source code of the Application or any part thereof;</li>
                                <li style={{ marginTop: "16px" }}>remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Application, including any copy thereof;</li>
                                <li style={{ marginTop: "16px" }}>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Application, or any features or functionality of the Application, to any third party for any reason (except with respect to pick-up and delivery contacts as directed by the in-app prompts while completing electronic proof of delivery documentation), including by making the Application available on a network where it is capable of being accessed by more than one device at any time;</li>
                                <li style={{ marginTop: "16px" }}>remove, disable, circumvent, or otherwise create or implement any workaround to any copy protection, rights management, or security features in or protecting the Application;</li>
                                <li style={{ marginTop: "16px" }}>use MYTURBODISPATCH.COM and/or the Application’s rate information or any other proprietary product information to develop a competitive lane rate product;</li>
                                <li style={{ marginTop: "16px" }}>provide MYTURBODISPATCH.COM and/or the Application rate or product/service information to any company considered by MYTURBODISPATCH.COM to be a competitor; or</li>
                                <li style={{ marginTop: "16px" }}>attempt to mine or replicate the rate database without the express written permission of MYTURBODISPATCH.COM.</li>
                            </ol>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Reservation of Rights.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>You acknowledge and agree that the Application is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions, and restrictions, under this Agreement. The Company and its licensors and service providers reserve and shall retain their entire right, title, and interest in and to the Application, including all copyrights, trademarks, patent rights, database rights, moral rights and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement (hereinafter, the “Protected Data.”). The Protected Data shall include but is not limited to the design, layout, look, appearance, organization, compilation of the content, code, data, materials, software, audio, photographs, artwork, text forms and graphics of the Application. Reproduction of the Protected Data in any form without express permission is strictly prohibited. Nothing contained on the Application or any other service provided by MYTURBODISPATCH.COM should be construed as granting, directly or by implication, any license or right to use any trademark without the written permission of MYTURBODISPATCH.COM or that of any third-party rights holder. Your misuse of any trademark is strictly prohibited. If You would like to license the use of any of MYTURBODISPATCH.COM trademarks or have questions regarding trademarks, please contact us.</li>
                            <li style={{ marginTop: "16px" }}>Furthermore, MYTURBODISPATCH.COM may disclose to You, or You may otherwise learn of or discover, our documents, business practices, object code, source code, management styles, day-to-day business operations, capabilities, systems, current and future strategies, marketing information, financial information, software technologies, processes, procedures, methods and applications, or other aspects of our business including but not limited to such information gained through Your use of the Application (“Our Information”). You hereby agree and acknowledge that any and all of Our Information is confidential and shall be our sole and exclusive intellectual property and proprietary information. You agree to use Our Information only for the specific purposes as allowed by these Terms & Conditions. Any disclosure of Our Information to a third-party, specifically including but not limited to a direct competitor, is strictly prohibited and will be vigorously challenged in a court of law. All obligations contained herein shall survive the termination of this agreement. Furthermore, You acknowledge that Our Information is proprietary, confidential and extremely valuable to us, and that we would be materially damaged by your disclosure of Our Information. You acknowledge and agree that monetary damages provide an insufficient remedy for the breach of this confidentiality obligation, and that we shall be entitled to injunctive relief.</li>
                            <li style={{ marginTop: "16px" }}>Your agreement to the provisions contained within this Reservation of Rights shall survive any termination of Your agreement(s).</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Collection and Use of Your Information.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>You acknowledge that when you download, install, register for, use, or submit information (including photographs) to the Application, the Company may use third-party services that may use automatic means (including, for example, cookies and web beacons) to collect information about your Mobile Device and about your use of the Application. You also may be required to provide certain information about yourself as a condition to downloading, installing, registering for, or using the Application or certain of its features or functionality, and the Application may provide you with opportunities to share information about yourself with others, including our affiliates and service providers, as well as the relevant carrier, broker and shipper for any particular order. The Application uses location-based services in order to perform many of its primary functions. You hereby consent to the collection, transmission and use of your location data by the Application as further described in the Privacy Notice. All information we collect through or in connection with this Application is subject to our Privacy Notice. By downloading, installing, using, and providing information to or through this Application, you consent to all actions taken by us with respect to your information in compliance with the Privacy Notice.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Content and Services.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The Application may provide products and services, certain features, functionality, and content accessible on or through the Application (collectively, “<b>Content and Services</b>”).  Your access to and use of such Content and Services are governed by this Agreement and the Privacy Notice. The End User may enter information or data and may transmit photos or other materials in connection with the End Users’ use of the Application. (“<b>Application Data</b>”). You agree that the Company is and shall remain the sole and exclusive over of all right, title, and interest in and to the Application Data, including all intellectual property rights therein. In furtherance of the foregoing, you hereby do:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>immediately on its creation, assign, transfer, and otherwise convey to the Company, irrevocably and in perpetuity, throughout the universe, all right, title, and interest in and to such Application Data, including all intellectual property rights therein;</li>
                                <li style={{ marginTop: "16px" }}>irrevocably waive any and all claims you may now or hereafter have in any jurisdiction to so-called “moral rights” or rights of droit moral with respect to the Application Data; and</li>
                                <li style={{ marginTop: "16px" }}>take all appropriate action and execute and deliver all documents, necessary or reasonably requested by the Company to effectuate this Section 6 or otherwise as may be necessary for the Company to prosecute, register, perfect, or record its rights in or to any Application Data or any intellectual property right therein.</li>
                            </ol>
                            <li style={{ marginTop: "16px" }}>You hereby appoint the Company as your attorney-in-fact with full irrevocable power and authority to take any such actions and execute any such documents if you refuse or, within a period deemed reasonable by the Company, otherwise fail to do so.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Geographic Restrictions.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The Application, Content and Services are based in the United States and provided for access and use only by persons located in the United States. You acknowledge that you may not be able to access all or some of the Content and Services outside of the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Content and Services from outside the United States, You are solely responsible for compliance with local laws. You also agree that you will comply with all applicable laws, domestic or foreign, including but not limited to the laws and regulations concerning import and export of goods, the Foreign Corrupt Practices Act and other laws prohibiting bribery, nondiscrimination, forced or involuntary labor, and equal opportunity in employment.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Updates.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The Company may from time to time in its sole discretion develop and provide Application updates, which may include changes, upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, “Updates”).  Updates may also modify or delete in their entirety certain features and functionality.  All changes are effective immediately when posted by the Company, and apply to all access to and use of the Application thereafter. You agree that the Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your Mobile Device settings, when your Mobile Device is connected to the internet either: (a) the Application will automatically download and install all available Updates; or (b) you may receive notice of or be prompted to download and install available Updates.</li>
                            <li style={{ marginTop: "16px" }}>You shall promptly download and install all Updates and acknowledge and agree that the Application or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the Application and be subject to all terms and conditions of this Agreement.</li>
                            <li style={{ marginTop: "16px" }}>You acknowledge that You are expected to check this page each time you access the Application so that you are aware of any changes, as they are binding upon you. Your continued use of the Application, Content and Services will be deemed Your acceptance of any changes to these Terms.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Third-Party Materials.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The Application may rely upon, display, include, or make available third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to third-party websites or services, including through third-party advertising (“Third-Party Materials”). You acknowledge and agree that the Company is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof.  The Company does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Term and Termination.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The term of this Agreement commences when you download the Application and will continue in effect until terminated by you or the Company as set forth in this Section 10.</li>
                            <li style={{ marginTop: "16px" }}>You may terminate this Agreement with thirty (30) days advance notice to MYTURBODISPATCH.COM  to terminate your Account. Upon the expiration of said thirty-day notice period You shall then delete the Application and all copies thereof from your Mobile Device.</li>
                            <li style={{ marginTop: "16px" }}>The Company may terminate this Agreement and/or Your access to the Application at any time without notice for any reason including but not limited to if it ceases to support the Application, which the Company may do in its sole discretion. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement. MYTURBODISPATCH.COM may deny or cancel your Account with the Application immediately at its sole discretion if complaints are received.</li>
                            <li style={{ marginTop: "16px" }}>Upon termination, (i) all rights granted to you under this Agreement will also terminate; and (ii) you must cease all use of the Application and delete all copies of the Application from your Mobile Device and account.</li>
                            <li style={{ marginTop: "16px" }}>Termination will not limit any of the Company’s rights or remedies at law or in equity. Furthermore, any payment obligations incurred by You pursuant to this Agreement or through Your use of the Application may survive termination.</li>
                            <li style={{ marginTop: "16px" }}>The Company reserves the right to:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>Take any action with respect to any use of the Application that we deem necessary or appropriate in our sole discretion, including if we believe that such use violates the Terms of Use, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Application or the public or that could create liability for the Company.</li>
                                <li style={{ marginTop: "16px" }}>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
                                <li style={{ marginTop: "16px" }}>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Application.</li>
                            </ol>
                            <li style={{ marginTop: "16px" }}>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone accessing the Application. You waive and hold harmless the Company and its affiliates, licensees, licensors, and service providers from any claims resulting from any action taken by the Company during or as a result of its investigations and from any action taken as a consequence of investigations by either the Company or law enforcement authorities.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Disclaimer of Warranties.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The Application is provided to You “As Is” and with all faults and defects without warranty of any kind. You understand that we cannot and do not guarantee or warrant that files available for downloading from the Internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to the Website for any reconstruction of any lost data. To the maximum extent permitted under applicable law. The Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Application, including but not limited to any functions of the Application relating to use of any geotagging, geo-location, global positioning system/s/ (“GPS”) and all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage, or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes not representation of any kind that the Application will meet Your requirements, achieve any intended results, be compatible, or work with any other software, applications, systems of services, operate without interruption, meet any performance or reliability standards or be error-free, or that errors or defects can or will be corrected.</li>
                            <li style={{ marginTop: "16px" }}>MYTURBODISPATCH.COM shall not be liable for any direct, special, indirect, incidental, consequential, exemplary, extra-contractual, or punitive damages of any kind whatsoever, including, without limitation, lost revenues or lost profits, which may or does result from the use of, access to, or inability to use the Application, the content, or the products or services connected therewith, regardless of legal theory, whether or not any party had been advised of the possibility or probability of such damages, and even if the remedies otherwise available fail their essential purpose.</li>
                            <li style={{ marginTop: "16px" }}>You acknowledge that MYTURBODISPATCH.COM sole obligation and exclusive responsibility in the event of material and continuing non-conformity, defect or error in the Application shall be to take reasonable corrective actions upon discovery of the problem. MYTURBODISPATCH.COM liability is limited to the fullest extent permitted by law. In no event shall MYTURBODISPATCH.COM and/or its third-party information provider’s cumulative liability under this Agreement exceed the total fees paid by You to MYTURBODISPATCH.COM during the preceding month.</li>
                            <li style={{ marginTop: "16px" }}>MYTURBODISPATCH.COM presents information in many ways; most often on our websites; always as a service to You. Our goal is to provide the most accurate information available in our complex and constantly changing transportation marketplace. While we endeavor to be as accurate and timely as possible, we make no warranty or guarantee concerning accuracy, reliability, completeness, or suitability, and provide all information, including but not limited to information generated from GPS data, AS IS. Use of the Application is at your own risk. MYTURBODISPATCH.COM endeavors to keep this information as updated as possible, however, You are responsible for verifying the accuracy of all information input by you into the Application and of all information provided to you by the Application. You must make your own determination as to safety, authority and/or business practices. MYTURBODISPATCH.COM provides the Application as a venue for carriers, brokers, freight forwarders, shippers, dealers and manufacturers or other entities to meet in order to offer sell and buy services. MYTURBODISPATCH.COM is not involved in the actual transaction between buyer and seller. While MYTURBODISPATCH.COM may help facilitate, it has no control over and does not guarantee safety or legality. By using the Application, You agree that it is your sole responsibility, as a user of the Application, to check the credentials, including but not limited to the safety/authority record, of any party introduced to You by the Application.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Your Account.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>To access the Application, You must register for an Account (“Account”) with MYTURBODISPATCH.COM. You agree to provide true, accurate and complete information as prompted by the registration form and all forms You access in our site or receive directly from a MYTURBODISPATCH.COM representative, and You agree to update this information to maintain its truthfulness, accuracy, and completeness.  By subscribing to the Application, You authorize MYTURBODISPATCH.COM  to include data about You in the Application’s internal directory and also in the directories of related services provided by MYTURBODISPATCH.COM. This data may include publicly available data about you or your company such as your name, address, contact information, DOT profile (if any) as well as any other data You have provided MYTURBODISPATCH.COM or the Application about your company and operations.</li>
                            <li style={{ marginTop: "16px" }}>You acknowledge that the Application allows you to make your own independent evaluations as to the condition of any vehicle or cargo. Any contract or agreement for vehicle transport that you may enter into by accessing the Application, is solely between you and the applicable carrier, broker, dealer, freight forwarder, shipper, dealer, manufacturer or other entity.  The Company has no monitoring, enforcement or other obligations or responsibilities in relation to compliance with such industry and professional standards. The Company provides the Application merely as a means of facilitating electronic proof of delivery documentation and electronic invoicing. You acknowledge that if you disable certain functionalities of the Application including geotagging and/or location services, the Application will not function as intended. Furthermore, disabling such functionalities could conflict with your obligations to your respective carrier. You are responsible for, and will indemnify and hold the Company harmless from and against, any claims arising out of your responsibilities or failure to comply with obligations imposed on you by your carrier, industry and professional standards, or your failure to enable the Application as intended.</li>
                            <li style={{ marginTop: "16px" }}>MYTURBODISPATCH.COM assumes no responsibility for whether You or other users abide by all applicable industry and professional standards, including, without limitation, the:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>pick-up and delivery of vehicles within the agreed-upon windows of time and at the agreed-upon price (and to the extent you arrange delivery directly with the customer, including alternative arrangements, you are solely responsible for any liability in connection with such alternative arrangements, and you shall indemnify and hold the Company harmless from and against any claims or losses arising therefrom);</li>
                                <li style={{ marginTop: "16px" }}>prompt notification to all appropriate parties of unexpected delays in the pick-up or delivery of vehicles;</li>
                                <li style={{ marginTop: "16px" }}>handling of vehicles so as to avoid any personal injury or damage to the vehicles;</li>
                                <li style={{ marginTop: "16px" }}>providing to appropriate parties with a completed and signed bill of lading/vehicle condition form upon delivery of the vehicles;</li>
                                <li style={{ marginTop: "16px" }}>Adherence and compliance with any and all Federal, State and Municipal rules and regulations governing the use of electronic documents;</li>
                                <li style={{ marginTop: "16px" }}>handling of damage claims in a fair and responsive manner;</li>
                                <li style={{ marginTop: "16px" }}>interaction with all parties in a professional and courteous manner;</li>
                                <li style={{ marginTop: "16px" }}>maintenance of adequate insurance coverage and applicable licenses and bonds at all times; and</li>
                                <li style={{ marginTop: "16px" }}>compliance with all applicable laws, rules and regulations.</li>
                            </ol>
                            <li style={{ marginTop: "16px" }}>The Application may refer to some services or programs that are not available to You without specifically identifying the reason that the service of program is not available. Reference to such services or programs by the Application does not imply that MYTURBODISPATCH.COM intends to offer such services or programs to all users or locations.</li>
                            <li style={{ marginTop: "16px" }}>The Application may provide certain services that are available to You via your mobile phone or other mobile device if You have subscribed to them, including the ability to use your mobile device to receive and reply to messages from the Application and/or MYTURBODISPATCH.COM and to access certain other features (collectively, the "Mobile Services"). Your mobile carrier's normal messaging, data, and other rates and fees may apply to your use of the Mobile Services and MYTURBODISPATCH.COM does not assume any responsibility for any such rates and/or fees. In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by your mobile carrier, and not all Mobile Services may work with all carriers or devices. Therefore, You are responsible for checking with your mobile carrier to determine if the Mobile Services are available for your mobile devices, what restrictions, if any, may be applicable to your use of the Mobile Services and how much they will cost You. By using the Mobile Services, You agree that the Application and/or MYTURBODISPATCH.COM may communicate with You by SMS, MMS or other electronic means to your mobile device and that certain information about your usage of the Mobile Services may be communicated to MYTURBODISPATCH.COM.. In the event You change or deactivate your mobile telephone number, You agree to promptly update your mobile subscription account information with us to ensure that the messages MYTURBODISPATCH.COM intends to send to You are not sent to another entity who acquires such mobile telephone number.</li>
                            <li style={{ marginTop: "16px" }}>MYTURBODISPATCH.COM may, from time to time, monitor or review discussions, chats, blogs, forums, social media postings, transmissions, bulletin boards, and the like on the Application or other services . You understand and agree that MYTURBODISPATCH.COM is under no obligation to do so and assumes no responsibility or liability arising from the content of any such locations nor for any error, defamation, libel, slander, omission, falsehood, obscenity, pornography, profanity, danger, or inaccuracy contained in any information contained within such locations on the Application. You are prohibited from posting or transmitting any unlawful, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material or any material that could constitute or encourage conduct that could be considered a criminal offense, give rise to civil liability, or otherwise violate any law. MYTURBODISPATCH.COM will fully cooperate with any law enforcement authorities or court order requesting or directing MYTURBODISPATCH.COM to disclose the identity of anyone posting any such information or materials. MYTURBODISPATCH.COM reserves the right to remove messages or material posted by You, as a user of the Application, to message boards or other areas, at its sole discretion. By submitting messages and/or materials to the Application, You agree to indemnify, defend and hold harmless MYTURBODISPATCH.COM from all damages, costs and expenses, including reasonable attorneys' fees and costs arising out of all claims, challenges or actions, including claims for infringement, libel and slander, related to your submission.</li>
                            <li style={{ marginTop: "16px" }}>MYTURBODISPATCH.COM may, from time to time, make messaging services, chat services, bulletin boards, message boards, blogs, other forums and other such services available on or through the Application and/or its websites. In addition to any other rules or regulations that we may post in connection with a particular service, You agree that You shall not upload, post, transmit, distribute or otherwise publish through any website or any service or feature made available on or through our Application or websites, any materials which:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>restrict or inhibit any other user from using and enjoying the Application or the Application’s services;</li>
                                <li style={{ marginTop: "16px" }}>are fraudulent, unlawful, threatening, abusive, harassing, libelous, defamatory, obscene, vulgar, offensive, pornographic, profane, sexually explicit or indecent;</li>
                                <li style={{ marginTop: "16px" }}>constitute or encourage conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, national or international law;</li>
                                <li style={{ marginTop: "16px" }}>violate, plagiarize or infringe the rights of third-parties including, without limitation, copyright, trademark, trade secret, confidentiality, contract, patent, rights of privacy or publicity or any other proprietary right;</li>
                                <li style={{ marginTop: "16px" }}>contain a virus, spyware, or other harmful component;</li>
                                <li style={{ marginTop: "16px" }}>contain embedded links, advertising, chain letters or pyramid schemes of any kind; or</li>
                                <li style={{ marginTop: "16px" }}>constitute or contain false or misleading indications of origin, endorsement or statements of fact.</li>
                                <li style={{ marginTop: "16px" }}>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
                                <li style={{ marginTop: "16px" }}>Use the Application in any manner that could disable, overburden, damage, or impair the Application or interfere with any other party’s use of the Application, including its ability to engage in real-time activities through the Application.</li>
                                <li style={{ marginTop: "16px" }}>Use any robot, spider, or other automatic device, process, or means to access the Application for any purpose, including monitoring or copying any of the material on the Application.</li>
                                <li style={{ marginTop: "16px" }}>Use any manual process to monitor or copy any of the material on the Application or for any other unauthorized purpose without our prior written consent.</li>
                                <li style={{ marginTop: "16px" }}>Use any device, software, or routine that interferes with the proper working of the Application.</li>
                                <li style={{ marginTop: "16px" }}>Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
                                <li style={{ marginTop: "16px" }}>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Application, the server on which the Application is stored, or any server, computer, or database connected to the Application.</li>
                                <li style={{ marginTop: "16px" }}>Attack the Application via a denial-of-service attack or a distributed denial-of-service attack.</li>
                                <li style={{ marginTop: "16px" }}>Otherwise attempt to interfere with the proper working of the Application.</li>
                                <li style={{ marginTop: "16px" }}>Modify copies of any materials from the Application.</li>
                                <li style={{ marginTop: "16px" }}>Make any use of any illustrations, photographs, video, or audio sequences or any graphics or text from the Application for any purposes other than electronic proof of delivery services or as otherwise expressly contemplated herein.</li>
                                <li style={{ marginTop: "16px" }}>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from the Application.</li>
                                <li style={{ marginTop: "16px" }}>Access or use for any commercial purposes any part of the Application or any services or materials available through the Application.</li>
                            </ol>
                            <li style={{ marginTop: "16px" }}>You further agree not to impersonate any other person or entity, whether actual or fictitious, including anyone from MYTURBODISPATCH.COM. You also may not offer to buy or sell any product or service on or through your comments submitted to our forums. You alone are responsible for the content and consequences of any of your activities.</li>
                            <li style={{ marginTop: "16px" }}>The Application includes an email invoicing function whereby an electronic invoice is generated and emailed to the Shipper upon proof of delivery. Invoices are generated from data you have provided to the Application. You acknowledge that any errors in the content of said invoices is Your sole responsibility and not that of MYTURBODISPATCH.COM You further acknowledge that this invoicing function is for Your convenience only and MYTURBODISPATCH.COM assumes no responsibility for invoicing or other financial transactions between You and any Third-Parties, including any disputes that may arise from Your generating your own invoices while the automatic invoicing function is active or Your failure to generate invoices should You choose to deactivate the automatic invoicing function. The automatic invoicing function is activated for your Account by default but may be deactivated by You in the “Company Profile” and may also be deactivated by MYTURBODISPATCH.COM sole discretion at any time.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Limitation of Liability.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>To the fullest extent permitted by applicable law, in no event will the Company or its affiliates, or any of its or their respective licensors or service providers, have any liability arising from or related to Your use of or inability to use the Application or the content and Services for:</li>
                            <li style={{ marginTop: "16px" }}>Personal injury, property damage, lost profits, cost of substitute goods or services, loss of data, loss of goodwill, business interruption, computer failure or malfunction, or any other consequential, incidental, indirect, exemplary, special or punitive damages.</li>
                            <li style={{ marginTop: "16px" }}>Direct damages in amounts that in the aggregate exceed (other than as may be required by applicable law in cases involving personal injury) the amount of fifty dollars ( $50.00).</li>
                            <li style={{ marginTop: "16px" }}>The foregoing limitations will apply whether such damages arise out of breach of contract, tort (including negligence), or otherwise and regardless of whether such damages were foreseeable or the Company was advised of the possibility of such damages.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Indemnification.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>You agree to indemnify, defend, and hold harmless the Company, its affiliates, and its and their officers, directors, employees, agents, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys’ fees, arising from or relating to your use or misuse of the Application or your breach of this Agreement, including but not limited to the content you submit or make available through this Application. You and the Company acknowledge that in the event of any third-party claim that the Application or the End User’s possession and use of the Application infringes that third party’s intellectual property rights, the Company, and not the Third-Party Outlet, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Export Regulation.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The Application may be subject to U.S. export control laws, including the U.S. Export Administration Act and its associated regulations. You represent and warrant:</li>
                            <ol style={{ marginLeft: "32px" }} type='i'>
                                <li style={{ marginTop: "16px" }}>you are not located in a country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist supporting” country;</li>
                                <li style={{ marginTop: "16px" }}>you are not listed on any U.S. government list of prohibited or restricted parties. You shall not, directly or indirectly, export, re-export, or release the Application to, or make the Application accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule, or regulation; and</li>
                                <li style={{ marginTop: "16px" }}>You shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing, or otherwise making the Application available outside the US.</li>
                            </ol>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>US Government Rights.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>The Application is commercial computer software, as such term is defined in 48 C.F.R. §2.101. Accordingly, if you are an agency of the U.S. Government or any contractor therefor, you receive only those rights with respect to the Application as are granted to all other end users under license, in accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with respect to the Department of Defense and their contractors, or (b) 48 C.F.R. §12.212, with respect to all other U.S. Government licensees and their contractors.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Severability.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>If any provision of this Agreement is contrary to law or otherwise unenforceable under any applicable laws or regulations, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Governing Law and Dispute Resolution.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>This Agreement is governed by and construed in accordance with the internal laws of the State of Illinois without giving effect to any choice or conflict of law provision or rule.</li>
                            <li style={{ marginTop: "16px" }}>The Parties further agree that all actions or proceedings arising in connection with this Agreement shall be litigated in the state or federal court located in Cook County, Illinois. Each party waives any right it may have to assert the doctrine of forum non conveniens or to otherwise object to venue with respect to any action or proceeding brought pursuant to this paragraph. The Parties further agree to submit themselves to the personal jurisdiction of any court or tribunal authorized by this Paragraph. Notwithstanding the foregoing and at the Company’s sole discretion, disputes may also be resolved by submission to the American Arbitration Association using its Commercial Arbitration rules.</li>
                            <li style={{ marginTop: "16px" }}>Any cause of action or claim you may have arising out of or relating to these Terms of Use or the Application must be commenced within one (1) year after the cause of action accrues; otherwise, such cause of action or claim is permanently barred.</li>
                        </ol>

                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Claims.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>Any cause or action or claim you may have arising out of or relating to this Agreement or the Application must be commenced within one (1) year after the cause of action accrues. Otherwise, such cause of action or claim is permanently barred. Any and all disputes related to billing must be presented to MYTURBODISPATCH.COM within thirty (30) days of the invoice date. Direct all inquiries to MYTURBODISPATCH.COM at 847-636-8432 or by e-mail to <a href="mailto:myturbodispatch@gmail.com">myturbodispatch@gmail.com</a>. In the event MYTURBODISPATCH.COM retains legal counsel to enforce this agreement it shall be entitled to receive attorney’s fees, including fees on appeal, whether or not suit or action is commenced.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Entire Agreement.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>This Agreement, our Privacy Notice and any updates thereto constitute the entire agreement between you and the Company with respect to the Application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Application. MYTURBODISPATCH.COM may, from time to time, at our sole discretion, make changes to certain of the Terms & Conditions. Notification of any changes will be highlighted on the Application and/or the MYTURBODISPATCH.COM website (“Site”), or any successor site explicitly designated. Said Site, or successor site shall be accessible via a link entitled MYTURBODISPATCH.COM Terms & Conditions, in advance of any such change. You agree that Your continued use of the Application after any changes shall constitute your agreement. MYTURBODISPATCH.COM reserves the right (1) to modify, discontinue or suspend any aspect of the Application or Site at any time, and (2) to impose limitations/restriction or restrict access to our Service without notice or liability.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Waiver.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Notice.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>Any End User questions, complaints, or claims with respect to the Application should be directed to the Company at the contact information below:<br /><br />TURBO DISPATCH INC<br />1204 STERLING DR<br />MUNDELEIN, IL 60060<br /><br />Telephone:847-636-8432.<br /><br />Email Address: myturbodispatch@gmail.com</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Third Party Beneficiaries.</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>You and the Company acknowledge and agree that the Third-Party Outlets and each of their subsidiaries, are third party beneficiaries of this Agreement, and that upon the End User’s acceptance of this Agreement, each Third-Party Outlet will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third-party beneficiary thereof.</li>
                        </ol>
                        <li style={{ textDecoration: "underline", marginTop: "16px" }}>Contact Information .</li>
                        <ol type="a" style={{ marginLeft: "36px" }}>
                            <li style={{ marginTop: "16px" }}>
                                If you wish to report a concern regarding these Terms & Conditions, have any questions or need assistance unrelated to the operation of the Application, please contact MYTURBODISPATCH.COM via email at myturbodispatch@gmail.com, or by telephone at <a href="tel:846-636-8432">846-636-8432.</a>
                            </li>
                        </ol>
                    </ol>
                </AuthWrapper>
            </Block>
        </>
    );
};

export default Terms;
