import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFFFFF;;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #C6C7DA;;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.PhoneInput {
  display: flex;
  align-items: center;
  width: inherit;
  position: 'absolute'; 
  top: 40;
  left: 10
}

.PhoneInputInput {
  border: none;
  outline: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
}

.MuiTabs-scroller {
  padding-bottom: 14px;
}

.MuiTabScrollButton-root {
  padding-bottom: 14px !important;
}

`;
export const Wrapper = styled.div`
  background-color: #f4f4f4;
  max-width: 100%;
  min-height: 100vh;
`;
