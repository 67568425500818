import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { getPublicDataLoad } from '../../../API/loads';
import { Print } from '../../../assets/icons';
import ModalViewLoads from '../../../layout/Modal/ModalLoads/ModalViewLoads/ModalViewLoads';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch } from '../../../store/hooks';
import Preloader from '../../Preloader/Preloader';
import LoadItemActivity from '../LoadsItem/LoadsBlock/LoadsItemActivity';
import LoadsItemAttachments from '../LoadsItem/LoadsBlock/LoadsItemAttachments';
import LoadsItemCustomer from '../LoadsItem/LoadsBlock/LoadsItemCustomer';
import LoadsItemDelivery from '../LoadsItem/LoadsBlock/LoadsItemDelivery';
import LoadsItemDriverInstructions from '../LoadsItem/LoadsBlock/LoadsItemDriverInstructions';
import LoadsItemExpenses from '../LoadsItem/LoadsBlock/LoadsItemExpenses';
import LoadsItemInspectionDetails from '../LoadsItem/LoadsBlock/LoadsItemInspectionDetails';
import LoadsItemInternalNotes from '../LoadsItem/LoadsBlock/LoadsItemInternalNotes';
import LoadsItemPayment from '../LoadsItem/LoadsBlock/LoadsItemPayment';
import LoadItemPickup from '../LoadsItem/LoadsBlock/LoadsItemPickup';
import LoadItemVehicle from '../LoadsItem/LoadsBlock/LoadsItemVehicle';
import {
  Action,
  Actions,
  ActionText,
  Count,
  ImageContainer,
  LoadName,
  LoadsAssigned,
  LoadsBig,
  LoadsBigPrintOnly,
  LoadsBlock,
  LoadsEditBlock,
  LoadsFlexBig,
  LoadsSmall,
  PrintWrapper,
  Top,
  TopText,
  TopTitle,
} from '../LoadsItem/loadsItem.style';

const LoadPdf = () => {
  const dispatch = useAppDispatch();

  const { load_uuid } = useParams();
  const [data, setData] = useState<any>(null);
  const printRef = useRef(null);
  const refPayment = useRef<any>();
  const [typeView, setTypeView] = useState<string>('');

  const [openViewModalLoads, setOpenViewModalLoads] = useState<boolean>(false);
  const [showSlider, setShowSlider] = useState<boolean>(false);

  useOnClickOutside(refPayment, () => setOpenViewModalLoads(false));

  useEffect(() => {
    if (!data && load_uuid) {
      dispatch(getPublicDataLoad({ load_uuid })).then((res) => {
        setData(res.payload);
      });
    }
  });

  const pickupData = data?.points?.find((elem: any) => elem.type === 'pickup');
  const deliveryData = data?.points?.find((elem: any) => elem.type === 'delivery');

  const loadInfo = useMemo(() => ({ load: data }), [data]);

  if (!data) return <Preloader />;

  return (
    <PrintWrapper>
      <Top>
        <TopTitle>
          <TopText>{loadInfo?.load?.load_id}</TopText>
          <Count>{data?.status}</Count>
          <LoadsAssigned>
            {data?.driver ? (
              <>
                Assigned to <LoadName>{data?.driver?.first_name + ' ' + data?.driver?.last_name}</LoadName>
              </>
            ) : (
              'Not assigned'
            )}
          </LoadsAssigned>
        </TopTitle>
        <Actions>
          <ReactToPrint
            trigger={() => (
              <Action>
                <Print /> <ActionText>Print</ActionText>
              </Action>
            )}
            content={() => printRef.current}
          />
        </Actions>
      </Top>
      <LoadsEditBlock ref={printRef}>
        <LoadsSmall>
          <LoadsItemPayment
            loadInfo={loadInfo}
            setViewPayment={() => setOpenViewModalLoads(true)}
            setTypeVeiw={() => setTypeView('Payment')}
          />
          <LoadItemVehicle
            loadInfo={loadInfo}
            setVehicleModal={() => {}}
            setDeleteVehicle={() => {}}
            setHover={() => {}}
            hover={''}
            vehicleItemRef={() => {}}
            vehicleRef={() => {}}
            vehicleModel={() => {}}
            vehicleMake={() => {}}
            vehicleYear={() => {}}
          />
          <LoadsItemCustomer loadInfo={loadInfo} />
          {loadInfo?.load?.attachments.length > 0 && (
            <LoadsItemAttachments loadInfo={loadInfo} deleteAttachments={() => {}} />
          )}
          <LoadItemActivity
            loadInfo={loadInfo}
            setViewActivity={() => setOpenViewModalLoads(true)}
            setTypeVeiw={() => setTypeView('Activity')}
          />
        </LoadsSmall>
        <LoadsFlexBig>
          <LoadsBig>
            <LoadItemPickup pickupData={pickupData} pickupOnDate={data?.picked_at} />
            {data?.expenses.length > 0 && <LoadsItemExpenses expenses={data?.expenses} loadId={data.id} printMode />}
            <LoadsItemInspectionDetails
              classes={{ root: 'noPrint' }}
              showSlider={showSlider}
              loadInfo={loadInfo}
              setShowSlider={() => setShowSlider(!showSlider)}
            />
          </LoadsBig>
          <LoadsBig className="page-break">
            <LoadsItemDelivery deliveryData={deliveryData} />
            <LoadsItemDriverInstructions loadInfo={loadInfo} />
            <LoadsItemInternalNotes loadInfo={loadInfo} />
          </LoadsBig>
          <LoadsBigPrintOnly>
            <LoadsItemInspectionDetails
              showSlider={showSlider}
              loadInfo={loadInfo}
              setShowSlider={() => setShowSlider(!showSlider)}
            />
          </LoadsBigPrintOnly>
        </LoadsFlexBig>
      </LoadsEditBlock>
      <LoadsBigPrintOnly>
        <LoadsBlock vehiclesPrintBlock className="page-break-before">
          {loadInfo?.load?.vehicles.map((vehicle) => {
            if (vehicle?.inspections?.images) {
              return (
                loadInfo?.load?.vehicles.length > 0 &&
                vehicle?.inspections?.images.map(({ id, image }) => {
                  return (
                    <ImageContainer height={150} width={200} key={id}>
                      <img src={image} />
                    </ImageContainer>
                  );
                })
              );
            }
          })}
        </LoadsBlock>
      </LoadsBigPrintOnly>
      {openViewModalLoads && (
        <ModalViewLoads
          refPayment={refPayment}
          typeView={typeView}
          loadData={{ load: data }}
          close={() => setOpenViewModalLoads(false)}
        />
      )}
    </PrintWrapper>
  );
};

export default LoadPdf;
