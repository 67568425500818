import React, { useEffect, useState } from 'react';

import {
  Bar,
  BoldText,
  Bottom,
  RegularText,
  Text,
  Top,
  Width,
  Wrapper,
} from './step.style';
import { useAppSelector } from '../../../store/hooks';

const Step: React.FC = () => {
  const [stepAmount, setStepAmount] = useState<number>(1);
  const [stepCompleted, setStepCompleted] = useState<number>(0);
  const [nameStep, setNameStep] = useState<string>('Carrier information');
  const [fillOut, setFillOut] = useState<string>(
    'Fill out carrier information',
  );
  const [width, setWidth] = useState<number>(0);

  const { data } = useAppSelector(
    (state: { carrierProfile: any }) => state.carrierProfile,
  );

  const stepData = () => {
    if (data?.carrier_name) {
      setStepAmount(prev => prev + 1);
      setNameStep('Cargo Insurance');
      setFillOut('Fill out cargo nsurance');

      if (data?.cargo_limit) {
        setStepAmount(prev => prev + 1);
        setNameStep('W9');
        setFillOut('Fill out w9');

        if (data?.cargo_limit) {
          setStepAmount(prev => prev + 1);
          setNameStep('USDOT certificate');
          setFillOut('Fill out usdot certificate');

          if (data?.usdot_certificate) {
            setStepAmount(prev => prev + 1);
            setNameStep('Completed');
            setFillOut('All fields completed');
          }
        }
      }
    }
  };

  useEffect(() => {
    stepData();
    if (data?.carrier_name) {
      setStepCompleted(prev => prev + 1);
      setWidth(prev => prev + 25);
    }
    if (data?.cargo_limit) {
      setStepCompleted(prev => prev + 1);
      setWidth(prev => prev + 25);
    }
    if (data?.w9) {
      setStepCompleted(prev => prev + 1);
      setWidth(prev => prev + 25);
    }
    if (data?.usdot_certificate) {
      setStepCompleted(prev => prev + 1);
      setWidth(prev => prev + 25);
    }
  }, []);

  return (
    <Wrapper>
      <Top>
        <BoldText>Step {stepAmount} of 4:</BoldText>
        <RegularText>{nameStep}</RegularText>
      </Top>
      <Bar>
        <Width style={{ width: `${width}%` }} />
      </Bar>
      <Bottom>
        <BoldText>{fillOut}</BoldText>
        <Text>{stepCompleted} of 4 steps completed</Text>
      </Bottom>
    </Wrapper>
  );
};

export default Step;
