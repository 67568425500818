import React, { ChangeEvent, useState } from 'react';

import {
  Wrapper,
  Text,
  CheckboxBlock,
  LearnMore,
  InputField,
  Label,
  Input,
  Error,
  Bottom,
  Actions,
} from './billing.style';
import { saveBilling } from '../../../API/settings';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const Billing: React.FC = () => {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  const [attachBOL, setAttachBOL] = useState<boolean>(settings ? settings.bol_in_invoice : false);
  const [dateBOL, setDateBOL] = useState<boolean>(settings ? settings.points_to_bol : false);
  const [email, setEmail] = useState<string>(settings ? settings.copy_invoice_email : '');

  const [err, setErr] = useState({
    message: '',
    errors: { copy_invoice_email: [] },
  });

  const handleConfirm = async () => {
    const res: any = await dispatch(
      saveBilling({ bol_in_invoice: attachBOL, points_to_bol: dateBOL, copy_invoice_email: email }),
    );
    if (res.type === 'settings/saveBilling/rejected')
      return setErr(res.payload);
  };

  return (
    <Wrapper>
      <CheckboxBlock style={{ marginTop: 0 }}>
        <Checkbox
          title="Attach BOL to Invoices"
          checkboxName="attachBOL"
          checked={attachBOL}
          setChecked={() => setAttachBOL(!attachBOL)}
        />
      </CheckboxBlock>
      <CheckboxBlock>
        <Checkbox
          title="Add pickup/delivery dates to BOL"
          checkboxName="dateBOL"
          checked={dateBOL}
          setChecked={() => setDateBOL(!dateBOL)}
        />
      </CheckboxBlock>
      <InputField>
        <Label htmlFor="email">
          Send a copy of an invoice to this (BCC) email
        </Label>
        <Input
          id="email"
          type="email"
          style={{
            border: err.errors?.copy_invoice_email[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({ ...err, errors: { ...err.errors, copy_invoice_email: [] } })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          value={email}
        />
        <Error>{err.errors?.copy_invoice_email[0]}</Error>
        <Text>
          Receive a blind copy (or "BCC") of an invoice. Enter a single email
          address.
          <LearnMore>Learn more...</LearnMore>
        </Text>
      </InputField>
      <Bottom>
        <Actions>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </Actions>
      </Bottom>
    </Wrapper>
  );
};

export default Billing;
