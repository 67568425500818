import { SVGProps } from "react"

const PlusDriver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path fill="#252736" d="M9.166 1.83h1.667v16.667H9.166z" />
    <path fill="#252736" d="M18.334 9.33v1.667H1.667V9.33z" />
  </svg>
)

export default PlusDriver
