import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import {
  getSettings,
  getTerminal,
  getTimezones,
  saveBilling,
  saveDriverApp,
  saveFactoring,
  saveGeneral,
  saveNotifications,
  saveSubscription,
  saveTerminal,
  Settings,
  Terminal,
  Timezones,
} from '../../API/settings';

type InitialStateType = {
  timezones: Timezones;
  settings: Settings;
  terminal: Terminal[];
  loading: boolean;
  error: ErrorType | null;
};

const initialState: InitialStateType = {
  timezones: [],
  terminal: [],
  settings: {},
  loading: false,
  error: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.settings = action.payload;
    });
    builder.addCase(getSettings.pending, state => {
      state.loading = true;
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getTimezones.fulfilled, (state, action) => {
      state.loading = false;
      state.timezones = action.payload;
    });
    builder.addCase(getTimezones.pending, state => {
      state.loading = true;
    });
    builder.addCase(getTimezones.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getTerminal.fulfilled, (state, action) => {
      state.loading = false;
      state.terminal = action.payload;
    });
    builder.addCase(getTerminal.pending, state => {
      state.loading = true;
    });
    builder.addCase(getTerminal.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(saveGeneral.pending, state => {
      state.loading = true;
    });
    builder.addCase(saveGeneral.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(saveTerminal.pending, state => {
      state.loading = true;
    });
    builder.addCase(saveTerminal.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(saveDriverApp.pending, state => {
      state.loading = true;
    });
    builder.addCase(saveDriverApp.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(saveBilling.pending, state => {
      state.loading = true;
    });
    builder.addCase(saveBilling.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(saveFactoring.pending, state => {
      state.loading = true;
    });
    builder.addCase(saveFactoring.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(saveSubscription.pending, state => {
      state.loading = true;
    });
    builder.addCase(saveSubscription.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(saveNotifications.pending, state => {
      state.loading = true;
    });
    builder.addCase(saveNotifications.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export default settingsSlice.reducer;
