import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Line,
  LoadsTitle,
  LoadsFlex,
  CheckBoxItem,
  CheckBoxSquare,
  CustomCheckBoxSquare,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInputFieldThird,
  InputDiv,
  LoadsInput,
  LoadsInputTextArea,
  LoadsLabel,
  LoadsText,
  SelectedValue,
  FieldIcon,
  LoadsSelect,
  Opt,
  InputDate,
  Bottom,
  Actions,
  Cancel,
  Error,
  Header,
  Main,
  Wrapper,
} from './pointModal.style';
import americaStates from '../../../../americaStates.json';
import { Delivery, getLoadData, updateDelivery } from '../../../../API/loads';
import { Calendar, Check, SelectArrow } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { resetError } from '../../../../store/slices/loadsSlice';
import ModalPortal from '../../ModalPortal';

const defaultErrorState = {
  message: '',
  errors: {
    business_name: [],
    street_address: [],
    city: [],
  },
};

type ModalAssignProps = {
  close: () => void;
  deliveryData: Delivery;
  refDelivery: any;
};

const PointDelivery: React.FC<ModalAssignProps> = ({ close, deliveryData, refDelivery }) => {
  const dispatch = useAppDispatch();

  const { loadId, loadInfo, error } = useAppSelector((state: { loads: any }) => state.loads);

  const [err, setErr] = useState(error ?? defaultErrorState);

  const [pointDate, setPointDate] = useState<Date>(new Date(moment(deliveryData?.date).format('MM/DD/YYYY')));

  const [pickupOnDate, setPickupOnDate] = useState<string>(
    loadInfo?.load?.delivery_at ? moment(loadInfo?.load?.delivery_at).format('MM/DD/YYYY') : ''
  );

  const [copyToCustomer, setCopyToCustomer] = useState<boolean>(false);

  const [loadPoint, setLoadPoint] = useState({
    businessNamePoint: deliveryData.business_name,
    streetAddressPoint: deliveryData.street_address,
    cityPoint: deliveryData.city,
    zipPoint: deliveryData.zip ? deliveryData.zip : '',
    notesPoint: deliveryData.notes ? deliveryData.notes : '',
    fullNamePoint: deliveryData.full_name ? deliveryData.full_name : '',
    emaiPoint: deliveryData.email ? deliveryData.email : '',
  });

  const [statePoint, setStatePoint] = useState<string>(deliveryData.state);
  const [phonePoint, setPhonePoint] = useState<string>(deliveryData.phone ? deliveryData.phone : '');

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, []);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state: any) => {
    if (name === 'businessNamePoint')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'cityPoint')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'fullNamePoint')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else setState({ ...state, [name]: e.target.value });
  };

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const handleInputTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, setState: any, name: any, state: any) =>
    setState({ ...state, [name]: e.target.value });

  const handleCheckboxCopy = (e: React.ChangeEvent<HTMLInputElement>, setState: any, elem: any) => {
    setState(!elem);
  };

  const handleConfirm = async () => {
    const res: any = await dispatch(
      updateDelivery([
        {
          type: 'delivery',
          business_name: loadPoint.businessNamePoint,
          street_address: loadPoint.streetAddressPoint,
          city: loadPoint.cityPoint,
          state: statePoint,
          zip: loadPoint.zipPoint,
          date: moment(pointDate).format('DD.MM.YYYY'),
          notes: loadPoint.notesPoint,
          full_name: loadPoint.fullNamePoint,
          phone: phonePoint,
          email: loadPoint.emaiPoint,
        },
        loadId.id,
        copyToCustomer,
      ])
    );
    if (res.type === 'loads/updateDelivery/rejected') return setErr(res.payload);

    await dispatch(getLoadData(loadId.id));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refDelivery}>
        <Header>
          <LoadsTitle>Edit Delivery</LoadsTitle>
        </Header>
        <Main>
          <LoadsInputField
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CheckBoxItem>
              <CheckBoxSquare
                type="checkbox"
                name="copyToCustomer"
                id="copyToCustomer"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleCheckboxCopy(e, setCopyToCustomer, copyToCustomer)
                }
                checked={copyToCustomer}
              />
              <CustomCheckBoxSquare>
                <Check right="3px" />
              </CustomCheckBoxSquare>
            </CheckBoxItem>
            <LoadsLabel htmlFor="copyToCustomer" style={{ marginBottom: 0, marginLeft: '12px' }}>
              Copy to Customer
            </LoadsLabel>
          </LoadsInputField>
          <LoadsInputField>
            <LoadsLabel htmlFor="businessNamePoint">Business Name</LoadsLabel>
            <LoadsInput
              id="businessNamePoint"
              name="businessNamePoint"
              type="text"
              style={{
                border: err.errors?.business_name?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, business_name: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadPoint, 'businessNamePoint', loadPoint)
              }
              value={loadPoint.businessNamePoint}
            />
            <Error>{err.errors?.business_name?.[0]}</Error>
          </LoadsInputField>
          <LoadsInputField>
            <LoadsLabel htmlFor="streetAddressPoint">Street Address</LoadsLabel>
            <LoadsInput
              id="streetAddressPoint"
              name="streetAddressPoint"
              type="text"
              style={{
                border: err.errors?.street_address?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, street_address: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadPoint, 'streetAddressPoint', loadPoint)
              }
              value={loadPoint.streetAddressPoint}
            />
            <Error>{err.errors?.street_address?.[0]}</Error>
          </LoadsInputField>
          <LoadsFlex>
            <LoadsInputFieldThird>
              <LoadsLabel htmlFor="cityPoint">City</LoadsLabel>
              <LoadsInput
                id="cityPoint"
                name="cityPoint"
                type="text"
                style={{
                  border: err.errors?.city?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, city: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setLoadPoint, 'cityPoint', loadPoint)}
                value={loadPoint.cityPoint}
              />
              <Error>{err.errors?.city?.[0]}</Error>
            </LoadsInputFieldThird>
            <LoadsInputFieldThird>
              <LoadsLabel htmlFor="statePoint">State</LoadsLabel>
              <SelectedValue
                style={{
                  position: 'absolute',
                  top: '90%',
                  transform: 'translateY(-80%)',
                }}
              >
                {statePoint}
              </SelectedValue>
              <FieldIcon style={{ top: '95%' }}>
                <SelectArrow />
              </FieldIcon>
              <LoadsSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  changeSelectState(e, setStatePoint, setStatePoint)
                }
              >
                <Opt hidden></Opt>
                {americaStates?.map((item: any) => (
                  <Opt key={item} value={item} data-value={item}>
                    {item}
                  </Opt>
                ))}
              </LoadsSelect>
            </LoadsInputFieldThird>
            <LoadsInputFieldThird>
              <LoadsLabel htmlFor="zipPoint">ZIP</LoadsLabel>
              <LoadsInput
                id="zipPoint"
                name="zipPoint"
                type="number"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setLoadPoint, 'zipPoint', loadPoint)}
                value={loadPoint.zipPoint}
              />
            </LoadsInputFieldThird>
          </LoadsFlex>
          <LoadsFlex>
            <LoadsInputFieldHalf>
              <LoadsLabel htmlFor="pointDate">Scheduled Pickup Date</LoadsLabel>
              <FieldIcon>
                <Calendar />
              </FieldIcon>
              <InputDate
                id="pointDate"
                dateFormat="MM/dd/yyyy"
                selected={pointDate}
                onChange={(date: any) => setPointDate(date)}
              />
            </LoadsInputFieldHalf>
            <LoadsInputFieldHalf>
              <LoadsLabel htmlFor="pickupOnDate">Picked Up on</LoadsLabel>
              <SelectedValue style={{ backgroundColor: '#E9EAF8' }}>{pickupOnDate}</SelectedValue>
              <FieldIcon>
                <Calendar />
              </FieldIcon>
            </LoadsInputFieldHalf>
          </LoadsFlex>
          <LoadsInputField>
            <LoadsLabel htmlFor="notesPoint">Notes</LoadsLabel>
            <LoadsInputTextArea
              id="notesPoint"
              name="notesPoint"
              rows={1}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleInputTextArea(e, setLoadPoint, 'notesPoint', loadPoint)
              }
              value={loadPoint.notesPoint}
            />
          </LoadsInputField>
          <Line />
          <LoadsText>Contact details</LoadsText>
          <LoadsInputField>
            <LoadsLabel htmlFor="fullNamePoint">Full Name</LoadsLabel>
            <LoadsInput
              id="fullNamePoint"
              name="fullNamePoint"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setLoadPoint, 'fullNamePoint', loadPoint)}
              value={loadPoint.fullNamePoint}
            />
          </LoadsInputField>

          <LoadsFlex>
            <LoadsInputFieldHalf>
              <LoadsLabel htmlFor="phonePoint">Phone</LoadsLabel>
              <InputDiv>
                <PhoneInput
                  international
                  limitMaxLength
                  value={phonePoint}
                  id="phonePoint"
                  onChange={(value: string) => setPhonePoint(value || '')}
                />
              </InputDiv>
            </LoadsInputFieldHalf>
            <LoadsInputFieldHalf>
              <LoadsLabel htmlFor="emaiPoint">Email</LoadsLabel>
              <LoadsInput
                id="emaiPoint"
                name="emaiPoint"
                type="email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setLoadPoint, 'emaiPoint', loadPoint)}
                value={loadPoint.emaiPoint}
              />
            </LoadsInputFieldHalf>
          </LoadsFlex>
        </Main>
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default PointDelivery;
