import * as React from "react"
import { SVGProps } from "react"

const CloseLoads = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      d="m13.484 12 7.462-7.679c.125-.127.02-.321-.174-.321h-2.268a.496.496 0 0 0-.35.14L12 10.474 5.846 4.14a.486.486 0 0 0-.35-.14H3.228c-.194 0-.299.194-.174.321L10.516 12l-7.462 7.678a.184.184 0 0 0-.052.1.171.171 0 0 0 .02.11.208.208 0 0 0 .083.082c.037.02.08.03.123.03h2.268a.496.496 0 0 0 .35-.14L12 13.526l6.154 6.334c.086.088.213.14.35.14h2.268c.194 0 .299-.194.174-.322L13.484 12Z"
      fill="#616172"
    />
  </svg>
)

export default CloseLoads
