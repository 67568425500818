import styled from 'styled-components';

export const Block = styled.div`
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const TopTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const Title = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const Delete = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 14px;
  color: #f30f00;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ImagePlace = styled.div`
position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 0 0 16px;
`;

export const InputFilePlace = styled.div`
  border: 2px dashed #c6c7da;
  min-height: 60px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const InputPlace = styled.img`
  max-width: 100%;
  height: 100%;
  
  border-radius: 4px;
  z-index: 2;
`;

export const BlueButton = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  cursor: pointer;
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  font-size: 0;
  cursor: pointer;
`;

export const Bottom = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonSave = styled.div`
  display: flex;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;