import * as React from "react"
import { SVGProps } from "react"

const EditDriverImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 29.036V31.5h2.464l14-14.035L22.5 15l-14 14.036ZM6 28v6h6l16.5-16.535-6-6L6 28ZM25 9l5-5 6 6-5 5-6-6Zm3.535 0L31 11.464 32.465 10 30 7.536 28.535 9Z"
      fill="#fff"
    />
  </svg>
)

export default EditDriverImage
