import React, { useEffect, useRef, useState } from 'react';

import { LoadId } from '../../../../API/loads';
import { Close, Document } from '../../../../assets/icons';
import ModalExpenses from '../../../../layout/Modal/ModalLoads/ModalExpenses/ModalExpenses';
import useOnClickOutside from '../../../../layout/Modal/useClickOutsideModal';
import {
  BlueButton,
  DeliverText,
  DocumentName,
  DocumentText,
  FlexBetween,
  LoadsAttachFile,
  LoadsBlock,
  LoadsImagePlace,
  LoadsInputFile,
  LoadsTitle,
} from '../loadsItem.style';

type LoadsItemProps = {
  expenses: [];
  loadId: LoadId;
  deleteExpenses?: (id: number, loadId: number) => void;
  printMode?: boolean;
};

const LoadsItemExpenses: React.FC<LoadsItemProps> = ({ expenses, loadId, deleteExpenses, printMode = false }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [addCheck, setAddCheck] = useState<boolean>(false);
  const [expenseItem, setExpenseItem] = useState();

  const refExpense = useRef<any>();
  useOnClickOutside(refExpense, () => setOpen(false));

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  return (
    <>
      <LoadsBlock className="noPrint">
        <FlexBetween>
          <LoadsTitle>Expenses</LoadsTitle>
        </FlexBetween>
        {!printMode && (
          <LoadsImagePlace>
            <LoadsInputFile
              onClick={() => {
                setAddCheck(true);
                setOpen(true);
              }}
            >
              <BlueButton>ADD EXPENSES</BlueButton>
            </LoadsInputFile>
          </LoadsImagePlace>
        )}
        {expenses?.map((elem: any) => {
          return (
            <LoadsAttachFile key={elem.id}>
              <DocumentName>
                <Document />
                <DocumentText
                  clickable
                  onClick={() => {
                    setAddCheck(false);
                    setOpen(true);
                    setExpenseItem(elem);
                  }}
                >
                  expenses - {elem.expense_type.title}
                </DocumentText>
              </DocumentName>
              {deleteExpenses && (
                <div className="noPrint" onClick={() => deleteExpenses(elem.id, loadId.id)}>
                  <Close />
                </div>
              )}
            </LoadsAttachFile>
          );
        })}
      </LoadsBlock>

      {open && (
        <ModalExpenses refExpense={refExpense} elem={expenseItem} addCheck={addCheck} close={() => setOpen(false)} />
      )}
    </>
  );
};

export default LoadsItemExpenses;
