import { Content } from "./settingsContainer.style";
import AuthHeader from "../components/Header/AuthHeader";
import SidebarSettings from "../components/Sidebar/SidebarSettings";

const SettingsContainer:React.FC = ({children}) => {
    return (
        <>
            <AuthHeader />
            <Content>
            <SidebarSettings />
            {children}
            </Content>
        </>
    )
}

export default SettingsContainer;