import React, { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import { Calendar, SelectArrow } from '../../../../assets/icons';
import {
  Error,
  FieldIcon,
  InputDiv,
  LoadsBlock,
  LoadsFlex,
  LoadsInput,
  LoadsInputDate,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInputFieldThird,
  LoadsInputTextArea,
  LoadsLabel,
  LoadsSelect,
  LoadsText,
  LoadsTitle,
  Opt,
  SelectedValue,
} from '../createLoad.style';

type LoadsDetailProps = {
  handleInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: any,
    name: any,
    state: any,
  ) => void;
  handleInputTextArea: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    setState: any,
    name: any,
    state: any,
  ) => void;
  loadDelivery: any;
  setLoadDelivery: any;
  deliveryDate: any;
  setDeliveryDate: any;
  phoneDelivery: string;
  setPhoneDelivery: any;
  stateDelivery: string;
  setStateDelivery: any;
  changeSelectState: any;
  americaStates: string[];
  error: any;
};

const LoadsDelivery: React.FC<LoadsDetailProps> = ({
  handleInput,
  handleInputTextArea,
  loadDelivery,
  setLoadDelivery,
  deliveryDate,
  setDeliveryDate,
  phoneDelivery,
  setPhoneDelivery,
  stateDelivery,
  setStateDelivery,
  changeSelectState,
  americaStates,
  error,
}) => {
  const [errZip, setErrZip] = useState<boolean>(false);

  useEffect(() => {
    if (error?.errors['delivery.zip']?.[0]) setErrZip(true);
  }, [error]);

  return (
    <>
      <LoadsBlock>
        <LoadsTitle>Delivery Information</LoadsTitle>
        <LoadsInputField>
          <LoadsLabel htmlFor="businessNameDelivery">Business Name</LoadsLabel>
          <LoadsInput
            id="businessNameDelivery"
            name="businessNameDelivery"
            type="text"
            style={{
              border: '1px solid #c6c7da',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(
                e,
                setLoadDelivery,
                'businessNameDelivery',
                loadDelivery,
              )
            }
            value={loadDelivery.businessNameDelivery}
          />
          
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="streetAddressDelivery">
            Street Address
          </LoadsLabel>
          <LoadsInput
            id="streetAddressDelivery"
            name="streetAddressDelivery"
            type="text"
            style={{
              border: '1px solid #c6c7da',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(
                e,
                setLoadDelivery,
                'streetAddressDelivery',
                loadDelivery,
              )
            }
            value={loadDelivery.streetAddressDelivery}
          />
        </LoadsInputField>
        <LoadsFlex>
        <LoadsInputFieldThird>
            <LoadsLabel htmlFor="cityDelivery">City</LoadsLabel>
            <LoadsInput
              id="cityDelivery"
              name="cityDelivery"
              type="text"
              style={{
                border: '1px solid #c6c7da',
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadDelivery, 'cityDelivery', loadDelivery)
              }
              value={loadDelivery.cityDelivery}
            />
          </LoadsInputFieldThird>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="stateDelivery">State</LoadsLabel>
            <SelectedValue>{stateDelivery}</SelectedValue>
            <FieldIcon style={{ top: '95%' }}>
              <SelectArrow />
            </FieldIcon>
            <LoadsSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                changeSelectState(e, setStateDelivery, setStateDelivery)
              }
            >
              <Opt hidden></Opt>
              {americaStates?.map((item: any) => (
                <Opt key={item} value={item} data-value={item}>
                  {item}
                </Opt>
              ))}
            </LoadsSelect>
          </LoadsInputFieldThird>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="zipDelivery">ZIP</LoadsLabel>
            <LoadsInput
              id="zipDelivery"
              name="zipDelivery"
              type="number"
              style={{
                border: errZip ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() => setErrZip(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadDelivery, 'zipDelivery', loadDelivery)
              }
              value={loadDelivery.zipDelivery}
            />
            {errZip && (
              <Error>{error?.errors['delivery.zip']?.[0]}</Error>
            )}
          </LoadsInputFieldThird>
        </LoadsFlex>
        <LoadsInputField>
          <LoadsLabel htmlFor="deliveryDate">
            Scheduled Delivery Date
          </LoadsLabel>
          <FieldIcon>
            <Calendar />
          </FieldIcon>
          <LoadsInputDate
            id="deliveryDate"
            dateFormat="MM/dd/yyyy"
            selected={deliveryDate}
            onChange={(date: any) => setDeliveryDate(date)}
          />
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="notesDelivery">Notes</LoadsLabel>
          <LoadsInputTextArea
            id="notesDelivery"
            name="notesDelivery"
            rows={3}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleInputTextArea(
                e,
                setLoadDelivery,
                'notesDelivery',
                loadDelivery,
              )
            }
            value={loadDelivery.notesDelivery}
          />
        </LoadsInputField>

        <LoadsText>Contact details</LoadsText>
        <LoadsInputField>
          <LoadsLabel htmlFor="fullNameDelivery">Full Name</LoadsLabel>
          <LoadsInput
            id="fullNameDelivery"
            name="fullNameDelivery"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadDelivery, 'fullNameDelivery', loadDelivery)
            }
            value={loadDelivery.fullNameDelivery}
          />
        </LoadsInputField>

        <LoadsFlex>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="phoneDelivery">Phone</LoadsLabel>
            <InputDiv>
              <PhoneInput
                international
                limitMaxLength
                value={phoneDelivery}
                id="phoneDelivery"
                onChange={(value: string) => setPhoneDelivery(value || '')}
              />
            </InputDiv>
          </LoadsInputFieldHalf>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="emailDelivery">Email</LoadsLabel>
            <LoadsInput
              id="emailDelivery"
              name="emailDelivery"
              type="email"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadDelivery, 'emailDelivery', loadDelivery)
              }
              value={loadDelivery.emailDelivery}
            />
          </LoadsInputFieldHalf>
        </LoadsFlex>
      </LoadsBlock>
    </>
  );
};

export default LoadsDelivery;
