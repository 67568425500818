import React, { useEffect, useState } from 'react';

import {
  Wrapper,
  Top,
  Title,
  Close,
  Input,
  ListWrapper,
  List,
  Item,
  Name,
  Assign,
} from './modalAssign.style';
import { DriverItem, getDrivers } from '../../../../API/drivers';
import { assignLoad, getLoadData, getLoads } from '../../../../API/loads';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  itemPage?: boolean;
  refAssign: any;
};

const ModalAssign: React.FC<ModalAssignProps> = ({ close, itemPage, refAssign }) => {
  const [value, setValue] = useState<string>('');
  const [hover, setHover] = useState<number>();
  const { driversList } = useAppSelector(
    (state: { driver: any }) => state.driver,
  );

  const { loadId } = useAppSelector((state: { loads: any }) => state.loads);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDrivers(null));
  }, []);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const filteredPersons = driversList.filter((item: any) => {
    return item.first_name.toLowerCase().includes(value.toLowerCase());
  });

  const handleClick = async (elem: DriverItem) => {
    await dispatch(assignLoad([elem?.id || 0, loadId?.id]));
    if (itemPage) await dispatch(getLoadData(loadId.id));
    await dispatch(
      getLoads({
        type: 'load_id',
        sortBy: 'none',
      }),
    );
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refAssign}>
        <Top>
          <Title>Reasign to Driver</Title>
          <Close onClick={close}>Close</Close>
        </Top>
        <Input
          type="text"
          value={value}
          onChange={handleInput}
          placeholder="Search..."
        />

        <ListWrapper>
          <List>
            {filteredPersons.map((elem: any) => {
              return (
                <Item
                  style={{
                    backgroundColor:
                      elem.id === hover ? '#f4f4f4' : 'transparent',
                  }}
                  key={elem.id}
                  onMouseEnter={() => setHover(elem.id)}
                  onMouseLeave={() => setHover(0)}
                  onClick={() => {
                    handleClick(elem);
                  }}
                >
                  <Name>{elem.first_name + ' ' + elem.last_name}</Name>
                  <Name>{elem.phone}</Name>
                  <Name>{elem.trailer_capacity} vehicles trailer</Name>

                  {elem.id === hover && <Assign>Assign</Assign>}
                </Item>
              );
            })}
          </List>
        </ListWrapper>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalAssign;
