import React from 'react';
import ReactDOM from 'react-dom';

import { Wrapper } from './modalPortal.style';

const ModalPortal: React.FC = ({ children }) => {
  return ReactDOM.createPortal(
    <Wrapper>{children}</Wrapper>,
    document.getElementById('modal') as HTMLElement,
  );
};

export default ModalPortal;
