import * as React from "react"
import { SVGProps } from "react"

const PersonSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.947c1.139 0 2.061-.942 2.061-2.105s-.922-2.105-2.06-2.105c-1.139 0-2.061.942-2.061 2.105S6.862 6.947 8 6.947Zm3.435-2.105c0 1.938-1.538 3.509-3.435 3.509-1.896 0-3.434-1.571-3.434-3.509S6.104 1.333 8 1.333c1.897 0 3.435 1.571 3.435 3.509ZM11.435 10.456c-.76 0-1.374-.628-1.374-1.403h1.616c.502 0 .987.187 1.363.527s.619.808.68 1.317l.27 2.192a1.432 1.432 0 0 1-.334 1.103 1.35 1.35 0 0 1-1.03.475H3.374a1.35 1.35 0 0 1-1.03-.475 1.412 1.412 0 0 1-.333-1.103l.268-2.192a2.12 2.12 0 0 1 .68-1.318c.378-.34.863-.527 1.365-.526H5.94c0 .775-.615 1.403-1.374 1.403h-.24a.68.68 0 0 0-.456.176.707.707 0 0 0-.227.439l-.268 2.192h9.252l-.268-2.192a.707.707 0 0 0-.227-.44.679.679 0 0 0-.454-.175h-.242Z"
      fill="#9292A4"
    />
  </svg>
)

export default PersonSmall
