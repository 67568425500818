import React, { useState } from 'react';

import {
  Actions,
  BorderBottom,
  Bottom,
  ButtonBlock,
  Cancel,
  IntegrationStep,
  Line,
  Step,
  Steps,
  Text,
  Wrapper,
} from './modalIntegrations.style';
import Button from '../../../components/Button/Button';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  refIntegration: any;
};

const ModalIntegrations: React.FC<ModalContentProps> = ({ close, refIntegration }) => {
  const [tab, setTab] = useState<boolean>(false);
  const handleConfirm = () => console.log('download');

  return (
    <ModalPortal>
      <Wrapper ref={refIntegration}>
        <Steps>
          <Step
            style={{
              color: !tab ? '#1267CF' : '#616172',
            }}
            onClick={() => setTab(false)}
          >
            Step 1 {!tab && <BorderBottom />}
          </Step>
          <Step
            style={{
              color: tab ? '#1267CF' : '#616172',
            }}
            onClick={() => setTab(true)}
          >
            Step 2 {tab && <BorderBottom />}
          </Step>
        </Steps>
        <Line />

        {!tab && (
          <IntegrationStep>
            <Text>
              To start connecting your dashboard with QuickBooks Desktop, please download a QuickBooks Web Connector
              (QWC) file using the button below:
            </Text>
            <ButtonBlock>
              <Button text="Download" pad="8px 24px" handleButton={handleConfirm} />
            </ButtonBlock>
          </IntegrationStep>
        )}

        {tab && (
          <IntegrationStep>
            <Text>Download instructions:</Text>
            <ButtonBlock>
              <Button text="Download" pad="8px 24px" handleButton={handleConfirm} />
            </ButtonBlock>
          </IntegrationStep>
        )}

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalIntegrations;
