import moment from 'moment';
import React from 'react';

import { AddressSmall, CalendarSmall, ContactSmall, PersonSmall } from '../../../../assets/icons';
import {
  DeliverText,
  DeliverTextEmpty,
  DeliveryFlex,
  FlexBetween,
  FlexTopBetween,
  LoadsBlock,
  LoadsEdit,
  LoadsFlexColumn,
  LoadsNameSemiBold,
  LoadsTitle,
} from '../loadsItem.style';

type LoadsItemProps = {
  setPointPickup?: () => void;
  pickupData: any;
  pickupOnDate: string;
};

const LoadItemPickup: React.FC<LoadsItemProps> = ({ setPointPickup, pickupData, pickupOnDate }) => {
  return (
    <>
      <LoadsBlock>
        <FlexBetween>
          <LoadsTitle>Pickup Information</LoadsTitle>
          {setPointPickup && (
            <LoadsEdit className="noPrint" onClick={setPointPickup}>
              Edit
            </LoadsEdit>
          )}
        </FlexBetween>
        <FlexTopBetween>
          <LoadsFlexColumn>
            <LoadsNameSemiBold>{pickupData?.business_name}</LoadsNameSemiBold>

            <DeliveryFlex>
              <AddressSmall />
              <DeliverText>
                {pickupData?.street_address + ', ' + pickupData?.state + ', ' + pickupData?.zip}
              </DeliverText>
            </DeliveryFlex>

            <DeliveryFlex>
              <CalendarSmall />
              <LoadsFlexColumn>
                <DeliverText>{pickupData?.date}</DeliverText>
                <DeliverTextEmpty style={{ display: 'inline-flex' }}>
                  Picked Up on&nbsp;<DeliverText>{pickupOnDate}</DeliverText>
                </DeliverTextEmpty>
              </LoadsFlexColumn>
            </DeliveryFlex>

            <DeliveryFlex>
              <PersonSmall />
              <LoadsFlexColumn>
                {pickupData?.full_name ? (
                  <DeliverText>{pickupData?.full_name}</DeliverText>
                ) : (
                  <DeliverTextEmpty>No contact name</DeliverTextEmpty>
                )}
                <DeliverText>
                  {pickupData?.phone && pickupData?.phone}
                  {!pickupData?.phone && 'No phone number'}
                </DeliverText>
              </LoadsFlexColumn>
            </DeliveryFlex>

            <DeliveryFlex>
              <ContactSmall />
              {pickupData?.notes ? (
                <DeliverText>{pickupData?.notes}</DeliverText>
              ) : (
                <DeliverTextEmpty>No pickup notes</DeliverTextEmpty>
              )}
            </DeliveryFlex>
          </LoadsFlexColumn>
        </FlexTopBetween>
      </LoadsBlock>
    </>
  );
};

export default LoadItemPickup;
