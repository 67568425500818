import { InputAdornment } from '@mui/material';
import moment from 'moment';
import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-number-input';

import LoadsItem from './LoadsItem';
import {
  Wrapper,
  Title,
  InvoiceForm,
  FlexBlock,
  InputDiv,
  CheckboxBlock,
  InvoiceField,
  LoadList,
  LoadItem,
  LoadItemBlock,
  Status,
  InvoiceFieldHalf,
  SelectedValue,
  InvoiceSelect,
  Opt,
  FieldIcon,
  InvoiceLabel,
  InvoiceText,
  LoadsBlock,
  EmailBlock,
  EmailItem,
  EmailText,
  DeleteEmail,
  InvoiceInput,
  InvoiceInputEmail,
  InputDate,
  ViewText,
  Error,
  Line,
  Bottom,
  Actions,
  Preview,
  Cancel,
  MUIInvoiceInput,
} from './modalSendInvoice.style';
import { getPreviewInvoice, sendInvoice } from '../../../API/invoice';
import { getLoads, updateLoadStatus } from '../../../API/loads';
import { ArrowDown, ArrowUp, Calendar, Close, SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ViewFleetImageProps = {
  close: () => void;
  load: any;
  refInvoice: any;
};

const ModalSendInvoice: React.FC<ViewFleetImageProps> = ({ close, load, refInvoice }) => {
  const [err, setErr] = useState({
    message: '',
    errors: {
      invoice_id: [],
      template: [],
      emails: [],
      attachments: [],
    },
  });

  const { loadsList } = useAppSelector((state: { loads: any }) => state.loads);
  const ref = useRef<any>(null);
  const menuRef = useRef<any>(null);

  const [invoiceID, setInvoiceID] = useState<string>('');
  const [invoiceDate, setInvoiceDate] = useState<Date>(new Date());
  const [loadID, setLoadID] = useState<string>('');
  const [loads, setLoads] = useState<string[]>([load.id]);
  const [factorInvoice, setFactorInvoice] = useState<boolean>(false);
  const [emailTo, setEmailTo] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>('');
  const [emailList, setEmailList] = useState<string[]>([]);
  const [attachmentList, setAttachmentList] = useState<any[]>([{ id: load.id, attachments: load.attachments }]);
  const [attachmentData, setAttachmentData] = useState<string[]>([]);
  const [fax, setFax] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [bol, setBol] = useState<string>('default');
  const [bolName, setBolName] = useState<string>('Default');

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [showLoadsMenu, setShowLoadsMenu] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].label);
  };

  useEffect(() => {
    setInvoiceID(load?.payment?.invoice_id ?? '');
  }, [load?.payment?.invoice_id]);

  const addLoadsToList = (item: any) => {
    if (!loads.includes(item.id)) {
      setLoads([...loads, item.id]);

      setAttachmentList([...attachmentList, { id: item.id, attachments: item.attachments }]);
      setIsFocus(false);
    } else setIsFocus(false);
  };

  const deleteLoadsFromList = (item: any) => {
    setLoads(loads.filter((elem) => elem !== item));
    setAttachmentList(attachmentList.filter((elem) => elem.id !== item));
  };

  const addEmailsToList = (e: any) => {
    if (e.target.value !== '') {
      if (!emailList.includes(e.target.value) && /\S+@\S+\.\S+/.test(e.target.value))
        setEmailList([...emailList, e.target.value]);
      setEmails('');
    } else return;
  };

  const deleteEmailFromList = (item: string) => {
    setEmailList(emailList.filter((elem) => elem !== item));
  };

  const filterLoads = loadsList.filter((item: any) => {
    return item.load_id.toLowerCase().includes(loadID.toLowerCase());
  });

  const getPreview = async () => {
    const res = await dispatch(
      getPreviewInvoice({
        load_id: load.id,
        id: invoiceID,
        date: moment(invoiceDate).format('DD.MM.YYYY'),
        sticker: factorInvoice,
      })
    );

    const winHtml: string | any = res.payload;

    const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));

    window.open(winUrl, '_blank');
  };

  const handleConfirm = async () => {
    const res: any = await dispatch(
      sendInvoice({
        invoice_id: invoiceID,
        invoice_date: invoiceDate,
        sticker: factorInvoice,
        template: bol,
        fax: phone,
        attachments: attachmentData,
        loads: loads,
        emails: emailList,
      })
    );
    if (res.type === 'invoice/sendInvoice/rejected') return setErr(res.payload);
    await dispatch(updateLoadStatus(['billed', load.id]));
    await dispatch(
      getLoads({
        type: 'load_id',
        sortBy: 'none',
      })
    );
    close();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (isFocus && ref.current && !ref.current.contains(e.target)) {
        setIsFocus(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isFocus]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showLoadsMenu && menuRef.current && !menuRef.current.contains(e.target)) {
        setShowLoadsMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showLoadsMenu]);

  return (
    <ModalPortal>
      <Wrapper ref={refInvoice}>
        <Title>Invoice</Title>
        <InvoiceForm>
          <FlexBlock>
            <InvoiceFieldHalf>
              <InvoiceLabel htmlFor="invoiceID">Invoice ID</InvoiceLabel>
              <InvoiceInput
                id="invoiceID"
                type="text"
                style={{
                  border: err.errors?.invoice_id?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() =>
                  setErr({
                    ...err,
                    errors: { ...err.errors, invoice_id: [] },
                  })
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInvoiceID(e.target.value);
                }}
                value={invoiceID}
              />
              <Error>{err.errors?.invoice_id?.[0]}</Error>
            </InvoiceFieldHalf>
            <InvoiceFieldHalf>
              <InvoiceLabel htmlFor="invoiceDate">Invoice Date</InvoiceLabel>
              <FieldIcon style={{ top: '72%' }}>
                <Calendar />
              </FieldIcon>
              <InputDate
                id="invoiceDate"
                dateFormat="MM/dd/yyyy"
                selected={invoiceDate}
                onChange={(date: any) => setInvoiceDate(date)}
              />
            </InvoiceFieldHalf>
          </FlexBlock>
          <InvoiceField>
            <InvoiceLabel htmlFor="customer">Customer</InvoiceLabel>
            <InvoiceText style={{ marginTop: '4px' }}>{load?.customer?.business_name}</InvoiceText>
          </InvoiceField>
          <Line />
          <InvoiceField style={{ marginBottom: '0' }}>
            <InvoiceText style={{ marginTop: '32px', marginBottom: '8px' }}>
              Select orders to send multi-order invoice
            </InvoiceText>
            <MUIInvoiceInput
              id="loadID"
              type=""
              value={loadID}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setLoadID(e.target.value);
                setIsFocus(true);
              }}
              endAdornment={<InputAdornment position="start">{isFocus ? <ArrowUp /> : <ArrowDown />}</InputAdornment>}
              onFocus={() => setIsFocus(true)}
              placeholder="Load id..."
            />

            <LoadList ref={ref} style={{ opacity: isFocus ? '1' : '0' }}>
              {isFocus &&
                filterLoads.map((item: any) => {
                  return (
                    <LoadItem key={item.id} onClick={() => addLoadsToList(item)}>
                      <LoadItemBlock>
                        <Actions>
                          <InvoiceLabel style={{ margin: '0 8px 0 0', fontSize: '14px' }}>ID: {item.id}</InvoiceLabel>
                          <Status
                            style={{
                              color:
                                item.status === 'New' ? '#0e71eb' : item.status === 'Archived' ? '#9292A4' : '#0baa1b',
                              backgroundColor:
                                item.status === 'New'
                                  ? '#b0dcff'
                                  : item.status === 'Archived'
                                    ? '#E9EAF8'
                                    : 'rgba(11, 170, 27, 0.15)',
                            }}
                          >
                            {item.status}
                          </Status>
                        </Actions>
                        <InvoiceLabel style={{ margin: '8px 0 0 0', fontSize: '14px' }}>
                          {item?.vehicles[0]?.year + ' ' + item?.vehicles[0]?.make + ' ' + item?.vehicles[0]?.model}
                        </InvoiceLabel>
                        <InvoiceLabel style={{ margin: '8px 0 0 0', fontSize: '14px' }}>
                          VIN: {item?.vehicles[0]?.vin}
                        </InvoiceLabel>
                      </LoadItemBlock>
                      <LoadItemBlock>
                        <InvoiceText>{item?.points[0].business_name}</InvoiceText>
                        <InvoiceLabel style={{ margin: '8px 0 0 0', fontSize: '14px' }}>
                          {item?.points[0].street_address}
                          <br />
                          {item?.points[0].state + ', ' + item?.points[0].zip}
                        </InvoiceLabel>
                        <InvoiceLabel style={{ margin: '8px 0 0 0', fontSize: '14px' }}>
                          {moment(item?.points[0]?.date).format('MM/DD/yyyy')}
                        </InvoiceLabel>
                      </LoadItemBlock>
                      <LoadItemBlock>
                        <InvoiceText>{item?.points[1].business_name}</InvoiceText>
                        <InvoiceLabel style={{ margin: '8px 0 0 0', fontSize: '14px' }}>
                          {item?.points[1].street_address}
                          <br />
                          {item?.points[1].state + ', ' + item?.points[1].zip}
                        </InvoiceLabel>
                        <InvoiceLabel style={{ margin: '8px 0 0 0', fontSize: '14px' }}>
                          {moment(item?.points[1]?.date).format('MM/DD/yyyy')}
                        </InvoiceLabel>
                      </LoadItemBlock>
                    </LoadItem>
                  );
                })}
            </LoadList>
          </InvoiceField>
          <LoadsBlock>
            {loads?.map((item: string) => {
              return <LoadsItem key={item} loads={loads} item={item} deleteLoadsFromList={deleteLoadsFromList} />;
            })}
          </LoadsBlock>
          <Line style={{ marginTop: '32px' }} />
          <InvoiceField
            style={{
              marginTop: '32px',
              marginBottom: '0',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checkboxName="factorInvoice"
              checked={factorInvoice}
              setChecked={() => setFactorInvoice(!factorInvoice)}
            />
            <CheckboxBlock>
              <InvoiceText htmlFor="factorInvoice">Factor Invoice</InvoiceText>
              <InvoiceLabel htmlFor="factorInvoice" style={{ marginTop: '4px' }}>
                Notice of Assignment sticker is added to invoice.
              </InvoiceLabel>
            </CheckboxBlock>
          </InvoiceField>
          <InvoiceField
            style={{
              marginTop: '32px',
              marginBottom: '0',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Checkbox checkboxName="emailTo" checked={emailTo} setChecked={() => setEmailTo(!emailTo)} />
            <CheckboxBlock style={{ marginBottom: '14px' }}>
              <InvoiceText htmlFor="emailTo">Email to</InvoiceText>
            </CheckboxBlock>
          </InvoiceField>
          <InvoiceField>
            <EmailBlock>
              {emailList.map((item: string) => {
                return (
                  <EmailItem key={item}>
                    <EmailText>{item}</EmailText>
                    <DeleteEmail onClick={() => deleteEmailFromList(item)}>
                      <Close />
                    </DeleteEmail>
                  </EmailItem>
                );
              })}
              <InvoiceInputEmail
                id="emails"
                type="email"
                disabled={!emailTo}
                placeholder="Type to add more recipients..."
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmails(e.target.value)}
                onFocus={() =>
                  setErr({
                    ...err,
                    errors: { ...err.errors, emails: [] },
                  })
                }
                onBlur={addEmailsToList}
                value={emails}
              />
            </EmailBlock>

            <Error style={{ bottom: '-24px' }}>{err.errors?.emails?.[0]}</Error>
          </InvoiceField>
          <Line />
          <InvoiceField
            style={{
              marginBottom: '8px',
            }}
          >
            <InvoiceText style={{ marginTop: '32px' }}>Attachments</InvoiceText>
            <InvoiceLabel htmlFor="attachments" style={{ marginTop: '8px' }}>
              Selected attachments will be included to the email message.
            </InvoiceLabel>
          </InvoiceField>
          {attachmentList.map((elem: any) => {
            return (
              <div key={elem.id}>
                {elem?.attachments.length !== 0 && (
                  <InvoiceText style={{ marginTop: '32px', marginBottom: '0' }}>ID: {elem.id}</InvoiceText>
                )}
                {elem.attachments.map((attach: any) => {
                  return (
                    <InvoiceField
                      style={{
                        marginTop: '8px',
                        marginBottom: '0',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      key={attach.id}
                    >
                      <Checkbox
                        title={attach.id + ' Dispatch Sheet'}
                        checkboxName={'attach' + attach.id}
                        checked={attachmentData.includes(attach.id)}
                        setChecked={() => {
                          if (!attachmentData.includes(attach.id)) setAttachmentData([...attachmentData, attach.id]);
                          else setAttachmentData(attachmentData.filter((item) => item !== attach.id));
                        }}
                      />
                      <CheckboxBlock style={{ marginBottom: '14px', marginLeft: '16px' }}>
                        <ViewText href={attach.path} target="_blank">
                          View
                        </ViewText>
                      </CheckboxBlock>
                    </InvoiceField>
                  );
                })}
              </div>
            );
          })}
          <Line />
          <InvoiceField style={{ marginTop: '32px' }}>
            <Checkbox title="Fax" checkboxName="fax" checked={fax} setChecked={() => setFax(!fax)} />
            <InputDiv
              style={{
                backgroundColor: fax ? '#fff' : '#fafafa',
              }}
            >
              <PhoneInput
                international
                limitMaxLength
                value={phone}
                id="phone"
                disabled={!fax}
                onChange={(value: string) => setPhone(value || '')}
              />
            </InputDiv>
          </InvoiceField>
          <InvoiceField>
            <InvoiceLabel htmlFor="bol">BOL Template</InvoiceLabel>
            <SelectedValue>{bolName}</SelectedValue>
            <FieldIcon>
              <SelectArrow />
            </FieldIcon>
            <InvoiceSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, setBol, setBolName)}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, template: [] },
                })
              }
            >
              <Opt value="default">Default</Opt>
              <Opt value="multiple">Multiple</Opt>
            </InvoiceSelect>

            <Error style={{ bottom: '-35px' }}>{err.errors?.template?.[0]}</Error>
          </InvoiceField>
        </InvoiceForm>
        <Bottom>
          <Preview onClick={getPreview}>Preview</Preview>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Send" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalSendInvoice;
