import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { createReport, getReport, Report } from '../../API/reports';

type InitialStateType = {
  loading: boolean;
  error: ErrorType | null;
  data: Report;
  report: object;
};

const initialState: InitialStateType = {
  loading: false,
  error: null,
  report: {},
  data: {
    accounts_receivable: {
      current_due: '',
      past_due: '',
      total_due: '',
      records: [],
    },
    company_revenue: {
      revenue: '',
      records: [],
    },
    accounts_payable: {
      revenue: '',
      records: [],
    },
    driver_pay: {
      revenue: '',
      fee: '',
      records: [],
    },
  },
};

export const helpCenterSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    addParams: (state, action) => {
      state.report = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getReport.pending, state => {
      state.loading = true;
    });
    builder.addCase(getReport.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(createReport.pending, state => {
      state.loading = true;
    });
    builder.addCase(createReport.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { addParams } = helpCenterSlice.actions;

export default helpCenterSlice.reducer;
