import { NativeSelect, Select, TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;
  margin-bottom: 40px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;

  @media screen and (max-width: 1280px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

export const TopTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 425px) {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 16px;
  }
`;

export const TopText = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 425px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const BottomWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const BottomActions = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  @media screen and (max-width: 425px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const LoadsSmall = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  margin-top: 20px;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const LoadsBig = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 20px;
  overflow: hidden;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const LoadsBlock = styled.div`
  padding: 18px 16px 0;
  background-color: #fff;
  border-radius: 8px;
`;

export const LoadsVehicleBlock = styled.div`
  padding: 18px 16px 0;
  background-color: #fff;
  border-radius: 8px;

  @media screen and (max-width: 1600px) {
    overflow-x: scroll;
  }
`;

export const LoadsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const VehicleGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr repeat(5, 2fr) repeat(3, 1.5fr) 1fr;
  gap: 10px;
  align-items: flex-start;
`;

export const VehicleTitle = styled.span`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
  margin-bottom: 20px;
  white-space: nowrap;

  @media screen and (max-width: 1600px) {
    min-width: 100px;
  }

  @media screen and (max-width: 720px) {
    min-width: 150px;
  }
`;

export const LoadsInputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const LoadsVehicleInputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 1600px) {
    min-width: 100px;
  }

  @media screen and (max-width: 720px) {
    min-width: 150px;
  }
`;

export const LoadsInputFieldHalf = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const LoadsInputFieldThird = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  top: 90%;
  transform: translateY(-80%);
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
`;

export const LoadsSelect = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 6px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const MUISelect = styled(NativeSelect)({
  height: '38px',
  border: '1px solid #c6c7da',
  borderRadius: '4px',
  padding: '0 8px',

  '& .MuiNativeSelect-select': {
    // minWidth: '100% !important',
  },

  '&:before': {
    borderBottom: 'none !important',
  },

  '&:after': {
    borderBottom: 'none !important',
  },
});

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const BlueButton = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #0e71eb;
  margin-left: 5px;
  cursor: pointer;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 90%;
  transform: translateY(-80%);
  right: 0;
  padding: 10px;
`;

export const LoadsInputDate = styled(DatePicker)`
  width: 100%;
  min-height: 37px;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 7px 16px;
  background: transparent;
  cursor: pointer;
`;

export const LoadsTitle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  margin-bottom: 20px;
`;

export const TextPrice = styled.p`
  position: absolute;
  top: 9px;
  left: 5px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 16px;
`;

export const LoadsLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const LoadsInput = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputDiv = styled.div`
  height: 37px;
  border: 1px solid #c6c7da;
  padding: 10px;
  border-radius: 4px;
`;

export const LoadsInputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  max-width: 100%;
  max-height: 75px;
`;

export const LoadsText = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const CheckBoxBlock = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
`;

export const CheckBoxItem = styled.div`
  margin: 12px 0;
  display: flex;
  align-items: center;
  position: relative;
`;

export const CheckBoxTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
`;

export const CheckBoxTitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const CheckBoxSubtitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-top: 5px;
`;

export const CustomCheckBox = styled.span`
  position: absolute;
  top: 0;
  left: 5px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #c6c7da;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomCheckBoxSquare = styled.span`
  width: 32px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const CheckBoxSquare = styled.input`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 32px;
  height: 32px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked + span {
    background-color: #0e71eb;
    border: 1px solid transparent;
  }
`;

export const CheckBox = styled.input`
  position: absolute;
  top: 0;
  left: 5px;
  width: 32px;
  height: 32px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked + span {
    background-color: #0e71eb;
    border: 1px solid transparent;
  }
`;

export const Error = styled.span`
  margin-top: 5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
`;
