import { useEffect, useState } from 'react';

import {
  Wrapper,
  Input,
  List,
  ListWrapper,
  Item,
  Image,
  ImageDefault,
  ImageName,
  ImageHover,
  Name,
} from './contactsList.style';
import { ContactItem } from '../../../API/contacts';
import { Edit } from '../../../assets/icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showContactInfo } from '../../../store/slices/contactsSlice';

const ContactsList: React.FC = () => {
  const [value, setValue] = useState<string>('');
  const [hover, setHover] = useState<number>();
  const [isActive, setIsActive] = useState<number>();

  const dispatch = useAppDispatch();

  const { contactsList } = useAppSelector((state: { contact: any; }) => state.contact);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const filteredPersons = contactsList.filter((item: any) => {
    return item.name.toLowerCase().includes(value.toLowerCase());
  });

  const handleClick = (elem: ContactItem) => {
    
    dispatch(
      showContactInfo({
        id: elem.id,
        name: elem.name,
        city: elem.city,
        address: elem.address,
        contact_name: elem.contact_name,
        email: elem.email,
        phone: elem.phone,
        notes: elem.notes,
        fax: elem.fax,
        image: elem.image,
        zip: elem.zip,
        state: elem.state,
      }),
    );
  };

  useEffect(() => {
    dispatch(showContactInfo({}));
  }, []);

  return (
    <Wrapper>
      <Input
        type="text"
        value={value}
        onChange={handleInput}
        placeholder="Search..."
      />

      <ListWrapper>
        <List>
          {filteredPersons.map((elem: any) => {
            return (
              <Item
                style={{
                  backgroundColor:
                    elem.id === isActive || elem.id === hover
                      ? '#f4f4f4'
                      : '#fff',
                  borderRadius: '4px',
                }}
                key={elem.id}
                onMouseEnter={() => setHover(elem.id)}
                onMouseLeave={() => setHover(0)}
                onClick={() => {
                  handleClick(elem);
                  setIsActive(elem.id);
                }}
              >
                {elem.id === hover ? (
                  <ImageHover>
                    <Edit />
                  </ImageHover>
                ) : (
                  <>{elem.image ? <Image src={elem.image} /> : <ImageDefault><ImageName>{elem.name[0]} {elem.name[1]}</ImageName></ImageDefault>}</>
                  
                )}
                <Name>{elem.name}</Name>
              </Item>
            );
          })}
        </List>
      </ListWrapper>
    </Wrapper>
  );
};

export default ContactsList;
