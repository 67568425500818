import { InputBase } from '@mui/material';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 35%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
  max-height: 90%;
  overflow: auto;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  padding: 32px;

  @media screen and (max-width: 425px) {
    margin-bottom: 16px;
  }
`;

export const InvoiceForm = styled.div`
  overflow: hidden;
  height: 690px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media screen and (max-width: 1280px) {
    height: 500px;
  }

  @media screen and (max-width: 600px) {
    height: 400px;
  }
`;

export const FlexBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const InvoiceField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const LoadList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100px;
  max-height: 200px;
  min-height: auto;
  overflow: scroll;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px #494f5926;
  z-index: 5;
`;

export const LoadItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  cursor: pointer;
`;

export const LoadItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
`;

export const InvoiceFieldHalf = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;
  width: 48%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const InputDiv = styled.div`
  height: 41px;
  border: 1px solid #c6c7da;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 4px;
`;

export const CheckboxBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  top: 55%;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
  max-height: 37px;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 85%;
  transform: translateY(-50%);
  right: 0;
  padding: 10px;
`;

export const InvoiceSelect = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const InvoiceLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin: 0 0 4px;
`;

export const InvoiceText = styled.label`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;

export const ViewText = styled.a`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #0e71eb;
  cursor: pointer;
  text-decoration: none;
`;

export const LoadsBlock = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 0 0;
`;

export const LoadsItem = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #e9eaf8;
  padding: 8px;
  margin: 0 8px 0 0;
  cursor: pointer;
`;

export const LoadsText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const EmailBlock = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 0;
`;

export const EmailItem = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #e9eaf8;
  padding: 8px;
  margin: 2px 8px;
`;

export const EmailText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-right: 10px;
`;

export const InvoiceInputEmail = styled.input`
  width: 265px;
  border: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 8px 16px;
`;

export const DeleteEmail = styled.span`
  display: flex;
  align-items: center;
`;

export const InvoiceInput = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 8px 16px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const MUIInvoiceInput = styled(InputBase)`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 8px 16px;

  & svg path {
    fill: #616172;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputDate = styled(DatePicker)`
  width: 100%;
  min-height: 37px;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 7px 16px;
  background: transparent;
  cursor: pointer;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -17px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  padding: 0.05px;
  background-color: #c6c7da;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;

  @media screen and (max-width: 425px) {
    padding: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const Preview = styled.p`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 40px;
  left: 5px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(73, 79, 89, 0.15);
  border-radius: 4px;
  padding: 12px 0;
  min-width: 130px;
  z-index: 99;
`;

export const MenuItem = styled.div`
  padding: 12px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #1e2529;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
  }
`;
