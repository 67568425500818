import React, { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import { SelectArrow } from '../../../../assets/icons';
import {
  Error,
  FieldIcon,
  InputDiv,
  LoadsBlock,
  LoadsFlex,
  LoadsInput,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInputFieldThird,
  LoadsLabel,
  LoadsSelect,
  LoadsText,
  LoadsTitle,
  Opt,
  SelectedValue,
} from '../createLoad.style';

type LoadsDetailProps = {
  handleInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: any,
    name: any,
    state: any,
  ) => void;
  loadsCustomer: any;
  setLoadsCustomer: any;
  phoneCustomer: string;
  setPhoneCustomer: any;
  stateCustomer: string;
  setStateCustomer: any;
  changeSelectState: any;
  americaStates: string[];
  error: any;
};

const LoadsCustomer: React.FC<LoadsDetailProps> = ({
  handleInput,
  loadsCustomer,
  setLoadsCustomer,
  phoneCustomer,
  setPhoneCustomer,
  stateCustomer,
  setStateCustomer,
  changeSelectState,
  americaStates,
  error,
}) => {
  const [errZip, setErrZip] = useState<boolean>(false);

  useEffect(() => {
    if (error?.errors['customer.zip']?.[0]) setErrZip(true);
  }, [error]);

  return (
    <>
      <LoadsBlock>
        <LoadsTitle>Customer</LoadsTitle>
        <LoadsInputField>
          <LoadsLabel htmlFor="businessNameCustomer">Business Name</LoadsLabel>
          <LoadsInput
            id="businessNameCustomer"
            type="text"
            style={{
              border: '1px solid #c6c7da',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(
                e,
                setLoadsCustomer,
                'businessNameCustomer',
                loadsCustomer,
              )
            }
            value={loadsCustomer.businessNameCustomer}
          />
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="streetAddressCustomer">
            Street Address
          </LoadsLabel>
          <LoadsInput
            id="streetAddressCustomer"
            type="text"
            style={{
              border: '1px solid #c6c7da',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(
                e,
                setLoadsCustomer,
                'streetAddressCustomer',
                loadsCustomer,
              )
            }
            value={loadsCustomer.streetAddressCustomer}
          />
        </LoadsInputField>
        <LoadsFlex>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="cityCustomer">City</LoadsLabel>
            <LoadsInput
              id="cityCustomer"
              type="text"
              style={{
                border: '1px solid #c6c7da',
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsCustomer, 'cityCustomer', loadsCustomer)
              }
              value={loadsCustomer.cityCustomer}
            />
          </LoadsInputFieldThird>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="stateCustomer">State</LoadsLabel>
            <SelectedValue>{stateCustomer}</SelectedValue>
            <FieldIcon style={{ top: '95%' }}>
              <SelectArrow />
            </FieldIcon>
            <LoadsSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                changeSelectState(e, setStateCustomer, setStateCustomer)
              }
            >
              <Opt hidden></Opt>
              {americaStates?.map((item: any) => (
                <Opt key={item} value={item} data-value={item}>
                  {item}
                </Opt>
              ))}
            </LoadsSelect>
          </LoadsInputFieldThird>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="zipCustomer">ZIP</LoadsLabel>
            <LoadsInput
              id="zipCustomer"
              type="number"
              style={{
                border: errZip ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() => setErrZip(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsCustomer, 'zipCustomer', loadsCustomer)
              }
              value={loadsCustomer.zipCustomer}
            />
            {errZip && (
              <Error>{error?.errors['customer.zip']?.[0]}</Error>
            )}
          </LoadsInputFieldThird>
        </LoadsFlex>

        <LoadsText>Contact details</LoadsText>
        <LoadsInputField>
          <LoadsLabel htmlFor="fullNameCustomer">Full Name</LoadsLabel>
          <LoadsInput
            id="fullNameCustomer"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(
                e,
                setLoadsCustomer,
                'fullNameCustomer',
                loadsCustomer,
              )
            }
            value={loadsCustomer.fullNameCustomer}
          />
        </LoadsInputField>

        <LoadsFlex>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="phoneCustomer">Phone</LoadsLabel>
            <InputDiv>
              <PhoneInput
                international
                limitMaxLength
                value={phoneCustomer}
                id="phoneCustomer"
                onChange={(value: string) => setPhoneCustomer(value || '')}
              />
            </InputDiv>
          </LoadsInputFieldHalf>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="faxCustomer">Fax</LoadsLabel>
            <LoadsInput
              id="faxCustomer"
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsCustomer, 'faxCustomer', loadsCustomer)
              }
              value={loadsCustomer.faxCustomer}
            />
          </LoadsInputFieldHalf>
        </LoadsFlex>
        <LoadsInputField>
          <LoadsLabel htmlFor="emailCustomer">Email</LoadsLabel>
          <LoadsInput
            id="emailCustomer"
            type="email"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadsCustomer, 'emailCustomer', loadsCustomer)
            }
            value={loadsCustomer.emailCustomer}
          />
        </LoadsInputField>
      </LoadsBlock>
    </>
  );
};

export default LoadsCustomer;
