export const getPdfPreview = async (pdfData: string) => {
  const byteCharacters = atob(pdfData);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const blobURL = URL.createObjectURL(blob);

  window.open(blobURL);

  return Promise.resolve(true);
};
