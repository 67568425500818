import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AuthBlock,
  BackButton,
  BackTitle,
  Block,
  Error,
  Footer,
  Margin,
  Reset,
  ResetEmail,
} from './changePassword.style';
import { restorePassword } from '../../API/auth';
import { Back } from '../../assets/icons';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import Input from '../../components/Input/Input';
import { useAppDispatch, useAppSelector } from '../../store/hooks';


const ChangePassword: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [err, setErr] = useState<string | null>();

  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any) =>
    setState(e.target.value);

  const sentToChange = async () => {
    const res = await dispatch(
      restorePassword({
        token,
        password,
        password_confirmation: confirmPassword,
      }),
    );

    if (res.type === 'auth/restorePassword/rejected') setErr(res.payload?.message);

    else if (res.type === 'auth/restorePassword/fulfilled') navigation('/');
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryToken: any = queryParams.get('token');
    setToken(queryToken);
    const queryEmail: any = queryParams.get('email');
    setEmail(queryEmail);
  }, []);

  return (
    <>
      <Header />
      <Block>
        <Reset>
          <BackButton onClick={() => navigation('/reset-password')}>
            <Back />
            <BackTitle>Reset Password</BackTitle>
          </BackButton>
          <ResetEmail>{email}</ResetEmail>
          <AuthBlock>
            <Input
              inputId="newPassword"
              inputType="password"
              label="New password"
              handleInput={e => handleInput(e, setPassword)}
              value={password}
            />
            <Input
              inputId="confirmNewPassword"
              inputType="password"
              label="Confirm new password"
              handleInput={e => handleInput(e, setConfirmPassword)}
              value={confirmPassword}
            />
            <Error>{err}</Error>
          </AuthBlock>
          <Margin style={{ marginTop: '10px' }} />
          <Button text="Change" handleButton={sentToChange} borderRadius="8px" />
        </Reset>
        <Footer>
          © {new Date().getFullYear()} Turbo dispatch. All rights reserved.
        </Footer>
      </Block>
    </>
  );
};

export default ChangePassword;
