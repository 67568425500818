import React, { useEffect } from 'react';

import {
  Block,
  Wrapper,
  LogoWrapper,
  Title,
  Text,
  Bottom,
  BottomText,
} from './finishChangeEmail.style';
import { finishChangeEmail } from '../../API/auth';
import { LogoSmall, LogoFooter } from '../../assets/icons';
import AuthHeader from '../../components/Header/AuthHeader';
import { useAppDispatch } from '../../store/hooks';

const FinishChangeEmail: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryToken: any = queryParams.get('token');
    const queryEmail: any = queryParams.get('email');
    dispatch(finishChangeEmail([queryEmail, queryToken]));
  }, []);

  return (
    <>
      <AuthHeader />
      <Block>
        <Wrapper>
          <LogoWrapper>
            <LogoSmall />
          </LogoWrapper>
          <Title>Verification success</Title>
          <Text>
            Thank you for your support, we have successfully verified your
            email. You can сlose this page now.
          </Text>
        </Wrapper>
        <Bottom>
          <LogoFooter />
          <BottomText>© 2022 TUrbo dispatch. All rights reserved.</BottomText>
        </Bottom>
      </Block>
    </>
  );
};

export default FinishChangeEmail;
