import React, { ChangeEvent, useState } from 'react';

import { Check, CloseLoads } from '../../../../assets/icons';
import {
  BlueButton,
  CheckBoxItem,
  CheckBoxSquare,
  CustomCheckBoxSquare,
  Error,
  LoadsInput,
  LoadsTitle,
  LoadsVehicleBlock,
  LoadsVehicleInputField,
  MUISelect,
  TextPrice,
  VehicleGrid,
  VehicleTitle,
} from '../createLoad.style';

type LoadsDetailProps = {
  handleInputVehicle: (e: React.ChangeEvent<HTMLInputElement>, setState: any, index: number) => void;
  changeSelectVehicle: (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
    index: number,
    setStateIndex: any
  ) => void;
  handleCheckboxVehicle: (e: React.ChangeEvent<HTMLInputElement>, setState: any, index: number, elem: any) => void;
  addVehicleFields: () => void;
  removeVehicleFields: (index: number) => void;
  vehicle: any;
  setVehicle: any;
  loadData: any;
  setTypeVehicleId: any;
  typeVehicle: string;
  error: any;
};

const LoadsVehicle: React.FC<LoadsDetailProps> = ({
  handleInputVehicle,
  changeSelectVehicle,
  handleCheckboxVehicle,
  addVehicleFields,
  removeVehicleFields,
  vehicle,
  setVehicle,
  loadData,
  setTypeVehicleId,
  typeVehicle,
  error,
}) => {
  const [priceSign, setPriceSign] = useState<boolean>(false);
  return (
    <>
      <LoadsVehicleBlock>
        <LoadsTitle>
          {vehicle.length} Vehicles <BlueButton onClick={addVehicleFields}>+ Add</BlueButton>
        </LoadsTitle>

        <VehicleGrid>
          <VehicleTitle>Vin</VehicleTitle>
          <VehicleTitle>Year</VehicleTitle>
          <VehicleTitle>Make</VehicleTitle>
          <VehicleTitle>Model</VehicleTitle>
          <VehicleTitle>Type</VehicleTitle>
          <VehicleTitle>Color</VehicleTitle>
          <VehicleTitle>Lot number</VehicleTitle>
          <VehicleTitle>Price</VehicleTitle>
          <VehicleTitle style={{ textAlign: 'center' }}>INOP</VehicleTitle>
          <VehicleTitle style={{ textAlign: 'center' }}>Enclosed</VehicleTitle>
          <VehicleTitle style={{ textAlign: 'right' }}></VehicleTitle>
        </VehicleGrid>
        {vehicle.map((element: any, index: number) => {
          return (
            <VehicleGrid key={index}>
              <LoadsVehicleInputField>
                <LoadsInput
                  id="vin"
                  name="vin"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputVehicle(e, setVehicle, index)}
                  value={element.vin}
                />
                {error?.errors[`vehicles.${index}.vin`] && <Error>{error?.errors[`vehicles.${index}.vin`][0]}</Error>}
              </LoadsVehicleInputField>
              <LoadsVehicleInputField>
                <LoadsInput
                  id="year"
                  name="year"
                  type="number"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputVehicle(e, setVehicle, index)}
                  value={element.year}
                />
                {error?.errors[`vehicles.${index}.year`] && <Error>{error?.errors[`vehicles.${index}.year`][0]}</Error>}
              </LoadsVehicleInputField>
              <LoadsVehicleInputField>
                <LoadsInput
                  id="make"
                  name="make"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputVehicle(e, setVehicle, index)}
                  value={element.make}
                />
                {error?.errors[`vehicles.${index}.make`] && <Error>{error?.errors[`vehicles.${index}.make`][0]}</Error>}
              </LoadsVehicleInputField>
              <LoadsVehicleInputField>
                <LoadsInput
                  id="model"
                  name="model"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputVehicle(e, setVehicle, index)}
                  value={element.model}
                />
                {error?.errors[`vehicles.${index}.model`] && (
                  <Error>{error?.errors[`vehicles.${index}.model`][0]}</Error>
                )}
              </LoadsVehicleInputField>
              <LoadsVehicleInputField>
                <MUISelect
                  id="type"
                  name="type"
                  value={element.type || typeVehicle}
                  onChange={(e) => changeSelectVehicle(e, setVehicle, index, setTypeVehicleId)}
                >
                  {loadData?.vehicle_types?.map((item: any) => {
                    return (
                      <option key={item.id} value={item.title} data-value={item.title}>
                        {item.title}
                      </option>
                    );
                  })}
                </MUISelect>

                {error?.errors[`vehicles.${index}.type`] && <Error>{error?.errors[`vehicles.${index}.type`][0]}</Error>}
              </LoadsVehicleInputField>
              <LoadsVehicleInputField>
                <LoadsInput
                  id="color"
                  name="color"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputVehicle(e, setVehicle, index)}
                  value={element.color}
                />
                {error?.errors[`vehicles.${index}.color`] && (
                  <Error>{error?.errors[`vehicles.${index}.color`][0]}</Error>
                )}
              </LoadsVehicleInputField>
              <LoadsVehicleInputField>
                <LoadsInput
                  id="lot_number"
                  name="lot_number"
                  type="number"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputVehicle(e, setVehicle, index)}
                  value={element.lot_number}
                />
                {error?.errors[`vehicles.${index}.lot_number`] && (
                  <Error>{error?.errors[`vehicles.${index}.lot_number`][0]}</Error>
                )}
              </LoadsVehicleInputField>
              <LoadsVehicleInputField>
                <TextPrice
                  style={{
                    color: priceSign || element.price ? '#09091A' : '#C6C7DA',
                  }}
                >
                  $
                </TextPrice>
                <LoadsInput
                  id="price"
                  name="price"
                  type="number"
                  onFocus={() => setPriceSign(true)}
                  onBlur={() => setPriceSign(false)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputVehicle(e, setVehicle, index)}
                  value={element.price}
                />
                {error?.errors[`vehicles.${index}.price`] && (
                  <Error>{error?.errors[`vehicles.${index}.price`][0]}</Error>
                )}
              </LoadsVehicleInputField>
              <VehicleTitle style={{ textAlign: 'center' }}>
                <CheckBoxItem style={{ marginTop: 2 }}>
                  <CheckBoxSquare
                    type="checkbox"
                    name="inop"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxVehicle(e, setVehicle, index, element.inop)
                    }
                    checked={element.inop}
                  />
                  <CustomCheckBoxSquare>
                    <Check />
                  </CustomCheckBoxSquare>
                </CheckBoxItem>
              </VehicleTitle>
              <VehicleTitle style={{ textAlign: 'center' }}>
                <CheckBoxItem style={{ marginTop: 2 }}>
                  <CheckBoxSquare
                    type="checkbox"
                    name="enclosed"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxVehicle(e, setVehicle, index, element.enclosed)
                    }
                    checked={element.enclosed}
                  />
                  <CustomCheckBoxSquare>
                    <Check />
                  </CustomCheckBoxSquare>
                </CheckBoxItem>
              </VehicleTitle>
              <VehicleTitle
                style={{ cursor: 'pointer', textAlign: 'right', paddingRight: '15px' }}
                onClick={() => removeVehicleFields(index)}
              >
                <CloseLoads />
              </VehicleTitle>
            </VehicleGrid>
          );
        })}
      </LoadsVehicleBlock>
    </>
  );
};

export default LoadsVehicle;
