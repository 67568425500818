import * as React from "react"
import { SVGProps } from "react"

const Bell = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.325 16.771a4.745 4.745 0 0 0 1.921 1.77.779.779 0 0 1 .131 1.293.757.757 0 0 1-.472.166H1.095a.757.757 0 0 1-.472-.166.772.772 0 0 1-.218-.93.766.766 0 0 1 .349-.363 4.744 4.744 0 0 0 1.921-1.77c.465-.76.71-1.635.706-2.529v-5.03a7.748 7.748 0 0 1 1.496-4.554 7.606 7.606 0 0 1 3.875-2.77c.098-.531.377-1.01.787-1.355a2.27 2.27 0 0 1 2.92 0c.41.345.69.824.787 1.354a7.606 7.606 0 0 1 3.876 2.77 7.748 7.748 0 0 1 1.497 4.555v5.03c-.003.894.241 1.77.706 2.53Zm-6.44 6.448a2.667 2.667 0 0 1-4.552-1.886h5.333c0 .708-.28 1.386-.78 1.886Z"
      fill="#9292A4"
    />
  </svg>
)

export default Bell
