import React from 'react';

import {
  Actions,
  BoldText,
  Bottom,
  Item,
  ItemData,
  ItemText,
  Line,
  LineBlock,
  RegularText,
  ScrollBlock,
  Title,
  Wrapper,
} from './modalInspectionHistory.style';
import { FleetCalendar, FleetDriver } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  refInspHistory: any;
};

const ModalInspectionHistory: React.FC<ModalAssignProps> = ({ close, refInspHistory }) => {
  return (
    <ModalPortal>
      <Wrapper ref={refInspHistory}>
        <Title>Inspetction History</Title>
        <Line />

        <ScrollBlock>
          <Item>
            <ItemData>
              <ItemText>
                <FleetDriver />
                <RegularText>Driver:</RegularText>
                <BoldText>Henry Frei</BoldText>
              </ItemText>
              <ItemText>
                <FleetCalendar />
                <RegularText>Date:</RegularText>
                <BoldText>19/06/2022</BoldText>
              </ItemText>
            </ItemData>
            <Actions>
              <Button text="View" pad="12px 26px" handleButton={close} />
            </Actions>
          </Item>
          <LineBlock />
          <Item>
            <ItemData>
              <ItemText>
                <FleetDriver />
                <RegularText>Driver:</RegularText>
                <BoldText>Henry Frei</BoldText>
              </ItemText>
              <ItemText>
                <FleetCalendar />
                <RegularText>Date:</RegularText>
                <BoldText>19/06/2022</BoldText>
              </ItemText>
            </ItemData>
            <Actions>
              <Button text="View" pad="12px 26px" handleButton={close} />
            </Actions>
          </Item>
          <LineBlock />
          <Item>
            <ItemData>
              <ItemText>
                <FleetDriver />
                <RegularText>Driver:</RegularText>
                <BoldText>Henry Frei</BoldText>
              </ItemText>
              <ItemText>
                <FleetCalendar />
                <RegularText>Date:</RegularText>
                <BoldText>19/06/2022</BoldText>
              </ItemText>
            </ItemData>
            <Actions>
              <Button text="View" pad="12px 26px" handleButton={close} />
            </Actions>
          </Item>
          <LineBlock />
        </ScrollBlock>

        <Line />
        <Bottom>
          <Actions>
            <Button text="Close" pad="12px 26px" handleButton={close} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalInspectionHistory;
