import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export type Trips = [];

export type Trip = {};

export type TripId = {
  id: number;
};

export const routes = {
  trips: 'api/crm/trips',
};

export const getTrips = createAsyncThunk<
  Trips,
  null,
  {
    rejectValue: ErrorType;
  }
>('trips/getTrips', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.trips}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const getTripId = createAsyncThunk<
  Trip,
  number,
  {
    rejectValue: ErrorType;
  }
>('trips/getTripId', async (id, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.trips}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const addTrip = createAsyncThunk<
  null,
  Trip,
  {
    rejectValue: ErrorType;
  }
>('trips/addTrip', async (createData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.trips}`, createData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateTrip = createAsyncThunk<
  null,
  [number, string],
  {
    rejectValue: ErrorType;
  }
>('trips/updateTrip', async ([id, name], { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.trips}/${id}`, {
      name,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const assignDriver = createAsyncThunk<
  null,
  [number, number],
  {
    rejectValue: ErrorType;
  }
>('trips/assignDriver', async ([id, driver_id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.trips}/${id}/assign`, {
      driver_id,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const changeTripStatus = createAsyncThunk<
  null,
  [number, string],
  {
    rejectValue: ErrorType;
  }
>('trips/changeTripStatus', async ([id, status], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.trips}/${id}/status`, { status });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const deleteTrip = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>('trips/deleteTrip', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.trips}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const detachLoad = createAsyncThunk<
  null,
  [number, number],
  {
    rejectValue: ErrorType;
  }
>('trips/detachLoad', async ([id, load_id], { rejectWithValue }) => {
  try {
    const response = await api.delete(
      `${routes.trips}/${id}/detach?load_id=${load_id}`,
    );
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const attachLoad = createAsyncThunk<
  null,
  [number, number],
  {
    rejectValue: ErrorType;
  }
>('trips/attachLoad', async ([id, load_id], { rejectWithValue }) => {
  try {
    const response = await api.post(
      `${routes.trips}/${id}/attach?load_id=${load_id}`,
    );
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});
