import { Paper, Table, TableBody, TableContainer, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';

import {
  AddLoad,
  BottomBlock,
  Close,
  Input,
  InputWrapper,
  Line,
  LoadData,
  LoadsItem,
  LoadsListBlock,
  LoadStatus,
  LoadsTitle,
  LoadsTitleItem,
  LoadsWrapper,
  Top,
  TripName,
  Wrapper,
} from './modalTripAddLoads.style';
import { getLoads } from '../../../../API/loads';
import { attachLoad, getTripId } from '../../../../API/trips';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalContentProps = {
  close: () => void;
  tripId: number;
  load_id?: number;
  name: string;
  refTrips: any;
};

const ModalTripAddLoads: React.FC<ModalContentProps> = ({ close, tripId, name, refTrips }) => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [nameLoad, setNameLoad] = useState<string>('');

  const { loadsList } = useAppSelector((state: { loads: any }) => state.loads);
  const { tripElem } = useAppSelector((state: { trips: any }) => state.trips);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setNameLoad(e.target.value);
  };

  const filteredLoads = loadsList.filter((item: any) => {
    return item.load_id.toLowerCase().includes(nameLoad.toLowerCase()) && item.status !== 'Deleted';
  });

  const addLoadToTrips = async (load_id: number) => {
    if (tripElem?.trip?.loads.some((el: any) => el.id === load_id)) return alert('This loads exist');
    else {
      await dispatch(attachLoad([tripId, load_id]));
      await dispatch(getTripId(tripId));
    }
  };

  useEffect(() => {
    dispatch(
      getLoads({
        type: 'load_id',
        sortBy: 'none',
      })
    );
  }, []);

  return (
    <ModalPortal>
      <Wrapper ref={refTrips}>
        <Top>
          <TripName>{name}</TripName>
          <InputWrapper>
            <Input id="tripName" type="text" value={nameLoad} onChange={handleInput} placeholder="Search..." />
          </InputWrapper>
        </Top>
        <Line />
        <LoadsListBlock>
          {!window.matchMedia('(max-width: 768px)').matches ? (
            <>
              <LoadsTitle>
                <LoadsTitleItem>Load ID</LoadsTitleItem>
                <LoadsTitleItem>Status</LoadsTitleItem>
                <LoadsTitleItem>Vehile</LoadsTitleItem>
                <LoadsTitleItem>Origin</LoadsTitleItem>
                <LoadsTitleItem>Destination</LoadsTitleItem>
                <LoadsTitleItem>Customer Info</LoadsTitleItem>
              </LoadsTitle>
              <LoadsWrapper>
                {filteredLoads?.map((elem: any) => {
                  return (
                    <LoadsItem
                      key={elem.id}
                      style={{
                        display:
                          tripElem?.trip?.loads?.every((el: any) => el.id !== elem.id) ||
                          tripElem?.trip?.loads?.length === 0
                            ? 'grid'
                            : 'none',
                      }}
                    >
                      <LoadData>{elem.load_id}</LoadData>
                      <LoadStatus>{elem.status}</LoadStatus>
                      <LoadData>
                        {elem.vehicles[0].year}
                        <br />
                        {elem.vehicles[0].make + ' ' + elem.vehicles[0].model}
                      </LoadData>
                      <LoadData>
                        {elem.points[1].city} <br />
                        {elem.points[1].state + ', ' + elem.points[1].zip}
                      </LoadData>
                      <LoadData>
                        {elem.points[0].city} <br />
                        {elem.points[0].state + ', ' + elem.points[0].zip}
                      </LoadData>
                      <LoadData>{elem.customer.business_name}</LoadData>
                      <AddLoad onClick={() => addLoadToTrips(elem.id)}>Add</AddLoad>
                    </LoadsItem>
                  );
                })}
              </LoadsWrapper>
            </>
          ) : (
            <Paper sx={{ width: '100%', overflow: 'inherit' }}>
              <TableContainer sx={{ height: 'auto', overflow: 'auto' }}>
                <Table sx={{ tableLayout: 'auto' }}>
                  <TableBody>
                    {filteredLoads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((elem: any) => {
                      return (
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={elem.id}
                          sx={{
                            borderBottom: '1px solid #E2E2E2',
                            position: 'relative',
                          }}
                        >
                          <LoadsTitleItem>Load ID</LoadsTitleItem>
                          <LoadData>{elem.load_id}</LoadData>
                          <LoadsTitleItem>Status</LoadsTitleItem>
                          <LoadStatus>{elem.status}</LoadStatus>
                          <LoadsTitleItem>Vehile</LoadsTitleItem>
                          <LoadData>
                            {elem.vehicles[0].year}
                            <br />
                            {elem.vehicles[0].make + ' ' + elem.vehicles[0].model}
                          </LoadData>
                          <LoadsTitleItem>Origin</LoadsTitleItem>
                          <LoadData>
                            {elem.points[1].city} <br />
                            {elem.points[1].state + ', ' + elem.points[1].zip}
                          </LoadData>
                          <LoadsTitleItem>Destination</LoadsTitleItem>
                          <LoadData>
                            {elem.points[0].city} <br />
                            {elem.points[0].state + ', ' + elem.points[0].zip}
                          </LoadData>
                          <LoadsTitleItem>Customer Info</LoadsTitleItem>
                          <LoadData style={{ marginBottom: '8px' }}>{elem.customer.business_name}</LoadData>
                          <AddLoad onClick={() => addLoadToTrips(elem.id)}>Add</AddLoad>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[2]}
                component="div"
                count={filteredLoads.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </LoadsListBlock>
        <Line />
        <BottomBlock>
          <Close onClick={close}>Close</Close>
        </BottomBlock>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalTripAddLoads;
