import React, { useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Top,
  Title,
  Actions,
  SearchField,
  Input,
  SearchWrapper,
  TripTabs,
  Tab,
  Count,
  Line,
  BorderBottom,
} from './trips.style';
import { getTrips } from '../../API/trips';
import { Plus, Search } from '../../assets/icons';
import Button from '../../components/Button/Button';
import TripsTable from '../../components/Trips/TripsTable';
import Container from '../../layout/Container';
import ModalTrip from '../../layout/Modal/ModalTrip/ModalTrip';
import useOnClickOutside from '../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const Trips: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const dispatch = useAppDispatch();
  const refTrips = useRef<any>();
  const { tripsList, loading } = useAppSelector((state: { trips: any }) => state.trips);

  const tripsActive = tripsList?.filter(
    (elem: any) => elem.status === 'in_process',
  );

  const tripsArchived = tripsList?.filter(
    (elem: any) => elem.status === 'archived',
  );

  const [tab, setTab] = useState<string>('in_process');
  const [tabValue, setTabValue] = useState<number>(0);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const filteredPersons = tripsList.filter((item: any) => {
    return item.name.toLowerCase().includes(value.toLowerCase());
  });

  useEffect(() => {
    dispatch(getTrips(null));
  }, []);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open])

  const openModal = () => setOpen(true);

  useOnClickOutside(refTrips, () => setOpen(false));

  if (loading) return <Preloader />;

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <Title>Trips</Title>
            <Actions>
              <Button
                text={'New Trip'}
                pad="10px 20px"
                icon={<Plus />}
                handleButton={openModal}
              />
            </Actions>
          </Top>
          <SearchField>
            <Input
              type="text"
              value={value}
              onChange={handleInput}
              placeholder="Search..."
            />
            <SearchWrapper>
              <Search />
            </SearchWrapper>
          </SearchField>
          <TripTabs value={tabValue}>
            <Tab
              style={{
                color: tab === 'in_process' ? '#1267CF' : '#616172',
              }}
              onClick={() => {setTab('in_process'); setTabValue(0);}}
            >
              Active{' '}
              <Count
                style={{
                  color: tab === 'in_process' ? '#0E71EB' : '#616172',
                  backgroundColor: tab === 'in_process' ? '#B0DCFF' : '#e9eaf8',
                }}
              >
                {tripsActive?.length}
              </Count>{' '}
              {tab === 'in_process' && <BorderBottom />}
            </Tab>

            <Tab
              style={{
                color: tab === 'archived' ? '#1267CF' : '#616172',
              }}
              onClick={() => {setTab('archived'); setTabValue(1);}}
            >
              Archived{' '}
              <Count
                style={{
                  color: tab === 'archived' ? '#0E71EB' : '#616172',
                  backgroundColor: tab === 'archived' ? '#B0DCFF' : '#e9eaf8',
                }}
              >
                {tripsArchived.length}
              </Count>{' '}
              {tab === 'archived' && <BorderBottom />}
            </Tab>
          </TripTabs>
          <Line />
          <TripsTable tripsList={filteredPersons} tab={tab} />
          {open && <ModalTrip refTrips={refTrips} close={() => setOpen(false)} />}
        </Wrapper>
      </Container>
    </>
  );
};

export default Trips;
