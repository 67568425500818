import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 30%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const TopText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  padding: 20px 20px 24px 20px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const Block = styled.div`
  padding: 24px;
`;

export const BlockTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const InputDiv = styled.div`
  height: 41px;
  border: 1px solid #c6c7da;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 4px;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const Input = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const VerifyBlock = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
`;

export const VerifyText = styled.p`
  margin-left: 12px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
`;

export const ButtonBlock = styled.div`
  max-width: 100px;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const BtnDisabed = styled.div`
  background-color: #616172;
  padding: 8px 24px;
  border-radius: 4px;
  color: #fff;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  cursor: default;
`;
