import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { Calendar, SelectArrow } from '../../../../assets/icons';
import {
  Error,
  FieldIcon,
  Line,
  LoadsBlock,
  LoadsFlex,
  LoadsInput,
  LoadsInputDate,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInputTextArea,
  LoadsLabel,
  LoadsSelect,
  LoadsText,
  LoadsTitle,
  Opt,
  SelectedValue,
} from '../createLoad.style';

type LoadsDetailProps = {
  handleInput: (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state: any) => void;
  handleInputTextArea: (e: React.ChangeEvent<HTMLTextAreaElement>, setState: any, name: any, state: any) => void;
  changeSelectState: (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => void;
  loadsPayment: any;
  setLoadsPayment: any;
  receiptDatePayment: any;
  setReceiptDatePayment: any;
  methodPayment: any;
  setMethodPayment: any;
  loadData: any;
  setMethodPaymentId: any;
  error: any;
};

const LoadsPayment: React.FC<LoadsDetailProps> = ({
  handleInput,
  handleInputTextArea,
  changeSelectState,
  loadsPayment,
  setLoadsPayment,
  receiptDatePayment,
  setReceiptDatePayment,
  methodPayment,
  setMethodPayment,
  loadData,
  setMethodPaymentId,
  error,
}) => {
  const [errPrice, setErrPrice] = useState<boolean>(false);
  const [errDriverPay, setErrDriverPay] = useState<boolean>(false);

  useEffect(() => {
    if (error?.errors['payment.price']?.[0]) setErrPrice(true);
    if (error?.errors['payment.driver_pay']?.[0]) setErrDriverPay(true);
  }, [error]);

  return (
    <>
      <LoadsBlock>
        <LoadsTitle>Payment</LoadsTitle>
        <LoadsFlex>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="paymentPrice">Price</LoadsLabel>
            <LoadsInput
              id="paymentPrice"
              type="number"
              style={{
                border: errPrice ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() => setErrPrice(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsPayment, 'paymentPrice', loadsPayment)
              }
              value={loadsPayment.paymentPrice}
            />
            {errPrice && <Error>{error?.errors['payment.price']?.[0]}</Error>}
          </LoadsInputFieldHalf>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="brokerFeePayment">Broker Fee</LoadsLabel>
            <LoadsInput
              id="brokerFeePayment"
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsPayment, 'brokerFeePayment', loadsPayment)
              }
              value={loadsPayment.brokerFeePayment}
            />
          </LoadsInputFieldHalf>
        </LoadsFlex>
        <LoadsInputField>
          <LoadsLabel htmlFor="methodPayment">Method</LoadsLabel>
          <SelectedValue>{methodPayment}</SelectedValue>
          <FieldIcon style={{ top: '95%' }}>
            <SelectArrow />
          </FieldIcon>
          <LoadsSelect
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              changeSelectState(e, setMethodPayment, setMethodPaymentId)
            }
          >
            <Opt hidden defaultValue></Opt>
            {loadData?.payment_methods?.map((item: any) => (
              <Opt key={item.id} value={item.title} data-value={item.id}>
                {item.title}
              </Opt>
            ))}
          </LoadsSelect>
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="driverPayPayment">Driver Pay</LoadsLabel>
          <LoadsInput
            id="driverPayPayment"
            type="number"
            style={{
              border: errDriverPay ? '1px solid #ff0000' : '1px solid #c6c7da',
            }}
            onFocus={() => setErrDriverPay(false)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadsPayment, 'driverPayPayment', loadsPayment)
            }
            value={loadsPayment.driverPayPayment}
          />
          {errDriverPay && <Error>{error?.errors['payment.driver_pay']?.[0]}</Error>}
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="notePayment">Notes</LoadsLabel>
          <LoadsInput
            id="notePayment"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadsPayment, 'notePayment', loadsPayment)
            }
            value={loadsPayment.notePayment}
          />
        </LoadsInputField>
        {/* <LoadsFlex>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="paidAmountPayment">Paid Amount</LoadsLabel>
            <LoadsInput
              id="paidAmountPayment"
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(
                  e,
                  setLoadsPayment,
                  'paidAmountPayment',
                  loadsPayment,
                )
              }
              value={loadsPayment.paidAmountPayment}
            />
          </LoadsInputFieldHalf>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="receiptDatePayment">Receipt Date</LoadsLabel>
            <FieldIcon>
              <Calendar />
            </FieldIcon>
            <LoadsInputDate
              id="receiptDatePayment"
              dateFormat="MM/dd/yyyy"
              selected={receiptDatePayment}
              onChange={(date: any) => setReceiptDatePayment(date)}
            />
          </LoadsInputFieldHalf>
        </LoadsFlex> */}
        <LoadsInputField>
          <LoadsLabel htmlFor="referenceNumberPayment">Reference Number</LoadsLabel>
          <LoadsInput
            id="referenceNumberPayment"
            type="number"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadsPayment, 'referenceNumberPayment', loadsPayment)
            }
            value={loadsPayment.referenceNumberPayment}
          />
        </LoadsInputField>

        <Line />

        <LoadsText>Invoice details</LoadsText>
        <LoadsInputField>
          <LoadsLabel htmlFor="invoiceIDPayment">Invoice ID</LoadsLabel>
          <LoadsInput
            id="invoiceIDPayment"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadsPayment, 'invoiceIDPayment', loadsPayment)
            }
            value={loadsPayment.invoiceIDPayment}
          />
        </LoadsInputField>

        <LoadsInputField>
          <LoadsLabel htmlFor="notesPayment">Notes</LoadsLabel>
          <LoadsInputTextArea
            id="notesPayment"
            rows={3}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleInputTextArea(e, setLoadsPayment, 'notesPayment', loadsPayment)
            }
            value={loadsPayment.notesPayment}
          />
        </LoadsInputField>
      </LoadsBlock>
    </>
  );
};

export default LoadsPayment;
