import * as React from 'react';
import { SVGProps } from 'react';

type IconProps = {
  isActive?: boolean;
};

const Tracking: React.FC<IconProps> = ({ isActive }, props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={20} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8c0 2.868-1.594 5.485-3.436 7.386A20.6 20.6 0 0 1 8 17.6a20.603 20.603 0 0 1-2.564-2.213C3.594 13.486 2 10.868 2 8c0-3.409 2.716-6 6-6s6 2.591 6 6ZM8 20s8-4.758 8-12c0-4.483-3.582-8-8-8S0 3.517 0 8c0 7.242 8 12 8 12ZM9 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
  </svg>
);

export default Tracking;
