import styled from 'styled-components';

export const Block = styled.div`
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const UsdotBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const UsdotTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const UsdotNumber = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-left: 5px;
`;

export const TopTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const Title = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const Delete = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 14px;
  color: #f30f00;
  text-transform: uppercase;
  cursor: pointer;
`;

export const ImagePlace = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 0 0 20px;
`;

export const InputFilePlace = styled.div`
  border: 2px dashed #c6c7da;
  min-height: 60px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const InputPlace = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const InputFieldHalf = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const InputDiv = styled.div`
  height: 37px;
  border: 1px solid #c6c7da;
  padding: 10px;
  border-radius: 4px;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  padding: 10px;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const Input = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  max-width: 100%;
  max-height: 75px;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  top: 25px;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
`;

export const Select = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const BlueButton = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  cursor: pointer;
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  font-size: 0;
  cursor: pointer;
`;

export const Text = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export const Bottom = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonSave = styled.div`
  display: flex;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;
