import React, { ChangeEvent, useState } from 'react';

import {
  Actions,
  Button,
  Error,
  Input,
  InputField,
  InputLabel,
  Line,
  Title,
  TopText,
  Wrapper,
} from './modalTrip.style';
import 'react-phone-number-input/style.css';
import { addTrip, getTrips } from '../../../API/trips';
import { useAppDispatch } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  refTrips: any;
};

const ModalTrip: React.FC<ModalContentProps> = ({ close, refTrips }) => {
  const [name, setName] = useState<string>('');
  const [err, setErr] = useState({
    message: '',
    errors: {
      name: [],
    },
  });
  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    const res: any = await dispatch(addTrip({ name }));

    if (res.type === 'trips/addTrip/rejected') return setErr(res.payload);
    await dispatch(getTrips(null));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refTrips}>
        <TopText>
          <Title>Create</Title> new trip?
        </TopText>

        <InputField>
          <InputLabel htmlFor="name">Trip name</InputLabel>
          <Input
            id="name"
            type="text"
            style={{
              border: err.errors?.name?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
            }}
            onFocus={() => setErr({ ...err, errors: { ...err.errors, name: [] } })}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))}
            value={name}
          />
          <Error>{err.errors?.name?.[0]}</Error>
        </InputField>
        <Line />

        <Actions>
          <Button onClick={handleConfirm}>Create</Button>
          <Button onClick={close}>Cancel</Button>
        </Actions>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalTrip;
