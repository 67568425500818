import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Billing from './Billing/Billing';
import DriverApp from './DriverApp/DriverApp';
import Factoring from './Factoring/Factoring';
import General from './General/General';
import Integrations from './Integrations/Integrations';
import Notifications from './Notifications/Notifications';
import { BorderBottom, Item, Line, Tab, TabPanel, Title, Wrapper } from './settings.style';
import Subscription from './Subscription/Subscription';
import Terminals from './Terminals/Terminals';
import { getSettings } from '../../API/settings';
import SettingsContainer from '../../layout/SettingsContainer';
import { useAppDispatch } from '../../store/hooks';

const Settings: React.FC = () => {
  const [tab, setTab] = useState<string>('General');
  const [tabValue, setTabValue] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const isCheckoutSuccess = searchParams.get('checkout') === 'success';

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSettings(null));
  }, []);

  useEffect(() => {
    if (isCheckoutSuccess) {
      setTab('Subscription');
      setTabValue(5);
    }
  }, [isCheckoutSuccess]);

  const tabContent = () => {
    switch (tab) {
      case 'General': {
        return <General />;
      }

      case 'Terminals': {
        return <Terminals />;
      }

      case 'Driver App': {
        return <DriverApp />;
      }

      case 'Billing': {
        return <Billing />;
      }

      case 'Factoring': {
        return <Factoring />;
      }

      case 'Subscription': {
        return <Subscription />;
      }

      case 'Notifications': {
        return <Notifications />;
      }

      case 'Integrations': {
        return <Integrations />;
      }

      default:
        return null;
    }
  };

  return (
    <>
      <SettingsContainer>
        <Wrapper>
          <Title>Settings</Title>
          <TabPanel variant="scrollable" value={tabValue} scrollButtons allowScrollButtonsMobile>
            <Tab
              style={{
                color: tab === 'General' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('General');
                setTabValue(0);
              }}
            >
              General
            </Tab>

            <Tab
              style={{
                color: tab === 'Terminals' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('Terminals');
                setTabValue(1);
              }}
            >
              Terminals
            </Tab>
            <Tab
              style={{
                color: tab === 'Driver App' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('Driver App');
                setTabValue(2);
              }}
            >
              Driver App
            </Tab>

            <Tab
              style={{
                color: tab === 'Billing' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('Billing');
                setTabValue(3);
              }}
            >
              Billing
            </Tab>
            <Tab
              style={{
                color: tab === 'Factoring' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('Factoring');
                setTabValue(4);
              }}
            >
              Factoring
            </Tab>

            <Tab
              style={{
                color: tab === 'Subscription' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('Subscription');
                setTabValue(5);
              }}
            >
              Subscription
            </Tab>
            <Tab
              style={{
                color: tab === 'Notifications' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('Notifications');
                setTabValue(6);
              }}
            >
              Notifications
            </Tab>

            <Tab
              style={{
                color: tab === 'Integrations' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab('Integrations');
                setTabValue(7);
              }}
            >
              Integrations
            </Tab>
          </TabPanel>
          <Line />
          <Item>{tabContent()}</Item>
        </Wrapper>
      </SettingsContainer>
    </>
  );
};

export default Settings;
