import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  StyledLink,
  MenuItem,
  Text,
  Line,
  BurgerItem,
} from './burgerMenu.style';
import {
  Reports,
  Loads,
  Wheel,
  Fleet,
  Dashboard,
  Trips,
  Contact,
  Tracking,
  CloseBurger,
} from '../../../assets/icons';
import ModalPortal from '../../../layout/Modal/ModalPortal';

type BurgerMenuProps = {
  setShowBurger: any;
};

const BurgerMenu: React.FC<BurgerMenuProps> = ({ setShowBurger }) => {
  return (
    <ModalPortal>
      <Wrapper>
        <BurgerItem onClick={() => setShowBurger(false)}>
          <CloseBurger />
        </BurgerItem>
        <StyledLink to="/dashboard">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Dashboard isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Dashboard
                </Text>{' '}
              </MenuItem>
            </>
          )}
        </StyledLink>
        <StyledLink to="/loads">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Loads isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Loads
                </Text>
              </MenuItem>{' '}
            </>
          )}
        </StyledLink>
        <StyledLink to="/trips">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Trips isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Trips
                </Text>
              </MenuItem>{' '}
            </>
          )}
        </StyledLink>
        <StyledLink to="/tracking">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Tracking isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Tracking
                </Text>
              </MenuItem>
            </>
          )}
        </StyledLink>
        <StyledLink to="/fleet">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Fleet isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Fleet
                </Text>{' '}
              </MenuItem>
            </>
          )}
        </StyledLink>
        <Line />
        <StyledLink to="/contacts">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Contact isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Contact
                </Text>{' '}
              </MenuItem>
            </>
          )}
        </StyledLink>
        <StyledLink to="/drivers">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Wheel isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Drivers
                </Text>{' '}
              </MenuItem>
            </>
          )}
        </StyledLink>
        <StyledLink to="/reports">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Reports isActive={isActive} />
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Reports
                </Text>{' '}
              </MenuItem>
            </>
          )}
        </StyledLink>
      </Wrapper>
    </ModalPortal>
  );
};

export default BurgerMenu;
