import { SVGProps } from "react"

const FleetDriver = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8.553c1.566 0 2.834-1.296 2.834-2.895s-1.268-2.895-2.833-2.895c-1.565 0-2.834 1.296-2.834 2.895s1.269 2.895 2.834 2.895Zm4.723-2.895c0 2.665-2.114 4.825-4.722 4.825-2.609 0-4.723-2.16-4.723-4.825C4.278 2.994 6.392.833 9 .833c2.608 0 4.722 2.16 4.722 4.825ZM13.723 13.377c-1.044 0-1.89-.864-1.89-1.93h2.222a2.8 2.8 0 0 1 1.875.726c.518.466.85 1.11.936 1.81l.37 3.015a1.97 1.97 0 0 1-.459 1.516c-.177.206-.395.37-.64.482-.244.113-.509.17-.777.17H2.64c-.268 0-.533-.057-.777-.17a1.893 1.893 0 0 1-.64-.482 1.97 1.97 0 0 1-.458-1.517l.368-3.014c.086-.7.419-1.344.937-1.811a2.8 2.8 0 0 1 1.876-.725h2.22c0 1.066-.845 1.93-1.889 1.93h-.331a.935.935 0 0 0-.626.242.971.971 0 0 0-.313.603l-.368 3.015H15.36l-.368-3.014a.973.973 0 0 0-.312-.604.933.933 0 0 0-.625-.242h-.332Z"
      fill="#333443"
    />
  </svg>
)

export default FleetDriver
