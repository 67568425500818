import React, { ChangeEvent, useState } from 'react';

import {
  Wrapper,
  MapBlock,
  DriverWrapper,
  DriverBlock,
  Top,
  Drivers,
  DriversLength,
  Input,
  ListWrapper,
  List,
  Item,
  DriverItem,
  Image,
  ImageDefault,
  ImageName,
  Name,
  Line,
  ButtonWrapper,
  Actions,
  DriverExtra,
  DriverBlocks,
  DriverExtraBlock,
  DriverTitle,
  DriverData,
  MapWrapper,
} from './ModalMap.style';
import Button from '../../../../components/Button/Button';
import TrackingMap from '../../../../components/Tracking/TrackingMap/TrackingMap';
import { useAppDispatch } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  refMap: any;
};

const ModalMap: React.FC<ModalAssignProps> = ({ close, refMap }) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>('');
  const [selectDriver, setSelectDriver] = useState<number>();

  const arr = [
    {
      id: 1,
      first_name: 'Armein',
      last_name: 'Feinken',
    },
  ];

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const filteredPersons = arr.filter((item: any) => {
    return item.first_name.toLowerCase().includes(value.toLowerCase());
  });

  return (
    <ModalPortal>
      <Wrapper ref={refMap}>
        <MapBlock>
          <DriverWrapper>
            <DriverBlock>
              <Top
                style={{
                  justifyContent: 'space-between',
                }}
              >
                <Drivers style={{ display: 'block' }}>
                  Drivers <DriversLength>(1)</DriversLength>
                </Drivers>
              </Top>
              <Input
                type="text"
                value={value}
                onChange={handleInput}
                placeholder="Search..."
              />
              <ListWrapper>
                <List>
                  {filteredPersons.map((elem: any) => {
                    return (
                      <Item
                        style={{
                          backgroundColor:
                            selectDriver === elem.id ? '#f4f4f4' : '#fff',
                        }}
                        key={elem.id}
                        onClick={() => {
                          setSelectDriver(elem.id);
                        }}
                      >
                        <DriverItem>
                          {elem.image ? (
                            <Image src={elem.image} />
                          ) : (
                            <ImageDefault>
                              <ImageName>
                                {elem.first_name[0]} {elem.last_name[0]}
                              </ImageName>
                            </ImageDefault>
                          )}

                          <Name>
                            {elem.first_name} {elem.last_name}
                          </Name>
                        </DriverItem>
                        {selectDriver === elem.id && (
                          <DriverExtra>
                            <Line style={{backgroundColor: '#C6C7DA'}} />
                            <DriverBlocks>
                              <DriverExtraBlock>
                                <DriverTitle>Total miles:</DriverTitle>
                                <DriverData>431mil</DriverData>
                              </DriverExtraBlock>
                              <DriverExtraBlock>
                                <DriverTitle>Total flights:</DriverTitle>
                                <DriverData>2</DriverData>
                              </DriverExtraBlock>
                            </DriverBlocks>
                          </DriverExtra>
                        )}
                      </Item>
                    );
                  })}
                </List>
              </ListWrapper>
              <Line />
              <ButtonWrapper>
                <Actions>
                  <Button
                    text="All Flights"
                    pad="12px 26px"
                    handleButton={close}
                  />
                </Actions>
              </ButtonWrapper>
            </DriverBlock>
            <Actions>
              <Button text="Close" pad="12px 26px" handleButton={close} />
            </Actions>
          </DriverWrapper>
          <MapWrapper>
            <TrackingMap activeDriverList={[]} />
          </MapWrapper>
        </MapBlock>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalMap;
