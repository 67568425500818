import React, { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Actions,
  Bottom,
  Error,
  FieldIcon,
  Input,
  InputDiv,
  InputField,
  Label,
  Opt,
  Select,
  SelectedValue,
  Text,
  Wrapper,
} from './factoring.style';
import americaStates from '../../../americaStates.json';
import { saveFactoring } from '../../../API/settings';
import { SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';


const Factoring: React.FC = () => {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  const [name, setName] = useState<string>(settings ? settings.factoring_name : '');
  const [address, setAddress] = useState<string>(settings ? settings.factoring_address : '');
  const [city, setCity] = useState<string>(settings ? settings.factoring_city : '');
  const [state, setState] = useState<string>(settings ? settings.factoring_state : americaStates[0]);
  const [email, setEmail] = useState<string>(settings ? settings.factoring_email : '');
  const [phone, setPhone] = useState<string>(settings ? settings.factoring_phone : '');
  const [factoringFee, setFactoringFee] = useState<string>(settings ? settings.factoring_fee : '');

  const [err, setErr] = useState({
    message: '',
    errors: {
      factoring_name: [],
      factoring_address: [],
      factoring_city: [],
      factoring_email: [],
      factoring_phone: [],
    },
  });

  const handleConfirm = async () => {
    const res: any = await dispatch(
      saveFactoring({
        factoring_name: name,
        factoring_address: address,
        factoring_city: city,
        factoring_state: state,
        factoring_email: email,
        factoring_phone: phone,
        factoring_fee: factoringFee,
      }),
    );
    if (res.type === 'settings/saveFactoring/rejected')
      return setErr(res.payload);
  };

  const changeSelectState = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
  ) => {
    setState(e.target.value);
  };

  return (
    <Wrapper>
      <Text>
        Enter the details of your factoring company below to enable the option
        to factor your invoices
      </Text>
      <InputField>
        <Label htmlFor="name">Name *</Label>
        <Input
          id="name"
          type="text"
          style={{
            border: err.errors?.factoring_name[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, factoring_name: [] },
            })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value.replace(/[^A-Za-z\s]/gi, ""))
          }
          value={name}
        />
        <Error style={{ bottom: '-17px' }}>{err.errors?.factoring_name[0]}</Error>
      </InputField>
      <InputField>
        <Label htmlFor="address">Address *</Label>
        <Input
          id="address"
          type="text"
          style={{
            border: err.errors?.factoring_address[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, factoring_address: [] },
            })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress(e.target.value)
          }
          value={address}
        />
        <Error style={{ bottom: '-17px' }}>{err.errors?.factoring_address[0]}</Error>
      </InputField>
      <InputField>
        <Label htmlFor="city">City *</Label>
        <Input
          id="city"
          type="text"
          style={{
            border: err.errors?.factoring_city[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, factoring_city: [] },
            })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCity(e.target.value.replace(/[^A-Za-z\s]/gi, ""))
          }
          value={city}
        />
        <Error style={{ bottom: '-17px' }}>{err.errors?.factoring_city[0]}</Error>
      </InputField>
      <InputField>
        <Label htmlFor="state">State *</Label>
        <SelectedValue>{state}</SelectedValue>
        <FieldIcon>
          <SelectArrow />
        </FieldIcon>
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            changeSelectState(e, setState)
          }
        >
          <Opt hidden></Opt>
          {americaStates?.map((item: any) => (
            <Opt key={item} value={item}>
              {item}
            </Opt>
          ))}
        </Select>
      </InputField>
      <InputField>
        <Label htmlFor="email">Email *</Label>
        <Input
          id="email"
          type="email"
          style={{
            border: err.errors?.factoring_email[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, factoring_email: [] },
            })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          value={email}
        />
        <Error style={{ bottom: '-17px' }}>{err.errors?.factoring_email[0]}</Error>
      </InputField>
      <InputField>
        <Label htmlFor="phone">Phone Number *</Label>
        <InputDiv
          style={{
            border: err.errors?.factoring_phone[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({ ...err, errors: { ...err.errors, factoring_phone: [] } })
          }
        >
          <PhoneInput
            international
            limitMaxLength
            value={phone}
            id="phone"
            onChange={(value: string) => setPhone(value || '')}
          />
        </InputDiv>
        <Error>{err.errors?.factoring_phone[0]}</Error>
      </InputField>
      <InputField>
        <Label htmlFor="factoringFee">Factoring Fee (%)</Label>
        <Input
          id="factoringFee"
          type="number"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFactoringFee(e.target.value)
          }
          value={factoringFee}
        />
      </InputField>
      <Bottom>
        <Actions>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </Actions>
      </Bottom>
    </Wrapper>
  );
};

export default Factoring;
