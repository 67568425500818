import api from '../API';

export enum SubscriptionStatusType {
  inactive = 'inactive',
  active = 'active',
  gracePeriod = 'on_grace_period',
  onTrial = 'on_trial',
}

interface Subscription {
  reated_at: string;
  ends_at: string;
  id: number;
  quantity: 7;
  stripe_id: string;
  stripe_price: string;
  stripe_status: SubscriptionStatusType;
  trial_ends_at: null;
  type: string;
  updated_at: string;
  user_id: number;
}

const API_URL = {
  get: '/api/crm/subscription',
  resume: '/api/crm/subscription/resume',
  update: '/api/crm/subscription/checkout',
  cancel: '/api/crm/subscription/cancel',
  delete: '/api/crm/subscription',
};

export type SubscriptionInfoResponse = {
  next_payment_amount?: string;
  current_period_end?: string;
  subscription: Subscription;
  status: SubscriptionStatusType;
};

export async function getSubscriptionInfo(): Promise<SubscriptionInfoResponse> {
  try {
    const response = await api.get(API_URL.get);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching subscription');
  }
}

export async function resumeSubscription(): Promise<{ url: string }> {
  try {
    const response = await api.post(API_URL.resume);
    return response.data;
  } catch (error) {
    throw new Error('Error resume subscription');
  }
}

export async function updateSubscriptionInfo(): Promise<{ url: string }> {
  try {
    const response = await api.get(API_URL.update);
    return response.data;
  } catch (error) {
    throw new Error('Error update subscription');
  }
}

export async function cancelSubscription(): Promise<{ url: string }> {
  try {
    const response = await api.post(API_URL.cancel);
    return response.data;
  } catch (error) {
    throw new Error('Error cancel subscription');
  }
}

export async function deleteSubscriptionInfo(): Promise<Subscription> {
  try {
    const response = await api.delete(API_URL.delete);
    return response.data.subscription;
  } catch (error) {
    throw new Error('Error delete subscription');
  }
}
