import React, { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Actions,
  Block,
  BlockTitle,
  Bottom,
  BtnDisabed,
  ButtonBlock,
  Cancel,
  Error,
  Input,
  InputDiv,
  InputField,
  Label,
  Line,
  TopText,
  VerifyBlock,
  VerifyText,
  Wrapper,
} from './modalDispatcherProfile.style';
import { createChangeEmail } from '../../../API/auth';
import {
  changePassword,
  getDispatcherProfile,
  saveDispatcherProfile,
} from '../../../API/dispatcherProfile';
import Button from '../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ModalDispatcherProfileProps = {
  close: () => void;
  value: string;
  refDispatcher: any;
};

const ModalDispatcherProfile: React.FC<ModalDispatcherProfileProps> = ({
  close,
  value,
  refDispatcher,
}) => {
  const { data } = useAppSelector(
    (state: { dispatcherProfile: any }) => state.dispatcherProfile,
  );

  const { user } = useAppSelector((state: { auth: any }) => state.auth);

  const [userData, setUserData] = useState<any[]>([]);

  const [name, setName] = useState<string>(data ? data?.name : '');
  const [phone, setPhone] = useState<string>(data ? data?.phone : '');
  const [email, setEmail] = useState<string>(data ? data?.email : '');

  const [showCheck, setShowCheck] = useState<boolean>(false);

  const [oldPassword, setOldPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  const dispatch = useAppDispatch();

  const [err, setErr] = useState({
    message: '',
    errors: {
      name: [],
      phone: [],
      email: [],
      old_password: [],
      password: [],
      password_confirmation: [],
    },
  });

  const handleConfirm = async () => {
    if (value === 'edit') {
      const res: any = await dispatch(
        saveDispatcherProfile({ name, phone, email }),
      );
      if (res.type === 'dispatcherProfile/saveDispatcherProfile/rejected')
        return setErr(res.payload);
    } else {
      const res: any = await dispatch(
        changePassword({
          old_password: oldPassword,
          password,
          password_confirmation: passwordConfirmation,
        }),
      );
      if (res.type === 'dispatcherProfile/changePassword/rejected')
        return setErr(res.payload);
    }
    dispatch(getDispatcherProfile(null));
    close();
  };

  const handleVerify = async () => {
    const res: any = await dispatch(createChangeEmail(email));
    if (res.type === 'dispatcherProfile/createChangeEmail/rejected')
      return setErr(res.payload);
    setShowCheck(true);
    window.Echo.private(`change-email.${user.id}`).listen(
      'ChangeEmailEvent',
      (e: any) => {
        setUserData([
          ...userData,
          {
            id: e.id,
            email: e.email,
          },
        ]);
      },
    );
  };

  return (
    <ModalPortal>
      <Wrapper ref={refDispatcher}>
        {value === 'edit' ? (
          <>
            <TopText>Edit profile</TopText>
            <Line />
            <Block>
              <BlockTitle>Profile info</BlockTitle>
              <InputField>
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  type="text"
                  style={{
                    border: err.errors?.name?.[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, name: [] } })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
                  }
                  value={name}
                />
                <Error style={{ bottom: '-17px' }}>
                  {err.errors?.name?.[0]}
                </Error>
              </InputField>
              <InputField>
                <Label htmlFor="phone">Phone number</Label>
                <InputDiv
                  style={{
                    border: err.errors?.phone?.[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, phone: [] } })
                  }
                >
                  <PhoneInput
                    international
                    limitMaxLength
                    value={phone}
                    id="phone"
                    onChange={(value: string) => setPhone(value || '')}
                  />
                </InputDiv>
                <Error>{err.errors?.phone?.[0]}</Error>
              </InputField>
              <Line />
              <BlockTitle style={{ marginTop: '24px' }}>
                Account Info
              </BlockTitle>
              <InputField>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  style={{
                    border: err.errors?.email?.[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, email: [] } })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  value={email}
                />
                <Error style={{ bottom: '-17px' }}>
                  {err.errors?.email?.[0]}
                </Error>
              </InputField>
              {email !== data?.email && email !== '' && (
                <VerifyBlock>
                  <ButtonBlock>
                    <Button
                      text="Verify"
                      pad="8px 24px"
                      handleButton={handleVerify}
                    />
                  </ButtonBlock>
                  {showCheck && (
                    <VerifyText>
                      Please, check your email for verification
                    </VerifyText>
                  )}
                </VerifyBlock>
              )}
            </Block>
            <Line />
            <Bottom>
              <Actions>
                <Cancel onClick={close}>Cancel</Cancel>
                {!userData.length && email !== data?.email && email !== '' ? (
                  <BtnDisabed>Save</BtnDisabed>
                ) : (
                  <Button
                    text="Save"
                    pad="8px 24px"
                    handleButton={handleConfirm}
                  />
                )}
              </Actions>
            </Bottom>
          </>
        ) : (
          <>
            <TopText>Change password</TopText>
            <Line />
            <Block>
              <InputField>
                <Label htmlFor="oldPassword">Old password</Label>
                <Input
                  id="oldPassword"
                  type="password"
                  style={{
                    border: err.errors?.old_password?.[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({
                      ...err,
                      errors: { ...err.errors, old_password: [] },
                    })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setOldPassword(e.target.value)
                  }
                  value={oldPassword}
                />
                <Error style={{ bottom: '-17px' }}>
                  {err.errors?.old_password?.[0]}
                </Error>
              </InputField>
              <InputField>
                <Label htmlFor="password">New password</Label>
                <Input
                  id="password"
                  type="password"
                  style={{
                    border: err.errors?.password?.[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, password: [] } })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value)
                  }
                  value={password}
                />
                <Error style={{ bottom: '-17px' }}>
                  {err.errors?.password?.[0]}
                </Error>
              </InputField>
              <InputField>
                <Label htmlFor="passwordConfirmation">
                  Password confirmation
                </Label>
                <Input
                  id="passwordConfirmation"
                  type="password"
                  style={{
                    border: err.errors?.password_confirmation?.[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({
                      ...err,
                      errors: { ...err.errors, password_confirmation: [] },
                    })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setPasswordConfirmation(e.target.value)
                  }
                  value={passwordConfirmation}
                />
                <Error style={{ bottom: '-17px' }}>
                  {err.errors?.password_confirmation?.[0]}
                </Error>
              </InputField>
            </Block>
            <Line />
            <Bottom>
              <Actions>
                <Cancel onClick={close}>Cancel</Cancel>
                <Button
                  text="Save"
                  pad="8px 24px"
                  handleButton={handleConfirm}
                />
              </Actions>
            </Bottom>
          </>
        )}
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalDispatcherProfile;
