import React, { useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Label,
  SelectField,
  Flex,
  CheckBoxBlock,
  CheckBoxLabel,
  CheckBox,
  SelectedValue,
  Wrapp,
  Name,
  FieldIcon,
  Dropdown,
  Input,
  InputField,
  CheckBoxItem,
  CheckBoxSquare,
  CustomCheckBoxSquare,
  Error,
} from './drivers.style';
import { DriverItem, getDrivers } from '../../../../API/drivers';
import { Check, SelectArrow } from '../../../../assets/icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

type DriversProps = {
  activeDrivers: number[];
  setActiveDrivers: any;
  disabledDrivers: number[];
  setDisabledDrivers: any;
  err: any;
  setErr: any;
};

const Drivers: React.FC<DriversProps> = ({
  activeDrivers,
  setActiveDrivers,
  disabledDrivers,
  setDisabledDrivers,
  err,
  setErr,
}) => {
  const ref = useRef<any>();

  const dispatch = useAppDispatch();

  const { driversList } = useAppSelector(
    (state: { driver: any }) => state.driver,
  );

  const activeDriverList = driversList.filter(
    (elem: DriverItem) => elem.status === 'active',
  );

  const disabledDriverList = driversList.filter(
    (elem: DriverItem) => elem.status === 'inactive',
  );

  const [showActiveDrivers, setShowActiveDrivers] = useState<boolean>(false);
  const [selectAllDriver, setSelectAllDriver] = useState<boolean>(false);
  const [activeDriversName, setActiveDriversName] = useState<string[]>([]);
  const [activeDriverValue, setActiveDriverValue] = useState<string>('');

  const [showDisabledDrivers, setShowDisabledDrivers] =
    useState<boolean>(false);
  const [disableAllDriver, setDisableAllDriver] = useState<boolean>(false);
  const [disabledDriversName, setDisabledDriversName] = useState<string[]>([]);
  const [disabledDriverValue, setDisabledDriverValue] = useState<string>('');

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: any,
  ) => {
    setState(e.target.value);
  };

  const activeList = activeDriverList.filter((item: any) => {
    return item.first_name
      .toLowerCase()
      .includes(activeDriverValue.toLowerCase());
  });

  const disabledList = disabledDriverList.filter((item: any) => {
    return item.first_name
      .toLowerCase()
      .includes(disabledDriverValue.toLowerCase());
  });

  const addToList = (
    id: number,
    fName: string,
    lName: string,
    arrayDrivers: number[],
    setArrayDrivers: any,
    arrayDriversName: string[],
    setArrayDriversName: (value: React.SetStateAction<string[]>) => void,
  ) => {
    if (!arrayDrivers.includes(id)) {
      setArrayDrivers([...arrayDrivers, id]);
      setArrayDriversName([...arrayDriversName, fName + ' ' + lName]);
    } else {
      setArrayDrivers(arrayDrivers.filter((elem: number) => elem !== id));
      setArrayDriversName(
        arrayDriversName.filter((elem: string) => elem !== fName + ' ' + lName),
      );
    }
  };

  const selectAll = (
    stateAll: boolean,
    setStateAll: (value: React.SetStateAction<boolean>) => void,
    arrayDrivers: any,
    setArrayDriversName: (value: React.SetStateAction<string[]>) => void,
    list: any,
  ) => {
    if (!stateAll) {
      setStateAll(true);
      arrayDrivers([]);
      setArrayDriversName([]);
      list.forEach((elem: any) => {
        arrayDrivers((prev: any) => [...prev, elem.id]);
        setArrayDriversName((prev: any) => [
          ...prev,
          elem.first_name + ' ' + elem.last_name,
        ]);
      });
    } else {
      setStateAll(false);
      arrayDrivers([]);
      setArrayDriversName([]);
    }
  };

  useEffect(() => {
    dispatch(getDrivers(null));
  }, []);

  useEffect(() => {
    if (
      activeDrivers.length !== activeDriverList.length ||
      activeDrivers.length === 0
    )
      setSelectAllDriver(false);
    else setSelectAllDriver(true);
  }, [activeDrivers]);

  useEffect(() => {
    if (
      disabledDrivers.length !== disabledDriverList.length ||
      disabledDrivers.length === 0
    )
      setDisableAllDriver(false);
    else setDisableAllDriver(true);
  }, [disabledDrivers]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showActiveDrivers && ref.current && !ref.current.contains(e.target)) {
        setShowActiveDrivers(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showActiveDrivers]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (
        showDisabledDrivers &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowDisabledDrivers(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showDisabledDrivers]);

  return (
    <Wrapper>
      <SelectField>
        <Flex>
          <Label>Active drivers</Label>
          <CheckBoxBlock>
            <CheckBox
              type="checkbox"
              name="selectAllDriver"
              id="selectAllDriver"
              onChange={() =>
                selectAll(
                  selectAllDriver,
                  setSelectAllDriver,
                  setActiveDrivers,
                  setActiveDriversName,
                  activeDriverList,
                )
              }
              checked={selectAllDriver}
            />
            <CheckBoxLabel htmlFor="selectAllDriver">Select all</CheckBoxLabel>
          </CheckBoxBlock>
        </Flex>
        <SelectedValue
          onClick={() => {
            setShowActiveDrivers(true);
            setErr({ ...err, errors: { ...err.errors, drivers: [] } });
          }}
          style={{
            border: err.errors?.drivers?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
        >
          <Wrapp>
            {activeDriversName.map((elem: string, i: number) => (
              <Name key={i}>{elem}</Name>
            ))}
          </Wrapp>
          <FieldIcon>
            <SelectArrow />
          </FieldIcon>
          <Error style={{ bottom: '-17px' }}>{err.errors?.drivers?.[0]}</Error>
        </SelectedValue>

        {showActiveDrivers && (
          <Dropdown ref={ref}>
            <Input
              type="text"
              value={activeDriverValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setActiveDriverValue)
              }
              placeholder="Search..."
            />

            {activeList.map((elem: DriverItem) => {
              return (
                <InputField
                  key={elem.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CheckBoxItem>
                    <CheckBoxSquare
                      type="checkbox"
                      name={`driver-${elem.id}`}
                      id={`driver-${elem.id}`}
                      onChange={() =>
                        addToList(
                          elem.id || 0,
                          elem.first_name,
                          elem.last_name,
                          activeDrivers,
                          setActiveDrivers,
                          activeDriversName,
                          setActiveDriversName,
                        )
                      }
                      checked={activeDrivers.includes(elem.id || 0)}
                    />
                    <CustomCheckBoxSquare>
                      <Check right="3px" />
                    </CustomCheckBoxSquare>
                  </CheckBoxItem>
                  <Label
                    htmlFor={`driver-${elem.id}`}
                    style={{ marginBottom: 0, marginLeft: '12px' }}
                  >
                    {elem.first_name + ' ' + elem.last_name}
                  </Label>
                </InputField>
              );
            })}
          </Dropdown>
        )}
      </SelectField>

      <SelectField style={{ marginTop: '20px' }}>
        <Flex>
          <Label>Disabled drivers</Label>
          <CheckBoxBlock>
            <CheckBox
              type="checkbox"
              name="disableAllDriver"
              id="disableAllDriver"
              onChange={() =>
                selectAll(
                  disableAllDriver,
                  setDisableAllDriver,
                  setDisabledDrivers,
                  setDisabledDriversName,
                  disabledDriverList,
                )
              }
              checked={disableAllDriver}
            />
            <CheckBoxLabel htmlFor="disableAllDriver">Select all</CheckBoxLabel>
          </CheckBoxBlock>
        </Flex>
        <SelectedValue onClick={() => setShowDisabledDrivers(true)}>
          <Wrapp>
            {disabledDriversName.map((elem: string, i: number) => (
              <Name key={i}>{elem}</Name>
            ))}
          </Wrapp>
          <FieldIcon>
            <SelectArrow />
          </FieldIcon>
        </SelectedValue>

        {showDisabledDrivers && (
          <Dropdown ref={ref}>
            <Input
              type="text"
              value={disabledDriverValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setDisabledDriverValue)
              }
              placeholder="Search..."
            />

            {disabledList.map((elem: DriverItem) => {
              return (
                <InputField
                  key={elem.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CheckBoxItem>
                    <CheckBoxSquare
                      type="checkbox"
                      name={`driver-${elem.id}`}
                      id={`driver-${elem.id}`}
                      onChange={() =>
                        addToList(
                          elem.id || 0,
                          elem.first_name,
                          elem.last_name,
                          disabledDrivers,
                          setDisabledDrivers,
                          disabledDriversName,
                          setDisabledDriversName,
                        )
                      }
                      checked={disabledDrivers.includes(elem.id || 0)}
                    />
                    <CustomCheckBoxSquare>
                      <Check right="3px" />
                    </CustomCheckBoxSquare>
                  </CheckBoxItem>
                  <Label
                    htmlFor={`driver-${elem.id}`}
                    style={{ marginBottom: 0, marginLeft: '12px' }}
                  >
                    {elem.first_name + ' ' + elem.last_name}
                  </Label>
                </InputField>
              );
            })}
          </Dropdown>
        )}
      </SelectField>
    </Wrapper>
  );
};

export default Drivers;
