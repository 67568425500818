import React, { useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Top,
  Title,
  Length,
  Actions,
  DriverBlock,
  ClientBlock,
  ClientItem,
} from './drivers.style';
import { getDrivers } from '../../API/drivers';
import { Plus } from '../../assets/icons';
import Button from '../../components/Button/Button';
import DriversItem from '../../components/Drivers/DriversItem/DriversItem';
import DriversList from '../../components/Drivers/DriversList/DriversList';
import Container from '../../layout/Container';
import ModalDriver from '../../layout/Modal/ModalDriver/ModalDriver';
import useOnClickOutside from '../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const Drivers: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const refDriver = useRef<any>();

  const { driversList, loading } = useAppSelector(
    (state: { driver: any }) => state.driver,
  );

  const openModal = () => setOpen(true);

  useEffect(() => {
    dispatch(getDrivers(null));
  }, []);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useOnClickOutside(refDriver, () => setOpen(false));

  if (loading) return <Preloader />;

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <Title>
              Drivers <Length>({driversList.length})</Length>{' '}
            </Title>
            <Actions>
              <Button
                text="Add Driver"
                pad="10px 20px"
                icon={<Plus />}
                handleButton={openModal}
              />
            </Actions>
          </Top>
          <DriverBlock>
            <ClientBlock>
              <DriversList />
            </ClientBlock>
            <ClientItem>
              <DriversItem />
            </ClientItem>
          </DriverBlock>

          {open && (
            <ModalDriver
              refDriver={refDriver}
              addCheck={true}
              text="Add Driver"
              close={() => setOpen(false)}
            />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default Drivers;
