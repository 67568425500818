import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export const options = {
  responsive: true,
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'September',
  'AA',
  'GG',
];

export const data = {
  labels,
  datasets: [
    {
      label: 'To pickups',
      barPercentage: 0.5,
      barThickness: 10,
      maxBarThickness: 10,
      minBarLength: 2,
      data: [25, 81, 56, 44, 16, 27, 51, 90, 11, 56, 40, 26, 18, 2, 11],
      backgroundColor: '#616172',
    },
    {
      label: 'To deliver',
      barPercentage: 0.5,
      barThickness: 10,
      maxBarThickness: 10,
      minBarLength: 2,
      data: [61, 59, 80, 81, 56, 65, 59, 80, 81, 16, 43, 56, 67, 8, 44],
      backgroundColor: '#4A8AFF',
    },
  ],
};

const BarChart: React.FC = () => {
  return <Bar options={options} data={data} height={100} />;
};

export default BarChart;
