import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  Wrapper,
  Line,
  Block,
  BlockScroll,
  LoadsBlock,
  LoadsTitle,
  CheckBoxItem,
  CheckBoxSquare,
  CustomCheckBoxSquare,
  LoadsFlex,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInput,
  LoadsLabel,
  LoadsText,
  SelectedValue,
  FieldIcon,
  LoadsSelect,
  Opt,
  Bottom,
  Actions,
  Cancel,
  Error,
} from './vehicleModal.style';
import { addVehicle, getLoadData, updateVehicle } from '../../../../API/loads';
import { Check, SelectArrow } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { resetError } from '../../../../store/slices/loadsSlice';
import ModalPortal from '../../ModalPortal';

const defaultErrorState = {
  message: '',
  errors: {
    vin: [],
    year: [],
    make: [],
    model: [],
    color: [],
    price: [],
    lot_number: [],
  },
};

type ModalAssignProps = {
  close: () => void;
  addCheckVehicle: boolean;
  vehicleItem?: any;
  refVehicle: any;
};

const VehicleModal: React.FC<ModalAssignProps> = ({ close, addCheckVehicle, vehicleItem, refVehicle }) => {
  const { loadId, loadData, error } = useAppSelector((state: { loads: any }) => state.loads);

  const typeVehicleItem = loadData?.vehicle_types.find((elem: any) => elem.title === vehicleItem.current?.type);

  const [err, setErr] = useState(defaultErrorState);

  useEffect(() => {
    if (error) {
      setErr(error);
    }

    return () => {
      dispatch(resetError());
    };
  }, error);

  const [typeVehicle, setTypeVehcle] = useState<string>(
    addCheckVehicle ? loadData?.vehicle_types[0].id : typeVehicleItem.id
  );
  const [typeVehicleName, setTypeVehicleIdName] = useState<string>(
    addCheckVehicle ? loadData?.vehicle_types[0].title : vehicleItem.current?.type
  );
  const [inop, setInop] = useState<boolean>(addCheckVehicle ? false : vehicleItem.current?.inop);
  const [enclosed, setEnclosed] = useState<boolean>(addCheckVehicle ? false : vehicleItem.current?.enclosed);

  const [vehicle, setVehicle] = useState({
    vin: addCheckVehicle ? '' : vehicleItem.current?.vin,
    year: addCheckVehicle ? '' : vehicleItem.current?.year,
    make: addCheckVehicle ? '' : vehicleItem.current?.make,
    model: addCheckVehicle ? '' : vehicleItem.current?.model,
    color: addCheckVehicle ? '' : vehicleItem.current?.color,
    price: addCheckVehicle ? '' : vehicleItem.current?.price,
    lot_number: addCheckVehicle ? '' : vehicleItem.current?.lot_number,
  });

  const dispatch = useAppDispatch();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state: any) => {
    if (name === 'year') setState({ ...state, [name]: e.target.value.slice(0, 4) });
    else if (name === 'make')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'color')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else setState({ ...state, [name]: e.target.value });
  };

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const handleCheckboxVehicle = (e: React.ChangeEvent<HTMLInputElement>, setState: any, elem: any) => {
    setState(!elem);
  };

  const handleConfirm = async () => {
    if (addCheckVehicle) {
      const res: any = await dispatch(
        addVehicle([
          {
            type: typeVehicle,
            vin: vehicle.vin,
            year: vehicle.year,
            make: vehicle.make,
            model: vehicle.model,
            color: vehicle.color,
            lot_number: vehicle.lot_number,
            price: vehicle.price,
            inop: inop,
            enclosed: enclosed,
          },
          loadId.id,
        ])
      );
      if (res.type === 'loads/addVehicle/rejected') return setErr(res.payload);
    } else {
      const res: any = await dispatch(
        updateVehicle({
          vehicle_id: vehicleItem.current.id,
          type: typeVehicle,
          vin: vehicle.vin,
          year: vehicle.year,
          make: vehicle.make,
          model: vehicle.model,
          color: vehicle.color,
          lot_number: vehicle.lot_number,
          price: vehicle.price,
          inop: inop,
          enclosed: enclosed,
        })
      );
      if (res.type === 'loads/updateVehicle/rejected') return setErr(res.payload);
    }
    await dispatch(getLoadData(loadId.id));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refVehicle}>
        <LoadsBlock>
          <LoadsTitle>{addCheckVehicle ? 'Add vehicle' : 'Edit vehicle'}</LoadsTitle>
          <Line />
          <Block>
            <BlockScroll>
              <LoadsFlex>
                <LoadsInputFieldHalf
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <CheckBoxItem>
                    <CheckBoxSquare
                      type="checkbox"
                      name="vehicleInop"
                      id="vehicleInop"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckboxVehicle(e, setInop, inop)}
                      checked={inop}
                    />
                    <CustomCheckBoxSquare>
                      <Check right="3px" />
                    </CustomCheckBoxSquare>
                  </CheckBoxItem>
                  <LoadsLabel htmlFor="vehicleInop" style={{ marginBottom: 0, marginLeft: '12px' }}>
                    Vehicle is inoperable
                  </LoadsLabel>
                </LoadsInputFieldHalf>
                <LoadsInputFieldHalf
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <CheckBoxItem>
                    <CheckBoxSquare
                      type="checkbox"
                      name="vehicleEnclosed"
                      id="vehicleEnclosed"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckboxVehicle(e, setEnclosed, enclosed)}
                      checked={enclosed}
                    />
                    <CustomCheckBoxSquare>
                      <Check right="3px" />
                    </CustomCheckBoxSquare>
                  </CheckBoxItem>
                  <LoadsLabel htmlFor="vehicleEnclosed" style={{ marginBottom: 0, marginLeft: '12px' }}>
                    Vehicle is enclosed
                  </LoadsLabel>
                </LoadsInputFieldHalf>
              </LoadsFlex>
              <LoadsInputField>
                <LoadsLabel htmlFor="vin">Vin</LoadsLabel>
                <LoadsInput
                  id="vin"
                  type="text"
                  style={{
                    border: err.errors?.vin?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                  }}
                  onFocus={() => setErr({ ...err, errors: { ...err.errors, vin: [] } })}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVehicle, 'vin', vehicle)}
                  value={vehicle.vin}
                />
                <Error>{err.errors?.vin?.[0]}</Error>
              </LoadsInputField>
              <LoadsFlex>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="year">Year*</LoadsLabel>
                  <LoadsInput
                    id="year"
                    type="number"
                    style={{
                      border: err.errors?.year?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() => setErr({ ...err, errors: { ...err.errors, year: [] } })}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVehicle, 'year', vehicle)}
                    value={vehicle.year}
                  />
                  <Error>{err.errors?.year?.[0]}</Error>
                </LoadsInputFieldHalf>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="make">Make*</LoadsLabel>
                  <LoadsInput
                    id="make"
                    type="text"
                    style={{
                      border: err.errors?.make?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() => setErr({ ...err, errors: { ...err.errors, make: [] } })}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVehicle, 'make', vehicle)}
                    value={vehicle.make}
                  />
                  <Error>{err.errors?.make?.[0]}</Error>
                </LoadsInputFieldHalf>
              </LoadsFlex>
              <LoadsFlex>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="model">Model</LoadsLabel>
                  <LoadsInput
                    id="model"
                    type="text"
                    style={{
                      border: err.errors?.model?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() => setErr({ ...err, errors: { ...err.errors, model: [] } })}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVehicle, 'model', vehicle)}
                    value={vehicle.model}
                  />
                  <Error>{err.errors?.model?.[0]}</Error>
                </LoadsInputFieldHalf>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="typeVehicle">Type</LoadsLabel>
                  <SelectedValue>{typeVehicleName}</SelectedValue>
                  <FieldIcon>
                    <SelectArrow />
                  </FieldIcon>
                  <LoadsSelect
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      changeSelectState(e, setTypeVehicleIdName, setTypeVehcle)
                    }
                  >
                    <Opt hidden defaultValue></Opt>
                    {loadData?.vehicle_types?.map((item: any) => (
                      <Opt key={item.id} value={item.title} data-value={item.id}>
                        {item?.title}
                      </Opt>
                    ))}
                  </LoadsSelect>
                </LoadsInputFieldHalf>
              </LoadsFlex>
              <Line />
              <LoadsText>Additional details</LoadsText>
              <LoadsFlex>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="color">Color</LoadsLabel>
                  <LoadsInput
                    id="color"
                    type="text"
                    style={{
                      border: err.errors?.color?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() => setErr({ ...err, errors: { ...err.errors, color: [] } })}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVehicle, 'color', vehicle)}
                    value={vehicle.color}
                  />
                  <Error>{err.errors?.color?.[0]}</Error>
                </LoadsInputFieldHalf>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="price">Price</LoadsLabel>
                  <LoadsInput
                    id="price"
                    type="number"
                    style={{
                      border: err.errors?.price?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() => setErr({ ...err, errors: { ...err.errors, price: [] } })}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVehicle, 'price', vehicle)}
                    value={vehicle.price}
                  />
                  <Error>{err.errors?.price?.[0]}</Error>
                </LoadsInputFieldHalf>
              </LoadsFlex>
              <LoadsInputField>
                <LoadsLabel htmlFor="lot_number">Lot number</LoadsLabel>
                <LoadsInput
                  id="lot_number"
                  type="number"
                  style={{
                    border: err.errors?.lot_number?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({
                      ...err,
                      errors: { ...err.errors, lot_number: [] },
                    })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVehicle, 'lot_number', vehicle)}
                  value={vehicle.lot_number}
                />
                <Error>{err.errors?.lot_number?.[0]}</Error>
              </LoadsInputField>
            </BlockScroll>
          </Block>
        </LoadsBlock>

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default VehicleModal;
