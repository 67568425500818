import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BurgerMenu from './BurgerMenu/BurgerMenu';
import {
  AuthHeaderWrapper,
  BurgerBlock,
  BurgerItem,
  Dropdown,
  DropdownMenu,
  HeaderLeft,
  HeaderRight,
  Image,
  ImageDefault,
  ImageName,
  Line,
  LogoOnMobile,
  LogoOnWeb,
  LogoWrapper,
  MenuBlock,
  MenuItem,
  Notification,
  Phone,
  PhoneLink,
  UserEmail,
  UserName,
} from './header.style';
import { getUser } from '../../API/auth';
import { ArrowDown, Bell, Burger, Logo, LogoSmall } from '../../assets/icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setIsLogin, setLogout } from '../../store/slices/authSlice';

const AuthHeader: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef<any>();

  const { user } = useAppSelector((state: { auth: any }) => state.auth);
  const { isLogin } = useAppSelector(state => state.auth);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showBurger, setShowBurger] = useState<boolean>(false);

  const showDropdownMenu = () => setShowMenu(!showMenu);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenu]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) dispatch(setIsLogin());
    if (!isLogin) dispatch(getUser(null));
  }, []);

  return (
    <AuthHeaderWrapper>
      <HeaderLeft>
        <LogoWrapper>
          <LogoOnWeb onClick={() => navigation('/dashboard')}>
            <Logo />
          </LogoOnWeb>{' '}
          <LogoOnMobile>
            <BurgerBlock>
              {!showBurger && (
                <BurgerItem onClick={() => setShowBurger(true)}>
                  <Burger />
                </BurgerItem>
              )}
            </BurgerBlock>
            <LogoOnMobile onClick={() => navigation('/dashboard')}>
              <LogoSmall />
            </LogoOnMobile>
          </LogoOnMobile>
        </LogoWrapper>
        <Phone>
          <PhoneLink href="tel:+1 (800) 200 3592">+1 (800) 200 3592</PhoneLink>
        </Phone>
      </HeaderLeft>
      <HeaderRight>
        <Notification>
          <Bell />
        </Notification>
        <Dropdown onClick={showDropdownMenu}>
          {user.image ? (
            <Image src={user.image} />
          ) : (
            <ImageDefault>
              <ImageName>
                {user.first_name[0]} {user.last_name[0]}
              </ImageName>
            </ImageDefault>
          )}
          <ArrowDown />
        </Dropdown>
      </HeaderRight>

      {showBurger && <BurgerMenu setShowBurger={setShowBurger} />}

      {showMenu && (
        <DropdownMenu ref={ref}>
          <UserName>{user.first_name + ' ' + user.last_name}</UserName>
          <UserEmail>{user.email}</UserEmail>
          <Line />
          <MenuBlock>
            <MenuItem onClick={() => navigation('/settings')}>
              Settings
            </MenuItem>
            <MenuItem onClick={() => navigation('/carrier-profile')}>
              Carrier Profile
            </MenuItem>
            <MenuItem onClick={() => navigation('/dispatcher-profile')}>
              Dispatcher Profile
            </MenuItem>
            <MenuItem onClick={() => navigation('/help-center')}>
              Help Center
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(setLogout());
                localStorage.setItem('token', '');
                navigation('/');
              }}
            >
              Logout
            </MenuItem>
          </MenuBlock>
        </DropdownMenu>
      )}
    </AuthHeaderWrapper>
  );
};

export default AuthHeader;
