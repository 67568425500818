import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';

import {
  Actions,
  Bottom,
  Cancel,
  Error,
  Input,
  InputField,
  InputMask,
  Label,
  Line,
  TerminalBlock,
  TopText,
  Wrapper,
} from './modalSubscription.style';
import { getSettings, saveSubscription } from '../../../API/settings';
import Button from '../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  refSubscription: any;
};

const ModalSubscription: React.FC<ModalContentProps> = ({ close, refSubscription }) => {
  const dispatch = useAppDispatch();
  const { settings } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  const [card, setCard] = useState<string>(
    settings?.subscription_card ? settings?.subscription_card : '',
  );
  const [date, setDate] = useState<string>(
    settings?.subscription_date
      ? moment(settings?.subscription_date).format('MM/YYYY')
      : '',
  );
  const [name, setName] = useState<string>(
    settings?.subscription_name ? settings?.subscription_name : '',
  );

  const [err, setErr] = useState({
    message: '',
    errors: {
      subscription_card: [],
      subscription_date: [],
      subscription_name: [],
    },
  });

  const handleConfirm = async () => {
    const res: any = await dispatch(
      saveSubscription({
        subscription_card: card.replaceAll(' ', ''),
        subscription_date: moment('01.' + date.replaceAll('/', '.')).format(
          'DD.MM.YYYY',
        ),
        subscription_name: name,
      }),
    );
    if (res.type === 'settings/saveSubscription/rejected')
      return setErr(res.payload);
    dispatch(getSettings(null));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refSubscription}>
        <TopText>Edit payment information</TopText>
        <Line />

        <TerminalBlock>
          <InputField>
            <Label htmlFor="cardNumber">Card number</Label>
            <InputMask
              mask={'0000 0000 0000 0000'}
              unmask={true}
              value={card}
              id="cardNumber"
              style={{
                border: err.errors?.subscription_card?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, subscription_card: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCard(e.target.value)
              }
            />
            <Error style={{ bottom: '-17px' }}>
              {err.errors?.subscription_card?.[0]}
            </Error>
          </InputField>
          <InputField>
            <Label htmlFor="date">Expiration date</Label>
            <InputMask
              mask={'00/0000'}
              unmask={true}
              id="date"
              style={{
                border: err.errors?.subscription_date?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, subscription_date: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDate(e.target.value)
              }
              value={date}
            />
            <Error style={{ bottom: '-17px' }}>
              {err.errors?.subscription_date?.[0]}
            </Error>
          </InputField>
          <InputField>
            <Label htmlFor="name">Cardholder's name</Label>
            <Input
              id="name"
              type="text"
              style={{
                border: err.errors?.subscription_name?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, subscription_name: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
              }
              value={name}
            />
            <Error style={{ bottom: '-17px' }}>
              {err.errors?.subscription_name?.[0]}
            </Error>
          </InputField>
        </TerminalBlock>

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Update" pad="8px 24px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalSubscription;
