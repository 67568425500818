import React, { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Actions,
  Block,
  BlockTitle,
  ContactBlock,
  Error,
  FlexEnd,
  HelpCenterBlock,
  HelpCenterContact,
  Input,
  InputDiv,
  InputField,
  InputTextArea,
  Label,
  Title,
  Value,
  Wrapper,
} from './helpCenter.style';
import { sendMessage } from '../../API/helpCenter';
import Button from '../../components/Button/Button';
import SettingsContainer from '../../layout/SettingsContainer';
import { useAppDispatch } from '../../store/hooks';

const HelpCenter: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [question, setQuestion] = useState<string>('');

  const dispatch = useAppDispatch();

  const [err, setErr] = useState({
    message: '',
    errors: { name: [], email: [], phone: [], message: [] },
  });

  const handleConfirm = async () => {
    const res: any = await dispatch(
      sendMessage({ name, email, phone, message: question }),
    );
    if (res.type === 'helpCenter/sendMessage/rejected')
      return setErr(res.payload);
    setName('');
    setPhone('');
    setQuestion('');
  };

  return (
    <>
      <SettingsContainer>
        <Wrapper>
          <Title>Help Center</Title>

          <Block>
            <HelpCenterBlock>
              <BlockTitle>Support</BlockTitle>
              <InputField>
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  type="text"
                  style={{
                    border: err.errors?.name[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, name: [] } })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
                  }
                  value={name}
                />
                <Error style={{ bottom: '-17px' }}>{err.errors?.name[0]}</Error>
              </InputField>
              <InputField>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  style={{
                    border: err.errors?.email[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, email: [] } })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                  value={email}
                />
                <Error style={{ bottom: '-17px' }}>
                  {err.errors?.email[0]}
                </Error>
              </InputField>
              <InputField>
                <Label htmlFor="phone">Phone number</Label>
                <InputDiv
                  style={{
                    border: err.errors?.phone[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, phone: [] } })
                  }
                >
                  <PhoneInput
                    international
                    limitMaxLength
                    value={phone}
                    id="phone"
                    onChange={(value: string) => setPhone(value || '')}
                  />
                </InputDiv>
                <Error>{err.errors?.phone[0]}</Error>
              </InputField>
              <InputField>
                <Label htmlFor="question">Your question</Label>
                <InputTextArea
                  id="question"
                  name="question"
                  rows={5}
                  style={{
                    border: err.errors?.message[0]
                      ? '1px solid #ff0000'
                      : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({ ...err, errors: { ...err.errors, message: [] } })
                  }
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    setQuestion(e.target.value)
                  }
                  value={question}
                />
                <Error style={{ bottom: '-17px' }}>
                  {err.errors?.message[0]}
                </Error>
              </InputField>
              <FlexEnd>
                <Actions>
                  <Button
                    text="Send"
                    pad="8px 24px"
                    handleButton={handleConfirm}
                  />
                </Actions>
              </FlexEnd>
            </HelpCenterBlock>
            <HelpCenterContact>
              <BlockTitle>Contacts</BlockTitle>
              <ContactBlock style={{ marginBottom: '12px' }}>
                <Label htmlFor="contactPhoneNumber">Phone number:</Label>
                <Value>+38 063 3333333</Value>
              </ContactBlock>
              <ContactBlock>
                <Label htmlFor="contactEmail">Email:</Label>
                <Value>myturbodispatch@gmail.com</Value>
              </ContactBlock>
            </HelpCenterContact>
          </Block>
        </Wrapper>
      </SettingsContainer>
    </>
  );
};

export default HelpCenter;
