import { combineReducers } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import carrierProfileSlice from './slices/carrierProfileSlice';
import contactsSlice from './slices/contactsSlice';
import dashboardSlice from './slices/dashboardSlice';
import dispatcherProfileSlice from './slices/dispatcherProfileSlice';
import driversSlice from './slices/driversSlice';
import fleetSlice from './slices/fleetSlice';
import helpCenterSlice from './slices/helpCenterSlice';
import invoiceSlice from './slices/invoiceSlice';
import loadsSlice from './slices/loadsSlice';
import reportsSlice from './slices/reportsSlice';
import settingsSlice from './slices/settingsSlice';
import trackingSlice from './slices/trackingSlice';
import tripsSlice from './slices/tripsSlice';

export const rootReducer = combineReducers({
  auth: authSlice,
  contact: contactsSlice,
  driver: driversSlice,
  fleet: fleetSlice,
  loads: loadsSlice,
  trips: tripsSlice,
  tracking: trackingSlice,
  report: reportsSlice,
  helpCenter: helpCenterSlice,
  dispatcherProfile: dispatcherProfileSlice,
  carrierProfile: carrierProfileSlice,
  settings: settingsSlice,
  dashboard: dashboardSlice,
  invoice: invoiceSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
