import React, { ChangeEvent, useEffect, useState } from 'react';

import { Check } from '../../../../assets/icons';
import {
  CheckBox,
  CheckBoxBlock,
  CheckBoxItem,
  CheckBoxSubtitle,
  CheckBoxTextBlock,
  CheckBoxTitle,
  CustomCheckBox,
  Error,
  LoadsBlock,
  LoadsFlex,
  LoadsInput,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInputTextArea,
  LoadsLabel,
  LoadsText,
  LoadsTitle,
} from '../createLoad.style';

type LoadsDetailProps = {
  handleInput: (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state?: any) => void;
  handleInputTextArea: (e: React.ChangeEvent<HTMLTextAreaElement>, setState: any, name: any, state?: any) => void;
  handleDetailCheckbox: (e: React.ChangeEvent<HTMLInputElement>, setState: any) => void;
  loadDetails: any;
  setLoadDetails: any;
  inspectionType: any;
  setInspectionType: any;
  error: any;
};

const LoadsDetail: React.FC<LoadsDetailProps> = ({
  handleInput,
  handleInputTextArea,
  handleDetailCheckbox,
  loadDetails,
  setLoadDetails,
  inspectionType,
  setInspectionType,
  error,
}) => {
  const [errLoadId, setErrLoadId] = useState<boolean>(false);
  const [errInspectionType, setErrInspectionType] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      if (error?.errors['general.load_id']?.[0]) setErrLoadId(true);
      if (error?.errors['general.inspection_type']?.[0]) setErrInspectionType(true);
    }
  }, [error]);

  return (
    <>
      <LoadsBlock>
        <LoadsTitle>Load Details</LoadsTitle>
        <LoadsFlex>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="loadID">Load ID</LoadsLabel>
            <LoadsInput
              id="loadID"
              name="loadID"
              type="text"
              style={{
                border: errLoadId ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() => setErrLoadId(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setLoadDetails, 'loadID', loadDetails)}
              value={loadDetails.loadID}
            />
            {errLoadId && <Error>{error?.errors['general.load_id']?.[0]}</Error>}
          </LoadsInputFieldHalf>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="internalLoadID">Internal Load ID</LoadsLabel>
            <LoadsInput
              id="internalLoadID"
              name="internalLoadID"
              type="text"
              style={{
                border: '1px solid #c6c7da',
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadDetails, 'internalLoadID', loadDetails)
              }
              value={loadDetails.internalLoadID}
            />
          </LoadsInputFieldHalf>
        </LoadsFlex>
        <LoadsText>Inspection Type</LoadsText>
        <CheckBoxBlock>
          <CheckBoxItem>
            <CheckBox
              id="type"
              type="radio"
              name="inspection"
              onClick={() => setErrInspectionType(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleDetailCheckbox(e, setInspectionType)}
              value={'standard'}
              checked={inspectionType === 'standard'}
            />
            <CustomCheckBox>
              <Check />
            </CustomCheckBox>
            <CheckBoxTextBlock>
              <CheckBoxTitle>Standard</CheckBoxTitle>
              <CheckBoxSubtitle>Does’t have any required steps for drivers</CheckBoxSubtitle>
            </CheckBoxTextBlock>
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              id="type"
              type="radio"
              name="inspection"
              onClick={() => setErrInspectionType(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleDetailCheckbox(e, setInspectionType)}
              value={'advanced'}
              checked={inspectionType === 'advanced'}
            />
            <CustomCheckBox>
              <Check />
            </CustomCheckBox>
            <CheckBoxTextBlock>
              <CheckBoxTitle>Advanced</CheckBoxTitle>
              <CheckBoxSubtitle>Requires the driver to take at least 6 photos at pickup and delivery</CheckBoxSubtitle>
            </CheckBoxTextBlock>
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              id="type"
              type="radio"
              name="inspection"
              onClick={() => setErrInspectionType(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleDetailCheckbox(e, setInspectionType)}
              value={'aiag'}
              checked={inspectionType === 'aiag'}
            />
            <CustomCheckBox>
              <Check />
            </CustomCheckBox>
            <CheckBoxTextBlock>
              <CheckBoxTitle>AIAG</CheckBoxTitle>
              <CheckBoxSubtitle>Used for OEM customers and requires 2 photos per damage</CheckBoxSubtitle>
            </CheckBoxTextBlock>
          </CheckBoxItem>
          {errInspectionType && <Error>{error?.errors['general.inspection_type']?.[0]}</Error>}
        </CheckBoxBlock>
        <LoadsInputField>
          <LoadsLabel htmlFor="driverInstructions">Driver instructions</LoadsLabel>
          <LoadsInputTextArea
            id="driverInstructions"
            name="driverInstructions"
            rows={3}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleInputTextArea(e, setLoadDetails, 'driverInstructions', loadDetails)
            }
            value={loadDetails.driverInstructions}
          />
        </LoadsInputField>
      </LoadsBlock>
    </>
  );
};

export default LoadsDetail;
