import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const ImageDefault = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9eaf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageName = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -1px;
  color: #09091A;
  text-transform: uppercase;
`;

export const Name = styled.p`
  color: #09091a;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  margin-left: 16px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Edit = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
`;

export const Delete = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #f30f00;
  cursor: pointer;
  margin-left: 40px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #f4f4f4;
  margin-top: 24px;
`;

export const ContactInfo = styled.div`
display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const ContactInfoText = styled.p`
font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091A;
  margin-left: 15px;
`;

export const ContactInfoTextDefault = styled.p`
font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #9292A4;
  margin-left: 15px;
`;
