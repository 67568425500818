import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export const routes = {
  carrierProfile: 'api/crm/user/carrier_profile',
  carrierInformation: 'api/crm/user/carrier_profile/carrier_information',
  cargoInsurance: 'api/crm/user/carrier_profile/cargo_insurance',
  usdot: 'api/crm/user/carrier_profile/usdot_certificate',
  w9: 'api/crm/user/carrier_profile/w9',
};

export type CarrierProfile = {
    carrier_name: string;
    carrier_address: string;
    carrier_state: string;
    carrier_city: string;
    carrier_zip: string;
    carrier_country: string;
    carrier_mc_number: string;
    carrier_contact_name: string;
    carrier_phone_numbers: string;
    carrier_fax: string;
    carrier_email: string;
    carrier_website: string;
    carrier_logo: string;
    billing_contact_name: string;
    billing_phone_numbers: string;
    billing_email: string;
    billing_terms: string;
    cargo_certificate: string;
    cargo_date: string;
    cargo_limit: string;
    cargo_deductible: string;
    cargo_auto_police_number: string;
    cargo_police_number: string;
    cargo_agent_name: string;
    cargo_agent_phone: string;
    w9: string;
    usdot_certificate: string;
};

export const getCarrierProfile = createAsyncThunk<
  CarrierProfile,
  null,
  {
    rejectValue: ErrorType;
  }
>('carrierProfile/getCarrierProfile', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.carrierProfile}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveCarrierInformation = createAsyncThunk<
  null,
  FormData,
  {
    rejectValue: ErrorType;
  }
>('carrierProfile/saveCarrierInformation', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.carrierInformation}`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveCargoInsurance = createAsyncThunk<
  null,
  FormData,
  {
    rejectValue: ErrorType;
  }
>('carrierProfile/saveCargoInsurance', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.cargoInsurance}`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveW9 = createAsyncThunk<
  null,
  FormData,
  {
    rejectValue: ErrorType;
  }
>('carrierProfile/saveW9', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.w9}`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveUsdot = createAsyncThunk<
  null,
  FormData,
  {
    rejectValue: ErrorType;
  }
>('carrierProfile/saveUsdot', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.usdot}`, data);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});
