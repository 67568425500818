import React, { ChangeEvent, useState } from 'react';

import {
  Wrapper,
  Text,
  CheckboxBlock,
  LearnMore,
  LearnMoreBold,
  InputField,
  Label,
  CheckboxLabel,
  Input,
  Bottom,
  Actions,
} from './notifications.style';
import { saveNotifications } from '../../../API/settings';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();

  const { settings } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  const [enableNotifications, setEnableNotifications] = useState<boolean>(
    settings ? settings.notification_to_brokers : false,
  );
  const [email, setEmail] = useState<string>(
    settings ? settings.notification_emails : '',
  );
  const [confirmationEmail, setConfirmationEmail] = useState<string>(
    settings ? settings.delivery_emails : '',
  );

  const handleConfirm = async () => {
    await dispatch(
      saveNotifications({
        notification_emails: email,
        delivery_emails: confirmationEmail,
        notification_to_brokers: enableNotifications,
      }),
    );
  };

  return (
    <Wrapper>
      <InputField>
        <Label htmlFor="email">Notification emails</Label>
        <Input
          id="email"
          type="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          value={email}
        />
        <Text>
          Receive pickup/delivery notifications. Enter multiple emails with
          comma.
          <LearnMore>Learn more...</LearnMore>
        </Text>
      </InputField>
      <InputField>
        <Label htmlFor="confirmationEmail">Delivery confirmation emails</Label>
        <Input
          id="confirmationEmail"
          type="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setConfirmationEmail(e.target.value)
          }
          value={confirmationEmail}
        />
        <Text>
          Receive copy of BOLs for all delivered loads. Enter multiple emails
          with comma.
        </Text>
        <LearnMore style={{ marginLeft: '0' }}>Learn more...</LearnMore>
      </InputField>
      <CheckboxBlock style={{ marginTop: '32px' }}>
        <CheckboxLabel>
          Sending pickup/delivery notifications to brokers
        </CheckboxLabel>
        <Checkbox
          title="Enable sending pickup/delivery notifications to brokers."
          checkboxName="enableNotifications"
          checked={enableNotifications}
          setChecked={() => setEnableNotifications(!enableNotifications)}
        />
        <LearnMoreBold>Learn more...</LearnMoreBold>
      </CheckboxBlock>
      <Bottom>
        <Actions>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </Actions>
      </Bottom>
    </Wrapper>
  );
};

export default Notifications;
