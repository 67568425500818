import { SVGProps } from "react"

const FleetClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 20a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      stroke="#9292A4"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 6v5.25L15 13"
      stroke="#9292A4"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FleetClock
