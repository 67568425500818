import moment from 'moment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Top,
  Title,
  Line,
  ClaimBlock,
  FlexBetween,
  LoadID,
  More,
  DropdownMenu,
  MenuBlock,
  MenuItem,
  LoadName,
  LoadsFlexColumn,
  LoadsAssigned,
  LoadsPhone,
  Block,
  BlockPhone,
  BlockColumn,
  DriverContactTitle,
  DriverContactText,
  DriverSmallTitle,
  InputTextArea,
  FilePlace,
  InputFilePlace,
  InputFile,
  FlexColumn,
  BlueButton,
  TextEmpty,
  AttachFile,
  DocumentName,
  DocumentText,
  Bottom,
  Actions,
  Cancel,
  SelectedValue,
  FieldIcon,
  Dropdown,
  Input,
  InputField,
  Label,
} from './modalClaimDriver.style';
import {
  createClaim,
  updateClaim,
  deleteClaim,
  getClaims,
} from '../../../../API/drivers';
import { getLoads } from '../../../../API/loads';
import {
  Close,
  Document,
  SelectArrow,
  ThreeDots,
} from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalContentProps = {
  close: () => void;
  deleteClaimAttachments: (id: number) => void;
  addCheck: string;
  refDriver: any;
};

const ModalClaimDriver: React.FC<ModalContentProps> = ({
  close,
  deleteClaimAttachments,
  addCheck,
  refDriver,
}) => {
  const ref = useRef<any>();
  const loadRef = useRef<any>();
  const dispatch = useAppDispatch();
  const { driverInfo, claimInfo } = useAppSelector(
    (state: { driver: any }) => state.driver,
  );

  const { loadsList } = useAppSelector((state: { loads: any }) => state.loads);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [updateClaims, setUpdateClaims] = useState<boolean>(false);
  const [showLoads, setShowLoads] = useState<boolean>(false);
  const [activeLoadValue, setActiveLoadValue] = useState<string>('');
  const [load, setLoad] = useState(
    addCheck && updateClaims
      ? {
          id: claimInfo?.load.id,
          load_id: updateClaims ? claimInfo?.load.load_id : '',
          driver: {},
          vehicles: {},
          points: {},
        }
      : claimInfo?.load,
  );
  const [notes, setNotes] = useState<string>(
    addCheck && updateClaims ? '' : claimInfo?.notes,
  );
  const [attachment, setAttachment] = useState<any[]>(
    addCheck && updateClaims ? [] : claimInfo?.attachments,
  );
  const [claimAttachment, setClaimAttachment] = useState<any[]>([]);

  const showDropdownMenu = () => setShowMenu(!showMenu);
  let formData: any = new FormData();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = event.target.files;
    const arr = Array.from(file || []);

    arr.forEach((elem: any) => {
      if (elem.size / 1024 / 1024 > 2) {
        alert(`${elem.name} is too big`);
      } else {
        setClaimAttachment((prevfiles: any) => [...prevfiles, elem]);
      }
    });
  };

  const activeList = loadsList?.filter((item: any) => {
    return item.load_id.toLowerCase().includes(activeLoadValue.toLowerCase());
  });

  const handleToSave = async () => {
    if (!updateClaims) {
      formData.append(`load_id`, load.id);
      formData.append(`notes`, notes);
      claimAttachment.forEach((elem: any) => {
        formData.append(`attachments[]`, elem);
      });

      await dispatch(createClaim([driverInfo.id, formData]));
    } else {
      formData.append(`load_id`, load.id);
      formData.append(`notes`, notes);
      claimAttachment.forEach((elem: any) => {
        formData.append(`attachments[]`, elem);
      });

      await dispatch(updateClaim([claimInfo.id, formData]));
    }

    await dispatch(getClaims(driverInfo?.id));
    close();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showLoads && loadRef.current && !loadRef.current.contains(e.target)) {
        setShowLoads(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showLoads]);

  useEffect(() => {
    dispatch(
      getLoads({
        type: 'load_id',
        sortBy: 'none',
      }),
    );
  }, []);

  useEffect(() => {
    setAttachment(claimInfo?.attachments);
  }, [claimInfo]);

  return (
    <ModalPortal>
      <Wrapper ref={refDriver}>
        {addCheck === 'view' ? (
          <Top>
            <Title>Claims</Title>
            <More onClick={showDropdownMenu}>
              <ThreeDots />

              {showMenu && (
                <DropdownMenu ref={ref}>
                  <MenuBlock>
                    <MenuItem onClick={() => setUpdateClaims(true)}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        await dispatch(deleteClaim(claimInfo.id));
                        await dispatch(getClaims(driverInfo?.id));
                        close();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuBlock>
                </DropdownMenu>
              )}
            </More>
          </Top>
        ) : (
          <Top>
            <Title>New claim</Title>
          </Top>
        )}
        <Line />

        {addCheck === 'new' || updateClaims ? (
          <ClaimBlock>
            <FlexBetween>
              <LoadID>
                Load ID:{' '}
                <SelectedValue onClick={() => setShowLoads(true)}>
                  <LoadName>{load?.load_id ? load?.load_id : ''}</LoadName>
                  <FieldIcon>
                    <SelectArrow />
                  </FieldIcon>
                </SelectedValue>
                {showLoads && (
                  <Dropdown ref={loadRef}>
                    <Input
                      type="text"
                      value={activeLoadValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setActiveLoadValue(e.target.value)
                      }
                      placeholder="Search..."
                    />
                    <div style={{ height: '80px', overflowY: 'scroll' }}>
                      {activeList?.map((elem: any) => {
                        return (
                          <InputField
                            key={elem.id}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              width: '100%',
                            }}
                            onClick={() => {
                              setLoad(elem);
                              setShowLoads(false);
                            }}
                          >
                            <Label>{elem.load_id}</Label>
                          </InputField>
                        );
                      })}
                    </div>
                  </Dropdown>
                )}
              </LoadID>
              <LoadsFlexColumn>
                {(addCheck || updateClaims) && load?.driver ? (
                  <LoadsAssigned>
                    Assigned to
                    <LoadName>
                      {load?.driver?.first_name +
                        ' ' +
                        load?.driver?.last_name}
                    </LoadName>
                  </LoadsAssigned>
                ) : (
                  <LoadsAssigned>Not assigned</LoadsAssigned>
                )}
                <LoadsPhone>{load?.driver?.phone}</LoadsPhone>
              </LoadsFlexColumn>
            </FlexBetween>

            <Line style={{ margin: '28px 0' }} />

            <FlexBetween>
              <Block>
                <DriverContactTitle>Vehicle:</DriverContactTitle>
                {(addCheck || updateClaims) && load?.vehicles ? (
                  <DriverContactText style={{ marginLeft: '5px' }}>
                    {load.vehicles[0].year +
                      ' ' +
                      load.vehicles[0].make +
                      ' ' +
                      load.vehicles[0].model}
                  </DriverContactText>
                ) : (
                  <DriverContactText style={{ marginLeft: '5px' }}>
                    No vehicle
                  </DriverContactText>
                )}
              </Block>
              <Block>
                <DriverContactTitle>Brocker:</DriverContactTitle>
                <DriverContactText style={{ marginLeft: '5px' }}>
                  Anthony Smith
                </DriverContactText>
              </Block>
              <Block>
                <DriverContactTitle>VIN #:</DriverContactTitle>
                {(addCheck || updateClaims) && load?.vehicles ? (
                  <DriverContactText style={{ marginLeft: '5px' }}>
                    {load.vehicles[0].vin}
                  </DriverContactText>
                ) : (
                  <DriverContactText style={{ marginLeft: '5px' }}>
                    No VIN
                  </DriverContactText>
                )}
              </Block>
            </FlexBetween>

            <FlexBetween style={{ margin: '28px 0' }}>
              <BlockColumn>
                <DriverSmallTitle style={{ marginBottom: '8px' }}>
                  ORIGIN
                </DriverSmallTitle>
                {(addCheck || updateClaims) && load?.points ? (
                  <>
                    <DriverContactTitle style={{ lineHeight: '25px' }}>
                      {load?.points[0].business_name}
                      <br />
                      {load?.points[0].street_address}
                      <br />
                      {load?.points[0].state +
                        ', ' +
                        load?.points[0].zip}
                      <br />
                      {moment(load?.points[0]?.date).format(
                        'MM/DD/yyyy',
                      )}
                    </DriverContactTitle>
                    <BlockPhone>
                      <DriverContactTitle style={{ lineHeight: '25px' }}>
                        Phone:
                      </DriverContactTitle>
                      <DriverContactText
                        style={{ marginLeft: '5px', lineHeight: '25px' }}
                      >
                        {load?.points[0].phone
                          ? load?.points[0].phone
                          : '-'}
                      </DriverContactText>
                    </BlockPhone>
                  </>
                ) : (
                  <></>
                )}
              </BlockColumn>
              <BlockColumn>
                <DriverSmallTitle style={{ marginBottom: '8px' }}>
                  DESTINATION
                </DriverSmallTitle>
                {(addCheck || updateClaims) && load?.points ? (
                  <>
                    {' '}
                    <DriverContactTitle style={{ lineHeight: '25px' }}>
                      {load?.points[1].business_name}
                      <br />
                      {load?.points[1].street_address}
                      <br />
                      {load?.points[1].state +
                        ', ' +
                        load?.points[1].zip}
                      <br />
                    </DriverContactTitle>
                    <BlockPhone>
                      <DriverContactTitle style={{ lineHeight: '25px' }}>
                        Phone:
                      </DriverContactTitle>
                      <DriverContactText
                        style={{ marginLeft: '5px', lineHeight: '25px' }}
                      >
                        {load?.points[1].phone
                          ? load?.points[1].phone
                          : '-'}
                      </DriverContactText>
                    </BlockPhone>
                  </>
                ) : (
                  <></>
                )}
              </BlockColumn>
              <BlockColumn>
                <DriverSmallTitle style={{ marginBottom: '8px' }}>
                  SHIPPER/CUSTOMER
                </DriverSmallTitle>
                {(addCheck || updateClaims) && load?.points ? (
                  <>
                    <DriverContactTitle style={{ lineHeight: '25px' }}>
                      {load?.customer.business_name}
                    </DriverContactTitle>
                    <BlockPhone>
                      <DriverContactTitle style={{ lineHeight: '25px' }}>
                        Phone:
                      </DriverContactTitle>
                      <DriverContactText
                        style={{ marginLeft: '5px', lineHeight: '25px' }}
                      >
                        {load?.customer.phone
                          ? load?.customer.phone
                          : '-'}
                      </DriverContactText>
                    </BlockPhone>
                  </>
                ) : (
                  <></>
                )}
              </BlockColumn>
            </FlexBetween>

            <DriverSmallTitle style={{ marginBottom: '8px' }}>
              Notes
            </DriverSmallTitle>
            <InputTextArea
              id="notes"
              rows={4}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setNotes(e.target.value)
              }
              value={notes}
            />

            <DriverSmallTitle
              style={{ marginTop: '28px', marginBottom: '8px' }}
            >
              Attachments
            </DriverSmallTitle>
            <FilePlace>
              <InputFilePlace>
                <InputFile
                  type="file"
                  multiple
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e)
                  }
                />
                <FlexColumn>
                  <BlueButton>Upload attachments</BlueButton>
                  <TextEmpty>or drag and drop files</TextEmpty>
                </FlexColumn>
              </InputFilePlace>
            </FilePlace>
            {claimAttachment?.map((elem: any) => {
              return (
                <AttachFile key={elem}>
                  <DocumentName>
                    <Document />
                    <DocumentText>{elem.name}</DocumentText>
                  </DocumentName>
                </AttachFile>
              );
            })}
            {attachment?.map((elem: any) => {
              return (
                <AttachFile key={elem.id}>
                  <DocumentName>
                    <Document />
                    <DocumentText>
                      {elem?.id ? `document(${elem.id})` : elem.name}
                    </DocumentText>
                  </DocumentName>
                  <div
                    onClick={() =>
                      deleteClaimAttachments(elem.id ? elem.id : '')
                    }
                  >
                    <Close />
                  </div>
                </AttachFile>
              );
            })}
          </ClaimBlock>
        ) : (
          <ClaimBlock>
            <FlexBetween>
              <LoadID>
                Load ID:<LoadName>{claimInfo?.load?.load_id}</LoadName>
              </LoadID>
              <LoadsFlexColumn>
                {claimInfo?.load?.driver ? (
                  <LoadsAssigned>
                    Assigned to
                    <LoadName>
                      {claimInfo?.load?.driver?.first_name +
                        ' ' +
                        claimInfo?.load?.driver?.last_name}
                    </LoadName>
                  </LoadsAssigned>
                ) : (
                  <LoadsAssigned>Not assigned</LoadsAssigned>
                )}
                <LoadsPhone>{claimInfo?.load?.driver?.phone}</LoadsPhone>
              </LoadsFlexColumn>
            </FlexBetween>

            <Line style={{ margin: '28px 0' }} />

            <FlexBetween>
              <Block>
                <DriverContactTitle>Vehicle:</DriverContactTitle>
                <DriverContactText style={{ marginLeft: '5px' }}>
                  {claimInfo?.load.vehicles[0].year +
                    ' ' +
                    claimInfo?.load.vehicles[0].make +
                    ' ' +
                    claimInfo?.load.vehicles[0].model}
                </DriverContactText>
              </Block>
              <Block>
                <DriverContactTitle>Brocker:</DriverContactTitle>
                <DriverContactText style={{ marginLeft: '5px' }}>
                  Anthony Smith
                </DriverContactText>
              </Block>
              <Block>
                <DriverContactTitle>VIN #:</DriverContactTitle>
                <DriverContactText style={{ marginLeft: '5px' }}>
                  {claimInfo?.load.vehicles[0].vin}
                </DriverContactText>
              </Block>
            </FlexBetween>

            <FlexBetween style={{ margin: '28px 0' }}>
              <BlockColumn>
                <DriverSmallTitle style={{ marginBottom: '8px' }}>
                  ORIGIN
                </DriverSmallTitle>
                <DriverContactTitle style={{ lineHeight: '25px' }}>
                  {claimInfo?.load?.points[0].business_name}
                  <br />
                  {claimInfo?.load?.points[0].street_address}
                  <br />
                  {claimInfo?.load?.points[0].state +
                    ', ' +
                    claimInfo?.load?.points[0].zip}
                  <br />
                  {moment(claimInfo?.load?.points[0]?.date).format(
                    'MM/DD/yyyy',
                  )}
                </DriverContactTitle>
                <BlockPhone>
                  <DriverContactTitle style={{ lineHeight: '25px' }}>
                    Phone:
                  </DriverContactTitle>
                  <DriverContactText
                    style={{ marginLeft: '5px', lineHeight: '25px' }}
                  >
                    {claimInfo?.load?.points[0].phone
                      ? claimInfo?.load?.points[0].phone
                      : '-'}
                  </DriverContactText>
                </BlockPhone>
              </BlockColumn>
              <BlockColumn>
                <DriverSmallTitle style={{ marginBottom: '8px' }}>
                  DESTINATION
                </DriverSmallTitle>
                <DriverContactTitle style={{ lineHeight: '25px' }}>
                  {claimInfo?.load?.points[1].business_name}
                  <br />
                  {claimInfo?.load?.points[1].street_address}
                  <br />
                  {claimInfo?.load?.points[1].state +
                    ', ' +
                    claimInfo?.load?.points[1].zip}
                  <br />
                </DriverContactTitle>
                <BlockPhone>
                  <DriverContactTitle style={{ lineHeight: '25px' }}>
                    Phone:
                  </DriverContactTitle>
                  <DriverContactText
                    style={{ marginLeft: '5px', lineHeight: '25px' }}
                  >
                    {claimInfo?.load?.points[1].phone
                      ? claimInfo?.load?.points[1].phone
                      : '-'}
                  </DriverContactText>
                </BlockPhone>
              </BlockColumn>
              <BlockColumn>
                <DriverSmallTitle style={{ marginBottom: '8px' }}>
                  SHIPPER/CUSTOMER
                </DriverSmallTitle>
                <DriverContactTitle style={{ lineHeight: '25px' }}>
                  {claimInfo?.load?.customer.business_name}
                </DriverContactTitle>
                <BlockPhone>
                  <DriverContactTitle style={{ lineHeight: '25px' }}>
                    Phone:
                  </DriverContactTitle>
                  <DriverContactText
                    style={{ marginLeft: '5px', lineHeight: '25px' }}
                  >
                    {claimInfo?.load?.customer.phone
                      ? claimInfo?.load?.customer.phone
                      : '-'}
                  </DriverContactText>
                </BlockPhone>
              </BlockColumn>
            </FlexBetween>

            <DriverSmallTitle style={{ marginBottom: '8px' }}>
              Notes
            </DriverSmallTitle>
            <DriverContactTitle>
              {claimInfo?.notes ? claimInfo?.notes : 'No notes'}
            </DriverContactTitle>

            <DriverSmallTitle
              style={{ marginTop: '28px', marginBottom: '8px' }}
            >
              Attachments
            </DriverSmallTitle>

            {claimInfo?.attachments?.length ? (
              claimInfo?.attachments?.map((elem: any) => {
                return (
                  <DocumentName key={elem.id} target="_blank" href={elem?.path}>
                    <Document />
                    <DocumentText>document({elem.id})</DocumentText>
                  </DocumentName>
                );
              })
            ) : (
              <DocumentName>
                <DocumentText>No attachments</DocumentText>
              </DocumentName>
            )}
          </ClaimBlock>
        )}

        <Line />
        <Bottom>
          {addCheck === 'new' || updateClaims ? (
            <Actions>
              <Cancel onClick={close}>Cancel</Cancel>
              <Button text="Save" pad="12px 26px" handleButton={handleToSave} />
            </Actions>
          ) : (
            <Actions>
              <Button text="Close" pad="12px 26px" handleButton={close} />
            </Actions>
          )}
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalClaimDriver;
