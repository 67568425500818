import React, { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Actions,
  Bottom,
  Cancel,
  Error,
  FieldIcon,
  Input,
  InputDiv,
  InputField,
  Label,
  Line,
  Opt,
  Select,
  SelectedValue,
  TerminalBlock,
  TopText,
  Wrapper,
} from './modalTerminal.style';
import americaStates from '../../../americaStates.json';
import { getTerminal, saveTerminal } from '../../../API/settings';
import { SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  addCheck: string;
  item: number;
  refTerminal: any;
};

const ModalTerminal: React.FC<ModalContentProps> = ({ close, addCheck, item, refTerminal }) => {
  const dispatch = useAppDispatch();

  const { terminal } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  const [terminalName, setTerminalName] = useState<string>(addCheck === 'edit' ? terminal[item]?.terminal_name : '');
  const [city, setCity] = useState<string>(addCheck === 'edit' ? terminal[item]?.city : '');
  const [state, setState] = useState<string>(addCheck === 'edit' ? terminal[item]?.state : americaStates[0]);
  const [zip, setZip] = useState<string>(addCheck === 'edit' ? terminal[item]?.zip : '');
  const [contactName, setContactName] = useState<string>(addCheck === 'edit' ? terminal[item]?.contact_name : '');
  const [phone, setPhone] = useState<string>(addCheck === 'edit' ? terminal[item]?.phone : '');

  const [err, setErr] = useState({
    message: '',
    errors: {
      terminal_name: [],
      city: [],
      zip: [],
      contact_name: [],
      phone: [],
    },
  });

  const changeSelectState = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
  ) => {
    setState(e.target.value);
  };

  const handleConfirm = async () => {
    const res: any = await dispatch(
      saveTerminal({
        terminal_name: terminalName,
        city: city,
        state: state,
        zip: zip,
        contact_name: contactName,
        phone: phone,
      }),
    );
    if (res.type === 'settings/terminal/rejected') return setErr(res.payload);
    close();
    await dispatch(getTerminal(null));
  };

  return (
    <ModalPortal>
      <Wrapper ref={refTerminal}>
        <TopText>Add terminal</TopText>
        <Line />

        <TerminalBlock>
          <InputField>
            <Label htmlFor="terminalName">Terminal name</Label>
            <Input
              id="terminalName"
              type="text"
              style={{
                border: err.errors?.terminal_name?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, terminal_name: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setTerminalName(e.target.value.replace(/[^A-Za-z\s]/gi, ""))
              }
              value={terminalName}
            />
            <Error style={{ bottom: '-17px' }}>
              {err.errors?.terminal_name?.[0]}
            </Error>
          </InputField>
          <InputField>
            <Label htmlFor="city">City</Label>
            <Input
              id="city"
              type="text"
              style={{
                border: err.errors?.city?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, city: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCity(e.target.value.replace(/[^A-Za-z\s]/gi, ""))
              }
              value={city}
            />
            <Error style={{ bottom: '-17px' }}>{err.errors?.city?.[0]}</Error>
          </InputField>
          <InputField>
            <Label htmlFor="state">State</Label>
            <SelectedValue>{state}</SelectedValue>
            <FieldIcon>
              <SelectArrow />
            </FieldIcon>
            <Select
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                changeSelectState(e, setState)
              }
            >
              <Opt hidden></Opt>
              {americaStates?.map((item: any) => (
                <Opt key={item} value={item}>
                  {item}
                </Opt>
              ))}
            </Select>
          </InputField>
          <InputField>
            <Label htmlFor="zip">Zip</Label>
            <Input
              id="zip"
              type="number"
              style={{
                border: err.errors?.zip?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, zip: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setZip(e.target.value)
              }
              value={zip}
            />
            <Error style={{ bottom: '-17px' }}>{err.errors?.zip?.[0]}</Error>
          </InputField>
          <InputField>
            <Label htmlFor="contactName">Contact name</Label>
            <Input
              id="contactName"
              type="text"
              style={{
                border: err.errors?.contact_name?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, contact_name: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setContactName(e.target.value.replace(/[^A-Za-z\s]/gi, ""))
              }
              value={contactName}
            />
            <Error style={{ bottom: '-17px' }}>
              {err.errors?.contact_name?.[0]}
            </Error>
          </InputField>
          <InputField>
            <Label htmlFor="phone">Phone</Label>
            <InputDiv
              style={{
                border: err.errors?.phone[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({ ...err, errors: { ...err.errors, phone: [] } })
              }
            >
              <PhoneInput
                international
                limitMaxLength
                value={phone}
                id="phone"
                onChange={(value: string) => setPhone(value || '')}
              />
            </InputDiv>
            <Error style={{ bottom: '-17px' }}>{err.errors?.phone?.[0]}</Error>
          </InputField>
        </TerminalBlock>

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="8px 24px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalTerminal;
