import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Block = styled.div`
  width: 49%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #000;
  margin-bottom: 12px;
`;