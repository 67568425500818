import { SVGProps } from "react"

const Burger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#09091A" d="M22 11v2H2v-2zM22 17v2H2v-2zM22 5v2H2V5z" />
  </svg>
)

export default Burger
