import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import CreateLoad from './components/Loads/CreateLoad/CreateLoad';
import Auth from './containers/Auth/Auth';
import CarrierProfile from './containers/CarrierProfile/CarrierProfile';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import ConfirmReset from './containers/ConfirmReset/ConfirmReset';
import Contacts from './containers/Contacts/Contacts';
import ImportContacts from './containers/Contacts/ImportContacts/ImportContacts';
import Dashboard from './containers/Dashboard/Dashboard';
import DashboardItem from './containers/Dashboard/DashboardItem/DashboardItem';
import DispatcherProfile from './containers/DispatcherProfile/DispatcherProfile';
import DriverItem from './containers/Drivers/DriverItem/DriverItem';
import Drivers from './containers/Drivers/Drivers';
import FinishChangeEmail from './containers/FinishChangeEmail/FinishChangeEmail';
import Fleet from './containers/Fleet/Fleet';
import Trailer from './containers/Fleet/FleetItem/TrailerPage';
import Truck from './containers/Fleet/FleetItem/TruckPage';
import HelpCenter from './containers/HelpCenter/HelpCenter';
import ImportLoads from './containers/Loads/ImportLoads/ImportLoads';
import LoadPdf from './containers/Loads/LoadPdf/LoadPdf';
import Loads from './containers/Loads/Loads';
import LoadsItem from './containers/Loads/LoadsItem/LoadsItem';
import ReportItem from './containers/Reports/ReportItem/ReportItem';
import Reports from './containers/Reports/Reports';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import Settings from './containers/Settings/Settings';
import PrivacyPolice from './containers/TermPolicyPage/PrivacyPolicy';
import Terms from './containers/TermPolicyPage/Terms';
import Tracking from './containers/Tracking/Tracking';
import TripItem from './containers/Trips/TripItem/TripItem';
import Trips from './containers/Trips/Trips';
import ProtectedRoute from './ProtectedRoute';

const Routing: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={'/'} element={<Auth />} />
        <Route path={'/change-password'} element={<ChangePassword />} />
        <Route path={'/confirm-password'} element={<ConfirmReset />} />
        <Route path={'/reset-password'} element={<ResetPassword />} />
        <Route path={'/terms'} element={<Terms />} />
        <Route path={'/privacy-policy'} element={<PrivacyPolice />} />
        <Route
          path={'/finish-change-email'}
          element={
            <ProtectedRoute>
              <FinishChangeEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/dashboard'}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/dashboard/loads'}
          element={
            <ProtectedRoute>
              <DashboardItem />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/contacts'}
          element={
            <ProtectedRoute>
              <Contacts />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/contacts/download'}
          element={
            <ProtectedRoute>
              <ImportContacts />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/drivers'}
          element={
            <ProtectedRoute>
              <Drivers />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/drivers/:id'}
          element={
            <ProtectedRoute>
              <DriverItem />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/fleet'}
          element={
            <ProtectedRoute>
              <Fleet />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/fleet/truck/:id'}
          element={
            <ProtectedRoute>
              <Truck />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/fleet/trailer/:id'}
          element={
            <ProtectedRoute>
              <Trailer />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/loads'}
          element={
            <ProtectedRoute>
              <Loads />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/loads/download'}
          element={
            <ProtectedRoute>
              <ImportLoads />
            </ProtectedRoute>
          }
        />
        <Route path={'/public/loads/:load_uuid'} element={<LoadPdf />} />
        <Route
          path={'/loads/create'}
          element={
            <ProtectedRoute>
              <CreateLoad />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/loads/:id'}
          element={
            <ProtectedRoute>
              <LoadsItem />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/trips'}
          element={
            <ProtectedRoute>
              <Trips />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/trips/:id'}
          element={
            <ProtectedRoute>
              <TripItem />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/tracking'}
          element={
            <ProtectedRoute>
              <Tracking />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/reports'}
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/report'}
          element={
            <ProtectedRoute>
              <ReportItem />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/settings'}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/carrier-profile'}
          element={
            <ProtectedRoute>
              <CarrierProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/dispatcher-profile'}
          element={
            <ProtectedRoute>
              <DispatcherProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/help-center'}
          element={
            <ProtectedRoute>
              <HelpCenter />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default Routing;
