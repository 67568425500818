import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { addContact, Contact, ContactItem, getContacts, importContact, updateContact } from '../../API/contacts';

type InitialStateType = {
  contactsList: Contact;
  loading: boolean;
  error: ErrorType | null;
  contactInfo: ContactItem;
};

const initialState: InitialStateType = {
  contactsList: [],
  loading: false,
  error: null,
  contactInfo: {
    name: '',
    city: '',
    address: '',
    contact_name: '',
    email: '',
    phone: '',
    notes: '',
    fax: '',
    state: '',
    zip: '',
  },
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    showContactInfo: (state, action) => {
      state.contactInfo = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.loading = false;
      state.contactsList = action.payload;
    });
    builder.addCase(getContacts.pending, state => {
      state.loading = true;
    });
    builder.addCase(getContacts.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(addContact.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateContact.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(importContact.pending, state => {
      state.loading = true;
    });
    builder.addCase(importContact.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { showContactInfo } = contactsSlice.actions;

export default contactsSlice.reducer;
