import React, { useEffect, useState } from 'react';

import {
  Wrapper,
  TrackingBlock,
  ClientBlock,
  ClientItem,
} from './tracking.style';
import { getActiveDrivers } from '../../API/tracking';
import ActiveDriversList from '../../components/Tracking/DriverList/DriverList';
import TrackingMap from '../../components/Tracking/TrackingMap/TrackingMap';
import Container from '../../layout/Container';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const Tracking: React.FC = () => {
  const dispatch = useAppDispatch();
  const [actionList, setActionList] = useState<boolean>(false);

  const { activeDriverList, loading } = useAppSelector(
    (state: { tracking: any }) => state.tracking,
  );

  useEffect(() => {
    dispatch(getActiveDrivers(null));
  }, []);

  if (loading) return <Preloader />;

  return (
    <>
      <Container>
        <Wrapper>
          <TrackingBlock>
            <ClientBlock style={{  minWidth: actionList ? "100px" : "200px", width: actionList ? '6%' : '22%' }}>
              <ActiveDriversList
                activeDriverList={activeDriverList}
                actionList={actionList}
                setActionList={setActionList}
              />
            </ClientBlock>
            <ClientItem style={{ width: '100%' }}>
              <TrackingMap activeDriverList={activeDriverList} />
            </ClientItem>
          </TrackingBlock>
        </Wrapper>
      </Container>
    </>
  );
};

export default Tracking;
