import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-left: 32px;
  margin-top: 60px;

  @media screen and (max-width: 820px) {
    margin-left: 0;
    margin-bottom: 24px;
  }
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Block = styled.div`
  width: 40%;
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1120px) {
    width: 75%;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlockTitle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  margin-bottom: 16px;
`;

export const Name = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 32px;
`;

export const Email = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #0e71eb;
  margin-bottom: 32px;
`;

export const Button = styled.p`
  display: inline-block;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  padding: 12px 24px;
  text-align: center;
  background: #616172;
  border-radius: 4px;
  cursor: pointer;
`;

export const Edit = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
`;
