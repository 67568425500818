import { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Wrapper,
  TopText,
  Line,
  Info,
  Image,
  ImageWrapper,
  ImagePlace,
  ImageDefault,
  ImageName,
  EditIcon,
  ImageEmpty,
  AddPhoto,
  InfoBlock,
  InputField,
  InputFieldHalf,
  InputDiv,
  InputLabel,
  Required,
  Input,
  SelectedValue,
  FieldIcon,
  Select,
  Opt,
  TextArea,
  Block,
  Bottom,
  ShowAddButton,
  Actions,
  Cancel,
  Error,
  ModalBlock,
} from './modalContact.style';
import americaStates from '../../../americaStates.json';
import { addContact, getContacts, updateContact } from '../../../API/contacts';
import { Camera, EditDriverImage, SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showContactInfo } from '../../../store/slices/contactsSlice';
import ModalPortal from '../ModalPortal';

import 'react-phone-number-input/style.css';

type ModalContentProps = {
  close: () => void;
  text: string;
  addCheck?: boolean;
  contactId?: number;
  refContact: any;
};

const ModalContact: React.FC<ModalContentProps> = ({ addCheck, close, text, contactId, refContact }) => {
  const [err, setErr] = useState({ message: '', errors: { name: [] } });

  const { contactInfo } = useAppSelector((state: { contact: any }) => state.contact);
  const [showAddInfo, setShowAddInfo] = useState<boolean>(false);
  const [name, setName] = useState<string>(addCheck ? '' : contactInfo.name);
  const [address, setAddress] = useState<string>(addCheck ? '' : contactInfo.address ? contactInfo.address : '');
  const [city, setCity] = useState<string>(addCheck ? '' : contactInfo.city);
  const [state, setStatee] = useState<string>(
    addCheck ? americaStates[0] : contactInfo.state ? contactInfo.state : americaStates[0]
  );
  const [zip, setZip] = useState<string>(addCheck ? '' : contactInfo.zip ? contactInfo.zip : '');
  const [contactName, setContactName] = useState<string>(
    addCheck ? '' : contactInfo.contact_name ? contactInfo.contact_name : ''
  );
  const [phone, setPhone] = useState<string>(addCheck ? '' : contactInfo.phone ? contactInfo.phone : '');
  const [email, setEmail] = useState<string>(addCheck ? '' : contactInfo.email ? contactInfo.email : '');
  const [fax, setFax] = useState<string>(addCheck ? '' : contactInfo.fax ? contactInfo.fax : '');
  const [notes, setNotes] = useState<string>(addCheck ? '' : contactInfo.notes ? contactInfo.notes : '');

  const showInfo = () => setShowAddInfo(!showAddInfo);
  const dispatch = useAppDispatch();

  const [image, setImage] = useState(addCheck ? '' : contactInfo.image?.name);
  const [imageFile, setImageFile] = useState<File>(addCheck ? '' : contactInfo.image);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any) => setState(e.target.value);

  const handleInputNotes = (e: React.ChangeEvent<HTMLTextAreaElement>, setState: any) => setState(e.target.value);

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any) => {
    setState(e.target.value);
  };

  const formdata: any = new FormData();

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let file: File = (target.files as FileList)[0];
    if (file.size / 1024 / 1024 > 2) {
      alert('File is too big!');
    } else {
      const base64: any = await convertBase64(file);
      setImage(base64);
      setImageFile(file);
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleToSave = async () => {
    if (addCheck) {
      formdata.append('name', name);
      formdata.append('city', city);
      formdata.append('address', address);
      formdata.append('contact_name', contactName);
      formdata.append('email', email);
      formdata.append('phone', phone);
      formdata.append('notes', notes);
      formdata.append('fax', fax);
      formdata.append('zip', zip);
      formdata.append('state', state);
      formdata.append('image', imageFile);

      const res: any = await dispatch(addContact(formdata));
      if (res.type === 'contacts/addContact/rejected') return setErr(res.payload);
    } else {
      formdata.append('name', name);
      city && formdata.append('city', city);
      address && formdata.append('address', address);
      contactName && formdata.append('contact_name', contactName);
      email && formdata.append('email', email);
      phone && formdata.append('phone', phone);
      notes && formdata.append('notes', notes);
      fax && formdata.append('fax', fax);
      zip && formdata.append('zip', zip);
      state && formdata.append('state', state);
      imageFile !== contactInfo.image && formdata.append('image', imageFile);

      const res: any = await dispatch(updateContact([formdata, contactId || 0]));
      if (res.type === 'contacts/updateContact/rejected') return setErr(res.payload);
      await dispatch(
        showContactInfo({
          id: contactId,
          name,
          city,
          address,
          contact_name: contactName,
          email,
          phone,
          notes,
          fax,
          state,
          zip,
          image: imageFile !== contactInfo.image ? image : imageFile,
        })
      );
    }
    close();
    await dispatch(getContacts(null));
  };

  return (
    <ModalPortal>
      <Wrapper ref={refContact}>
        <TopText>{text}</TopText>

        <ModalBlock>
          <Info>
            {addCheck ? (
              <ImageEmpty>
                <AddPhoto type="file" accept=".png, .jpg, .jpeg" onChange={handleChange} /> <Camera />
                {image && <ImagePlace src={image} />}
              </ImageEmpty>
            ) : (
              <ImageWrapper>
                <AddPhoto type="file" accept=".png, .jpg, .jpeg" onChange={handleChange} />
                {image && <ImagePlace style={{ zIndex: 1 }} src={image} />}
                {contactInfo.image ? (
                  <Image src={contactInfo.image} />
                ) : (
                  <ImageDefault>
                    <ImageName>
                      {contactInfo.name[0]} {contactInfo.name[1]}
                    </ImageName>
                  </ImageDefault>
                )}
                <EditIcon>
                  <EditDriverImage />
                </EditIcon>
              </ImageWrapper>
            )}
            <InfoBlock>
              <InputField style={{ marginBottom: '8px' }}>
                <InputLabel htmlFor="name">
                  Name <Required>(required)</Required>
                </InputLabel>
                <Input
                  id="name"
                  type="text"
                  style={{
                    border: err.errors?.name[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                  }}
                  onFocus={() => setErr({ ...err, errors: { name: [] } })}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value.replace(/[^A-Za-z]/gi, ''))}
                  value={name}
                />
                <Error>{err.errors?.name[0]}</Error>
              </InputField>
              <InputField>
                <InputLabel htmlFor="address">Address</InputLabel>
                <Input
                  id="address"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setAddress)}
                  value={address}
                />
              </InputField>
              <InputField>
                <InputLabel htmlFor="city">City</InputLabel>
                <Input
                  id="city"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setCity(e.target.value.replace(/[^A-Za-z\s]/gi, ''))}
                  value={city}
                />
              </InputField>
              <Block>
                <InputFieldHalf>
                  <InputLabel htmlFor="state">State</InputLabel>
                  <SelectedValue>{state}</SelectedValue>
                  <FieldIcon>
                    <SelectArrow />
                  </FieldIcon>
                  <Select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, setStatee)}>
                    <Opt hidden></Opt>
                    {americaStates?.map((item: any) => (
                      <Opt key={item} value={item}>
                        {item}
                      </Opt>
                    ))}
                  </Select>
                </InputFieldHalf>
                <InputFieldHalf>
                  <InputLabel htmlFor="zip">Zip</InputLabel>
                  <Input
                    id="zip"
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setZip)}
                    value={zip}
                  />
                </InputFieldHalf>
              </Block>

              {showAddInfo && (
                <>
                  <Line style={{ margin: '20px auto' }} />
                  <InputField>
                    <InputLabel htmlFor="contactName">Contact name</InputLabel>
                    <Input
                      id="contactName"
                      type="text"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setContactName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
                      }
                      value={contactName}
                    />
                  </InputField>
                  <Block>
                    <InputFieldHalf>
                      <InputLabel htmlFor="phone">Phone</InputLabel>
                      <InputDiv>
                        <PhoneInput
                          international
                          limitMaxLength
                          value={phone}
                          onChange={(value) => setPhone(value || '')}
                        />
                      </InputDiv>
                    </InputFieldHalf>
                    <InputFieldHalf>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        id="email"
                        type="text"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setEmail)}
                        value={email}
                      />
                    </InputFieldHalf>
                  </Block>
                  <InputField>
                    <InputLabel htmlFor="fax">Fax</InputLabel>
                    <Input
                      id="fax"
                      type="number"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setFax)}
                      value={fax}
                    />
                  </InputField>
                  <InputField>
                    <InputLabel htmlFor="notes">Notes</InputLabel>
                    <TextArea
                      id="notes"
                      rows={3}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleInputNotes(e, setNotes)}
                      value={notes}
                    />
                  </InputField>
                </>
              )}
            </InfoBlock>
          </Info>
        </ModalBlock>

        <Bottom>
          <ShowAddButton onClick={showInfo}>
            {showAddInfo ? 'Hide additional info' : 'Show additional info'}
          </ShowAddButton>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleToSave} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalContact;
