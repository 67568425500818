import React, { useEffect, useRef, useState } from 'react';

import { Wrapper, Plan, Name, Text, GetStarted } from './integrations.style';
import ModalIntegrations from '../../../layout/Modal/ModalIntegrations/ModalIntegrations';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';

const Integrations: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const refIntegration = useRef<any>(); 

  const openModal = () => setOpen(true);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open])

  useOnClickOutside(refIntegration, () => setOpen(false));

  return (
    <Wrapper>
      <Plan>
        <Name>QuickBooks Online</Name>
        <Text>
          QuickBooks Online integration allows the followings:
          <br />
          - Import your contacts from QuickBooks Online
          <br />
          - Send invoices automatically from Super Dispatch dashboard to
          QuickBooks Online.
          <br />
          -Update Received Payments in Quickbooks Online
          <br />
          <br /> 
          Please note, the supported versions include Quickbooks Online,
          Pro, Premier and Enterprise Editions. This includes the US and
          Canadian Editions.
        </Text>
        <GetStarted>Get started</GetStarted>
      </Plan>
      <Plan>
        <Name>QuickBooks Desktop</Name>
        <Text>
          QuickBooks Desktop integration allows the followings:<br />
          - Import and update your contacts from QuickBooks Desktop<br />
          - Send invoices automatically from Super Dispatch dashboard to QuickBooks Desktop.<br /> 
          -Update Received Payments in Quickbooks Desktop<br />
          -Import your Service items from QuickBooks Desktop to Super Dispatch Dashboard
        </Text>
        <GetStarted onClick={openModal}>Get started</GetStarted>
      </Plan>

      {open && <ModalIntegrations refIntegration={refIntegration} close={() => setOpen(false)} />}
    </Wrapper>
  );
};

export default Integrations;
