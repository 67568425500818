import { Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import BarChart from './BarChart/BarChart';
import DelayedBarChart from './BarChart/DelayedBarChart';
import {
  Wrapper,
  Top,
  Title,
  Count,
  Value,
  Block,
  ChartBlock,
  Label,
  IconBlock,
  Icon,
  IconItem,
  DashboardSvg,
  IconText,
  IconDesc,
  StatisticBlock,
  StatisticItem,
  BarChartBlock,
  StatisticDropDown,
  DropdownMenu,
  MenuBlock,
  MenuItem,
} from './dashboard.style';
import {
  getSubscriptionInfo,
  resumeSubscription,
  SubscriptionStatusType,
  updateSubscriptionInfo,
} from '../../apis/subscription';
import {
  DashboardArrowDown,
  DashboardDelayedDelivers,
  DashboardDelayedPickups,
  DashboardToDeliver,
} from '../../assets/icons';
import Container from '../../layout/Container';
import AlertModal from '../../layout/Modal/ModalLoads/AlertModal/AlertModal';
import { useAppDispatch } from '../../store/hooks';
import { showLoadsTab } from '../../store/slices/dashboardSlice';
import Preloader from '../Preloader/Preloader';

const Dashboard: React.FC = () => {
  const refDelayed = useRef<any>();
  const refToday = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showMenuToday, setShowMenuToday] = useState<boolean>(false);
  const [showMenuDelayed, setShowMenuDelayed] = useState<boolean>(false);

  const { isLoading, data } = useQuery('subscriptionInfo', () => getSubscriptionInfo());

  const subscriptionStatus = data?.status || SubscriptionStatusType.inactive;

  const isActiveSubscription = [
    SubscriptionStatusType.active,
    SubscriptionStatusType.gracePeriod,
    SubscriptionStatusType.onTrial,
  ].includes(subscriptionStatus);

  const [valueToday, setValueToday] = useState<string>('This month');
  const [valueDelayed, setValueDelayed] = useState<string>('This month');

  const showDropdownMenuToday = () => setShowMenuToday(!showMenuToday);
  const showDropdownMenuDelayed = () => setShowMenuDelayed(!showMenuDelayed);

  const navigateToDashboardLoads = (tab: string) => {
    navigate('/dashboard/loads');
    dispatch(showLoadsTab(tab));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showMenuToday && refToday.current && !refToday.current.contains(e.target)) {
        setShowMenuToday(false);
      }
      if (showMenuDelayed && refDelayed.current && !refDelayed.current.contains(e.target)) {
        setShowMenuDelayed(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenuToday, showMenuDelayed]);

  const handleResumeSubscription = () => {
    updateSubscriptionInfo().then((res) => {
      window.open(res.url, '_blank');
    });
  };

  const modalContent = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (!isActiveSubscription) {
      return (
        <AlertModal
          title="Your subscription has expired."
          submitButtonLabel="New Subscription."
          onSuccess={handleResumeSubscription}
        >
          Please renew your subscription
        </AlertModal>
      );
    }

    return null;
  };

  return (
    <>
      {modalContent()}
      <Container>
        <Wrapper>
          <Top>
            <Title>Dashboard</Title>
            <Tooltip title="Press to view orders" onClick={() => navigateToDashboardLoads('To Pick Up')}>
              <Count>
                Aging Orders : <Value>10</Value>
              </Count>
            </Tooltip>
          </Top>

          <Block>
            <ChartBlock>
              <Label>Today</Label>
              <IconBlock>
                <Tooltip title="Press to view orders">
                  <Icon onClick={() => navigateToDashboardLoads('To Pick Up')}>
                    <IconItem>
                      <DashboardSvg style={{ backgroundColor: '#E9EAF8' }}>
                        <DashboardArrowDown />
                      </DashboardSvg>
                      <IconText>100</IconText>
                    </IconItem>
                    <IconDesc>To Pick Up</IconDesc>
                  </Icon>
                </Tooltip>
                <Tooltip title="Press to view orders">
                  <Icon onClick={() => navigateToDashboardLoads('To Deliver')}>
                    <IconItem>
                      <DashboardSvg style={{ backgroundColor: '#B0DCFF' }}>
                        <DashboardToDeliver />
                      </DashboardSvg>
                      <IconText>100</IconText>
                    </IconItem>
                    <IconDesc>To Deliver</IconDesc>
                  </Icon>
                </Tooltip>
              </IconBlock>
              <StatisticBlock>
                <StatisticItem>Dynamic</StatisticItem>
                <StatisticDropDown onClick={showDropdownMenuToday}>
                  {valueToday}
                  {showMenuToday && (
                    <DropdownMenu ref={refToday}>
                      <MenuBlock>
                        <MenuItem onClick={() => setValueToday('This month')}>This month</MenuItem>
                        <MenuItem onClick={() => setValueToday('Last month')}>Last month</MenuItem>
                      </MenuBlock>
                    </DropdownMenu>
                  )}
                </StatisticDropDown>
              </StatisticBlock>
              <BarChartBlock>
                <BarChart />
              </BarChartBlock>
            </ChartBlock>
            <ChartBlock>
              <Label>Delayed</Label>
              <IconBlock>
                <Tooltip title="Press to view orders">
                  <Icon onClick={() => navigateToDashboardLoads('Delayed pickups')}>
                    <IconItem>
                      <DashboardSvg style={{ backgroundColor: '#FFEEB9' }}>
                        <DashboardDelayedPickups />
                      </DashboardSvg>
                      <IconText>100</IconText>
                    </IconItem>
                    <IconDesc>Pickups</IconDesc>
                  </Icon>
                </Tooltip>
                <Tooltip title="Press to view orders">
                  <Icon onClick={() => navigateToDashboardLoads('Delayed deliveries')}>
                    <IconItem>
                      <DashboardSvg style={{ backgroundColor: '#C3B9FF' }}>
                        <DashboardDelayedDelivers />
                      </DashboardSvg>
                      <IconText>100</IconText>
                    </IconItem>
                    <IconDesc>Deliveries</IconDesc>
                  </Icon>
                </Tooltip>
              </IconBlock>
              <StatisticBlock>
                <StatisticItem>Dynamic</StatisticItem>
                <StatisticDropDown onClick={showDropdownMenuDelayed}>
                  {valueDelayed}
                  {showMenuDelayed && (
                    <DropdownMenu ref={refDelayed}>
                      <MenuBlock>
                        <MenuItem onClick={() => setValueDelayed('This month')}>This month</MenuItem>
                        <MenuItem onClick={() => setValueDelayed('Last month')}>Last month</MenuItem>
                      </MenuBlock>
                    </DropdownMenu>
                  )}
                </StatisticDropDown>
              </StatisticBlock>
              <BarChartBlock>
                <DelayedBarChart />
              </BarChartBlock>
            </ChartBlock>
          </Block>
        </Wrapper>
      </Container>
    </>
  );
};

export default Dashboard;
