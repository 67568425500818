import { useEffect, useRef, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  Info,
  Image,
  ImageDefault,
  ImageName,
  Name,
  Actions,
  Edit,
  Delete,
  Line,
  Active,
  Deactivated,
  DriverInfo,
  DriverInfoText,
  DriverInfoTextDefault,
  Reactivate,
} from './driversItem.style';
import {
  deleteDriver,
  getDrivers,
  updateDriverStatus,
} from '../../../API/drivers';
import {
  EmailForm,
  PasswordForm,
  PersonForm,
  PhoneForm,
} from '../../../assets/icons';
import ActionModalDriver from '../../../layout/Modal/ModalDriver/ActionModalDriver';
import ModalDriver from '../../../layout/Modal/ModalDriver/ModalDriver';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showDriverInfo } from '../../../store/slices/driversSlice';

const DriversItem: React.FC = () => {
  const { driverInfo } = useAppSelector(
    (state: { driver: any }) => state.driver,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const refDriver = useRef<any>();

  const [title, setTitle] = useState<string>('');
  const [colorTitle, setColorTitle] = useState<string>('');
  const [text, setText] = useState<string[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [openDeactivate, setOpenDeactivate] = useState<boolean>(false);

  const openModal = () => setOpen(true);

  const openDeactivateModal = (
    value: string,
    color: string,
    text: string[],
  ) => {
    setOpenDeactivate(true);

    setTitle(value);
    setColorTitle(color);
    setText(text);
  };

  const handleConfirm = async () => {
    if (driverInfo.status === 'active')
      await dispatch(updateDriverStatus(driverInfo));
    else if (driverInfo.status === 'inactive' && title === 'Reactivate')
      await dispatch(updateDriverStatus(driverInfo));
    else await dispatch(deleteDriver(driverInfo.id));

    await dispatch(getDrivers(null));
    dispatch(showDriverInfo({}));

    setOpenDeactivate(false);
  };

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useEffect(() => {
    document.body.style.overflow = openDeactivate ? 'hidden' : 'unset';
  }, [openDeactivate]);

  useOnClickOutside(refDriver, () => setOpen(false));

  useOnClickOutside(refDriver, () => setOpenDeactivate(false));

  return (
    <Wrapper>
      {Object.keys(driverInfo).length !== 0 && (
        <>
          <Top>
            <Info>
              {driverInfo.image ? (
                <Image src={driverInfo.image} />
              ) : (
                <ImageDefault>
                  <ImageName
                    onClick={() => navigate(`/drivers/${driverInfo.id}`)}
                  >
                    {driverInfo.first_name[0]} {driverInfo.last_name[0]}
                  </ImageName>
                </ImageDefault>
              )}
              <Name onClick={() => navigate(`/drivers/${driverInfo.id}`)}>
                {driverInfo.first_name} {driverInfo.last_name}
                {driverInfo.status === 'active' ? (
                  <Active>Active</Active>
                ) : (
                  <Deactivated>Deactivated</Deactivated>
                )}
              </Name>
            </Info>
            <Actions>
              <Edit onClick={openModal}>Edit</Edit>
              {driverInfo.status === 'active' && (
                <Delete
                  onClick={() =>
                    openDeactivateModal('Deactivate', '#F9BB01', [
                      'All of the orders related to the driver will remain in your account.',
                      'The driver will not be able to login to Superdispatch driver app.',
                      'Your subsciption plan will be adjusted and you will not be charged for this driver.',
                      'You can reactivate this driver at any time in the future.',
                    ])
                  }
                >
                  Deactivate
                </Delete>
              )}
              {driverInfo.status === 'inactive' && (
                <Reactivate
                  onClick={() =>
                    openDeactivateModal('Reactivate', '#09091A', [
                      'The driver will be added to your current subscription plan.',
                      'You will be charged for this driver.',
                    ])
                  }
                >
                  Reactivate
                </Reactivate>
              )}
              {driverInfo.status === 'inactive' && (
                <Delete
                  onClick={() =>
                    openDeactivateModal('Delete', '#F30F00', [
                      'Removing a driver is permanent. This cannot be undone.',
                      'This driver will be removed from your drivers list.',
                      'The orders assigned to this driver will not be deleted but will be unassigned from them.',
                      'Your monthly fee will reduce starting the next billing cycle.',
                    ])
                  }
                >
                  Delete
                </Delete>
              )}
            </Actions>
          </Top>
          <Line />
          <DriverInfo>
            <EmailForm data={driverInfo.email} />{' '}
            <DriverInfoText style={{ color: '#1267CF' }}>
              {driverInfo.email}
            </DriverInfoText>
          </DriverInfo>
          <DriverInfo>
            <>
              <PasswordForm data={'true'} />{' '}
              <DriverInfoTextDefault style={{ color: '#09091A' }}>
                **********
              </DriverInfoTextDefault>
            </>
          </DriverInfo>
          <DriverInfo>
            {driverInfo.phone ? (
              <>
                <PhoneForm data={driverInfo.phone} />{' '}
                <DriverInfoText style={{ color: '#1267CF' }}>
                  {formatPhoneNumberIntl(driverInfo.phone)}
                </DriverInfoText>
              </>
            ) : (
              <>
                <PhoneForm data={driverInfo.phone} />{' '}
                <DriverInfoTextDefault>No phone number</DriverInfoTextDefault>
              </>
            )}
          </DriverInfo>
          <DriverInfo>
            {driverInfo.phone ? (
              <>
                <PersonForm data={driverInfo.phone} />{' '}
                <DriverInfoText style={{ color: '#1267CF' }}>
                  {driverInfo.phone}
                </DriverInfoText>
              </>
            ) : (
              <>
                <PersonForm data={driverInfo.phone} />{' '}
                <DriverInfoTextDefault>No phone number</DriverInfoTextDefault>
              </>
            )}
          </DriverInfo>
        </>
      )}

      {open && (
        <ModalDriver
          refDriver={refDriver}
          driverId={driverInfo.id}
          text="Edit driver"
          close={() => setOpen(false)}
        />
      )}

      {openDeactivate && (
        <ActionModalDriver
          refDriver={refDriver}
          handleConfirm={handleConfirm}
          title={title}
          colorTitle={colorTitle}
          text={text}
          driverFirstName={driverInfo.first_name}
          driverLastName={driverInfo.last_name}
          close={() => setOpenDeactivate(false)}
        />
      )}
    </Wrapper>
  );
};

export default DriversItem;
