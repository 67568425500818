import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;

  // @media screen and (max-width: 1200px) {
  //   overflow: scroll;
  //   height: 100%;
  // }

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const TrackingBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  // @media screen and (max-width: 1200px) {
  //   flex-direction: column;
  //   height: 100vh;
  // }
`;

export const ClientBlock = styled.div`
  border-radius: 8px;
  background-color: #fff;
  margin-right: 32px;

  // @media screen and (max-width: 1300px) {
  //   width: 100% !important;
  //   height: 90%;
  //   margin-right: 0;
  // }
`;

export const ClientItem = styled.div`
  height: 100%;
  border-radius: 8px;
  overflow: hidden;

  // @media screen and (max-width: 1200px) {
  //   margin-top: 44px;
  // }
`;
