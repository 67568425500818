import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export type Settings = {};

export type General = {
  bol_template: boolean;
  initial_load_id: string;
  timezone: string;
};

export type Terminal = {
  terminal_name: string;
  city: string;
  state: string;
  zip: string;
  contact_name: string;
  phone: string;
};

export type DriverApp = {
  fleet_drive_mode: boolean;
  hide_payment_info: boolean;
  super_loadboard: boolean;
  customer_not_available: boolean;
  sti: boolean;
  driver_pay: boolean;
  driver_edit_carrier: boolean;
};

export type Billing = {
  bol_in_invoice: boolean;
  points_to_bol: boolean;
  copy_invoice_email: string;
};

export type Factoring = {
  factoring_name: string;
  factoring_address: string;
  factoring_city: string;
  factoring_state: string;
  factoring_email: string;
  factoring_phone: string;
  factoring_fee: string;
};

export type Subscription = {
  subscription_card: string;
  subscription_date: string;
  subscription_name: string;
};

export type Notifications = {
  notification_emails: string;
  delivery_emails: string;
  notification_to_brokers: boolean;
};

export type Timezones = [];

export const routes = {
  getSettings: 'api/crm/user/settings',
  getTimezones: 'api/crm/user/settings/create',
  general: 'api/crm/user/settings/general',
  terminal: 'api/crm/user/settings/terminal',
  driverApp: 'api/crm/user/settings/driver_app',
  billing: 'api/crm/user/settings/billing',
  factoring: 'api/crm/user/settings/factoring',
  subscription: 'api/crm/user/settings/subscription',
  notifications: 'api/crm/user/settings/notification',
};

export const getSettings = createAsyncThunk<
  Settings,
  null,
  {
    rejectValue: ErrorType;
  }
>('settings/getSettings', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.getSettings}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getTerminal = createAsyncThunk<
  Terminal[],
  null,
  {
    rejectValue: ErrorType;
  }
>('settings/getTerminal', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.terminal}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getTimezones = createAsyncThunk<
  Timezones,
  null,
  {
    rejectValue: ErrorType;
  }
>('settings/getTimezones', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.getTimezones}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveGeneral = createAsyncThunk<
  null,
  General,
  {
    rejectValue: ErrorType;
  }
>('settings/general', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.general}`, {
      bol_template: data.bol_template,
      initial_load_id: data.initial_load_id,
      timezone: data.timezone,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveTerminal = createAsyncThunk<
  null,
  Terminal,
  {
    rejectValue: ErrorType;
  }
>('settings/terminal', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.terminal}`, {
      terminal_name: data.terminal_name,
      city: data.city,
      state: data.state,
      zip: data.zip,
      contact_name: data.contact_name,
      phone: data.phone,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveDriverApp = createAsyncThunk<
  null,
  DriverApp,
  {
    rejectValue: ErrorType;
  }
>('settings/driverApp', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.driverApp}`, {
      fleet_drive_mode: data.fleet_drive_mode,
      hide_payment_info: data.hide_payment_info,
      super_loadboard: data.super_loadboard,
      customer_not_available: data.customer_not_available,
      sti: data.sti,
      driver_pay: data.driver_pay,
      driver_edit_carrier: data.driver_edit_carrier,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveBilling = createAsyncThunk<
  null,
  Billing,
  {
    rejectValue: ErrorType;
  }
>('settings/saveBilling', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.billing}`, {
      bol_in_invoice: data.bol_in_invoice,
      points_to_bol: data.points_to_bol,
      copy_invoice_email: data.copy_invoice_email,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveFactoring = createAsyncThunk<
  null,
  Factoring,
  {
    rejectValue: ErrorType;
  }
>('settings/saveFactoring', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.factoring}`, {
      factoring_name: data.factoring_name,
      factoring_address: data.factoring_address,
      factoring_city: data.factoring_city,
      factoring_state: data.factoring_state,
      factoring_email: data.factoring_email,
      factoring_phone: data.factoring_phone,
      factoring_fee: data.factoring_fee,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveSubscription = createAsyncThunk<
  null,
  Subscription,
  {
    rejectValue: ErrorType;
  }
>('settings/saveSubscription', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.subscription}`, {
      subscription_card: data.subscription_card,
      subscription_date: data.subscription_date,
      subscription_name: data.subscription_name,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const saveNotifications = createAsyncThunk<
  null,
  Notifications,
  {
    rejectValue: ErrorType;
  }
>('settings/saveNotifications', async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.notifications}`, {
      notification_emails: data.notification_emails,
      delivery_emails: data.delivery_emails,
      notification_to_brokers: data.notification_to_brokers,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});
