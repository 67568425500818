import { NativeSelect } from '@mui/material';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`;

export const ErrorContent = styled.span`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const MUISelect = styled(NativeSelect)({
  height: '38px',
  border: '1px solid #c6c7da',
  borderRadius: '4px',
  padding: '0 8px',

  '& .MuiNativeSelect-select': {
    // minWidth: '100% !important',
  },

  '&:before': {
    borderBottom: 'none !important',
  },

  '&:after': {
    borderBottom: 'none !important',
  },
});

export const InvoiceField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InvoiceFieldHalf = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;
  width: 48%;
`;

export const InvoiceInput = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 8px 16px;
  border: ${(props) => (props.isError ? '1px solid #ff0000' : '1px solid #c6c7da')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InvoiceLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin: 0 0 4px;
`;

export const InputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  outline: none;
  max-width: 100%;
  width: 100%;
  max-height: 162px;
  margin: 0 auto;
`;

export const InputDate = styled(DatePicker)`
  width: 100%;
  min-height: 37px;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 7px 16px;
  background: transparent;
  cursor: pointer;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 85%;
  transform: translateY(-50%);
  right: 0;
  padding: 10px;
`;
