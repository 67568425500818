import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 88%;
  padding: 0 8px;

  // @media screen and (max-width: 1280px) {
  //   height: 82%;
  // }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 7px;
`;

export const Drivers = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
`;

export const DriversLength = styled.span`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;
`;

export const ActionList = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  margin: 12px auto;
  width: 95%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 16px;
  outline: none;
`;

export const ListWrapper = styled.div`
  height: calc(100vh - 320px);
  overflow: auto;

  // @media screen and (max-width: 1200px) {
  //   height: 40%;
  // }

  // @media screen and (max-width: 1023px) {
  //   height: calc(100% - 85px);
  // }

  // @media screen and (max-width: 600px) {
  //   height: calc(100% - 110px);
  // }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  overflow: hidden;
  border-radius: 4px;
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const ImageDefault = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9eaf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageName = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -1px;
  color: #09091a;
  text-transform: uppercase;
`;

export const Name = styled.p`
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #09091a;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
`;

export const ButtonWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
  padding: 16px 0;

  @media screen and (max-width: 1280px) {
    max-width: 100%;
  }
`;
