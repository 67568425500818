import * as React from "react"
import { SVGProps } from "react"

const AddressSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.667c0 1.911-1.063 3.656-2.29 4.924A13.735 13.735 0 0 1 8 13.066a13.733 13.733 0 0 1-1.71-1.475C5.063 10.323 4 8.578 4 6.667c0-2.273 1.81-4 4-4 2.189 0 4 1.727 4 4Zm-4 8s5.333-3.173 5.333-8A5.306 5.306 0 0 0 8 1.333a5.306 5.306 0 0 0-5.333 5.334c0 4.827 5.333 8 5.333 8Zm.666-8a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm1.334 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      fill="#9292A4"
    />
  </svg>
)

export default AddressSmall
