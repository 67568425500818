import React from 'react';
import 'react-phone-number-input/style.css';

import { Actions, Button, Line, Text, TitleAction, TopText, Wrapper } from './modalTrip.style';
import { deleteTrip, detachLoad, getTripId, getTrips } from '../../../API/trips';
import { useAppDispatch } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  tripId: number;
  typeData: string;
  load_id?: number;
  refTrips: any;
};

const ModalTripAction: React.FC<ModalContentProps> = ({ close, tripId, typeData, load_id, refTrips }) => {
  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    if (typeData === 'trip') await dispatch(deleteTrip(tripId));
    else {
      await dispatch(detachLoad([tripId, load_id || 0]));
      await dispatch(getTripId(tripId));
    }
    await dispatch(getTrips(null));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refTrips}>
        {typeData === 'trip' ? (
          <TopText>
            <TitleAction>Delete</TitleAction> this trip?
          </TopText>
        ) : (
          <TopText>
            <TitleAction>Remove</TitleAction> this load?
          </TopText>
        )}

        {typeData === 'trip' ? (
          <Text>Deleting the trip will not remove your orders.</Text>
        ) : (
          <Text>It will not delete the order.</Text>
        )}

        <Line />
        <Actions>
          <Button onClick={handleConfirm}>Yes</Button>
          <Button onClick={close}>No</Button>
        </Actions>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalTripAction;
