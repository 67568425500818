import * as React from "react"
import { SVGProps } from "react"

const CalendarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.75 2.875h-2.625v-1A.125.125 0 0 0 11 1.75h-.875a.125.125 0 0 0-.125.125v1H6v-1a.125.125 0 0 0-.125-.125H5a.125.125 0 0 0-.125.125v1H2.25a.5.5 0 0 0-.5.5V13.75a.5.5 0 0 0 .5.5h11.5a.5.5 0 0 0 .5-.5V3.375a.5.5 0 0 0-.5-.5Zm-.625 10.25H2.875V7.187h10.25v5.938Zm-10.25-7V4h2v.75c0 .069.056.125.125.125h.875A.125.125 0 0 0 6 4.75V4h4v.75c0 .069.056.125.125.125H11a.125.125 0 0 0 .125-.125V4h2v2.125H2.875Z"
      fill="#9292A4"
    />
  </svg>
)

export default CalendarSmall
