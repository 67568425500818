import { useNavigate } from 'react-router-dom';

import {
  BackButton,
  BackTitle,
  Block,
  Confirm,
  Footer,
  Reset,
} from './confirmReset.style';
import { Back } from '../../assets/icons';
import Header from '../../components/Header/Header';

const ConfirmReset: React.FC = () => {
  const navigation = useNavigate();

  return (
    <>
    <Header />
    <Block>
      <Reset>
        <BackButton onClick={() => navigation('/reset-password')}>
          <Back />
          <BackTitle>Reset Password</BackTitle>
        </BackButton>
        <Confirm>
          Thank you! The request has been sent if such an email exists, please
          wait for a message from the manager.
        </Confirm>
      </Reset>
      <Footer>
        © {new Date().getFullYear()} Turbo dispatch. All rights reserved.
      </Footer>
    </Block>
    </>
  );
};

export default ConfirmReset;
