import React from 'react';

import { FlexBetween, FlexTopBetween, LoadsBlock, LoadsEdit, LoadsTextRegular, LoadsTitle } from '../loadsItem.style';

type LoadsItemProps = {
  setTextAreaModal?: () => void;
  setTextAreaTitle?: () => void;
  loadInfo: any;
};

const LoadsItemInternalNotes: React.FC<LoadsItemProps> = ({ setTextAreaModal, setTextAreaTitle, loadInfo }) => {
  return (
    <LoadsBlock overflow>
      <FlexBetween>
        <LoadsTitle>Internal Notes</LoadsTitle>
        {setTextAreaModal && setTextAreaTitle && (
          <LoadsEdit
            className="noPrint"
            onClick={() => {
              setTextAreaModal();
              setTextAreaTitle();
            }}
          >
            Edit
          </LoadsEdit>
        )}
      </FlexBetween>
      <FlexTopBetween>
        <LoadsTextRegular textWrap>
          {loadInfo?.load?.internal_notes ? loadInfo?.load?.internal_notes : 'No internal notes'}
        </LoadsTextRegular>
      </FlexTopBetween>
    </LoadsBlock>
  );
};

export default LoadsItemInternalNotes;
