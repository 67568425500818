import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';

import {
  Wrapper,
  Block,
  BlockScroll,
  LoadsBlock,
  LoadsTitle,
  LoadsFlex,
  LoadsInputField,
  LoadsInputFieldHalf,
  SelectedValue,
  FieldIcon,
  LoadsSelect,
  Opt,
  LoadsInput,
  InputDate,
  LoadsLabel,
  InputTextArea,
  LoadsFlexColumn,
  LoadsImagePlace,
  LoadsInputFile,
  InputFile,
  BlueButton,
  DeliverTextEmpty,
  CheckBoxItem,
  CheckBoxSquare,
  CustomCheckBoxSquare,
  TextPrice,
  Line,
  Bottom,
  Actions,
  Cancel,
  Error,
  ImagesError,
} from './modalExpenses.style';
import { addExpense, getLoadData, updateExpense } from '../../../../API/loads';
import { Calendar, Check, SelectArrow } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalExpensesProps = {
  close: () => void;
  addCheck: boolean;
  elem?: any;
  refExpense: any;
};

const ModalExpenses: React.FC<ModalExpensesProps> = ({ close, addCheck, elem, refExpense }) => {
  const { loadId, loadInfo } = useAppSelector((state: { loads: any }) => state.loads);

  let formData: any = new FormData();

  const [err, setErr] = useState({
    message: '',
    errors: {
      price: [],
      images: [],
    },
  });

  const [loadsExpense, setLoadsExpense] = useState({
    price: addCheck ? '' : elem.price,
    specifyType: addCheck ? '' : elem.specify_type,
  });
  const images = addCheck ? [] : elem.images;

  const [priceSign, setPriceSign] = useState<boolean>(false);
  const [updatedImages, setUpdatedImages] = useState<any[]>([]);

  const [receiptDateType, setReceiptDateType] = useState<Date>(addCheck ? new Date() : new Date(elem.date));

  const [invoice, setInvoice] = useState<boolean>(addCheck ? false : elem.invoice);
  const [driverPay, setDriverPay] = useState<boolean>(addCheck ? false : elem.driver_pay);

  const [expenseType, setExpenseType] = useState<string>(
    addCheck ? loadInfo?.expense_types[0].title : elem.expense_type.title
  );
  const [expenseTypeId, setExpenseTypeId] = useState<number>(
    addCheck ? loadInfo?.expense_types[0].id : elem.expense_type.id
  );

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state: any) => {
    setState({ ...state, [name]: e.target.value });
  };

  const handleInputTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, setState: any, name: any, state: any) =>
    setState({ ...state, [name]: e.target.value });

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const handleCheckboxExpense = (e: React.ChangeEvent<HTMLInputElement>, setState: any, elem: any) => {
    setState(!elem);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.from(event.target.files || []);

    fileList.forEach((elem: any) => {
      if (elem.size / 1024 / 1024 > 2) {
        alert(`${elem.name} is too big`);
      } else if (!['image/png', 'image/jpeg', 'image/jpg'].includes(elem.type)) {
        alert(`${elem.name} must be png, jpeg, jpg format`);
      } else {
        setUpdatedImages([...updatedImages, elem]);
      }
    });
  };

  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    formData.append('price', loadsExpense.price);
    formData.append('expense_type_id', expenseTypeId);
    formData.append('date', moment(receiptDateType).format('DD.MM.YYYY'));
    formData.append('specify_type', loadsExpense.specifyType ? loadsExpense.specifyType : '');
    formData.append('invoice', invoice ? 1 : 0);
    formData.append('driver_pay', driverPay ? 1 : 0);
    updatedImages.forEach((elem: any) => {
      return formData.append('images[]', elem);
    });

    if (addCheck) {
      const res: any = await dispatch(addExpense([formData, loadId.id]));
      if (res.type === 'loads/addExpense/rejected') return setErr(res.payload);
      await dispatch(getLoadData(loadId.id));
      close();
    } else {
      const res: any = await dispatch(updateExpense([formData, loadId.id, elem.id]));
      if (res.type === 'loads/updateExpense/rejected') return setErr(res.payload);
      await dispatch(getLoadData(loadId.id));
      close();
    }
  };

  return (
    <ModalPortal>
      <Wrapper ref={refExpense}>
        <LoadsBlock>
          <LoadsTitle>Add Expense</LoadsTitle>
          <Line />
          <Block>
            <BlockScroll>
              <LoadsInputField>
                <LoadsLabel htmlFor="expenseType">Type</LoadsLabel>
                <SelectedValue>{expenseType}</SelectedValue>
                <FieldIcon>
                  <SelectArrow />
                </FieldIcon>
                <LoadsSelect
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    changeSelectState(e, setExpenseType, setExpenseTypeId)
                  }
                >
                  <Opt hidden defaultValue></Opt>
                  {loadInfo?.expense_types?.map((item: any) => (
                    <Opt key={item.id} value={item.title} data-value={item.id}>
                      {item.title}
                    </Opt>
                  ))}
                </LoadsSelect>
              </LoadsInputField>
              <LoadsFlex>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="price">Price</LoadsLabel>
                  <TextPrice
                    style={{
                      color: priceSign || loadsExpense.price ? '#09091A' : '#C6C7DA',
                    }}
                  >
                    $
                  </TextPrice>
                  <LoadsInput
                    id="price"
                    type="number"
                    style={{
                      border: err.errors?.price?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                      paddingLeft: '20px',
                    }}
                    onFocus={() => {
                      setErr({ ...err, errors: { ...err.errors, price: [] } });
                      setPriceSign(true);
                    }}
                    onBlur={() => setPriceSign(false)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, setLoadsExpense, 'price', loadsExpense)
                    }
                    value={loadsExpense.price}
                  />
                  <Error>{err.errors?.price?.[0]}</Error>
                </LoadsInputFieldHalf>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="receiptDateExpense">Receipt Date</LoadsLabel>
                  <FieldIcon>
                    <Calendar />
                  </FieldIcon>
                  <InputDate
                    id="receiptDateExpense"
                    dateFormat="MM/dd/yyyy"
                    selected={receiptDateType}
                    onChange={(date: any) => setReceiptDateType(date)}
                  />
                </LoadsInputFieldHalf>
              </LoadsFlex>
              <LoadsInputField>
                <LoadsLabel htmlFor="specifyType">Specify Type</LoadsLabel>
                <InputTextArea
                  id="specifyType"
                  rows={2}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    handleInputTextArea(e, setLoadsExpense, 'specifyType', loadsExpense)
                  }
                  value={loadsExpense.specifyType}
                />
              </LoadsInputField>
              <LoadsImagePlace>
                <LoadsInputFile
                  onFocus={() => {
                    setErr((_err) => ({ ..._err, errors: { ..._err.errors, images: [] } }));
                  }}
                >
                  <InputFile
                    accept="image/png, image/jpeg, image/jpg"
                    type="file"
                    multiple
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
                  />
                  <LoadsFlexColumn>
                    <BlueButton>Upload Receipt File</BlueButton>
                    <DeliverTextEmpty>or Drag & Drop files</DeliverTextEmpty>
                  </LoadsFlexColumn>
                </LoadsInputFile>
                <ImagesError>{err.errors?.images?.[0]}</ImagesError>
                {[...images, ...updatedImages]?.map((elem: any, i: number) => {
                  let href;
                  if (!elem?.image) {
                    href = URL.createObjectURL(elem);
                  } else {
                    href = elem.image;
                  }

                  return (
                    <a
                      style={{ textDecoration: 'none', color: '#0e71eb' }}
                      key={elem.id}
                      target="_blank"
                      rel="noreferrer"
                      href={href}
                    >
                      file {i}
                    </a>
                  );
                })}
              </LoadsImagePlace>
              <LoadsInputFieldHalf
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CheckBoxItem>
                  <CheckBoxSquare
                    type="checkbox"
                    name="invoice"
                    id="invoice"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckboxExpense(e, setInvoice, invoice)}
                    checked={invoice}
                  />
                  <CustomCheckBoxSquare>
                    <Check right="3px" />
                  </CustomCheckBoxSquare>
                </CheckBoxItem>
                <LoadsLabel htmlFor="invoice" style={{ marginBottom: 0, marginLeft: '12px' }}>
                  Show expense on invoice
                </LoadsLabel>
              </LoadsInputFieldHalf>
              <LoadsInputFieldHalf
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CheckBoxItem>
                  <CheckBoxSquare
                    type="checkbox"
                    name="driverPay"
                    id="driverPay"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleCheckboxExpense(e, setDriverPay, driverPay)}
                    checked={driverPay}
                  />
                  <CustomCheckBoxSquare>
                    <Check right="3px" />
                  </CustomCheckBoxSquare>
                </CheckBoxItem>
                <LoadsLabel htmlFor="driverPay" style={{ marginBottom: 0, marginLeft: '12px' }}>
                  Deduct from driver pay
                </LoadsLabel>
              </LoadsInputFieldHalf>
            </BlockScroll>
          </Block>
        </LoadsBlock>

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalExpenses;
