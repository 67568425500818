import React, { ChangeEvent } from 'react';

import {
  Error,
  FieldIcon,
  Flex,
  FlexSelect,
  Input,
  InputDate,
  InputField,
  InputFieldHalf,
  Label,
  Opt,
  Select,
  SelectedValueWithoutBorders,
  Wrapper,
} from './general.style';
import { Calendar, SelectArrow } from '../../../../assets/icons';
import Checkbox from '../../../../components/Checkbox/Checkbox';

type GeneralProps = {
  orderSelect: { title: string; value: string }[];
  includeSelect: { title: string; value: string }[];
  statusSelect: { title: string; value: string }[];
  email: string;
  setEmail: any;
  createDate: Date[];
  setCreateDate: any;
  deliveryDate: Date[];
  setDeliveryDate: any;
  order: string;
  setOrder: any;
  setOrderValue: any;
  include: string;
  setInclude: any;
  setIncludeValue: any;
  status: string;
  setStatus: any;
  setStatusValue: any;
  noDriver: boolean;
  setNoDriver: any;
  removedDrivers: boolean;
  setRemovedDrivers: any;
  err: any;
  setErr: any;
};

const General: React.FC<GeneralProps> = ({
  orderSelect,
  includeSelect,
  statusSelect,
  email,
  setEmail,
  createDate,
  setCreateDate,
  deliveryDate,
  setDeliveryDate,
  order,
  setOrder,
  setOrderValue,
  include,
  setInclude,
  setIncludeValue,
  status,
  setStatus,
  setStatusValue,
  noDriver,
  setNoDriver,
  removedDrivers,
  setRemovedDrivers,
  err,
  setErr,
}) => {
  const changeSelectState = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
    setStateIndex?: any,
  ) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const [createStartDate, createEndDate] = createDate;
  const [deliveryStartDate, deliveryEndDate] = deliveryDate;

  return (
    <Wrapper>
      <InputField>
        <Label htmlFor="email">Reciever email</Label>
        <Input
          id="email"
          type="email"
          style={{
            border: err.errors?.receiver_email?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({ ...err, errors: { ...err.errors, receiver_email: [] } })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          value={email}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.receiver_email?.[0]}
        </Error>
      </InputField>
      <Flex>
        <InputFieldHalf>
          <Label htmlFor="createDate">Filter by creation period</Label>
          <FieldIcon>
            <Calendar />
          </FieldIcon>
          <InputDate
            selectsRange={true}
            startDate={createStartDate}
            endDate={createEndDate}
            dateFormat="MM/dd/yyyy"
            onChange={(update: any) => setCreateDate(update)}
          />
        </InputFieldHalf>
        <InputFieldHalf>
          <Label htmlFor="deliveryDate">Filter by delivery period</Label>
          <FieldIcon>
            <Calendar />
          </FieldIcon>
          <InputDate
            selectsRange={true}
            startDate={deliveryStartDate}
            endDate={deliveryEndDate}
            dateFormat="MM/dd/yyyy"
            onChange={(update: any) => setDeliveryDate(update)}
          />
        </InputFieldHalf>
      </Flex>
      <FlexSelect>
        <Label htmlFor="order">Order by</Label>
        <InputField style={{ marginBottom: 0 }}>
          <SelectedValueWithoutBorders>{order}</SelectedValueWithoutBorders>
          <FieldIcon>
            <SelectArrow />
          </FieldIcon>
          <Select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              changeSelectState(e, setOrder, setOrderValue);
            }}
          >
            {orderSelect.map((elem: any, i: number) => {
              return (
                <Opt key={i} value={elem.title} data-value={elem.value}>
                  {elem.title}
                </Opt>
              );
            })}
          </Select>
        </InputField>
      </FlexSelect>
      <FlexSelect>
        <Label htmlFor="include">Include</Label>
        <InputField style={{ marginBottom: 0 }}>
          <SelectedValueWithoutBorders>{include}</SelectedValueWithoutBorders>
          <FieldIcon>
            <SelectArrow />
          </FieldIcon>
          <Select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              changeSelectState(e, setInclude, setIncludeValue);
            }}
          >
            {includeSelect.map((elem: any, i: number) => {
              return (
                <Opt key={i} value={elem.title} data-value={elem.value}>
                  {elem.title}
                </Opt>
              );
            })}
          </Select>
        </InputField>
      </FlexSelect>
      <FlexSelect>
        <Label htmlFor="status">Status</Label>
        <InputField style={{ marginBottom: 0 }}>
          <SelectedValueWithoutBorders>{status}</SelectedValueWithoutBorders>
          <FieldIcon>
            <SelectArrow />
          </FieldIcon>
          <Select
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              changeSelectState(e, setStatus, setStatusValue);
            }}
          >
            {statusSelect.map((elem: any, i: number) => {
              return (
                <Opt key={i} value={elem.title} data-value={elem.value}>
                  {elem.title}
                </Opt>
              );
            })}
          </Select>
        </InputField>
      </FlexSelect>
      <Checkbox
        title="No driver (not assigned to any driver)"
        checkboxName="noDriver"
        checked={noDriver}
        setChecked={() => setNoDriver(!noDriver)}
      />
      <Checkbox
        title="Removed drivers"
        checkboxName="removedDrivers"
        checked={removedDrivers}
        setChecked={() => setRemovedDrivers(!removedDrivers)}
      />
    </Wrapper>
  );
};

export default General;
