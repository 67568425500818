import * as React from 'react';
import { SVGProps } from 'react';

type DataProps = {
  data?: string;
};

const ContactsForm: React.FC<DataProps> = (
  { data },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M3.889 4.074c0-.41.342-.741.764-.741h13.75c.422 0 .764.332.764.74v8.89c0 .409-.342.74-.764.74H4.653a.753.753 0 0 1-.764-.74v-8.89Zm12.986 8.148a.753.753 0 0 0 .764-.74V5.554c0-.409-.342-.74-.764-.74H6.181a.753.753 0 0 0-.764.74v5.926c0 .41.342.74.764.74h10.694Zm-.764 3.704c0-.41-.342-.741-.764-.741H3.125a.753.753 0 0 1-.764-.74V7.036c0-.41-.342-.741-.764-.741a.753.753 0 0 0-.764.74v8.89c0 .409.342.74.764.74h13.75a.753.753 0 0 0 .764-.74Zm-8.403-8.89c0-.408.342-.74.764-.74s.764.332.764.74c0 .41-.342.741-.764.741a.753.753 0 0 1-.764-.74ZM10 10c0-.41-.342-.741-.764-.741H7.708a.753.753 0 0 0-.764.74c0 .41.342.741.764.741h1.528A.753.753 0 0 0 10 10Zm1.528-2.963c0-.41.342-.741.764-.741h3.055c.422 0 .764.332.764.74 0 .41-.342.741-.764.741h-3.055a.753.753 0 0 1-.764-.74ZM16.11 10c0-.41-.342-.741-.764-.741h-3.055a.753.753 0 0 0-.764.74c0 .41.342.741.764.741h3.055a.753.753 0 0 0 .764-.74Z"
      fill={data ? '#616172' : '#C6C7DA'}
    />
  </svg>
);

export default ContactsForm;
