import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { HelpCenter, sendMessage } from '../../API/helpCenter';

type InitialStateType = {
  data: HelpCenter;
  loading: boolean;
  error: ErrorType | null;
};

const initialState: InitialStateType = {
  data: {
    name: '',
    email: '',
    phone: '',
    message: '',
  },
  loading: false,
  error: null,
};

export const helpCenterSlice = createSlice({
  name: 'helpCenter',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(sendMessage.pending, state => {
      state.loading = true;
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export default helpCenterSlice.reducer;
