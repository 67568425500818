import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  Wrapper,
  CheckboxBlock,
  Title,
  LearnMore,
  InputField,
  Label,
  Input,
  BottomCaption,
  SelectedValue,
  FieldIcon,
  Select,
  Opt,
  Text,
  BoldTerms,
  Mail,
  Terms,
  Bottom,
  Actions,
} from './general.style';
import { getTimezones, saveGeneral } from '../../../API/settings';
import { SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const General: React.FC = () => {
  const dispatch = useAppDispatch();
  const { timezones, settings } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  const [bol, setBol] = useState<boolean>(
    settings ? settings.bol_template : false,
  );
  const [initialLoadID, setInitialLoadID] = useState<string>(
    settings ? settings.initial_load_id : '',
  );
  const [timeZone, setTimeZone] = useState<string>(
    settings?.timezone ? settings?.timezone : timezones[0],
  );

  const changeSelectState = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
  ) => {
    setState(e.target.value);
  };

  const handleConfirm = () =>
    dispatch(
      saveGeneral({
        bol_template: bol,
        initial_load_id: initialLoadID,
        timezone: timeZone,
      }),
    );

  useEffect(() => {
    setTimeZone(settings?.timezone);
  }, [settings]);

  useEffect(() => {
    dispatch(getTimezones(null));
  }, []);

  return (
    <Wrapper>
      <CheckboxBlock>
        <Title>BOL Template</Title>
        <Checkbox
          title="Enable multiple unit BOL template for orders that contain more than one vehicle."
          checkboxName="bol"
          checked={bol}
          setChecked={() => setBol(!bol)}
        />
        <LearnMore>Learn more...</LearnMore>
      </CheckboxBlock>
      <InputField>
        <Label htmlFor="initialLoadID">Initial Load ID</Label>
        <Input
          id="initialLoadID"
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setInitialLoadID(e.target.value)
          }
          value={initialLoadID}
        />
      </InputField>
      <BottomCaption>
        Used as an initial value on calculating Internal Load ID and Invoice ID.
      </BottomCaption>
      <InputField>
        <Label htmlFor="timeZone">Timezone*</Label>
        <SelectedValue>{timeZone}</SelectedValue>
        <FieldIcon>
          <SelectArrow />
        </FieldIcon>
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            changeSelectState(e, setTimeZone)
          }
        >
          <Opt hidden></Opt>
          {timezones?.map((item: any) => (
            <Opt key={item} value={item}>
              {item}
            </Opt>
          ))}
        </Select>
      </InputField>
      <BottomCaption>
        Date and time in your dashboard is represented in this timezone.
      </BottomCaption>
      <Text>
        Import dispatch sheets directly to your Dashboard by emailing them as an
        attachment to:{' '}
        <Mail href="mailto:zJlbHYpoVl@in.turbodispatch.com">
          zJlbHYpoVl@in.turbodispatch.com
        </Mail>
      </Text>
      <LearnMore>Learn more...</LearnMore>
      <Terms>
        Turbo dispatch <BoldTerms>Terms</BoldTerms> &{' '}
        <BoldTerms>Conditions</BoldTerms>
      </Terms>
      <Bottom>
        <Actions>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </Actions>
      </Bottom>
    </Wrapper>
  );
};

export default General;
