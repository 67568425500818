import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export type Dashboard = {};

export const routes = {
    dashboard: 'api/crm/dashboard',
  };
  
  export const getDashboard = createAsyncThunk<
    Dashboard,
    null,
    {
      rejectValue: ErrorType;
    }
  >('contacts/getDashboard', async (_, { rejectWithValue }) => {
    try {
      const response = await api.get(`${routes.dashboard}`);
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue({
        message: e.message,
      } as ErrorType);
    }
  });